import '../../App.scss';
import React, {useEffect, useContext, createRef, useState} from 'react';
import { useSearchParams } from "react-router-dom";
import {BaseContext, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import SubscriptionListSection from '../../components/SubscriptionListSection';
import {serverFetch} from "../../helpers/server";
const _ = require('lodash');

function Subscriptions() {
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const subscriptionListRef = createRef();
    const [subscriptionMetricsSummary, setSubscriptionMetricsSummary] = useState(null)
    const [ searchParams ] = useSearchParams();
    let product_pricing_id = searchParams.get('pp');
    let bundle_pricing_id = searchParams.get('bpp');
    let customer_id = searchParams.get('customer_id');
    let statuses = searchParams.get('statuses');
    const hasParams = customer_id != null || product_pricing_id != null;
    const [disableInitialFilters, setDisableInitialFilters] = useState(product_pricing_id != null || customer_id != null);
    const [extraQueryFilters, setExtraQueryFilters] = useState({
        product_pricing_id: product_pricing_id || null,
        customer_id: customer_id || null,
    });

    useEffect(() => {
        setPageTitle(`Subscriptions`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/reports/subscription_metrics_summary`)).then(res => {
            setSubscriptionMetricsSummary(res)
        })
    }, []);

    useEffect(() => {
        setDisableInitialFilters(product_pricing_id != null || customer_id != null);
        setExtraQueryFilters({
            product_pricing_id: product_pricing_id || null,
            bundle_pricing_id: bundle_pricing_id || null,
            customer_id: customer_id || null,
        })
    }, [customer_id, product_pricing_id, statuses]);

    return (
        <BaseContainer>
            <ContentContainer>
                <SubscriptionListSection
                    showEmptyState={true}
                    ref={subscriptionListRef}
                    includeDescription={true}
                    disableInitialFilters={disableInitialFilters}
                    defaultSelectedFilters={!_.isNil(statuses) ? { statuses: statuses.split(",") }: null}
                    metricsSummary={!hasParams && subscriptionMetricsSummary}
                    extraQueryFilters={extraQueryFilters}
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Subscriptions;
