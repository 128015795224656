import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Button, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
const _ = require("lodash");

function SingleContractEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [contract, setContract] = useState({});

    useEffect(() => {
        setInitialFields({
            email: props.contract && getEmails(),
            cc_email: ""
        })
        setContract(props.contract)
    }, [props.contract])

    const onSubmit = (data) => {
        const emails = _.filter(_.map(data.email.split(","), (e) => e.trim()), e => e);
        const ccEmails = _.filter(_.map(data.cc_email.split(","), (e) => e.trim()), e => e);
        const emailData = {
            emails: emails,
            cc_emails: ccEmails,
            message: data.message
        };
        serverPost(getApiUrl(`/proposals/${contract.id}/send_signature_emails`), emailData).then((res) => {
            props.onClose();
        });
    }

    const getEmails = () => {
        let emails = ""
        if (props.contract.status === "PENDING_COUNTER_SIGNATURES" && !_.isEmpty(props.contract.counter_signatories)) {
            emails = _.join(_.map(props.contract.counter_signatories, s => s.company_user.user.email), ",")
        } else if (!_.isEmpty(props.contract.signatories)) {
            emails = _.join(_.map(props.contract.signatories, s => s.email), ",")
        }
        return emails
    }

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Send Contract Email</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Row>
                        <Alert variant="info">
                            Feel free to add as many emails as you like. Separate each email with a comma such as "one@maplebilling.com, two@maplebilling.com, three@maplebilling.com"
                        </Alert>
                        <BaseForm.Input colSpan={12} name="email" label="Email Addresses" type="text" placeholder="Email addresses" transformations={["lowercase"]} validations={{required: true, validEmails: true}} />
                        <BaseForm.Input colSpan={12} name="cc_email" description="The cc emails will be added to each of the emails sent to the addresses above" label="CC Email Addresses" type="text" placeholder="CC Email addresses" transformations={["lowercase"]} validations={{validEmails: true}} />
                        <BaseForm.Input colSpan={12} name="message" label="Customer Message (Optional)" type="textarea" placeholder="Custom message to the customer" />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton>Send</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default SingleContractEmailModal;
