import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import {getItemTypeOptions} from '../../helpers/common';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditBillableItemModal(props) {
    const { t } = useTranslation('common');

    const cleanupFields = (fields) => {
        if (props.itemToEdit && !props.itemToEdit.editable) {
            delete fields.type;
        }
        return fields;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            processUpdateFields={cleanupFields}
            itemLabel={t('billable_item.item')}
            itemBaseUrl="/billable/items"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input
                    colSpan="12" name="external_name" label={"Public Name (optional)"} type="text"
                    description="This will display in communications to your customers, if different from the name."
                />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="textarea" />
                <BaseForm.Input
                    colSpan="12" name="type" label={t('billable_item.item_type')} type="select"
                    options={getItemTypeOptions()} showSearch={false} disabled={props.itemToEdit && !props.itemToEdit.editable}
                />
                {
                    props.itemToEdit && !props.itemToEdit.editable &&
                        <span className="text-sm font-normal text-gray-700 italic">*The type can't be changed once an item is attached to a metric or a price.</span>
                }
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBillableItemModal;
