import '../../App.scss';
import '../../css/contract.scss';
import React, {useEffect, useContext, useState} from 'react';
import {serverFetch, serverPost} from '../../helpers/server';
import { BaseContext, downloadBlob, ContractContext } from '../../helpers/common';
import ContentContainer from '../../components/ContentContainer';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import variables from "../../App.scss";
const _ = require('lodash');

function ContractDone() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();

    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const { contract } = useContext(ContractContext);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        setPageTitle(`Contract - ${contract.title} - Done`);

        if (contract.options && contract.options.completion_action === "REDIRECT" && contract.options.redirect_url) {
            let urlParser = new URL(contract.options.redirect_url);
            urlParser.searchParams.set('customer_id', contract.customer_id);
            window.location = urlParser.href
        }
    }, [contract]);

    useEffect(() => {
        localStorage.removeItem(`proposal_${uuid}_customer_token`);
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, [])

    const renderContract = () => {
        let brandColor = variables.primaryColor;
        if (settings.branding) {
            brandColor = settings.branding.color || variables.primaryColor;
        }
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-file-contract fa-6x" style={{color: brandColor}}/>
                    <h3 className="gray4">All set! Contract is Signed</h3>
                    <div className="body1 gray4">{ contract.title }</div>
                    {
                        (contract.status === "EXECUTED" || contract.status === "AWAITING_MANUAL_ACTION") &&
                            <span className="body1 text-center gray3" style={{ maxWidth: "400px" }}>
                                Thank you for signing the contract. { company.name } will reach out
                                about next steps. Meanwhile, a PDF copy of the contract will be emailed to you.</span>
                    }
                    {
                        (contract.status === "PENDING_SIGNATURES" || contract.status === "PENDING_COUNTER_SIGNATURES") &&
                            <span className="body1 text-center gray3" style={{ maxWidth: "400px" }}>
                                Thank you for signing the contract. We are still waiting for other parties to sign. We
                                will let you know as soon as it is complete.</span>
                    }
                    {
                        contract.options.confirmation_message &&
                            <span className="body1 text-center gray3 mt-2">{ contract.options.confirmation_message }</span>
                    }
                </div>
            </div>
        );
    }

    const renderExpired = () => {
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-file-contract danger-color fa-4x"/>
                    <h3 className="gray4">Contract expired</h3>
                    <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, this contract has expired. Please reach out to the person who send you the contract for further details.</div>
                </div>
            </div>
        )
    }

    return (
        <ContentContainer className="full" bodyClassName="full">
            {
                contract.isExpired ?
                    renderExpired()
                : renderContract()
            }
        </ContentContainer>
    )
}

export default ContractDone;
