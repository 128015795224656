import $ from "jquery";
import moment from 'moment';
import React, { createContext } from 'react';
import Label from '../components/Label';
import Link from "../components/Link";
const _ = require("lodash");

export function renderStatusLabel(invoice, getCompanySpecificUrl) {
    if (invoice.status === "DRAFT") {
        return (<Label.Info>Draft</Label.Info>);
    } else if (invoice.status === "PENDING") {
        if (moment(invoice.due_date).isBefore(moment())) {
            return (<Label.Danger>Overdue</Label.Danger>);
        } else {
            return (<Label.Info>Pending</Label.Info>);
        }
    } else if (invoice.status === "PAID") {
        return (<Label.Success>Paid</Label.Success>);
    } else if (invoice.status === "PAYMENT_PROCESSING") {
        return (<Label.Warning>Payment Processing</Label.Warning>);
    } else if (invoice.status === "VOID") {
        return (<Label.Info>Void</Label.Info>);
    } else if (invoice.status === "ROLLUP_PENDING") {
        return (
            <div className="flex flex-row gap-2">
                <Label.Info>Rollup Pending</Label.Info>
                <Link href={getCompanySpecificUrl(`/customer/${invoice.billed_customer_id}/next_rollup_invoice/${_.toLower(invoice.currency)}`)}>View estimate</Link>
            </div>
        );
    } else if (invoice.status === "ROLLUP_COMPLETE") {
        return (
            <div className="flex flex-row gap-2">
                <Label.Info>Rolled up</Label.Info>
                <Link href={getCompanySpecificUrl(`/invoice/${invoice.parent_invoice_uuid}`)}>View invoice</Link>
            </div>
        );
    }
    return (
        <Label.Info>{invoice.status}</Label.Info>
    )
}

export function getShareLinkUrl(invoice, getCompanySpecificUrl) {
    const relativeUrl = getCompanySpecificUrl(`/invoice/view?uuid=${invoice.uuid}`);
    const baseUrl = process.env.REACT_APP_BASE_WEB_URL || "https://app.maplebilling.com";
    return `${baseUrl}${relativeUrl}`
}

const creditNoteReasonOptions = [
    { value: null, label: "Select a reason" },
    { value: "ORDER_CHANGE", label: "Order Change" },
    { value: "DUPLICATE", label: "Duplicate" },
    { value: "FRAUDULENT", label: "Fraudulent" },
    { value: "UNSATISFACTORY", label: "Product Unsatisfactory" },
    { value: "OTHER", label: "Other" },
]
export function getCreditNoteReasonOptions() {
    return creditNoteReasonOptions;
}

export function getCreditNoteReasonDescription(reason) {
    const existingReason = _.find(creditNoteReasonOptions, (op) => op.value === reason);
    if (existingReason) {
        return existingReason.label;
    } else {
        return reason
    }
}

const creditNoteRefundTypeOptions = [
    { value: "CUSTOMER_CREDIT", label: "Credit customer's account" },
    { value: "ISSUE_NEW_REFUND", label: "Issue refund" },
    { value: "ATTACH_REFUND_TRANSACTION", label: "Attach to existing refund" },
]
export function getCreditNoteRefundTypeOptions() {
    return creditNoteRefundTypeOptions;
}

export function getCreditNoteRefundTypeDescription(reason) {
    const existingReason = _.find(creditNoteRefundTypeOptions, (op) => op.value === reason);
    if (existingReason) {
        return existingReason.label;
    } else {
        return reason
    }
}
