import React, { useContext, useEffect, useMemo, useState } from "react";
import Section from "../../components/Section";
import { BaseContext, CustomerContext, CustomerPortalContext } from "../../helpers/common";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { isEqual } from "lodash";
import usePostFindCreditLogsApi from "../../helpers/hooks/api/usePostFindCreditLogsApi";
import CustomerPortalCreditLogsTable from "../../components/CustomerPortalCreditLogsTable";
import { useNavigate } from "react-router-dom";

const DEFAULT_SELECTED_CREDIT_LOGS_FILTERS = {
    type: "ALL",
    action_type: "ALL",
};

function CustomerPortalCreditLogs() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation("common");
    const { setPageTitle } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const { portal } = useContext(CustomerPortalContext);
    const showCredits = portal?.options?.credits?.show;

    useEffect(() => {
        if (!showCredits) {
            navigate(-1);
        }
    }, [showCredits]);

    const [creditLogsSortKey, setCreditLogsSortKey] = useState("createdAtDesc");
    const creditLogsFilterOptions = useMemo(
        () => [
            {
                title: t("credit_logs.table.filter.type.title"),
                type: "select",
                name: "type",
                options: [
                    {
                        value: "ALL",
                        label: t("credit_logs.table.filter.type.all"),
                    },
                    {
                        value: "AMOUNT",
                        label: t("credit_logs.table.filter.type.amount"),
                    },
                    {
                        value: "UNITS",
                        label: t("credit_logs.table.filter.type.units"),
                    },
                ],
            },
            {
                title: t("credit_logs.table.filter.action_type.title"),
                type: "select",
                name: "action_type",
                options: [
                    {
                        value: "ALL",
                        label: t("credit_logs.table.filter.action_type.all"),
                    },
                    {
                        value: "ISSUED",
                        label: t("credit_logs.table.filter.action_type.issued"),
                    },
                    {
                        value: "CONSUMED",
                        label: t("credit_logs.table.filter.action_type.consumed"),
                    },
                    {
                        value: "EXPIRED",
                        label: t("credit_logs.table.filter.action_type.expired"),
                    },
                    {
                        value: "REVOKED",
                        label: t("credit_logs.table.filter.action_type.revoked"),
                    },
                ],
            },
        ],
        [i18n.language]
    );
    const defaultSelectedCreditLogsFilters = DEFAULT_SELECTED_CREDIT_LOGS_FILTERS;
    const [selectedCreditLogsFilters, setSelectedCreditLogsFilters] = useState(() => defaultSelectedCreditLogsFilters);
    const onSelectedCreditLogsFiltersChange = (newSelectedFilters) => {
        setSelectedCreditLogsFilters(newSelectedFilters);
    };

    const { creditLogs, totalUnpaginatedCreditLogsCount, isCreditLogsLoading, hasMoreCreditLogs, onCreditLogsSearch } =
        usePostFindCreditLogsApi({
            query: {
                type: selectedCreditLogsFilters?.type === "ALL" ? null : selectedCreditLogsFilters?.type,
                action_type:
                    selectedCreditLogsFilters?.action_type === "ALL" ? null : selectedCreditLogsFilters?.action_type,
            },
            options: {
                accessToken,
            },
            sortKey: creditLogsSortKey,
            limit: 40,
        });

    const isCreditLogsTableEmpty = useMemo(
        () =>
            creditLogs.length === 0 &&
            !isCreditLogsLoading &&
            isEqual(defaultSelectedCreditLogsFilters, selectedCreditLogsFilters),
        [creditLogs.length, isCreditLogsLoading, selectedCreditLogsFilters]
    );

    useEffect(() => {
        setPageTitle(t("customer_portal.credit_logs.page_title"));
    }, []);

    useEffect(() => {
        if (showCredits) {
            onCreditLogsSearch({ keepPreviousData: false });
        }
    }, [showCredits, selectedCreditLogsFilters, creditLogsSortKey]);

    return (
        <div className="max-w-3xl">
            <Section title={t("credit_logs.section_title")} className="mt-4">
                {isCreditLogsTableEmpty ? (
                    <span className={"text-sm text-gray-500"}>{t("credit_logs.table.no_credits")}</span>
                ) : (
                    <InfiniteScroll
                        dataLength={creditLogs.length}
                        next={() => onCreditLogsSearch({ keepPreviousData: true })}
                        hasMore={hasMoreCreditLogs}
                        scrollableTarget="portal-wrapper"
                        loader={<Loader loading={true} />}
                    >
                        <CustomerPortalCreditLogsTable
                            creditLogsData={{
                                creditLogs,
                                totalUnpaginatedCreditLogsCount,
                                isCreditLogsLoading,
                                hasMoreCreditLogs,
                            }}
                            filterData={{
                                creditLogsFilterOptions,
                                defaultSelectedCreditLogsFilters,
                                onSelectedCreditLogsFiltersChange,
                            }}
                            sortData={{
                                creditLogsSortKey,
                                setCreditLogsSortKey,
                            }}
                        />
                    </InfiniteScroll>
                )}
            </Section>
        </div>
    );
}

export default CustomerPortalCreditLogs;
