import '../../App.scss';
import '../../css/invoice.scss';
import React, {useEffect, useState, useContext, createRef} from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
import {serverFetch, serverPost} from '../../helpers/server';
import {
    BaseContext,
    CustomerContext, getCustomerNameOrEmail,
} from '../../helpers/common';
import ContentContainer from "../../components/ContentContainer";
import {useTranslation} from "react-i18next";
import StripeSetupPaymentMethod from "../../components/StripeSetupPaymentMethod";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/SubmitButton";
import Loader from "../../components/Loader";
import variables from "../../App.scss";
import {CreditCardIcon, ShoppingCartIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function CustomerAddPaymentMethod() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { customerId } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    let tokenId = searchParams.get('token_id');
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const [settings, setSettings] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});
    const [customerLoading, setCustomerLoading] = useState(true);
    const [hasNoRedirectUrl, setHasNoRedirectUrl] = useState(false);
    const [errorFields, setErrorFields] = useState(null);
    const setupRef = createRef();

    useEffect(() => {
        setPageTitle(`Customer - Add Payment Method`);
    }, []);

    const fetchData = (skipCache=false) => {
        const options = {
            skipCache: skipCache,
            accessToken: accessToken,
            suppressUnauthenticated: true
        }
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
        serverFetch(getApiUrl(`/customers/${customerId}`), options).then((res) => {
            setCustomerLoading(false)
            if (res) {
                setCustomerDetails(res);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        if (redirectStatus === "succeeded") {
            const linkData = {
                token_id: tokenId,
            }
            const options = {
                accessToken: accessToken
            }
            serverPost(getApiUrl(`/customers/${customerId}/add_payment_method_redirect_link`), linkData, options).then((res) => {
                if (res && res.redirect_url) {
                    let urlParser = new URL(res.redirect_url);
                    urlParser.searchParams.set('customer_id', customerId);
                    window.location = urlParser.href
                } else {
                    setHasNoRedirectUrl(true);
                }
            })

        } else {
            setTimeout(() => {
                let urlParser = new URL(window.location.href);
                urlParser.searchParams.delete('setup_intent');
                urlParser.searchParams.delete('setup_intent_client_secret');
                urlParser.searchParams.delete('redirect_status');
                navigate(urlParser.pathname + urlParser.search, { replace: true });
            }, 100);
        }
    }, [redirectStatus])

    const errorHandler = async (res) => {
        const errorResponse = await res.json();
        setErrorFields({
            error: errorResponse.error_message
        });
    }

    const onSubmit = async (data) => {
        if (_.includes(["AUTHORIZE_NET", "authorize_net"], settings.payment_config.provider)) {
            // if (setupRef.current) {
            //     const { paymentMethodData, error } = await setupRef.current.getPaymentMethodData(data);
            //     if (error) {
            //         setErrorFields(error);
            //         return;
            //     }
            //     serverPost(getApiUrl(`/customers/${props.customer.id}/payment_methods`), paymentMethodData, {}, errorHandler).then((res) => {
            //         if (res) {
            //             Notification.Success("Successfully added payment method");
            //             if (props.onClose) {
            //                 props.onClose(true);
            //             }
            //         }
            //     });
            // }
        } else {
            if (setupRef.current) {
                await setupRef.current.onSubmit(data);
            }
        }
    }

    let brandingLogo = null;
    let brandColor = variables.primaryColor;
    if (settings.branding) {
        brandingLogo = settings.branding.logo_url || null;
        brandColor = settings.branding.color || variables.primaryColor;
    }

    const renderFooterDetails = () => {
        if (settings.payment_config && _.includes(["STRIPE", "stripe"], settings.payment_config.provider)) {
            return (
                <div className="payment-details">
                    <div className="flex flex-row my-4 justify-center">
                        <a href="https://www.stripe.com" target="_blank">
                            <span className="gray3 caption">Payments powered by&nbsp;</span>
                            <svg className="inline-block InlineSVG Icon powered-by-icon Icon--md" focusable="false"
                                 width="33" height="15" role="img" aria-labelledby="stripe-title"><title
                                id="stripe-title">Stripe</title><g fill-rule="evenodd"><path
                                d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
                        </a>
                    </div>
                </div>
            )
        } else if (settings.payment_config && _.includes(["AUTHORIZE_NET", "authorize_net"], settings.payment_config.provider)) {
            return (
                <div className="payment-details">
                    <div className="flex flex-row my-4 justify-center">
                        <a href="https://www.authorize.net" target="_blank">
                            <span className="gray3 caption">Payments powered by Authorize.Net</span>
                        </a>
                    </div>
                </div>
            )
        }
    }

    const customerPresent = !_.isNil(customerDetails) && !_.isEmpty(customerDetails);

    return (
        <div className="d-flex justify-content-center">
            <div className="customer-portal-wrapper">
                <Loader loading={(!_.isNil(redirectStatus) && !hasNoRedirectUrl) || customerLoading}>
                    <ContentContainer title={ getCustomerNameOrEmail(customerDetails) } subtitle={"Add a new payment method."}>
                        {
                            _.isNil(redirectStatus) && customerPresent &&
                                <BaseForm onSubmit={onSubmit} errorFields={errorFields}>
                                    <div>
                                        <StripeSetupPaymentMethod
                                            ref={setupRef} redirectUrl={window.location.href} paymentConfig={settings.payment_config}
                                            customer={customerDetails} accessToken={accessToken}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <SubmitButton variant="primary" color={brandColor}>{t('payment_methods.add.action')}</SubmitButton>
                                    </div>
                                </BaseForm>
                        }
                        {
                            !_.isNil(redirectStatus) &&
                                <div className="flex justify-center py-4 my-16">
                                    <div className="flex flex-col gap-3 items-center" style={{color: brandColor}}>
                                        <CreditCardIcon className="h-12 w-12"/>
                                        <span className="text-xl font-semibold">Successfully added payment method</span>
                                    </div>
                                </div>
                        }
                        {
                            !customerPresent &&
                                <div className="flex justify-center py-4 my-16">
                                    <div className="flex flex-col gap-3 items-center text-gray-500 max-w-xl">
                                        <ShoppingCartIcon className="h-12 w-12"/>
                                        <span className="text-xl font-semibold text-center">Checkout session has expired. Please request a new check session link.</span>
                                    </div>
                                </div>
                        }
                        { customerPresent && renderFooterDetails() }
                    </ContentContainer>
                </Loader>
            </div>
        </div>
    );
}

export default CustomerAddPaymentMethod;
