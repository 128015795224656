import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverPost } from "../../server";

function useGetAllSubscriptionPmpUsage({ subscriptionId, pmpIds, options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [subscriptionPmpsUsage, setSubscriptionPmpsUsage] = useState(undefined);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = () => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        Promise.all(
            pmpIds.map((pmpId) => {
                return serverPost(
                    getApiUrl(`/subscriptions/${subscriptionId}/usage`),
                    {
                        product_metric_pricing_id: pmpId,
                    },
                    options
                ).then((res) => {
                    return {
                        pmpId,
                        ...res
                    }
                });
            })
        ).then((results) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false)           

            if (results) {
                setSubscriptionPmpsUsage(results);
            }
        });
    };

    return {
        subscriptionPmpsUsage,
        isSubscriptionPmpsUsageLoading: isLoading,
        onSubscriptionPmpsUsageSearch: onSearch,
    };
}

export default useGetAllSubscriptionPmpUsage;
