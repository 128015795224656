import '../../App.scss';
import React, {useEffect, useState, useContext, useCallback, useRef} from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import CopyableComponent from '../../components/CopyableComponent';
import moment from "moment/moment";
import Label from "../../components/Label";
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require('lodash');

function BillableEvents() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [events, setEvents] = useState([]);
    const [processedEvents, setProcessedEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null)
    const [triggerPull, setTriggerPull] = useState(false);

    const [counter, setCounter] = useState(0);
    const latestEvent = useRef(null);
    const refreshCounterRef = useRef(null);
    const counterRef = useRef(0);

    useEffect(() => {
        setPageTitle(`Billable Events`);
    }, []);

    useEffect(() => {
        if (refreshCounterRef.current) {
            clearInterval(refreshCounterRef.current);
        }
        refreshCounterRef.current = setInterval(() => {
            counterRef.current = counterRef.current + 1
            setCounter(counterRef.current);
        }, 30000);
    }, []);

    useEffect(() => {
        if (counter === 0) {
            return;
        }
        searchNewInternal();
    }, [counter]);

    const searchNewInternal = () => {
        if (!latestEvent.current) {
            return;
        }
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: "createdAtAsc",
            pagination: {
                from_key: latestEvent.current.created_at,
                limit: limit
            },
            query: {
                search: query,
                status: "ACTIVE"
            }
        }
        serverPost(getApiUrl("/events/find"), params).then(res => {
            if (res) {
                const results = res.results || [];
                const allEvents = _.concat(results, events);
                latestEvent.current = allEvents[0];
                setEvents(allEvents);
            }
        })
    };

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
                status: "ACTIVE"
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/events/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    latestEvent.current = results[0];
                    setEvents(results);
                    setMeta(res.meta);
                } else {
                    const allEvents = _.concat(events, results);
                    latestEvent.current = allEvents[0];
                    setEvents(allEvents);
                }
                setTriggerPull();
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    useEffect(() => {
        _.each(events, (ev, i) => {
            if (!ev.post_body) {
                return;
            }
            try {
                const parsedBody = JSON.parse(ev.post_body);
                if (Array.isArray(parsedBody)) {
                    ev.event = parsedBody[0]
                    ev.events = parsedBody
                } else if (_.endsWith(ev.path, "objects/ingest")) {
                    const objects = parsedBody['objects']
                    ev.event = objects[0]
                    ev.events = objects
                } else if (_.includes(ev.path, "/objects")) {
                    ev.event = parsedBody
                    ev.events = [parsedBody]
                } else {
                    const events = parsedBody['events']
                    if (events && Array.isArray(events)) {
                        ev.event = events[0]
                        ev.events = events
                    } else {
                        ev.event = null
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });
        setProcessedEvents(events);
    }, [events])

    const renderStatus = (item) => {
        if (item.status >= 200 && item.status < 300) {
            return <Label.Success>{ item.status }</Label.Success>
        } else {
            return <Label.Danger>{ item.status }</Label.Danger>
        }
    }

    const renderItemIds = (row) => {
        if (!row.events || row.events.length === 0) {
            return null;
        }
        const itemIds = _.uniq(_.map(row.events, (ev) => ev.item_id));
        return <div className="flex flex-col items-start whitespace-nowrap">
            {
                _.map(itemIds, (itemId, i) =>
                    <CopyableComponent key={i} value={ itemId }/>
                )
            }
        </div>
    }

    const renderEvents = (row) => {
        if (!row.events || row.events.length === 0) {
            return null;
        }
        const filteredEvents = row.events.length > 3 ? row.events.slice(0, 3): row.events;
        const additionalEventsCount = row.events.length - 3;
        return <div className="flex flex-col items-start max-w-md">
            {
                _.map(filteredEvents, (ev, j) =>
                    <div key={j} className="text-xs text-gray-500 max-w-md"><code>{ JSON.stringify({...ev.properties, object_identifier:ev.object_identifier, customer_id:ev.customer_id}) }</code></div>
                )
            }
            {
                additionalEventsCount > 0 &&
                    <div className="mt-1text-sm text-gray-500">+{ additionalEventsCount } more events</div>
            }
            <CopyableComponent copyableValue={JSON.stringify(row.events)} value={"Copy all events"}></CopyableComponent>
        </div>
    }

    const renderType = (row) => {
        if (_.endsWith(row.path, "events/ingest")) {
            return "Event(s)"
        } else if (_.includes(row.path, "objects")) {
            return "Object(s)"
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={processedEvents.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title="Billable Events" loading={loading}
                             subtitle={"A list of all billable events. These form the base for creating any billable metrics and prices associated with them."}
                             >
                        <div className="mt-2 flex flex-row justify-content-start items-center gap-2">
                            <div className="spinner-border text-secondary h-4 w-4"/>
                            <div className="text-gray-500">Looking for new events (every 30 seconds)...</div>
                        </div>
                        {
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Time</MapleTable.TH>
                                        <MapleTable.TH>Status</MapleTable.TH>
                                        <MapleTable.TH>Method</MapleTable.TH>
                                        <MapleTable.TH>Type</MapleTable.TH>
                                        <MapleTable.TH>Item</MapleTable.TH>
                                        <MapleTable.TH>Body</MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(processedEvents, (item, i) =>
                                            <tr key={i}>
                                                <td>{ moment(item.time).format("MMM D, YYYY h:mm:ss a") }</td>
                                                <td>{ renderStatus(item) }</td>
                                                <td>{ item.method }</td>
                                                <td>{ renderType(item) }</td>
                                                <td>{ renderItemIds(item) }</td>
                                                <td>{ renderEvents(item) }</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        hasMore &&
                                        <tr>
                                            <td colSpan="10" className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default BillableEvents;
