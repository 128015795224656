import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import React, {useContext, useEffect, useState} from 'react';
import {
    BaseContext,
    getInvoicePaymentTermFromDueDateFromCreation,
    getInvoicePaymentTermOptions
} from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function EditChildRollupBillingModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [invoicePaymentTerms, setInvoicePaymentTerms] = useState(0);
    const [rollupBilling, setRollupBilling] = useState(false);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, []);

    useEffect(() => {
        if (props.customer) {
            const ifields = {
                child_rollup_billing: props.customer.child_rollup_billing,
                child_rollup_billing_config: props.customer.child_rollup_billing_config
            }
            if (!ifields.child_rollup_billing) {
                ifields['child_rollup_billing_config'] = {
                    frequency: "MONTH",
                    invoice_payment_terms: getInvoicePaymentTermFromDueDateFromCreation(settings.invoice_due_date_from_creation),
                    invoice_due_date_from_creation: settings.invoice_due_date_from_creation
                }
            } else if (!_.isNil(ifields.child_rollup_billing_config)) {
                ifields['child_rollup_billing_config'] = {
                    ...ifields.child_rollup_billing_config,
                    invoice_payment_terms: getInvoicePaymentTermFromDueDateFromCreation(ifields.child_rollup_billing_config.invoice_due_date_from_creation),
                    invoice_due_date_from_creation: ifields.child_rollup_billing_config.invoice_due_date_from_creation
                }
                setInvoicePaymentTerms(getInvoicePaymentTermFromDueDateFromCreation(ifields.child_rollup_billing_config.invoice_due_date_from_creation));
            }
            setInitialFields(ifields);
            setRollupBilling(props.customer && props.customer.child_rollup_billing);
        } else {
            setInitialFields({});
        }
    }, [props.customer])

    const onSubmit = (data, errorHandler) => {
        if (!data.child_rollup_billing) {
            data['child_rollup_billing_config'] = null
        } else {
            if (!_.isNil(data.child_rollup_billing_config.invoice_payment_terms)) {
                if (data.child_rollup_billing_config.invoice_payment_terms !== "custom") {
                    data.child_rollup_billing_config.invoice_due_date_from_creation = parseInt(data.child_rollup_billing_config.invoice_payment_terms);
                }
            }
        }
        serverPatch(getApiUrl(`/customers/${props.customer.id}`), data, {}, errorHandler).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "child_rollup_billing") {
            setRollupBilling(value);
        } else if (name === "child_rollup_billing_config.invoice_payment_terms") {
            setInvoicePaymentTerms(value);
        }
    }

    const periodOptions = [
        false && { value: "DAY", label: "Daily" },
        { value: "WEEK", label: "Weekly" },
        { value: "MONTH", label: "Monthly" },
        { value: "QUARTER", label: "Quarterly" },
        { value: "BI_ANNUAL", label: "Bi-Annually" },
        { value: "YEAR", label: "Yearly" }
    ]

    const paymentMechanismOptions = [
        { value: true, label: "Automatically charge a payment method on file",
            disabled: _.isNil(settings.payment_config), disabledDescription: "* Payment Provider needs to be setup" },
        { value: false, label: "Email invoice to customer manually" },
    ]

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form onSubmit={onSubmit} onFieldChange={onFieldChange} initialFormFields={initialFields}>
                <BaseSideModal.Header title={"Change Child Invoice Rollup"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="6" type="switch" name="child_rollup_billing" label="Rollup Invoices?" />
                    </Row>
                    {
                        rollupBilling &&
                            <>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="6" name="child_rollup_billing_config.frequency" label={t('billable_item_pricing.frequency')}
                                        type="select" options={periodOptions} showSearch={false}
                                    />
                                    <BaseForm.Input
                                        colSpan="6" name="child_rollup_billing_config.start_date" label={"Start date (local timezone)"}
                                        type="date" includeTime={true} required
                                    />
                                </Row>
                                <Row>
                                    <BaseForm.Input
                                        colSpan="6" name="child_rollup_billing_config.auto_charges"
                                        label={t('subscriptions.payment_mechanism')} type="select"
                                        options={paymentMechanismOptions} showSearch={false}
                                    />
                                    <BaseForm.Input
                                        type="select" colSpan="3" name="child_rollup_billing_config.invoice_payment_terms" label="Payment Terms"
                                        options={getInvoicePaymentTermOptions()} showSearch={false}
                                    />
                                    {
                                        invoicePaymentTerms === "custom" &&
                                            <BaseForm.Input
                                                type="number" colSpan="3" name="child_rollup_billing_config.invoice_due_date_from_creation"
                                                label="Due Date (in days)" step="1" min="0"
                                            />
                                    }
                                </Row>
                            </>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default EditChildRollupBillingModal;
