import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row } from 'react-bootstrap';
const _ = require("lodash");
const sha1 = require('js-sha1');

function RejectApprovalRequestModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [approvalRequest, setApprovalRequest] = useState({});

    useEffect(() => {
        setApprovalRequest(props.approvalRequest);
    }, [props.approvalRequest])

    const rejectApprovalRequest = (data) => {
        const rejectData = {
            approval_request_ids: [approvalRequest.id],
            reason: data.reason
        }
        serverPost(getApiUrl(`/proposals/${approvalRequest.proposal.id}/reject`), rejectData).then(res => {
            if (res) {
                if (props.onUpdate) {
                    props.onUpdate();
                }
                if (props.onClose) {
                    props.onClose();
                }
            }
        })
    }

    return (
        <BaseModal {...props}>
            <BaseForm onSubmit={rejectApprovalRequest}>
                <BaseModal.Header>
                    <BaseModal.Title>Reject Contract</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input
                            name="reason" type="textarea" label="Reason (Optional)" height="120px"
                            placeholder="Too high a discount" description="You can provide a reason for rejecting the contract."
                        />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton variant="danger">Reject</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default RejectApprovalRequestModal;
