import '../App.scss';
import React, { useState } from 'react';
import {FolderPlusIcon} from "@heroicons/react/24/outline";
import {PlusIcon} from "@heroicons/react/20/solid";
import classnames from "classnames";
const _ = require("lodash");

function EmptyState(props) {
    const [hasWritePermissions, setHasWritePermissions] = useState(true);
    const Icon = props.icon || FolderPlusIcon;

    useState(() => {
        if (_.isNil(props.hasWritePermissions)) {
            setHasWritePermissions(true);
        } else {
            setHasWritePermissions(props.hasWritePermissions);
        }
    }, [props.hasWritePermissions])

    return (
        <div className={classnames("text-center m-auto p-5 rounded-md shadow-sm ring-1 ring-black ring-opacity-5 bg-white", props.className)}>
            <Icon className="mx-auto h-12 w-12 text-gray-400"/>
            <h3 className="mt-2 text-sm font-semibold text-gray-900">{ props.title }</h3>
            <p className="mt-1 text-sm text-gray-500">{ props.subtitle }</p>
            <div className="mt-6">
                {
                    hasWritePermissions &&
                        <button
                            type="button"
                            className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={props.onClick}
                        >
                            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                            { props.buttonLabel }
                        </button>
                }
            </div>
        </div>
    );
}

export default EmptyState;
