import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext, removeHtmlTags, UserContext } from '../../helpers/common';
import { serverPatch } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function EditInvoiceModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const [initialFields, setInitialFields] = useState({});
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        if (props.invoice) {
            setInitialFields({
                invoice_date: props.invoice.invoice_date,
                due_date: props.invoice.due_date,
                notes: props.invoice.notes,
                payment_instructions: props.invoice.payment_instructions,
            })
        } else {
            setInitialFields({});
        }
        setInvoice(props.invoice)
    }, [props.invoice])

    const onSubmit = (data, errorHandler) => {
        const uploadData = {
            invoice_date: moment(data.invoice_date),
            due_date: moment(data.due_date),
            notes: data.notes,
            payment_instructions: removeHtmlTags(data.payment_instructions).length ? data.payment_instructions : null,
        }
        serverPatch(getApiUrl(`/invoices/${props.invoice.uuid}`), uploadData, {}, errorHandler).then((res) => {
            if (res) {
                props.onClose(true);
            }
        });
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseSideModal.Header title={"Invoice - Edit"}/>
                <BaseSideModal.Body>
                    {
                        !_.isEmpty(invoice) &&
                            <Row>
                                <BaseForm.Input colSpan={12} name="invoice_date" label="Invoice Date" type="date" includeTime={true} disabled required/>
                                <BaseForm.Input colSpan={12} name="due_date" label="Due Date" type="date" includeTime={true} required/>
                                <BaseForm.Input colSpan={12} name="notes" label={"Notes"} type="editor" />
                                {isMapleUser && <BaseForm.Input colSpan={12} name="payment_instructions" label={"Payment Instructions"} type="editor" />}
                            </Row>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default EditInvoiceModal;
