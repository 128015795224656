import '../App.scss';
import variables from '../App.scss';
import '../css/forms.scss';
import $ from "jquery";
import 'daterangepicker/daterangepicker';
import React, { useState, useEffect, useMemo } from 'react';
import { currencyFormatFromPrice } from '../helpers/common';
import moment from 'moment';
import { Line, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement,
    Title, Tooltip, Legend, BarElement
} from "chart.js";
import BaseForm from '../components/BaseForm';
import Label from '../components/Label';
import classnames from 'classnames';
import Link from "./Link";
import {ArrowDownTrayIcon, ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";
const _ = require("lodash");

ChartJS.register(
    CategoryScale, LinearScale, PointElement, LineElement,
    Title, Tooltip, Legend, BarElement
);

function BaseChart(props) {
    const [chartType, setChartType] = useState("line");
    const [dateRangeLabel, setDateRangeLabel] = useState(null);
    const [lines, setLines] = useState([]);
    const [data, setData] = useState([]);
    const [linesToShow, setLinesToShow] = useState([]);
    const [chartData, setChartData] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [summaryAggregator, setSummaryAggregator] = useState("last");

    const [randomId, setRandomId] = useState(null);

    const customRanges = useMemo(() => {
        return {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('day')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            'This Year': [moment().startOf('year'), moment().endOf('day')],
            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
        }
    }, []);

    useEffect(() => {
        setRandomId(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5))
    }, []);

    useEffect(() => {
        setInitialFields({ frequency: props.frequency });
    }, [props.frequency])

    useEffect(() => {
        setSummaryAggregator(props.summaryAggregator);
    }, [props.summaryAggregator])

    useEffect(() => {
        if (!_.isNil(props.defaultDateRange) && !_.isUndefined(props.defaultDateRange)) {
            if (props.onDatesChange) {
                if (_.has(customRanges, props.defaultDateRange)) {
                    const dates = customRanges[props.defaultDateRange]
                    props.onDatesChange(dates[0], dates[1]);
                }
            }
            setDateRangeLabel(props.defaultDateRange);
        }
    }, [props.defaultDateRange])

    const getColor = (type, i) => {
        const colorMap = [{
            borderColor: '#7E80E8',
            backgroundColor: '#7E80E880',
        }, {
            borderColor: '#FF9800',
            backgroundColor: '#FF980080',
        }, {
            borderColor: '#b92b7e',
            backgroundColor: '#b92b7e',
        }, {
            borderColor: '#09a052',
            backgroundColor: '#09a052',
        }, {
            borderColor: '#8c828e',
            backgroundColor: '#8c828e',
        }, {
            borderColor: '#f49ac6',
            backgroundColor: '#f49ac6',
        }, {
            borderColor: '#8869a8',
            backgroundColor: '#8869a8',
        }, {
            borderColor: '#6312bf',
            backgroundColor: '#6312bf',
        }, {
            borderColor: '#ffd732',
            backgroundColor: '#ffd732',
        }, {
            borderColor: '#98fb98',
            backgroundColor: '#98fb98',
        }, {
            borderColor: '#eedda0',
            backgroundColor: '#eedda0',
        }, {
            borderColor: '#303b27',
            backgroundColor: '#303b27',
        }, {
            borderColor: '#bcbad7',
            backgroundColor: '#bcbad7',
        }, {
            borderColor: '#58f72f',
            backgroundColor: '#58f72f',
        }, {
            borderColor: '#314992',
            backgroundColor: '#31499280',
        }, {
            borderColor: '#ffe2d9',
            backgroundColor: '#ffe2d9',
        }]
        const index = i || 0;
        if (index >= colorMap.length) {
            return {
                borderColor: '#7E80E8',
                backgroundColor: '#7E80E880',
            }
        } else {
            return colorMap[index];
        }
    }

    useEffect(() => {
        setLines(props.lines);
        setLinesToShow(_.map(props.lines, (l) => true));
    }, [props.lines])

    useEffect(() => {
        setData(props.data);
        if (!props.data) {
            setChartData(null);
        }
    }, [props.data])

    useEffect(() => {
        setChartType(props.chartType);
    }, [props.chartType])

    const processData = (d, line) => {
        const lineValue = line.getValue(d)
        if (line.type === "currency") {
            return lineValue.value_in_cents/100;
        } else if (line.type === "percent") {
            return parseFloat(lineValue);
        }else {
            return lineValue
        }
    }

    useEffect(() => {
        const keys = [];
        _.each(data, (dd, i) => {
            const dkeys = _.map(data[i], d => props.xKey(d));
            _.each(dkeys, (dk) => {
                if (!_.includes(keys, dk)) {
                    keys.push(dk);
                }
            })
        })
        const newChartData = {
            labels: _.map(keys, (k) => formatXLabel(k)),
            datasets: _.map(lines, (line, i) => {
                let currency = null
                if (line.type === "currency" && data[i]) {
                    currency = line.getValue(data[i][0]).currency
                }
                return {
                    label: line.label,
                    currency: currency,
                    data: linesToShow[i] ? _.map(data[i], (d) => processData(d, line)): [],
                    borderColor: getColor(chartType, i).borderColor,
                    backgroundColor: getColor(chartType, i).backgroundColor,
                    borderWidth: props.chartType === "bar" ? 0: 2,
                    minBarLength: 2,
                    tension: 0.1,
                    radius: 2,
                    hidden: !line.visible,
                    options: {
                        tooltips: {

                        }
                    }
                }
            }),
        };
        setChartData(newChartData);
    }, [lines, data, linesToShow])

    useEffect(() => {
        if (_.isNil(randomId)) {
            return;
        }
        $( function() {
            const name = '#' + randomId + "-chart-calendar-icon"
            $(name).daterangepicker({
                opens: "left",
                autoApply: true,
                maxDate: moment().format("MM/DD/YYYY"),
                ranges: customRanges,
            }, function(start, end, label) {
                if (label === 'Custom Range') {
                    setDateRangeLabel(start.format('MMM D') + " - " + end.format('MMM D'));
                } else {
                    setDateRangeLabel(label);
                }
                if (props.onDatesChange) {
                    props.onDatesChange(start, end);
                }
            });
        });
    }, [randomId])

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const {chart, tooltip} = context;
        console.log("Here " + tooltip);
    }

    const options = {
        clip: false,
        plugins: {
            legend: {
                display: false,
            },
            layout: {
                padding: "25"
            },
            tooltip: {
                position: "nearest",
                callbacks: {
                    title: function(tooltipItems) {
                        return tooltipItems[0]['label'] + " - " + tooltipItems[0].dataset['label'];
                    },
                    label: function(tooltipItem) {
                        const value = tooltipItem['raw'];
                        const displayType = !_.isEmpty(lines) && lines[0].type;
                        if (displayType === "currency") {
                            return currencyFormatFromPrice({ value_in_cents: value*100, currency: tooltipItem.dataset.currency });
                        } else if (displayType === "percent") {
                            return `${value.toFixed(2)}%`;
                        } else {
                            return tooltipItem['formattedValue'];
                        }
                    },
                    afterLabel1: function(tooltipItem, data) {
                      var dataset = data['datasets'][0];
                      var percent = Math.round((dataset['data'][tooltipItem['index']] / dataset["_meta"][0]['total']) * 100)
                      return '(' + percent + '%)';
                    }
                },
                backgroundColor: variables.darkGrayColor,
                titleFont: { size: 14, lineHeight: 1.57, weight: 600 },
                titleColor: variables.whiteColor,
                bodyColor: variables.whiteColor,
                bodyFont: { size: 14, lineHeight: 1.57, weight: 400 },
                bodyFontSize: 16,
                displayColors: true,
                padding: 15,
                borderColor: variables.gray1Color,
                borderWidth: 1,
                boxPadding: 5
            }
        },
        scales: {
            xAxis:{
                grace: '5%',
                grid: {
                    grace: '5%',
                    display: false,
                    drawBorder: false,
                    offset: true,
                },
                beginAtZero: true,
                ticks: {
                    grace: '5%',
                    maxTicksLimit: 8,
                    beginAtZero: true,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            yAxis:{
                grace: '5%',
                grid: {
                    grace: '5%',
                    display: true,
                    drawBorder: false,
                    borderDash: [4,4],
                    offset: true,
                    beginAtZero: true,
                },
                max: (scale) => {
                    const datasets = scale.chart.data.datasets;
                    let max = null;
                    _.each(datasets, (ds) => {
                        if (ds.hidden) {
                            return;
                        }
                        const rawMax = parseFloat(_.max(ds.data));
                        const localMax = Math.ceil(rawMax + Math.abs(parseFloat(_.max(ds.data)) * 0.05));
                        if (max === null || localMax > max) {
                            max = localMax;
                        }
                    })
                    if (max === 0 || max === null) {
                        max = 1;
                    }
                    return max;
                },
                min: (scale) => {
                    const datasets = scale.chart.data.datasets;
                    let min = null;
                    _.each(datasets, (ds) => {
                        if (ds.hidden) {
                            return;
                        }
                        const rawMin = parseFloat(_.min(ds.data));
                        const localMin = Math.floor(rawMin - Math.abs(parseFloat(_.min(ds.data)) * 0.05))
                        if (min === null || localMin < min) {
                            min = localMin;
                        }
                    })
                    if (min === null) {
                        min = 0;
                    }
                    return min;
                },
                beginAtZero: true,
                ticks: {
                    grace: '5%',
                    autoSkip: true,
                    autoSkipPadding: 75,
                    beginAtZero: true,
                    offset: true,
                    // Include a dollar sign in the ticks
                    callback: function(value, index, ticks) {
                        const displayType = !_.isEmpty(lines) && lines[0].type;
                        if (displayType === "currency") {
                            return currencyFormatFromPrice({ value_in_cents: value*100, currency: chartData.datasets[0].currency });
                        } else if (displayType === "percent") {
                            return `${parseFloat(value).toFixed(2)}%`;
                        } else {
                            return value;
                        }
                    }
                }
            }
        }
    }

    const formatXLabel = (val) => {
        if (props.frequency === "DAY") {
            const start = moment(val.start_date).utc();
            return start.format("MMM D");
        } else if (props.frequency === "WEEK") {
            const start = moment(val.start_date).utc();
            const end = moment(val.end_date).utc();
            return `${start.format("M/D")}-${end.format("M/D")}`;
        } else if (props.frequency === "MONTH") {
            const start = moment(val.start_date).utc();
            return start.format("MMM'YY");
        } else {
            return "";
        }
    }

    const onLegendClick = (i) => {
        setLinesToShow(prevLinesToShow => {
            const newLinesToShow = [...prevLinesToShow];
            newLinesToShow[i] = !newLinesToShow[i];
            return newLinesToShow;
        })
    }

    const showAllLines = () => {
        setLinesToShow(prevLinesToShow => {
            const newLinesToShow = [...prevLinesToShow];
            _.each(newLinesToShow, (line, k) => {
                newLinesToShow[k] = true;
            })
            return newLinesToShow;
        })
    }

    const hideAllLines = () => {
        setLinesToShow(prevLinesToShow => {
            const newLinesToShow = [...prevLinesToShow];
            _.each(newLinesToShow, (line, k) => {
                newLinesToShow[k] = false;
            })
            return newLinesToShow;
        })
    }

    const renderChart = () => {
        if (_.isEmpty(chartData)) {
            return;
        }
        if (chartType === "bar") {
            return (
                <Bar options={options} data={chartData}/>
            );
        } else {
            return (
                <Line options={options} data={chartData}/>
            );
        }
    }

    const renderChange = (change) => {
        if (_.isNil(change)) {
            return;
        }

        if (change >= 0) {
            return (
                <Label.Success className="body2 align-self-center">+{ change }%</Label.Success>
            )
        } else {
            return (
                <Label.Danger className="body2 align-self-center">{ change }%</Label.Danger>
            )
        }
    }

    const renderSummaryValue = () => {
        if (!data || data.length === 0 || lines.length === 0) {
            return;
        }
        const rows = _.map(data[0], (d) => processData(d, lines[0]));
        let value = null;
        if (summaryAggregator === "last") {
            value = rows[rows.length - 1]
        } else if (summaryAggregator === "sum") {
            value = _.sum(rows);
        }
        if (!_.isNil(value)) {
            const displayType = !_.isEmpty(lines) && lines[0].type;
            if (displayType === "currency") {
                value = currencyFormatFromPrice({ value_in_cents: value*100, currency: chartData.datasets[0].currency });
            } else if (displayType === "percent") {
                value = `${value.toFixed(2)}%`;
            }
            value = value.toLocaleString('en-US')

            return (
                <h3 className="text-start">{ value }</h3>
            )
        }
    }

    const onFieldChange = (name, value) => {
        if (name === "frequency") {
            if (props.onFrequencyChange) {
                props.onFrequencyChange(value);
            }
        }
    }

    const frequencyOptions = [
        { label: "Day", value: "DAY" },
        { label: "Week", value: "WEEK" },
        { label: "Month", value: "MONTH" }
    ]

    return (
        <div className="chart-outer">
            {
                !props.hideHeader &&
                <div className="chart-header align-items-center">
                    <div className="flex-grow-1 d-flex flex-column gap-2">
                        <div className="body2 gray4">{ props.title || props.yLabel }</div>
                        <div className="body1 gray3 d-none d-md-block">{ props.description }</div>
                    </div>
                    {
                        props.exportCSV &&
                        <div>
                            <Link onClick={props.exportCSV}><ArrowTopRightOnSquareIcon className="w-5 h-5"/></Link>
                        </div>
                    }
                    <div>
                        <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                            <BaseForm.SelectGroup name="frequency" options={frequencyOptions}
                                                  showSearch={false} formClassName="thin" borderless/>
                        </BaseForm>
                    </div>
                    <div id={randomId + "-chart-calendar-icon"} className="d-flex flex-row gap-2 flex-shrink-0">
                        <div>
                            <span className="body1 gray4">{ dateRangeLabel }</span>
                        </div>
                        <div className="calendar-icon"/>
                    </div>
                </div>
            }
            {
                !props.hideHeader &&
                <div className="chart-header">
                    { renderSummaryValue() }
                    { renderChange(props.change) }
                </div>
            }
            <div className="chart-inner">
                { renderChart() }
            </div>
            <div className="chart-footer">
                <div className="flex-grow-1"></div>
                <div className="flex-grow-0 legend d-flex flex-row gap-3 flex-wrap">
                {
                    _.map(lines, (line, i) => {
                        if (line.visible) {
                            return (
                                <div key={i}
                                     className={classnames("legend", "caption", "gray4", "d-flex", "flex-row", "gap-3", "align-items-center", linesToShow[i] ? "" : "strikethrough")}
                                     onClick={() => onLegendClick(i)}>
                                    <span className="legend-dot"
                                          style={{backgroundColor: getColor(chartType, i).borderColor}}></span>
                                    <span className="gray3">{line.label}</span>
                                </div>
                            )
                        } else {
                            return null;
                        }
                    })
                }
                    {
                        lines.length > 2 &&
                            <div className="flex-grow-1 caption d-flex justify-content-end gray3 gap-2">
                                <span onClick={showAllLines}>All</span>|<span onClick={hideAllLines}>None</span>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BaseChart;
