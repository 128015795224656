import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverPost } from "../../server";
import { concat } from "lodash";

function usePostFindCreditLogsApi({ options, limit = 20, query, sortKey = "createdAtDesc" }) {
    const { getApiUrl} = useContext(BaseContext);

    const [creditLogs, setCreditLogs] = useState([]);
    const [totalUnpaginatedCount, setTotalUnpaginatedCount] = useState(null);
    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = ({ keepPreviousData = false }) => {
        setLoading(true);
        if (!keepPreviousData) {
            setHasMore(false);
            setCreditLogs([]);
            setTotalUnpaginatedCount(null);
            setFromKey(null);
        }

        const params = {
            sort_key: sortKey,
            pagination: {
                from_key: keepPreviousData ? fromKey : null,
                limit: limit,
            },
            query: query,
            include_meta: !keepPreviousData,
        };
        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverPost(getApiUrl("/credits/logs/find"), params, options).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                const results = res.results || [];
                if (keepPreviousData) {
                    setCreditLogs(concat(creditLogs, results));
                } else {
                    setCreditLogs(results);
                    setTotalUnpaginatedCount(res.meta.total);
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    return {
        creditLogs,
        totalUnpaginatedCreditLogsCount: totalUnpaginatedCount,
        isCreditLogsLoading: isLoading,
        hasMoreCreditLogs: hasMore,
        onCreditLogsSearch: onSearch,
    };
}

export default usePostFindCreditLogsApi;
