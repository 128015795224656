import '../App.scss';
import '../css/modals.scss';
import React, { useState, useEffect, useContext, createRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
const _ = require('lodash');

const StripeSetupPaymentMethodV2 = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));

    const { t } = useTranslation('common');
    const formRef = createRef();

    const onFieldChange = (name, value) => {
    }

    const onSubmit = async (data) => {
        if (formRef.current) {
            await formRef.current.onSubmit(data);
        }
    }

    const publishableKey = props.settings.payment_provider_live_mode ?
        process.env.REACT_APP_STRIPE_PROD_PUBLISHABLE_KEY
        : process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
    const stripePromise = loadStripe(publishableKey, {
        stripeAccount: props.paymentIntent && props.paymentIntent.provider_account_id
    });
    const options = props.paymentIntent && {
        clientSecret: props.paymentIntent.intent_secret,
        appearance: {/*...*/},
    };

    if (_.isNil(props.paymentIntent)) {
        return (<div className="spinner-border text-secondary"/>);
    } else {
        return (
            <Elements stripe={stripePromise} options={options}>
                <SetupForm ref={formRef} customer={props.customer} company={props.company} redirectUrl={props.redirectUrl}/>
            </Elements>
        )
    }
})

const SetupForm = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async onSubmit(data) {
            await onSubmit(data);
        },
    }));

    const { t } = useTranslation('common');
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [redirectUrl, setRedirectUrl] = useState(null);

    useEffect(() => {
        if (_.isNil(props.customer)) {
            setRedirectUrl(null);
            return;
        }
    }, [props.customer])

    useEffect(() => {
        setRedirectUrl(props.redirectUrl);
    }, [props.redirectUrl])

    const onSubmit = async (data) => {
        if (!stripe || !elements) {
            return;
        }

        setErrorMessage(null);
        const {error} = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: redirectUrl,
            },
        });

        if (error) {
            setErrorMessage(error.message);
        } else {

        }
    };

    return (
        <>
            <PaymentElement />
            {errorMessage && <div className="form-error-message">{errorMessage}</div>}
        </>
    )
})

export default StripeSetupPaymentMethodV2;
