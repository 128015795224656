import { useTranslation } from "react-i18next";
import { currencyFormatFromPrice } from "../helpers/common";
import Loader from "./Loader";
import MapleTable from "./MapleTable";

function CreditSummaryTable({
    creditBalanceData: { creditBalance, isLoading },
}) {
    const { t } = useTranslation("common");
    return (
        <MapleTable>
            <MapleTable.Content>
                <thead>
                    <tr>
                        <MapleTable.TH>
                            {t("credits_summary.table.header.name")}
                        </MapleTable.TH>
                        <MapleTable.TH>
                            {t("credits_summary.table.header.remaining")}
                        </MapleTable.TH>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {isLoading ? (
                        <tr>
                            <td colSpan="2">
                                <Loader loading={true} />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {creditBalance?.total_amount &&
                                Object.entries(creditBalance.total_amount).map(
                                    ([key, value]) => (
                                        <tr key={key}>
                                            <td>{key}</td>
                                            <td>
                                                {currencyFormatFromPrice(value)}
                                            </td>
                                        </tr>
                                    )
                                )}
                            {creditBalance?.total_units &&
                                Object.entries(creditBalance.total_units_with_meta).map(
                                    ([key, value]) => (
                                        <tr key={key}>
                                            <td>{value.name}(s)</td>
                                            <td>{value.total_units}</td>
                                        </tr>
                                    )
                                )}
                        </>
                    )}
                </tbody>
            </MapleTable.Content>
        </MapleTable>
    );
}

export default CreditSummaryTable;
