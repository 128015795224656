import '../../App.scss';
import React, {useEffect, useContext, useMemo} from 'react';
import { BaseContext } from '../../helpers/common';
import Metric from '../../components/Metric';
import MetricSummary from '../../components/MetricSummary';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BaseForm from '../../components/BaseForm';
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SecondaryLeftNav from "../../components/SecondaryLeftNav";
const _ = require('lodash');

function Metrics() {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    let { key } = useParams();
    const navigate = useNavigate();
    const initialFields = useMemo(() => {
        return {
            key: key
        }
    }, [key])

    useEffect(() => {
        setPageTitle(`Metrics`);
    }, []);

    useEffect(() => {
        if (_.isNil(key)) {
            // In main metrics page, redirect to mrr page
            navigate(window.location.pathname + "/mrr", { replace: true });
        }
    }, [key])

    if (_.isNil(key)) {
        return null;
    }

    const items = [
        { label: t('metrics.header.revenue'), isHeader: true },
        { label: t('metrics.metric.mrr'), value: "mrr", link: getCompanySpecificUrl("/metrics/mrr") },
        { label: t('metrics.metric.arr'), value: "arr", link: getCompanySpecificUrl("/metrics/arr") },
        { label: t('metrics.metric.mrr_growth'), value: "mrr_growth", link: getCompanySpecificUrl("/metrics/mrr_growth") },
        { label: t('metrics.metric.total_revenue'), value: "total_revenue", link: getCompanySpecificUrl("/metrics/total_revenue") },
        { label: t('metrics.metric.fees'), value: "fees", link: getCompanySpecificUrl("/metrics/fees") },
        { label: t('metrics.metric.net_revenue'), value: "net_revenue", link: getCompanySpecificUrl("/metrics/net_revenue") },
        { label: t('metrics.metric.other_revenue'), value: "other_revenue", link: getCompanySpecificUrl("/metrics/other_revenue") },
        { label: t('metrics.metric.invoiced_revenue'), value: "total_invoiced_revenue", link: getCompanySpecificUrl("/metrics/total_invoiced_revenue") },

        { label: t('metrics.header.subscriptions'), isHeader: true },
        { label: t('metrics.metric.active_subscriptions'), value: "active_subs", link: getCompanySpecificUrl("/metrics/active_subs") },
        { label: t('metrics.metric.new_subscriptions'), value: "new_subs", link: getCompanySpecificUrl("/metrics/new_subs") },
        { label: t('metrics.metric.upgrades'), value: "upgrades", link: getCompanySpecificUrl("/metrics/upgrades") },
        { label: t('metrics.metric.downgrades'), value: "downgrades", link: getCompanySpecificUrl("/metrics/downgrades") },
        { label: t('metrics.metric.failed_charges'), value: "failed_charges", link: getCompanySpecificUrl("/metrics/failed_charges") },
        { label: t('metrics.metric.refunds'), value: "refunds", link: getCompanySpecificUrl("/metrics/refunds") },
        
        { label: t('metrics.header.licenses'), isHeader: true },
        { label: t('metrics.metric.licenses'), value: "licenses", link: getCompanySpecificUrl("/metrics/licenses") },
        { label: t('metrics.metric.arpl'), value: "arpl", link: getCompanySpecificUrl("/metrics/arpl") },

        { label: t('metrics.header.credits'), isHeader: true },
        { label: t('metrics.metric.credit_amounts_created'), value: "credit_amounts_created", link: getCompanySpecificUrl("/metrics/credit_amounts_created") },
        { label: t('metrics.metric.credit_amounts_consumed'), value: "credit_amounts_consumed", link: getCompanySpecificUrl("/metrics/credit_amounts_consumed") },
        { label: t('metrics.metric.credit_units_created'), value: "credit_units_created", link: getCompanySpecificUrl("/metrics/credit_units_created") },
        { label: t('metrics.metric.credit_units_consumed'), value: "credit_units_consumed", link: getCompanySpecificUrl("/metrics/credit_units_consumed") },

        { label: t('metrics.header.customer'), isHeader: true },
        { label: t('metrics.metric.active_customers'), value: "active_customers", link: getCompanySpecificUrl("/metrics/active_customers") },
        { label: t('metrics.metric.new_customers'), value: "new_customers", link: getCompanySpecificUrl("/metrics/new_customers") },
        { label: t('metrics.metric.arpu'), value: "arpu", link: getCompanySpecificUrl("/metrics/arpu") },
        { label: t('metrics.metric.historical_arpu'), value: "historical_arpu", link: getCompanySpecificUrl("/metrics/historical_arpu") },
        { label: t('metrics.metric.ltv'), value: "ltv", link: getCompanySpecificUrl("/metrics/ltv") },
        { label: t('metrics.metric.reactivations'), value: "reactivations", link: getCompanySpecificUrl("/metrics/reactivations") },

        { label: t('metrics.header.connected_accounts'), isHeader: true },
        { label: t('metrics.metric.revenue_connected_accounts'), value: "revenue_connected_accounts", link: getCompanySpecificUrl("/metrics/revenue_connected_accounts") },
        { label: t('metrics.metric.application_fees_connected_accounts'), value: "application_fees_connected_accounts", link: getCompanySpecificUrl("/metrics/application_fees_connected_accounts") },

        { label: t('metrics.header.trials'), isHeader: true },
        { label: t('metrics.metric.new_trials'), value: "new_trials", link: getCompanySpecificUrl("/metrics/new_trials") },
        { label: t('metrics.metric.active_trials'), value: "active_trials", link: getCompanySpecificUrl("/metrics/active_trials") },
        { label: t('metrics.metric.converted_trials'), value: "converted_trials", link: getCompanySpecificUrl("/metrics/converted_trials") },

        { label: t('metrics.header.churn'), isHeader: true },
        { label: t('metrics.metric.user_churn'), value: "user_churn", link: getCompanySpecificUrl("/metrics/user_churn") },
        { label: t('metrics.metric.revenue_churn'), value: "revenue_churn", link: getCompanySpecificUrl("/metrics/revenue_churn") },
        { label: t('metrics.metric.net_revenue_churn'), value: "net_revenue_churn", link: getCompanySpecificUrl("/metrics/net_revenue_churn") },
        { label: t('metrics.metric.churned_customers'), value: "churned_customers", link: getCompanySpecificUrl("/metrics/churned_customers") },
        false && { label: t('metrics.metric.churned_subscriptions'), value: "churned_subs", link: getCompanySpecificUrl("/metrics/churned_subs") }
    ];

    const onItemSelect = (name, value) => {
        if (name === "key") {
            navigate(getCompanySpecificUrl("/metrics/" + value));
        }
    }

    return (
        <BaseContainer>
            <div className="d-flex flex-row gap-4">
                <SecondaryLeftNav activeKey={key} items={items} className={"d-none d-md-block"} />
                <ContentContainer className="grow">
                    <div className="d-md-none">
                        <BaseForm initialFormFields={initialFields} onFieldChange={onItemSelect}>
                            <div className="d-flex flex-row gap-3 align-items-center">
                                <span className="body2">Metric:</span>
                                <BaseForm.Input colSpan="4" type="select" name="key"
                                                formClassName="thin"
                                                label="Metric" showSearch={false} hideLabel
                                                options={_.filter(items, (i) => !i.isHeader)} />
                            </div>
                        </BaseForm>
                        <br/>
                    </div>
                    <Section>
                        <Metric
                            metricKey={_.toUpper(key)}
                            showBreakout={false}
                            defaultDateRange='This Month'
                            showGroupingOptions={true}
                        />
                    </Section>
                    <Section>
                        <MetricSummary metricKey={_.toUpper(key)} />
                    </Section>
                </ContentContainer>
            </div>
        </BaseContainer>
    );
}

export default Metrics;
