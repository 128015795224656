import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverPost } from "../../server";

function usePostSubscriptionChangeByCustomerPreview({ options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [invoicePreview, setPreviewInvoice] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = ({ sID, payload }) => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverPost(getApiUrl(`/subscriptions/${sID}/change_by_customer/preview`), payload, options).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                setPreviewInvoice(res);
            }
        });
    };

    const onReset = () => {
        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        setPreviewInvoice(null);
        setLoading(false)
    }

    return {
        invoicePreview: invoicePreview,
        isInvoicePreviewLoading: isLoading,
        onSearchInvoicePreview: onSearch,
        onSearchInvoicePreviewReset: onReset
    };
}

export default usePostSubscriptionChangeByCustomerPreview;
