import '../App.scss';
import {BaseContext, IntegrationContext, UserContext} from '../helpers/common'
import React, {useContext} from 'react';
import BaseContainer from './BaseContainer';
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import Label from "./Label";
import {
    getFeatureDescription, isActionMappingAvailable,
    isActionMappingEnabled, isFieldMappingAvailable,
    isFieldMappingEnabled,
    isSyncEnabled
} from "../helpers/integrations";
import SecondaryLeftNav from "./SecondaryLeftNav";
import Breadcrumb from "./Breadcrumb";
const _ = require('lodash');

function IntegrationContainer(props) {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const { isSuperUser } = useContext(UserContext);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const outletContext = useOutletContext();
    let { key, id } = useParams();

    let items = [
        { label: "General", isHeader: true },
        {
            'label': 'Home',
            'link': getIntegrationSpecificUrl("/dashboard"),
            'value': 'home',
        }, isSuperUser && isSyncEnabled(integration) && {
            'label': 'Syncs',
            'link': getIntegrationSpecificUrl("/syncs"),
            'value': 'syncs',
        }, isSuperUser && isSyncEnabled(integration) && {
            'label': 'Matches',
            'link': getIntegrationSpecificUrl("/references/customer"),
            'value': 'references',
        }
    ]
    items = [];

    _.each(integration.available_features, (feature) => {
        const details = getFeatureDescription(feature);
        items.push({ 'label': details.title, 'value': feature, isHeader: true })
        if (isFieldMappingAvailable(integration, feature)) {
            items.push({
                'label': "Field Mapping",
                'link': getIntegrationSpecificUrl(`/feature/${feature.toLowerCase()}`),
                'value': feature,
            })
        }
        if (isActionMappingAvailable(integration, feature)) {
            items.push({
                'label': "Action Mapping",
                'link': getIntegrationSpecificUrl(`/feature/${feature.toLowerCase()}/actions`),
                'value': feature,
            })
        }
    })

    items.push({ label: "Settings", isHeader: true })
    items.push({ label: 'Logs', link: getIntegrationSpecificUrl("/logs"), value: 'logs' })
    items.push({ label: 'Settings', link: getIntegrationSpecificUrl("/settings"), value: 'settings' })

    const breadcrumbItems = [
        { name: 'Integrations', href: getCompanySpecificUrl(`/integrations`), current: false },
        { name: integration.name, current: true }
    ]

    return (
        <BaseContainer>
            <div className="flex flex-row">
                <Breadcrumb items={breadcrumbItems} className="grow" />
                <div className="shrink-0">
                    {
                        integration.live_mode ?
                            <Label.Success>Live</Label.Success>
                            : <Label.Warning>Test</Label.Warning>
                    }
                </div>
            </div>
            <div className="flex flex-row gap-3">
                <div className="shrink-0">
                    <div className="grow text-lg font-semibold leading-6 text-gray-900">{ integration.name }</div>
                    <SecondaryLeftNav className="mt-2" activeKey={key} items={items} />
                </div>
                <div className="grow">
                    <Outlet context={outletContext} />
                </div>
            </div>
        </BaseContainer>
    );
}

export default IntegrationContainer;
