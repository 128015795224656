import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverFetch} from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import PaymentListSection from '../../components/PaymentListSection';
import {useSearchParams} from "react-router-dom";

function Payments() {
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const [metricsSummary, setMetricsSummary] = useState(null)
    const [ searchParams ] = useSearchParams();
    let customer_id = searchParams.get('customer_id');
    const [disableInitialFilters, setDisableInitialFilters] = useState(customer_id != null);
    const [extraQueryFilters, setExtraQueryFilters] = useState({
        customer_id: customer_id || null,
    });

    useEffect(() => {
        setPageTitle(`Payments`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/reports/payments_metrics_summary`)).then(res => {
            setMetricsSummary(res)
        })
    }, []);

    useEffect(() => {
        setDisableInitialFilters(customer_id != null);
        setExtraQueryFilters({
            customer_id: customer_id || null,
        })
    }, [customer_id]);

    return (
        <BaseContainer>
            <ContentContainer>
                <PaymentListSection
                    includeCustomer={true} showTableActions={true} metricsSummary={metricsSummary}
                    disableInitialFilters={disableInitialFilters}
                    extraQueryFilters={extraQueryFilters}
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default Payments;
