import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import {serverDelete, serverPost} from '../../helpers/server';
import { BaseContext, getItemTypeDescription } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditEntitlementModal from '../../components/modals/AddOrEditEntitlementModal';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import CopyableComponent from '../../components/CopyableComponent';
import { Button } from 'react-bootstrap';
import EmptyState from "../../components/EmptyState";
import DeleteButton from "../../components/DeleteButton";
import Notification from '../../components/Notification';
import {TrashIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function Entitlements() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [entitlements, setEntitlements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [entitlementToEdit, setEntitlementToEdit] = useState(null);
    const [showAddOrEditEntitlementModal, setShowAddOrEditEntitlementModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Entitlements`);
    }, []);

    const fetchData = (skipCache=false) => {
        setLoading(true);
        serverPost(getApiUrl("/entitlements/find"), { company_id: company.id }, { skipCache }).then((res) => {
            setLoading(false);
            setEntitlements(res);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = (didUpdate) => {
        setShowAddOrEditEntitlementModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onAddEntitlement = () => {
        setShowAddOrEditEntitlementModal(true);
        setEntitlementToEdit(null);
    }

    const onEditEntitlement = (item) => {
        setShowAddOrEditEntitlementModal(true);
        setEntitlementToEdit(item);
    }

    const onDeleteError = async (errorRes, errorJsonMessage) => {
        let errorJson = errorJsonMessage;
        if (!errorJson) {
            errorJson = await errorRes.json();
        }
        Notification.Danger(errorJson.error_message, 5000);
    }

    const onDeleteEntitlement = (item) => {
        serverDelete(getApiUrl(`/entitlements/${item.id}`), onDeleteError).then(res => {
            if (res) {
                Notification.Success("Successfully deleted entitlement");
                fetchData(true);
            }
        });
    }

    const typeMap = {
        "BOOL": "Boolean",
        "NUMBER": "Number",
        "TEXT": "Text",
        "ENUM": "Choice",
    }

    const aggregatorTypeMap = {
        "OR": "Or",
        "AND": "And",
        "ADD": "Add",
        "MINIMUM": "Minimum",
        "MAXIMUM": "Maximum",
        "COALESCE": "Coalesce",
    }

    const showChoicesColumn = _.some(entitlements, (e) => e.type === "ENUM");
    return (
        <BaseContainer>
            <ContentContainer>
                <Section
                    title="Entitlements"
                    subtitle="Entitlements are an easy way to track what features are available for specific product, plans and subscriptions. They are not tied to any billing metrics and are purely for feature access and tracking purposes."
                    loading={loading} actions={!loading && !_.isEmpty(entitlements) && [
                    {variant: "primary", icon: "fa-plus", label: "Create Entitlement", onClick: onAddEntitlement}
                ]}>
                    {
                        _.isEmpty(entitlements) ?
                            <EmptyState
                                title={"No entitlements."}
                                subtitle={"Get started by creating a new one."}
                                buttonLabel={"New Entitlement"}
                                onClick={onAddEntitlement}
                            />
                        : <MapleTable className="mt-2">
                            <MapleTable.Content>
                                <thead>
                                    <tr>
                                        <MapleTable.TH>{ t('common.name') }</MapleTable.TH>
                                        <MapleTable.TH></MapleTable.TH>
                                        <MapleTable.TH>{ t('billable_item.item_type') }</MapleTable.TH>
                                        <MapleTable.TH>Aggregator Type</MapleTable.TH>
                                        {
                                            showChoicesColumn &&
                                                <MapleTable.TH>Choices</MapleTable.TH>
                                        }
                                        <MapleTable.TH className="fit-content text-end"></MapleTable.TH>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(entitlements, (item, i) =>
                                        <tr key={i}>
                                            <td>{ item.name }</td>
                                            <td>
                                                <CopyableComponent value={item.key}>{ item.key }</CopyableComponent>
                                            </td>
                                            <td>{ typeMap[item.type] }</td>
                                            <td>{ aggregatorTypeMap[item.aggregator_type] }</td>
                                            {
                                                showChoicesColumn &&
                                                    <td><span className="text-sm font-normal text-gray-500">{ _.join(item.possible_values, ", ") }</span></td>
                                            }
                                            <td className="text-end">
                                                <div className="flex flex-row text-end items-center h-full justify-end gap-1">
                                                    <Button variant="text-primary" size="sm" onClick={() => onEditEntitlement(item)}>Edit</Button>
                                                    <DeleteButton size="sm" onDelete={() => onDeleteEntitlement(item)}><i className="fa fa-trash"/></DeleteButton>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    }

                </Section>
            </ContentContainer>
            <AddOrEditEntitlementModal show={showAddOrEditEntitlementModal} onClose={onModalClose} itemToEdit={entitlementToEdit} />
        </BaseContainer>
    );
}

export default Entitlements;
