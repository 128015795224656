import '../App.scss';
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import classnames from 'classnames';
const _ = require("lodash");

function SectionNav(props) {
    const [shouldAutoMatch, setShouldAutoMatch] = useState(true);

    useEffect(() => {
        if (!_.isNil(props.shouldAutoMatch)) {
            setShouldAutoMatch(props.shouldAutoMatch);
        }
    }, [props.shouldAutoMatch]);

    const onClick = (event, itemId) => {
        if (props.onClick) {
            event.preventDefault();
            props.onClick(itemId);
        }
    }

    return (
        <>
        <div className={classnames("section-nav", props.size === "sm" ? "small" : "", props.className)}>
            <div className="nav-wrapper left">
                <NavItems items={props.items} shouldAutoMatch={shouldAutoMatch} onClick={onClick}/>
            </div>
            {
                !_.isEmpty(props.rightItems) &&
                    <div className="nav-wrapper right">
                        <NavItems items={props.rightItems} shouldAutoMatch={shouldAutoMatch} onClick={onClick}/>
                    </div>
            }
        </div>
        </>
    );
}

function NavItems(props) {

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (props.shouldAutoMatch) {
            if (item.matchPathnameOnly) {
                return item.href === window.location.pathname;
            } else {
                return item.href === (window.location.pathname + window.location.search);
            }
        } else {
            return false;
        }
    }

    const selectedItem = _.find(props.items, (item) => isItemActive(item));
    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={selectedItem && selectedItem.id}
                    onChange={(event) => props.onClick(event, event.target.value)}
                >
                    {
                        _.map(props.items, (item, i) =>
                            <option key={i} value={item.id}>{item.label}</option>
                        )
                    }
                </select>
            </div>
            <div className="hidden sm:block">
                <div className="border-b border-gray-200">
                    <nav className="-mb-px flex space-x-4" aria-label="Tabs">
                        {
                            _.map(props.items, (item, i) =>
                                <Link
                                    key={i}
                                    to={item.href}
                                    onClick={(event) => props.onClick(event, item.id)}
                                    className={classnames(
                                        isItemActive(item)
                                            ? 'border-indigo-500 text-indigo-600'
                                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                        'whitespace-nowrap border-b-2 py-2.5 px-1 text-sm font-medium'
                                    )}
                                    tabIndex="-1"
                                    aria-current={item.active ? 'page' : undefined}
                                >
                                    {item.label}
                                </Link>
                            )
                        }
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default SectionNav;
