import React, { useEffect, useContext, useMemo, useRef, useState } from "react";
import { BaseContext } from "../../helpers/common";
import BaseContainer from "../../components/BaseContainer";
import ContentContainer from "../../components/ContentContainer";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import CreditLogsTable from "../../components/CreditLogsTable";
import usePostFindCreditLogsApi from "../../helpers/hooks/api/usePostFindCreditLogsApi";
import { isEqual } from "lodash";
import Section from "../../components/Section";
import Loader from "../../components/Loader";

function CreditLogs(props) {
    const { t, i18n } = useTranslation("common");

    const { setPageTitle, company } = useContext(BaseContext);

    const [queryParams] = useSearchParams();
    const customerId = queryParams.get("customer_id");
    const disableFilterCache = queryParams.get("disableFilterCache") === "true";

    const [creditLogsSortPref, setCreditLogsSortPref] = useState("createdAtDesc");

    const findCreditLogsFiltersCacheKey = `${company.id}_filter_cache_find_credit_logs`;
    const creditLogFilterOptions = useMemo(
        () => [
            {
                title: t("credit_logs.table.filter.type.title"),
                type: "select",
                name: "type",
                options: [
                    {
                        value: "ALL",
                        label: t("credit_logs.table.filter.type.all"),
                    },
                    {
                        value: "AMOUNT",
                        label: t("credit_logs.table.filter.type.amount"),
                    },
                    {
                        value: "UNITS",
                        label: t("credit_logs.table.filter.type.units"),
                    },
                ],
            },
            {
                title: t("credit_logs.table.filter.action_type.title"),
                type: "select",
                name: "action_type",
                options: [
                    {
                        value: "ALL",
                        label: t("credit_logs.table.filter.action_type.all"),
                    },
                    {
                        value: "ISSUED",
                        label: t("credit_logs.table.filter.action_type.issued"),
                    },
                    {
                        value: "CONSUMED",
                        label: t("credit_logs.table.filter.action_type.consumed"),
                    },
                    {
                        value: "EXPIRED",
                        label: t("credit_logs.table.filter.action_type.expired"),
                    },
                    {
                        value: "REVOKED",
                        label: t("credit_logs.table.filter.action_type.revoked"),
                    },
                ],
            },
        ],
        [i18n.language]
    );
    const defaultSelectedCreditLogsFilters = useMemo(
        () => ({
            type: "ALL",
            action_type: "ALL",
            customer_id: customerId,
        }),
        [customerId]
    );
    const cachedCreditLogsSelectedFilters = useMemo(
        () => (disableFilterCache ? null : JSON.parse(localStorage.getItem(findCreditLogsFiltersCacheKey))),
        [disableFilterCache, findCreditLogsFiltersCacheKey]
    );
    const [selectedCreditLogsFilters, setSelectedCreditLogsFilters] = useState(
        () => cachedCreditLogsSelectedFilters || defaultSelectedCreditLogsFilters
    );
    const onSelectedCreditLogsFiltersChange = (newSelectedFilters) => {
        setSelectedCreditLogsFilters(newSelectedFilters);
        if (!disableFilterCache) {
            localStorage.setItem(findCreditLogsFiltersCacheKey, JSON.stringify(newSelectedFilters));
        }
    };

    const { creditLogs, totalUnpaginatedCreditLogsCount, isCreditLogsLoading, hasMoreCreditLogs, onCreditLogsSearch } =
        usePostFindCreditLogsApi({
            limit: 20,
            query: {
                search: selectedCreditLogsFilters?.search,
                type: selectedCreditLogsFilters?.type === "ALL" ? null : selectedCreditLogsFilters?.type,
                customer_id: selectedCreditLogsFilters?.customer_id,
                action_type: selectedCreditLogsFilters?.action_type === "ALL" ? null : selectedCreditLogsFilters?.action_type,
            },
            sortKey: creditLogsSortPref,
        });

    const isCreditLogsTableEmpty = useMemo(
        () =>
            creditLogs.length === 0 &&
            !isCreditLogsLoading &&
            isEqual(defaultSelectedCreditLogsFilters, selectedCreditLogsFilters),
        [creditLogs.length, isCreditLogsLoading, selectedCreditLogsFilters]
    );

    useEffect(() => {
        setPageTitle(`${t("credit_logs.page_title")}`);
    }, [i18n.language]);

    useEffect(() => {
        onCreditLogsSearch({ keepPreviousData: false });
    }, [creditLogsSortPref, selectedCreditLogsFilters]);

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title={t("credit_logs.section_title")}>
                    <InfiniteScroll
                        dataLength={creditLogs.length}
                        next={() => {
                            onCreditLogsSearch({ keepPreviousData: true });
                        }}
                        hasMore={hasMoreCreditLogs}
                        scrollableTarget="content-wrapper"
                        loader={<Loader loading={true} />}
                    >
                        {isCreditLogsTableEmpty ? (
                            <span className={"text-sm text-gray-500"}>{t("credit_logs.table.no_credits")}</span>
                        ) : (
                            <CreditLogsTable
                                showTableActions={true}
                                creditLogsData={{
                                    creditLogs,
                                    totalUnpaginatedCreditLogsCount,
                                    isCreditLogsLoading,
                                    hasMoreCreditLogs,
                                }}
                                filterData={{
                                    creditLogFilterOptions,
                                    defaultSelectedCreditLogsFilters,
                                    cachedCreditLogsSelectedFilters,
                                    onSelectedCreditLogsFiltersChange,
                                }}
                                sortData={{
                                    creditLogsSortPref,
                                    setCreditLogsSortPref,
                                }}
                            />
                        )}
                    </InfiniteScroll>
                </Section>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CreditLogs;
