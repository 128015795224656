import '../App.scss';
import React, { useState, useEffect } from 'react';
import { Table, Row, Col, Button } from 'react-bootstrap';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import MapleTable from './MapleTable';
import classnames from 'classnames';
const _ = require("lodash");

function KeyValueDisplay(props) {
    const [items, setItems] = useState([]);
    const [key, setKey] = useState("key");
    const [value, setValue] = useState("value");
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!_.isNil(props.keyField)) {
            setKey(props.keyField);
        } else {
            setKey("key");
        }
    }, [props.keyField]);

    useEffect(() => {
        if (!_.isNil(props.valueField)) {
            setValue(props.valueField);
        } else {
            setValue("value");
        }
    }, [props.valueField]);

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(false);
        } else {
            if (props.isEditing && items.length === 0) {
                // Set up one item so there is at least one to edit.
                setItems([{}]);
            }
            setIsEditing(props.isEditing)
        }
    }, [props.isEditing])

    const processItemsProps = () => {
        if (Array.isArray(props.items)) {
            setItems(props.items);
        } else {
            const keys = _.keys(props.items);
            const items = [];
            _.each(keys, (akey, i) => {
                const item = {};
                item[key] = akey;
                item[value] = props.items[akey];
                items.push(item)
            });
            setItems(items);
        }
    }

    useEffect(() => {
        if (props.isEditing) {
            return;
        }
        processItemsProps();
    }, [props.items, key, value]);

    const onSubmit = (data) => {
        const keys = _.keys(data);
        const values = _.values(data);
        const keyValueMap = _.mapValues(_.keyBy(_.values(data), 'key'), 'value');
        delete keyValueMap[""];
        delete keyValueMap[null];
        if (props.onUpdateKeyValues) {
            props.onUpdateKeyValues(keyValueMap);
        }
    }

    const deleteRow = (i) => {
        const newItems = [...items];
        newItems.splice(i, 1);
        if (_.isEmpty(newItems)) {
            setItems([{}]);
        } else {
            setItems(newItems);
        }
    }

    const addNewRow = (i) => {
        const newItems = [...items];
        newItems.push({});
        setItems(newItems);
    }

    const cancelEditing = () => {
        if (props.onCancelEditing) {
            props.onCancelEditing();
        }
        processItemsProps();
    }

    if (isEditing) {
        return (
            <div className="max-w-3xl">
            <BaseForm initialFormFields={items} onSubmit={onSubmit} >
                {
                    _.map(_.keys(items), (item, i) =>
                        <Row key={i}>
                            <BaseForm.Input type="text" colSpan="5" name={`${i}.key`} placeholder="Key" />
                            <BaseForm.Input type="text" colSpan="5" name={`${i}.value`} placeholder="Value" />
                            <Col md="2">
                                <DeleteButton onDelete={() => deleteRow(i)}/>
                            </Col>
                        </Row>
                    )
                }
                <div className="flex flex-row gap-3 items-center">
                    <div className="flex-grow-0">
                        <Button variant="text-primary" size="sm" onClick={addNewRow}><i className="fa fa-plus"/> Add New</Button>
                    </div>
                    <div className="flex-grow-1">
                    </div>
                    <div className="flex-grow-0 d-flex flex-row gap-3">
                        <Button variant="link" onClick={cancelEditing}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </div>
                </div>
            </BaseForm>
            </div>
        )
    } else {
        return (
            <div className={classnames("max-w-3xl", "flex-grow-1", props.className)}>
                <MapleTable>
                    <MapleTable.Content>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(items, (item, i) =>
                                <tr key={i}>
                                    <td width="50%">{ item[key] }</td>
                                    <td>
                                        { item[props.valueField || "value"] || <span className="gray2">None</span> }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </div>
        );
    }
}

export default KeyValueDisplay;
