import '../../../App.scss';
import React from 'react';
import { getIntegrationInterfaceForKey } from '../../../helpers/integrations';
import { Container, Row, Col } from 'react-bootstrap';
import {notifyEvent, serverPatch} from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import { useEffect, useContext } from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Label from "../../../components/Label";

function API(props) {
    const navigate = useNavigate();
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    let { key, id } = useParams();
    const { next } = useOutletContext();
    const { setShowNext, setShowPrev, setShowProgress } = useOutletContext();
    const integrationInterface = getIntegrationInterfaceForKey(integration.key);

    useEffect(() => {
        setPageTitle(`Integration Setup - API Key`);
    }, []);

    useEffect(() => {
        setShowNext(false);
        setShowPrev(false);
        setShowProgress(false);
    }, []);

    useEffect(() => {
        if (integration && integration.auth_type === "oauth2") {
            next();
        }
    }, [])

    useEffect(() => {
        checkIntegrationStatus();
    }, [integration]);

    const checkIntegrationStatus = () => {
        if (integration.status === "ACTIVE") {
            window.location.href = getIntegrationSpecificUrl("/settings");
        } else if (integration.status === "SETUP_INCOMPLETE") {
            navigate(getIntegrationSpecificUrl("/setup/data"));
        }
    }

    const updateApiKey = (data) => {
        const updateData = {
            authentication: {
                api_key: data.api_key,
                api_id: data.api_id,
                client_secret: data.client_secret
            }
        }
        serverPatch(getApiUrl(`/integrations/${id}`), updateData).then((res) => {
            if (res) {
                notifyEvent('integration_settings');
                checkIntegrationStatus();
            }
        })
    }

    return (
        <div className="flex flex-col gap-3 mt-4 max-w-3xl mx-auto">
            <div className="flex flex-row gap-3 items-center">
                <div className="grow-0 shrink-0">
                    <img src={ integration.icon } alt="Integration Logo" className="h-20 w-20 rounded-md"/>
                </div>
                <div className="flex flex-col gap-1 grow">
                    <h2>{ integration.name } API Key</h2>
                    <span className="text-base text-gray-700">Enter API authentication information.</span>
                </div>
                <div className="self-center">
                    {
                        integration.live_mode ?
                            <Label.Success>Live</Label.Success>
                            : <Label.Warning>Test</Label.Warning>
                    }
                </div>
            </div>
            <Container className="integration-flow-content">
            <Row>
                <Col md={{ span: "10", offset: "1" }}>
                    { integrationInterface.getApiInstructions(updateApiKey) }
                </Col>
            </Row>
            </Container>
        </div>
    );
}

export default API;
