import '../../App.scss';
import React, {useEffect, useState, useContext, useCallback, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import {serverFetch, serverPatch, serverPost} from '../../helpers/server';
import {BaseContext, renderTags} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import AddOrEditProductModal from '../../components/modals/AddOrEditProductModal';
import MapleTable from '../../components/MapleTable';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import { Button, Image } from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import Label from "../../components/Label";
import SectionNav from "../../components/SectionNav";
import ArchiveButton from "../../components/ArchiveButton";
import EmptyState from "../../components/EmptyState";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
import classnames from 'classnames';
const _ = require('lodash');

function Products() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [products, setProducts] = useState([]);
    const [showAddOrEditProductModal, setShowAddOrEditProductModal] = useState(false);
    const [productToEdit, setProductToEdit] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [filters, setFilters] = useState([]);
    const [meta, setMeta] = useState({});
    const [fromKey, setFromKey] = useState(null);
    const [query, setQuery] = useState(null);
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});
    const [hasArchivedProducts, setHasArchivedProducts] = useState(false);
    const [activeNav, setActiveNav] = useState("ACTIVE");

    const defaultSelectedFilters = useMemo(() => {
        return {"state":"ACTIVE"}
    }, []);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_products");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
        return null;
    }, [])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        setPageTitle(`Products`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 100
        const params = {
            company_id: company.id,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: selectedFilters.search,
                state: selectedFilters.state
            },
            sort_key: "createdAtDesc",
            include_meta: restart
        }
        serverPost(getApiUrl("/products/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    const sortedResults = _.sortBy(results, (r) => r.state === "ACTIVE" ? 0: 1)
                    setProducts(sortedResults);
                    setMeta(res.meta);
                } else {
                    const sortedResults = _.sortBy(_.concat(products, results), (r) => r.state === "ACTIVE" ? 0: 1)
                    setProducts(sortedResults);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, []);

    useEffect(() => {
        const stateOptions = [
            { value: null, label: "All" },
            { value: "ACTIVE", label: "Active" },
            { value: "ARCHIVED", label: "Archived" }
        ]

        setFilters([
            { title: "Status", type: "select", name: "state", options: stateOptions }
        ])
    }, [])

    useEffect(() => {
        onSearch(true);
    }, [query, selectedFilters]);

    // useEffect(() => {
    //     setHasArchivedProducts(!_.isEmpty(_.filter(products, p => p.state === "ARCHIVED")));
    //     setFilteredProducts(_.filter(products, p => p.state === activeNav));
    // }, [products, activeNav])

    const onModalClose = () => {
        setShowAddOrEditProductModal(false);
        onSearch(true);
    }

    const onParamsChange = (params) => {
        if (!_.isEqual(selectedFilters, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            setQuery(params.search);
            localStorage.setItem(company.id + "_filter_cache_products", JSON.stringify(newFilters));
        }
    }

    const addProduct = () => {
        setProductToEdit(null);
        setShowAddOrEditProductModal(true);
    }

    const archiveProduct = (product) => {
        serverPatch(getApiUrl(`/products/${product.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                onSearch(true);
            }
        })
    }

    const editProduct = (event, product) => {
        event.stopPropagation();
        setProductToEdit(product);
        setShowAddOrEditProductModal(true);
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const tabItems = [{
        'label': 'Active',
        'id': 'ACTIVE',
        active: activeNav === "ACTIVE"
    }, {
        'label': 'Archived',
        'id': 'ARCHIVED',
        active: activeNav === "ARCHIVED"
    }]

    const isTaxAutomatic = settings.collect_taxes_type === "automatic";
    const isEmpty = useMemo(() => {
        return (
            products.length === 0 &&
            _.isEqual(defaultSelectedFilters, selectedFilters)
        );
    }, [products, selectedFilters, defaultSelectedFilters]);
    const showTagsColumn = _.some(products, (p) => !_.isEmpty(p.tags))
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={products.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section title={t('common.products')} loading={loading} actions={!loading && !isEmpty && [{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Create Product",
                            onClick: addProduct
                        }]}>
                        {
                            hasArchivedProducts &&
                            <>
                                <SectionNav items={tabItems} onClick={onNavClick} />
                            </>
                        }
                        {
                            isEmpty ?
                                <EmptyState
                                    title={"No products."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Product"}
                                    onClick={addProduct}
                                />
                            : <MapleTable className="mt-2">
                                    <MapleTableHeaderWithActions
                                        showSearch={true}
                                        searchPlaceholder="Search Products"
                                        showFilters={true}
                                        filters={filters}
                                        meta={meta}
                                        defaultSelectedFilters={defaultSelectedFilters}
                                        cachedSelectedFilters={cachedSelectedFilters}
                                        onParamsChange={onParamsChange}
                                    />
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <MapleTable.TH>Name</MapleTable.TH>
                                                {
                                                    showTagsColumn &&
                                                        <MapleTable.TH>Tags</MapleTable.TH>
                                                }
                                                <MapleTable.TH></MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                                <MapleTable.TH></MapleTable.TH>
                                                <MapleTable.TH className="text-end"></MapleTable.TH>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                        {
                                            _.map(products, (product, i) =>
                                                 <MapleTable.TR key={i} className={classnames("cursor-pointer", product.state === "ARCHIVED"? "bg-gray-50":"")} href={getCompanySpecificUrl(`/product/${product.id}`)}>
                                                    <td>
                                                        <div className="flex flex-row align-items-center gap-2">
                                                        {
                                                            product.image_url ?
                                                                <div className="small-product-logo align-self-center">
                                                                    <Image src={product.image_url} />
                                                                </div>
                                                            : <i className="fa fa-product-hunt fa-primary" style={{ fontSize: "35px" }}/>
                                                        }
                                                        <div className="flex flex-col">
                                                            <span>{ product.name }</span>
                                                            <span className="-mt-1 text-gray-400">{ product.description }</span>
                                                        </div>
                                                        </div>
                                                    </td>
                                                     {
                                                         showTagsColumn &&
                                                            <td>{ renderTags(product.tags) || <span className="gray2">None</span> }</td>
                                                     }
                                                    <td>
                                                        {
                                                            product.state === "ACTIVE" ?
                                                                <Label.Success>Active</Label.Success>
                                                            : <Label.Info>Archived</Label.Info>
                                                        }
                                                    </td>
                                                    <td>
                                                    {
                                                        isTaxAutomatic && _.isNil(product.tax_code) &&
                                                            <span><i className="fa fa-triangle-exclamation warning-color"/> Tax Code Missing</span>
                                                    }
                                                    </td>
                                                     <td><span className="text-gray-400 whitespace-nowrap">{ product.product_pricings && `${product.product_pricings.length} prices` }</span></td>
                                                    <td className="text-end">
                                                    {
                                                        product.state !== "ARCHIVED" &&
                                                            <div className="flex flex-row justify-content-end gap-1">
                                                                <ArchiveButton size="sm" onDelete={() => archiveProduct(product)}></ArchiveButton>
                                                            </div>
                                                    }
                                                    </td>
                                                 </MapleTable.TR>
                                            )
                                        }
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
            <AddOrEditProductModal show={showAddOrEditProductModal} onClose={onModalClose} itemToEdit={productToEdit} />
        </BaseContainer>
    );
}

export default Products;
