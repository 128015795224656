import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverPatch} from '../../helpers/server';
import {Row} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getCreditNoteReasonOptions} from "../../helpers/invoices";
const _ = require("lodash");

function EditCreditNoteModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [creditNote, setCreditNote] = useState(null);

    useEffect(() => {
        setCreditNote(props.creditNote);
    }, [props.creditNote])

    const onSubmit = (data, errorHandler) => {
        if (!creditNote) {
            return;
        }
        serverPatch(getApiUrl(`/credit_notes/${creditNote.id}`), data, null, errorHandler).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} initialFormFields={creditNote}>
                <BaseSideModal.Header title={"Edit Credit Note"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input
                            colSpan="12" type="select" name="internal_reason" options={getCreditNoteReasonOptions()} showSearch={false}
                            label="Internal Reason" required
                        />
                    </Row>
                    <Row>
                        <BaseForm.Input
                            colSpan="12" type="textarea" name="note" height="100px"
                            label="Memo" placeholder="Customer requested..."
                            description="Add a note to be displayed to the customer."
                        />
                        <BaseForm.Input
                            colSpan="12" type="textarea" name="internal_note" height="100px"
                            label="Internal Note (optional)" placeholder="Customer requested..."
                            description={"This will not be visible to the customer."}
                        />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default EditCreditNoteModal;
