import React, { useEffect, useState, useContext, useMemo, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment/moment";
import { WrenchScrewdriverIcon } from "@heroicons/react/20/solid";
import _ from "lodash";

import "../../App.scss";
import "../../css/invoice.scss";
import { serverPost } from "../../helpers/server";
import {
    BaseContext,
    CustomerContext,
    CustomerPortalContext,
    UserContext,
    currencyFormatFromPrice,
    getInvoicePaymentTermDescription,
    getPaymentMethodDescription,
    renderDescriptionForItemPricing,
} from "../../helpers/common";
import { getSubscriptionStatus } from "../../helpers/subscriptions";
import Section from "../../components/Section";
import DropdownMenu from "../../components/DropdownMenu";
import KeyValueDisplay2 from "../../components/KeyValueDisplay2";
import useGetSubscribtionApi from "../../helpers/hooks/api/useGetSubscriptionApi";
import useGetCustomerPaymentMethodsApi from "../../helpers/hooks/api/useGetCustomerPaymentMethodsApi";
import useGetSubscriptionUsage from "../../helpers/hooks/api/useGetAllSubscriptionPmpUsage";
import ContentBox from "../../components/ContentBox";
import ContentContainer from "../../components/ContentContainer";
import Loader from "../../components/Loader";
import MapleTable from "../../components/MapleTable";
import CustomerPortalSubscriptionUsageChart from "../../components/CustomerPortalSubscriptionUsageChart";
import CustomerPortalCancelSubscriptionSideModal from "../../components/CustomerPortalCancelSubscriptionSideModal";
import Notification from "../../components/Notification";
import usePostSubscriptionChangeByCustomerPreview from "../../helpers/hooks/api/usePostSubscriptionChangeByCustomerPreview";
import BaseForm from "../../components/BaseForm";
import SimpleModal from "../../components/modals/SimpleModal";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import SubmitButton from "../../components/SubmitButton";
import { useTranslation } from "react-i18next";

function CustomerPortalSubscription() {
    const navigate = useNavigate();
    const { subscriptionId } = useParams();
    const { getApiUrl, setPageTitle } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const { accessToken } = useContext(CustomerContext);
    const { portal } = useContext(CustomerPortalContext);

    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(false);
    const [isCancelActionLoading, setIsCancelActionLoading] = useState(false);

    const [showEditSubscription, setShowEditSubscription] = useState(false);
    const [showConfirmEditSubscriptionModal, setShowConfirmEditSubscriptionModal] = useState(false);
    const [isEditActionLoading, setIsEditActionLoading] = useState(false);

    const editSubscriptionFormRef = createRef();

    const { subscription, isSubscriptionLoading, onSubscriptionSearch } = useGetSubscribtionApi({
        subscriptionId,
        options: {
            accessToken,
            skipCache: true,
        },
    });

    const { customerPaymentMethods, isCustomerPaymentMethodsLoading, onCustomerPaymentMethodsSearch } = useGetCustomerPaymentMethodsApi({
        customerID: subscription?.customer_id,
        options: {
            accessToken,
        },
    });

    const { subscriptionPmpsUsage, isSubscriptionPmpsUsageLoading, onSubscriptionPmpsUsageSearch } = useGetSubscriptionUsage({
        subscriptionId: subscriptionId,
        pmpIds: subscription?.product_pricings.flatMap((pp) => pp.product_metric_pricings.map((pmp) => pmp.id)),
        options: { accessToken: accessToken },
    });

    const { invoicePreview, isInvoicePreviewLoading, onSearchInvoicePreview, onSearchInvoicePreviewReset } = usePostSubscriptionChangeByCustomerPreview({
        options: {
            accessToken,
        },
    });

    const hasShowSubscriptionAccess = portal?.options?.subscription?.show;
    const hasCancelSubscriptionAccess = portal?.options?.subscription?.cancellable;
    const hasEditPlanSubscriptionAccess = portal?.options?.subscription?.editable_plan;
    const hasShowPaymentMethodAccess = portal?.options?.payment_methods?.show;

    const hasCustomMetricPricings = useMemo(
        () =>
            subscription?.product_pricings
                .flatMap((pp) => pp.product_metric_pricings)
                .some(
                    (pmp) =>
                        pmp.metric.type === "CUSTOM_METRIC" &&
                        !_.startsWith(pmp.metric.metering_rule.aggregator, "OBJ_") &&
                        !_.includes(["CUSTOMER_LAST_EVER", "LAST_EVER_SUM"], pmp.metric.metering_rule.aggregator)
                ),
        [subscription]
    );

    const pricingsUsageMap = useMemo(
        () =>
            !!subscriptionPmpsUsage
                ? subscriptionPmpsUsage.reduce((map, pmpUsage) => {
                      map[pmpUsage.pmpId] = _.find(
                          pmpUsage.usage,
                          (u) => moment(u.period.start_date).isBefore(moment()) && moment(u.period.end_date).isAfter(moment())
                      )?.value;
                      return map;
                  }, {})
                : {},
        [subscriptionPmpsUsage]
    );

    const initialEditSubscriptionFormFields = useMemo(() => {
        if (!subscription) {
            return;
        }
        return {
            config: _.keyBy(_.cloneDeep(subscription.config_items), "product_metric_pricing_id"),
        };
    }, [subscription]);

    const onChangeSubscriptionPreview = () => {
        const existingConfig = _.keyBy(subscription.config_items, "product_metric_pricing_id");
        const newConfig = editSubscriptionFormRef.current?.getFormData().config;

        let hasFormChanged = false;
        hasFormChanged =
            hasFormChanged ||
            _.some(newConfig, (v, k) => {
                return !_.isEqual(existingConfig[k]?.num_licenses, v?.num_licenses);
            });

        if (!hasFormChanged) {
            onSearchInvoicePreviewReset();
            return;
        }

        onSearchInvoicePreview({
            sID: subscription.id,
            payload: {
                config_items: _.map(
                    {
                        ...existingConfig,
                        ...newConfig,
                    },
                    (v, k) => {
                        return {
                            ...v,
                            product_metric_pricing_id: k,
                        };
                    }
                ),
            },
        });
    }

    const onChangeSubscription = async (sid, payload) => {
        setIsEditActionLoading(true);
        serverPost(getApiUrl(`/subscriptions/${sid}/change_by_customer`), payload, {
            accessToken: accessToken,
        }).then((res) => {
            if (res) {
                setIsEditActionLoading(false);
                onSubscriptionSearch();
                onSearchInvoicePreviewReset();
                setShowEditSubscription(false);
                Notification.Success("Subscription updated");
            }
        });
    };

    const onCancelSubscription = async (sid, cancelReason, cancelReasonOther) => {
        const cancelData = { customer_cancel_reason: cancelReasonOther || cancelReason };
        setIsCancelActionLoading(true);
        serverPost(getApiUrl(`/subscriptions/${sid}/cancel_by_customer`), cancelData, {
            accessToken: accessToken,
        }).then((res) => {
            if (res) {
                setIsCancelActionLoading(false);
                onSubscriptionSearch();
                setShowCancelSubscriptionModal(false);
                Notification.Success("Subscription cancelled");
            }
        });
    };

    useEffect(() => {
        setPageTitle(`Customer Portal - Manage Subscription`);
    }, []);

    useEffect(() => {
        if (portal && (!hasShowSubscriptionAccess || !isMapleUser)) {
            navigate(-1);
        }
    }, [portal, hasShowSubscriptionAccess, isMapleUser]);

    useEffect(() => {
        if (portal && hasShowSubscriptionAccess) {
            onSubscriptionSearch();
        }
    }, [portal, hasShowSubscriptionAccess]);

    useEffect(() => {
        if (portal && hasCustomMetricPricings && subscription) {
            onSubscriptionPmpsUsageSearch();
        }
    }, [portal, hasCustomMetricPricings, subscription]);

    useEffect(() => {
        if (portal && hasShowPaymentMethodAccess && subscription) {
            onCustomerPaymentMethodsSearch();
        }
    }, [portal, subscription, hasShowPaymentMethodAccess]);

    const pageActions = useMemo(() => {
        const actionDropdownItems = [];

        if (hasEditPlanSubscriptionAccess && !subscription?.cancel_config) {
            actionDropdownItems.push({
                id: "edit",
                label: "Edit",
                onClick: () => setShowEditSubscription(true),
            });
        }

        if (hasCancelSubscriptionAccess && !subscription?.cancel_config && !_.isNil(subscription?.renewal_date)) {
            actionDropdownItems.push({
                id: "cancel",
                label: "Cancel",
                onClick: () => setShowCancelSubscriptionModal(true),
            });
        }

        if (actionDropdownItems.length) {
            return (
                <DropdownMenu className="p-2" items={actionDropdownItems}>
                    <WrenchScrewdriverIcon className="h-4 w-4 mr-1" />
                    <span>Actions</span>
                </DropdownMenu>
            );
        }

        return null;
    }, [hasCancelSubscriptionAccess, hasEditPlanSubscriptionAccess, subscription]);

    return (
        <>
            <Notification />
            <ContentContainer className="mt-4 max-w-2xl" title={`Manage Subscription`} customActions={pageActions}>
                {isSubscriptionLoading || isSubscriptionPmpsUsageLoading || isCustomerPaymentMethodsLoading || !subscription ? (
                    <Loader loading={true} />
                ) : (
                    <>
                        <Section title={"Details"} className="mt-4">
                            <CustomerPortalSubscriptionDetails
                                subscription={subscription}
                                hasShowPaymentMethodsAccess={hasShowPaymentMethodAccess}
                                paymentMethod={hasShowPaymentMethodAccess ? _.find(customerPaymentMethods, (m) => m.id === subscription.payment_method_id) : null}
                            />
                        </Section>
                        <Section title={"Product Prices"}>
                            <BaseForm
                                ref={editSubscriptionFormRef}
                                initialFormFields={initialEditSubscriptionFormFields}
                                onSubmit={(vals) => {
                                    setShowConfirmEditSubscriptionModal(true);
                                }}
                                onFieldChange={onChangeSubscriptionPreview}
                            >
                                <MapleTable>
                                    <MapleTable.Content>
                                        <thead>
                                            <tr>
                                                <th>Product</th>
                                                <th>Metric/Quantity</th>
                                                <th>Pricing</th>
                                                {hasCustomMetricPricings && <th>Current Usage</th>}
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200">
                                            {subscription.product_pricings.map((pp, i) => {
                                                return pp.product_metric_pricings.map((pmp, j) => {
                                                    return (
                                                        <tr key={`${pp.id} - ${pmp.id}`}>
                                                            <td>{j === 0 ? pp.product.name : null}</td>
                                                            <td>
                                                                {pmp.metric.type === "ONETIME_METRIC" && (pmp.num_licenses || 0)}
                                                                {pmp.metric.type === "LICENSE_METRIC" &&
                                                                    (showEditSubscription ? (
                                                                        <BaseForm.Input
                                                                            type="number"
                                                                            errorLabel="number of licenses"
                                                                            name={`config.${pmp.id}.num_licenses`}
                                                                            min="1"
                                                                            validations={{ required: true, min: 1 }}
                                                                            required
                                                                        />
                                                                    ) : (
                                                                        pmp.num_licenses || 0
                                                                    ))}
                                                                {pmp.metric.type === "CUSTOM_METRIC" && "Based on Usage"}
                                                            </td>
                                                            <td>
                                                                {
                                                                    // one_time prices are not rendered. if this, changes, allItems param will need to be populated
                                                                    renderDescriptionForItemPricing(pmp.item_pricing, false, pmp.item, null)
                                                                }
                                                            </td>
                                                            <td>{hasCustomMetricPricings && pricingsUsageMap[pmp.id]}</td>
                                                        </tr>
                                                    );
                                                });
                                            })}
                                        </tbody>
                                    </MapleTable.Content>
                                </MapleTable>
                                {showEditSubscription && (
                                    <>
                                        {isInvoicePreviewLoading && (
                                            <Row className="justify-end">
                                                <Col className="col-auto">
                                                    <Loader loading={true} />
                                                </Col>
                                            </Row>
                                        )}
                                        {invoicePreview && !isInvoicePreviewLoading && (
                                            <>
                                                <Row className="justify-end">
                                                    <Col className="col-auto">
                                                        <p className="font-semibold">
                                                            {`Amount due today: ${currencyFormatFromPrice({
                                                                ...invoicePreview.prorated_amount,
                                                                value_in_cents:
                                                                    invoicePreview.prorated_amount.value_in_cents < 0 ? 0 : invoicePreview.prorated_amount.value_in_cents,
                                                            })}`}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row className="justify-end">
                                                    <Col className="col-auto ">
                                                        <p className="font-semibold">{`Next Invoice Amount: ${currencyFormatFromPrice(invoicePreview.sub_total)}`}</p>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                        <Row className="justify-end mt-3">
                                            <Col className="col-auto">
                                                <Button
                                                    size="md"
                                                    onClick={() => {
                                                        setShowEditSubscription(false);
                                                    }}
                                                    variant="outline-primary"
                                                    disabled={isEditActionLoading}
                                                >
                                                    Cancel
                                                </Button>
                                            </Col>
                                            <Col className="col-auto">
                                                {isEditActionLoading ? (
                                                    <Button disabled>
                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> <span>Processing...</span>
                                                    </Button>
                                                ) : (
                                                    <SubmitButton disabled={isInvoicePreviewLoading || !invoicePreview}>Finalize</SubmitButton>
                                                )}
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </BaseForm>
                        </Section>
                        {hasCustomMetricPricings && (
                            <Section title={"Usage"} className="mt-4">
                                <ContentBox>
                                    <ContentBox.Body>
                                        <CustomerPortalSubscriptionUsageChart subscription={subscription} className="mt-4" />
                                    </ContentBox.Body>
                                </ContentBox>
                            </Section>
                        )}
                        {hasCancelSubscriptionAccess && !subscription.cancel_config && subscription.renewal_date && (
                            <CustomerPortalCancelSubscriptionSideModal
                                showSideModal={showCancelSubscriptionModal}
                                onClose={() => {
                                    setShowCancelSubscriptionModal(false);
                                }}
                                onConfirm={onCancelSubscription}
                                subscription={subscription}
                                isCancelActionLoading={isCancelActionLoading}
                            />
                        )}
                        {hasEditPlanSubscriptionAccess && (
                            <SimpleModal
                                show={showConfirmEditSubscriptionModal}
                                title="Confirm Edit Subscription?"
                                body={
                                    isInvoicePreviewLoading || !invoicePreview ? (
                                        <Loader loading={true} />
                                    ) : (
                                        <>
                                            <p>Are you sure you want to edit the subscription?</p>
                                            <br />
                                            <p>
                                                {`Amount due today: ${currencyFormatFromPrice({
                                                    ...invoicePreview.prorated_amount,
                                                    value_in_cents: invoicePreview.prorated_amount.value_in_cents < 0 ? 0 : invoicePreview.prorated_amount.value_in_cents,
                                                })}`}
                                            </p>
                                            <p>{`Next Invoice Amount: ${currencyFormatFromPrice(invoicePreview.sub_total)}`}</p>
                                        </>
                                    )
                                }
                                buttonVariant="danger"
                                buttonTitle="Confirm Edit"
                                confirmationText="confirm"
                                onConfirm={() => {
                                    setShowConfirmEditSubscriptionModal(false);
                                    onChangeSubscription(subscription.id, {
                                        config_items: _.map(
                                            {
                                                ..._.keyBy(subscription.config_items, "product_metric_pricing_id"),
                                                ...editSubscriptionFormRef.current.getFormData().config,
                                            },
                                            (v, k) => {
                                                return {
                                                    ...v,
                                                    product_metric_pricing_id: k,
                                                };
                                            }
                                        ),
                                    });
                                }}
                                onClose={() => {
                                    setShowConfirmEditSubscriptionModal(false);
                                }}
                            />
                        )}
                    </>
                )}
            </ContentContainer>
        </>
    );
}

const CustomerPortalSubscriptionDetails = ({ subscription, hasShowPaymentMethodsAccess, paymentMethod }) => {
    const { t } = useTranslation("common");

    const items = {
        "Status": getSubscriptionStatus(subscription),
        "Start Date": subscription.start_date && moment(subscription.start_date).format("DD MMM, YYYY h:mm:ssa"),
        "Renewal Date": subscription.renewal_date && moment(subscription.renewal_date).format("DD MMM, YYYY h:mm:ssa"),
        "End Date": subscription.end_date && moment(subscription.end_date).format("DD MMM, YYYY h:mm:ssa"),
        "Payment Terms": getInvoicePaymentTermDescription(subscription?.invoice_due_date_from_creation),
    };

    if (hasShowPaymentMethodsAccess) {
        items["Payment Method"] = !!paymentMethod ? getPaymentMethodDescription(t, paymentMethod) : <span className="text-gray-400">Default Payment Method</span>;
    }

    return <KeyValueDisplay2 items={items} />;
};

export default CustomerPortalSubscription;
