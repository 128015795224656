import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext, getCurrencyOptions } from '../../helpers/common';
import { serverPatch } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const _ = require("lodash");

function UploadFileModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const onSubmit = (data, errorHandler) => {
        console.log("Submitting data" + JSON.stringify(data));
        if (props.onClose) {
            props.onClose(true)
        }
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} customer_id={props.customer_id}>
                <BaseSideModal.Header title={"Upload Document"}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan={12} name="file" label="Document" type="file" fileType="customerUploads" required accept={"image/*,application/pdf"}/>
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Upload</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default UploadFileModal;
