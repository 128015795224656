import '../../../App.scss';
import React, {useEffect, useState, useContext} from 'react';
import { serverPost } from '../../../helpers/server';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import MapleTable from '../../../components/MapleTable';
import CopyableComponent from '../../../components/CopyableComponent';
import moment from "moment/moment";
import Label from "../../../components/Label";
import BaseOverlayTrigger from "../../../components/BaseOverlayTrigger";
import InfiniteScroll from "react-infinite-scroll-component";
const _ = require('lodash');

function IntegrationRequestLog() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);

    useEffect(() => {
        setPageTitle(`Integration Request Logs`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                integration_id: integration.id
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/integration_requests/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setEvents(results);
                } else {
                    const allEvents = _.concat(events, results);
                    setEvents(allEvents);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const renderStatus = (item) => {
        if (item.status >= 200 && item.status < 300) {
            return <Label.Success>{ item.status }</Label.Success>
        } else {
            return <Label.Danger>{ item.status }</Label.Danger>
        }
    }

    const renderPath = (row) => {
        let urlParser = new URL(row.url);
        let processedPath = urlParser.pathname + urlParser.search;
        return <div className="min-w-[150px] max-w-md truncate">
            <BaseOverlayTrigger content={row.url}>
                <span className="text-gray-500">{ processedPath }</span>
            </BaseOverlayTrigger>
        </div>
    }

    const renderBody = (row) => {
        return <div className="flex flex-col items-start max-w-md">
            <div className="text-xs text-gray-500 max-w-md">
                <code className="max-w-md">{ row.request_body || <span className="gray2">Empty</span> }</code>
            </div>
        </div>
    }

    const renderResponse = (row) => {
        const response = row.response_body ? JSON.stringify(JSON.parse(row.response_body)): "";
        return <div className="flex flex-col items-start max-w-md">
            <div className="flex flex-col gap-1 text-xs text-gray-500 max-w-md">
                <code className="max-w-md">
                    { response.slice(0, 300) }
                    {
                        response.length > 300 && <span>...</span>
                    }
                </code>
                {
                    response.length > 0 &&
                        <CopyableComponent copyableValue={response} value={"Copy full response"}></CopyableComponent>
                }
            </div>
        </div>
    }

    return (
        <ContentContainer>
            <InfiniteScroll
                dataLength={events.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                <Section
                    title={`Logs`} loading={loading}
                    subtitle={`A list of all requests made to ${integration.name} through the API.`}
                >
                    <MapleTable>
                        <MapleTable.Content>
                            <thead>
                            <tr>
                                <MapleTable.TH>Time</MapleTable.TH>
                                <MapleTable.TH>Status</MapleTable.TH>
                                <MapleTable.TH>Method</MapleTable.TH>
                                <MapleTable.TH className="!px-0">Path</MapleTable.TH>
                                <MapleTable.TH className="!px-0">Body</MapleTable.TH>
                                <MapleTable.TH className="!px-0">Response</MapleTable.TH>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(events, (item, i) =>
                                    <tr key={i}>
                                        <td>{ moment(item.created_at).format("MMM D, YYYY h:mm:ss a") }</td>
                                        <td className="text-center">{ renderStatus(item) }</td>
                                        <td className="text-center bold">{ item.method }</td>
                                        <td className="!pl-0">{ renderPath(item) }</td>
                                        <td className="!pl-0">{ renderBody(item) }</td>
                                        <td className="!pl-0">{ renderResponse(item) }</td>
                                    </tr>
                                )
                            }
                            {
                                hasMore &&
                                <tr>
                                    <td colSpan="10" className="text-center">
                                        <div className="spinner-border text-secondary"/>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </InfiniteScroll>
        </ContentContainer>
    );
}

export default IntegrationRequestLog;
