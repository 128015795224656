import '../../App.scss';
import React, {useEffect, useState, useContext, useCallback, useRef} from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import CopyableComponent from '../../components/CopyableComponent';
import moment from "moment/moment";
import Label from "../../components/Label";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import InfiniteScroll from "react-infinite-scroll-component";
import {BookmarkIcon} from "@heroicons/react/24/outline";
import {useParams} from "react-router-dom";
const _ = require('lodash');

function WebhookEventDeliveries() {
    const { endpointId } = useParams();
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null)

    useEffect(() => {
        setPageTitle(`Webhook Events`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
                status: "ACTIVE"
            },
            include_meta: restart
        }
        serverPost(getApiUrl(`/webhooks/${endpointId}/event_deliveries`), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setEvents(results);
                    setMeta(res.meta);
                } else {
                    const allEvents = _.concat(events, results);
                    setEvents(allEvents);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const renderStatus = (item) => {
        if (item.status === "Failure") {
            return <Label.Danger>{ item.status }</Label.Danger>
        } else if (item.status === "Success") {
            return <Label.Success>{ item.status }</Label.Success>
        } else if (item.status === "Retry" || item.status === "Processing") {
            return <Label.Warning>{ item.status }</Label.Warning>
        } else if (item.status === "Scheduled") {
            return <Label.Neutral>{ item.status }</Label.Neutral>
        } else {
            return <Label.Info>{ item.status }</Label.Info>
        }
    }

    const renderPayload = (row) => {
        const response = row.data.object ? JSON.stringify(row.data.object): "";
        return <div className="flex flex-col items-start max-w-md">
            <div className="flex flex-col gap-1 text-xs text-gray-500 max-w-md">
                <code className="max-w-md">
                    { response.slice(0, 300) }
                    {
                        response.length > 300 && <span>...</span>
                    }
                </code>
                {
                    response.length > 0 &&
                    <CopyableComponent copyableValue={response} value={"Copy full response"}></CopyableComponent>
                }
            </div>
        </div>
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={events.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section
                        title="Webhook Events" loading={loading}
                        subtitle={"A list of all delivery attempts for this webhook endpoint"}
                    >
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Time</MapleTable.TH>
                                    <MapleTable.TH>Event Type</MapleTable.TH>
                                    <MapleTable.TH>ID</MapleTable.TH>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH>Attempts</MapleTable.TH>
                                    <MapleTable.TH>Next Attempt</MapleTable.TH>
                                    <MapleTable.TH className="!px-0">Payload</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(events, (item, i) =>
                                        <tr key={i}>
                                            <td>{ moment(item.created_at).format("MMM D, YYYY h:mm:ss a") }</td>
                                            <td className=""><Label.Info>{ item.metadata.data.event_type }</Label.Info></td>
                                            <td className=""><CopyableComponent copyableValue={item.id} value={item.id}/></td>
                                            <td className="">{ renderStatus(item) }</td>
                                            <td className="">{ item.metadata.num_trials }</td>
                                            <td>{ moment(item.metadata.next_send_time).format("MMM D, YYYY h:mm:ss a") }</td>
                                            <td className="!pl-0">{ renderPayload(item.metadata.data) }</td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default WebhookEventDeliveries;
