import moment from "moment";
import {
    currencyFormatFromPrice,
    getDescriptionForCreditState,
} from "../helpers/common";
import classNames from "classnames";
import MapleTable from "./MapleTable";
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import Loader from "./Loader";
import DeleteButton from "./DeleteButton";
import { useTranslation } from "react-i18next";

function CreditTable({
    showTableActions,
    creditsData: {
        credits,
        totalUnpaginatedCount,
        isLoading,
        hasMore,
        onRevoke,
    },
    filterData: {
        filterOptions,
        defaultSelectedFilters,
        cachedSelectedFilters,
        onFiltersChange,
    },
    sortData: { sortPref, onSortChange },
}) {
    const { t } = useTranslation("common");
    return (
        <MapleTable>
            {showTableActions && (
                <MapleTable.Actions>
                    <MapleTableHeaderWithActions
                        showSearch
                        searchPlaceholder={t(
                            "credits.table.search_placeholder"
                        )}
                        showFilters
                        filters={filterOptions}
                        meta={{ total: totalUnpaginatedCount }}
                        defaultSelectedFilters={defaultSelectedFilters}
                        cachedSelectedFilters={cachedSelectedFilters}
                        onParamsChange={onFiltersChange}
                    />
                </MapleTable.Actions>
            )}
            <MapleTable.Content>
                <thead>
                    <tr>
                        <SortableTableHeader
                            sortKeyUp="createdAtAsc"
                            sortKeyDown="createdAtDesc"
                            currentSort={sortPref}
                            onSortChange={onSortChange}
                        >
                            {t("credits.table.header.issued_date")}
                        </SortableTableHeader>
                        <MapleTable.TH>
                            {t("credits.table.header.name")}
                        </MapleTable.TH>
                        <MapleTable.TH>
                            {t("credits.table.header.quantity")}
                        </MapleTable.TH>
                        <MapleTable.TH>
                            {t("credits.table.header.expiry")}
                        </MapleTable.TH>
                        <MapleTable.TH>
                            {t("credits.table.header.status")}
                        </MapleTable.TH>
                        <MapleTable.TH></MapleTable.TH>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {isLoading && !hasMore ? (
                        <tr>
                            <td colSpan="6">
                                <Loader loading={true} />
                            </td>
                        </tr>
                    ) : credits.length ? (
                        credits.map((cred) => (
                            <tr
                                key={cred.id}
                                className={classNames(
                                    cred.state !== "ACTIVE" && "de-highlighted"
                                )}
                            >
                                <td>
                                    {moment(cred.created_at).format(
                                        "MMM D, YYYY"
                                    )}
                                </td>
                                <td>{cred.name}</td>
                                <td>
                                    {cred.type === "AMOUNT" ? (
                                        <span>
                                            {currencyFormatFromPrice(
                                                cred.issued_amount
                                            )}
                                        </span>
                                    ) : (
                                        <span>
                                            {cred.issued_units}{" "}
                                            {cred.item && cred.item.name}{" "}
                                        </span>
                                    )}
                                    <br />
                                    {cred.state != "CONSUMED" && (
                                        <span className="caption">
                                            {`${t(
                                                "credits.table.body.quantity.remaining"
                                            )}: `}
                                            {cred.type === "AMOUNT"
                                                ? currencyFormatFromPrice(
                                                      cred.amount
                                                  )
                                                : `${cred.units} ${
                                                      cred.item &&
                                                      cred.item.name
                                                  }`}
                                        </span>
                                    )}
                                </td>
                                <td>
                                    {cred.expiration_date ? (
                                        moment(cred.expiration_date).format(
                                            "MMM D, YYYY h:mma"
                                        )
                                    ) : (
                                        <div className="gray2">
                                            {t(
                                                "credits.table.body.expiry.none"
                                            )}
                                        </div>
                                    )}
                                </td>
                                <td>
                                    {getDescriptionForCreditState(cred.state)}
                                </td>
                                <td className="text-right">
                                    {cred.state === "ACTIVE" && (
                                        <DeleteButton
                                            title="Revoke Credit?"
                                            body="Are you sure you want to revoke the credit?"
                                            onDelete={() => onRevoke(cred.id)}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="6">
                                <span className="text-sm text-gray-500">
                                    {t("credits.table.empty_search_results")}
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
            </MapleTable.Content>
        </MapleTable>
    );
}

export default CreditTable;
