import '../App.scss';
import '../css/invoice.scss';
import React, {useState, useMemo, useEffect, useContext} from 'react';
import BaseForm from './BaseForm';
import SubmitButton from "./SubmitButton";
import Label from "./Label";
import {Button} from "react-bootstrap";
import {serverFetch} from "../helpers/server";
const _ = require('lodash');

function Tags(props) {
    const [isEditing, setIsEditing] = useState(false);
    const [existingTags, setExistingTags] = useState([]);
    const initialFields = useMemo(() => {
        return {
            tags: props.tags
        }
    }, [props.tags]);

    useEffect(() => {
        if (isEditing && props.tagsUrl) {
            serverFetch(props.tagsUrl, {skipCache: true}).then((res) => {
                setExistingTags(_.map(res || [], r => {
                    return {
                        label: r,
                        value: r
                    }
                }));
            })
        }
    }, [isEditing]);

    const updateTags = async (data) => {
        if (data.tags) {
            data.tags = _.map(data.tags, (t) => _.trim(t));
        }
        if (props.onUpdate) {
            const result = await props.onUpdate(data);
            if (result) {
                setIsEditing(false);
            }
        } else {
            setIsEditing(false);
        }
    }

    const loadTags = (query, callback) => {
        let filteredResults = _.filter(existingTags, t => _.includes(t.value.toLowerCase(), query.toLowerCase()))
        callback(filteredResults)
    }

    if (isEditing) {
        return (
            <BaseForm initialFormFields={initialFields} onSubmit={updateTags}>
                <div className="flex flex-row gap-1">
                    <div className="grow">
                        <BaseForm.Input
                            colSpan="12" name="tags" label="Tags" type="selector" hideLabel formClassName="inline"
                            isMulti={true} canCreate={true} formatCreateLabel={(inputValue) => `Add tag - ${inputValue}`}
                            loadOptions={loadTags}
                            defaultOptions={existingTags}
                            isValidNewOption={(option) => _.trim(option).length !== 0 } />
                    </div>
                    <div>
                        <SubmitButton>Save</SubmitButton>
                    </div>
                </div>
            </BaseForm>
        )
    } else {
        return (
            <div className="flex flex-row gap-1">
                {
                    _.isEmpty(props.tags) &&
                        <span className="gray2">None</span>
                }
                {
                    _.map(props.tags, (tag, i) =>
                        <Label.Info key={i}>{tag}</Label.Info>
                    )
                }
                <div className="grow"/>
                <Button onClick={() => setIsEditing(true)} variant="text" size="sm"><i className="fa fa-edit"/></Button>
            </div>
        )
    }
}

export default Tags;
