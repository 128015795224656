import '../../App.scss';
import '../../css/modals.scss';
import variables from '../../App.scss';
import React, {createRef, forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import {BaseContext} from '../../helpers/common';
import {useTranslation} from 'react-i18next';
import {getUrl, serverFetch, serverPost} from "../../helpers/server";

const _ = require('lodash');

const RazorPayInvoicePaymentModal = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        setErrorFields(errors) {
            setErrorFields(errors);
        },
    }));

    const {getApiUrl} = useContext(BaseContext);
    const {t} = useTranslation('common');
    const [errorFields, setErrorFields] = useState(null);
    const [loading, setLoading] = useState(true);
    const scriptPromise = createRef();

    function loadScript(src) {
        scriptPromise.current = new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
        return scriptPromise.current;
    }

    async function displayRazorpay() {
        if (scriptPromise.current) {
            const res = await scriptPromise.current;
            if (!res) {
                alert("Razorpay SDK failed to load. Are you online?");
                return;
            }
        }

        const intent = await serverFetch(getApiUrl(`/invoices/${props.invoice.uuid}/payment_intent`), {skipCache: true})
        if (!intent) {
            // setPaymentIntent(res);
            return;
        }

        let brandColor = variables.primaryColor;
        if (props.settings && props.settings.branding) {
            brandColor = props.settings.branding.color || variables.primaryColor;
        }

        const order_id = intent.id;
        const options = {
            key: intent.intent_secret, // Enter the Key ID generated from the Dashboard
            order_id: order_id,
            customer_id: intent.provider_customer_id,
            handler: async function (response) {
                const data = {
                    payment_data: {
                        payment_provider_key: "razorpay",
                        razorpay: {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature
                        },
                    }
                };

                serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/pay`), data).then((res) => {
                    if (res) {
                        if (props.onClose) {
                            props.onClose(true);
                        }
                    }
                });
            },
            theme: {
                color: brandColor,
            },
            modal: {
                "ondismiss": function () {
                    if (props.onClose) {
                        props.onClose(false);
                    }
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
            console.log("The failure response is " + JSON.stringify(response));
        })
        paymentObject.open();
        setLoading(false);
    }

    useEffect(() => {
        loadScript("https://checkout.razorpay.com/v1/checkout.js");
    }, []);

    useEffect(() => {
        if (props.show) {
            displayRazorpay();
        }
    }, [props.show]);

    if (!props.show || !loading) {
        return null;
    }
    return (
        <div
            className="fixed w-full h-full text-gray-900 left-0 top-0 bg-[#0000003c] transition-all ease-in-out delay-300">
            <div className="flex items-center justify-center w-full h-full">
                <div className="flex flex-row justify-content-center">
                    <div className="spinner-border text-secondary"/>
                </div>
            </div>
        </div>
    )
})

export default RazorPayInvoicePaymentModal;
