import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverPatch } from '../../helpers/server';
import {
    BaseContext, getNameForProviderType, renderTags,
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay from '../../components/KeyValueDisplay';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import AddOrEditProductPricingModal from '../../components/modals/AddOrEditProductPricingModal';
import ArchiveButton from '../../components/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import ChangeProductModal from "../../components/modals/ChangeProductModal";
import SelectEntitlements from "../../components/SelectEntitlements";
import BundlePricingTable from "../../components/BundlePricingTable";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import {getExternalProductPricingReferenceURL} from "../../helpers/integrations";
import Tags from "../../components/Tags";
const _ = require('lodash');

function ProductPricing(props) {
    const { t } = useTranslation('common');
    const { pricingId } = useParams();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loadingPrice, setLoadingPrice] = useState(true);
    const [pricing, setPricing] = useState({});
    const [details, setDetails] = useState({});
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);
    const [isEditingEntitlements, setIsEditingEntitlements] = useState(false);
    const [showAddOrEditProductPricingModal, setShowAddOrEditProductPricingModal] = useState(false);
    const [showChangeProductModal, setShowChangeProductModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Pricing - ${pricing.name}`);
    }, [pricing]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/product_pricings/${pricingId}`), { skipCache }).then((res) => {
            if (res) {
                setPricing(res);
            }
            setLoadingPrice(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const data = {
            'Product': !_.isEmpty(pricing) && <Link to={getCompanySpecificUrl(`/product/${pricing.product_id}`)}>{pricing.product.name}</Link>,
            'Name': pricing.name,
            'Public Name': pricing.external_name,
            'Description': pricing.description,
            'Tags': <Tags tags={pricing.tags} onUpdate={updateTags} tagsUrl={getApiUrl(`/product_pricings/tags`)} />
        };
        if (!_.isNil(pricing.imported_from)) {
            const externalReferenceUrl = getExternalProductPricingReferenceURL(pricing);
            if (externalReferenceUrl) {
                data[`${getNameForProviderType(pricing.imported_from)} Reference`] =
                    <a href={externalReferenceUrl} target="_blank">{ pricing.import_ref }</a>;
            }
        }
        setDetails(data)
    }, [pricing]);

    const onModalClose = () => {
        setShowAddOrEditProductPricingModal(false);
        setShowChangeProductModal(false);
        fetchData(true);
    }

    const updateTags = async (data) => {
        const result = await serverPatch(getApiUrl(`/product_pricings/${pricingId}`), data)
        if (result) {
            fetchData(true);
        }
        return result;
    }

    const editPricing = () => {
        setShowAddOrEditProductPricingModal(true);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/product_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (newMetadata) => {
        const processedData = {
            metadata: newMetadata
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    const editEntitlements = () => {
        setIsEditingEntitlements(true);
    }

    const saveEntitlements = (newEntitlements) => {
        const processedData = {
            entitlements: newEntitlements
        };

        serverPatch(getApiUrl(`/product_pricings/${pricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingEntitlements(false);
        });
    }

    return (
        <BaseContainer>
            <Loader loading={loadingPrice}>
            {
                _.isEmpty(pricing) ?
                    <ErrorComponent
                        title={"Product Price Not Found"}
                        description={"Oops, we can't find the price you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/products"), label:"Show all products" }}
                        hideSupport
                    />
                : <>
                    <ContentContainer title={pricing.name} actions={[
                        {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editPricing}
                    ]} customActions={<ArchiveButton size="md" onDelete={() => onArchiveProductPricing(pricing)}></ArchiveButton>}>
                        <Section title="Details" actions={[]}>
                            <KeyValueDisplay2 items={details} />
                        </Section>
                        <Section title="Entitlements" className="mt-4" actions={!isEditingEntitlements && [
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editEntitlements}
                        ]}>
                            <div className="max-w-3xl">
                                <SelectEntitlements
                                    entitlements={pricing.computed_entitlements}
                                    isEditing={isEditingEntitlements}
                                    onUpdateEntitlements={saveEntitlements}
                                    onCancelEditing={() => setIsEditingEntitlements(false)}
                                />
                            </div>
                        </Section>
                        <Section title="Metadata" className="mt-4" actions={[
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                        ]} subtitle="These are fields that are stored on the product pricing for internal reference.">
                            {
                                (!isEditingMetadata && (_.isNil(pricing.metadata) || _.isEmpty(pricing.metadata))) ?
                                    <p className="text-gray-500">No metadata</p>
                                    : <KeyValueDisplay items={pricing.metadata} isEditing={isEditingMetadata}
                                                       onUpdateKeyValues={saveMetadata}
                                                       onCancelEditing={() => setIsEditingMetadata(false)}/>
                            }
                        </Section>
                        <Section title="Pricing Details" className="mt-4">
                            <BundlePricingTable product_pricing_ids={pricing.id && [pricing.id]} showProductMetricPricingID />
                        </Section>
                    </ContentContainer>
                    <AddOrEditProductPricingModal show={showAddOrEditProductPricingModal} onClose={onModalClose} itemToEdit={pricing} />
                    <ChangeProductModal show={showChangeProductModal} onClose={onModalClose} pricing={pricing} />
                </>
            }
            </Loader>
        </BaseContainer>
    );
}

export default ProductPricing;
