import '../../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import { serverFetch } from '../../../helpers/server';
import {
    BaseContext, CustomerContext,
} from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader';
import moment from 'moment';
import ErrorComponent from "../../../components/ErrorComponent";
import SubscriptionUsage from "../../../components/SubscriptionUsage";
import Section from "../../../components/Section";
const _ = require('lodash');

function CustomerUsageEmbed(props) {
    const { t } = useTranslation('common');
    const { customerId } = useParams();
    const { getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [subscriptionIds, setSubscriptionIds] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        setPageTitle(`Customer Usage`);
    }, []);

    const fetchData = (skipCache=false) => {
        const options = {
            skipCache: skipCache,
            accessToken: accessToken
        }
        serverFetch(getApiUrl(`/customers/${customerId}`), options).then((res) => {
            if (res) {
                setSubscriptionIds(_.map(res.subscriptions, s => s.id))
                // setSubscription(res.subscriptions[0]);
            }
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchSubscriptionData = (subscriptionId, skipCache=true) => {
        const options = {
            skipCache: skipCache,
            accessToken: accessToken
        }
        serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`), options).then((res) => {
            if (res && _.includes(subscriptionIds, subscriptionId) && res.status !== "CANCELLED") {
                let pmps = [];
                _.each(res.product_pricings, (pp) => {
                    _.each(pp.product_metric_pricings, (pmp) => {
                        pmps.push(pmp);
                    })
                })
                const customMetricPricings = _.filter(pmps, (pmp) => {
                    return pmp.metric.type === "CUSTOM_METRIC"
                })
                const hasUsageMetricPricings = !_.isEmpty(customMetricPricings)
                if (hasUsageMetricPricings) {
                    setSubscriptions(prevSubscriptions => {
                        let newSubscriptions = [];
                        _.each(prevSubscriptions, s => {
                            if (s.id !== subscriptionId) {
                                newSubscriptions.push(s);
                            }
                        })
                        newSubscriptions.push(res);
                        return newSubscriptions;
                    });
                }
            }
            setLoadingSubscription(false);
        });
    }

    useEffect(() => {
        if (!_.isEmpty(subscriptionIds)) {
            _.each(subscriptionIds, sid => {
                fetchSubscriptionData(sid)
            })
        } else {

        }
    }, [subscriptionIds]);

    return (
        <div className="h-full w-full bg-white overflow-auto p-3">
            <div>
                <Loader loading={loadingSubscription}>
                {
                    _.isEmpty(subscriptionIds) &&
                        <ErrorComponent
                            title={"Subscription Not Found"}
                            description={"Oops, we can't find the subscription you are looking for."}
                            hideSupport
                        />
                }
                {
                    !_.isEmpty(subscriptionIds) && subscriptions.length > 0 &&
                        <Section title="Usage">
                            <SubscriptionUsage subscriptions={subscriptions}/>
                        </Section>
                }
                {
                    !_.isEmpty(subscriptionIds) && subscriptions.length === 0 &&
                        <div>
                            <p className="text-gray-700">No metrics for subscriptions</p>
                        </div>
                }
                </Loader>
            </div>
        </div>
    );
}

export default CustomerUsageEmbed;
