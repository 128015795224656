import '../../App.scss';
import '../../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch, serverPost } from '../../helpers/server';
import {BaseContext, convertVariablesIntoHtml, downloadBlob} from '../../helpers/common';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import ContentContainer from '../../components/ContentContainer';
import Loader from '../../components/Loader';
import ContentBox from '../../components/ContentBox';
import classnames from 'classnames';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import variables from "../../App.scss";
const _ = require('lodash');

function ContractView() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid, sid } = useParams();

    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [contractDetails, setContractDetails] = useState(null);
    const [contractLoading, setContractLoading] = useState(true);
    const [initialFields, setInitialFields] = useState({});
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const title = _.isNil(contractDetails) ? "" : contractDetails.title;
        setPageTitle(`Contract - ${title} - Accept`);
    }, [contractDetails]);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
    }, [uuid])

    const downloadContract = () => {
        const filename = `Contract.pdf`;
        serverPost(getApiUrl(`/proposals/${uuid}/preview`), {}, { noJson: true }).then((res) => {
            downloadBlob(res, filename);
        })
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });

        serverFetch(getApiUrl(`/proposals/${uuid}`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setContractLoading(false);
            if (res) {
                if (res.expiration_date) {
                    res.isExpired = moment(res.expiration_date).isBefore(moment());
                }
                setContractDetails(res);
                setInitialFields({
                    owner: res.owner && res.owner.id,
                    expiration_date: res.expiration_date
                })
            } else {
                setContractDetails(null);
            }
        })
    }

    const renderExpirationDate = () => {
        if (_.isNil(contractDetails.expiration_date)) {
            return null;
        }
        const daysFromExpiry = moment(contractDetails.expiration_date).diff(moment(), "days");
        let color = "success-color";
        if (daysFromExpiry <= 0) {
            color = "danger-color";
        } else if (daysFromExpiry <= 3) {
            color = "warning-color";
        }
        return (
            <div className="align-self-center">
            {
                contractDetails.expiration_date &&
                    <div className={classnames("body2", color)}>Expires { moment(contractDetails.expiration_date).format("DD MMM h:mma") }</div>
            }
            </div>
        )
    }

    const onActionSelected = (type) => {
        if (type === "pdf") {
            serverPost(getApiUrl(`/proposals/${contractDetails.id}/preview`), {}, { noJson: true }).then((res) => {
                downloadBlob(res, `Contract-${contractDetails.title}`);
            })
        }
    }

    const actionOptions = [
        { id: "pdf", label: "Download" },
    ];

    const renderContract = () => {
        return (
            <>
                <div className="contract-wrapper-header">
                    <div>
                        <h3 className="gray4">{ contractDetails.title }</h3>
                        {
                            !_.isNil(contractDetails.owner) && contractDetails.owner.id &&
                                <span className="caption">By { contractDetails.owner.user.name || contractDetails.owner.user.email }</span>
                        }
                    </div>
                    <div className="flex-grow-1"/>
                    { renderExpirationDate() }
                    <div className="flex-grow-0 align-self-center">
                        <SingleSelectDropdown className="inline" toggleLabel={<span>Actions</span>} items={actionOptions} onSelect={onActionSelected} align="end" menuOnly/>
                    </div>
                </div>
                <ContentBox>
                    <ContentBox.Body className="scrollable">
                        <div className="content-body1">
                            <div className="contract-content mx-2" dangerouslySetInnerHTML={{__html: convertVariablesIntoHtml(contractDetails.content, contractDetails.counter_signatories) }} />
                        </div>
                    </ContentBox.Body>
                </ContentBox>
                <div className="contract-wrapper-footer">
                    <div className="flex-grow-1"/>
                    <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                        <span>Powered by Maple</span>
                        <a href="https://www.maplebilling.com/terms" target="_blank">Terms</a>
                        <a href="https://www.maplebilling.com/privacy" target="_blank">Privacy</a>
                    </div>
                    <div className="flex-grow-1"/>
                </div>
            </>
        );
    }

    const renderExpired = () => {
        return (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                <i className="fa fa-thin fa-file-contract danger-color fa-4x"/>
                <h3 className="gray4">Contract expired</h3>
                <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, this contract has expired. Please reach out to the person who send you the contract for further details.</div>
            </div>
        )
    }

    const renderNotFound = () => {
        return (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                <i className="fa fa-thin fa-file-contract danger-color fa-8x"/>
                <h3 className="gray4">Contract not found</h3>
                <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, we can't find the contract you are looking for. Please reach out to the person who send you the contract.</div>
            </div>
        )
    }

    const renderComplete = () => {
        let brandColor = variables.primaryColor;
        if (settings.branding) {
            brandColor = settings.branding.color || variables.primaryColor;
        }
        return (
            <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                <i className="fa fa-thin fa-file-contract fa-6x" style={{color: brandColor}}/>
                <h3 className="gray4">{ contractDetails.title }</h3>
                <div className="body1 gray4">Contract has been signed by all participants</div>
                <span className="body1 text-center gray3" style={{ maxWidth: "400px" }}>
                    <Link onClick={downloadContract}>Download a copy of the contract</Link>
                </span>
            </div>
        )
    }

    return (
        <ContentContainer className="full" bodyClassName="full">
            <div className="contract-wrapper">
                <Loader loading={contractLoading}>
                {
                    () => {
                        if (_.isNil(contractDetails)) {
                            return renderNotFound();
                        } else if (!_.isNil(contractDetails.signed_date)) {
                            return renderComplete();
                        } else if (contractDetails.isExpired) {
                            return renderExpired();
                        } else {
                            return renderContract();
                        }
                    }
                }
                </Loader>
            </div>
        </ContentContainer>
    )
}

export default ContractView;