import React, { useContext } from 'react';
import Columns from './Columns';
import {clearUserInfo, storageLogout} from '../helpers/storage';
import { serverPost, getUrl } from '../helpers/server';
import { UserContext, BaseContext } from '../helpers/common';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import $ from 'jquery';
import classnames from 'classnames';
import DropdownMenu from "./DropdownMenu";
import {EllipsisVerticalIcon} from "@heroicons/react/20/solid";
const _ = require('lodash');

const languageMap = {
    'en': 'English',
    'fr': 'French',
    'es': 'Spanish'
};

function Header(props) {
    const userContext = useContext(UserContext);
    const baseContext = useContext(BaseContext);
    const { userInfo, isSuperUser, isLoggedIn } = !_.isNil(userContext) ? userContext : { userInfo: {}, isLoggedIn: false };
    const { company } = !_.isNil(baseContext) ? baseContext : { company: null };
    const { t, i18n } = useTranslation('common');
    const showLanguageSelection = false;
    const spoofToken = localStorage.getItem("spoof_auth_token");

    const logout = () => {
        serverPost(getUrl("/users/logout"), {}).then((res) => {
            storageLogout();
            window.location = "/login";
        });
    }

    const changeLanguage = (event, language) => {
        event.preventDefault();
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    }

    const endSpoof = () => {
        localStorage.setItem("auth_token", localStorage.getItem("spoof_auth_token"));
        localStorage.removeItem("spoof_auth_token");
        window.location = "/internal/super/dashboard"
    }

    const toggleLeftNav = (event) => {
        event.preventDefault();
        if ($('#left-nav').hasClass("show")) {
            // It is showing. Hide it.
            $('#left-nav').removeClass('show');
        } else {
            // It is not showing. Show it.
            $('#left-nav').addClass('show');
        }
    }

    const topItems = _.filter([
        (userInfo.is_super_user  || (userInfo.companies && userInfo.companies.length > 1)) && {
            link: "/user/home",
            icon: "fa-arrows-rotate",
            label: "Switch Company"
        },
        userInfo.is_super_user && {
            icon: "fa-arrows-rotate",
            link: "/internal/super/dashboard",
            label: "Super User"
        },
        spoofToken && {
            onClick: endSpoof,
            icon: "fa-right-from-bracket",
            label: "End Spoof"
        }
    ], i => i);

    return (
        <div className={classnames("header", "border-b border-gray-200", props.className)}>
            <div className="header-toolbar">
                <div className="header-item d-lg-none">
                    <div className="header-item-inner">
                        <Button variant="text" onClick={(event) => toggleLeftNav(event)}><i className="fa fa-bars"/></Button>
                    </div>
                </div>
            </div>
            <div className="header-toolbar expandable">
            </div>
            <div className="header-toolbar">
            {
                showLanguageSelection &&
                    <div className="header-item">
                        <div className="header-item-inner">
                            <div className="dropdown">
                                <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="5,8">{languageMap[i18n.language]}</button>
                                <ul className="dropdown-menu dropdown-menu-end">
                                {
                                    _.map(languageMap, (label, code) =>
                                        <li key={code}><a href={`#${code}`} onClick={(event) => changeLanguage(event, code)}>{label}</a></li>
                                    )
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
            }
            {
                props.isSuper && isSuperUser &&
                    <div className="header-item">
                        <div className="header-item-inner">
                            <Button variant="danger">Super User Mode</Button>
                        </div>
                    </div>
            }
                {
                    isLoggedIn &&
                        <div className="flex items-center">
                            <DropdownMenu
                                hideCaret
                                items={[
                                    ...topItems, !_.isEmpty(topItems) && {
                                        divider: true
                                    }, {
                                        link: "/user/profile",
                                        icon: "fa-user",
                                        label: "Profile"
                                    }, {
                                        onClick: logout,
                                        icon: "fa-right-from-bracket",
                                        label: "Logout"
                                    }
                                ]}
                            >
                                <div className="flex flex-row items-center">
                                    <div>
                                        <Columns.CustomerAvatar2 customer={ userInfo }/>
                                    </div>
                                    <div className="ml-2 text-start whitespace-nowrap">
                                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{ userInfo.name || userInfo.email }</p>
                                        <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{ company ? company.name: "" }</p>
                                    </div>
                                    <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" />
                                </div>
                            </DropdownMenu>
                        </div>
                }
            {
                !isLoggedIn &&
                    <div className="header-item">
                        <div className="header-item-inner">
                            <Link to="/login"><Button variant="primary">Login</Button></Link>
                        </div>
                    </div>
            }
            </div>
        </div>
    );
}

export default Header;
