import '../App.scss';
import React, {useEffect, useState, forwardRef, useImperativeHandle, useContext} from 'react';
import BaseForm from './BaseForm';
import DeleteButton from './DeleteButton';
import SingleSelectDropdown from './SingleSelectDropdown';
import { Table, Row, Button, Col } from 'react-bootstrap';
import {
    renderDescriptionForItemPricing, getDescriptionForCoupon,
    getLabelForCouponType, BaseContext, renderTags,
    UserContext
} from '../helpers/common';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../helpers/server";
import Select from "react-select/async";
import classnames from 'classnames';
import Link from "./Link";
import moment from 'moment';
import ProductPricingInput from "./ProductPricingInput";
const _ = require('lodash');

const ProductPricingSelection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        validate(allFields) {
            return validate(allFields);
        },
        onFieldChange,
        getPricingSelectionFields(allFields) {
            return getPricingSelectionFields(allFields);
        }
    }));

    const { getApiUrl, company } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const { t } = useTranslation('common');
    const [coupons, setCoupons] = useState([]);
    const [selectedCoupons, setSelectedCoupons] = useState([]);
    const [allProductPricings, setAllProductPricings] = useState([]);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [editable, setEditable] = useState(true);
    const [allowDiscounts, setAllowDiscounts] = useState(true);
    const [requireRecurring, setRequireRecurring] = useState(false);
    const [billableItems, setBillableItems] = useState([]);
    const [showAddMinimumSpendInput, setShowAddMinimumSpendInput] = useState(false);
    const [discountsTypeMap, setDiscountsTypeMap] = useState({});

    useEffect(() => {
        setAllProductPricings(props.productPricings);
    }, [props.productPricings])

    useEffect(() => {
        setCoupons(props.coupons);
    }, [props.coupons])

    useEffect(() => {
        setAllowDiscounts(props.allowDiscounts);
    }, [props.allowDiscounts])

    useEffect(() => {
        if (_.isNil(props.editable)) {
            setEditable(true);
        } else {
            setEditable(props.editable);
        }
    }, [props.editable])

    useEffect(() => {
        setRequireRecurring(props.requireRecurring || false);
    }, [props.requireRecurring])

    useEffect(() => {
        const selectedIds = props.preselectedProductPricingIds || [];
        const selectPricings = []
        _.each(selectedIds, (id) => {
            const pp = _.find(allProductPricings, (p) => p.id === id);
            if (pp) {
                selectPricings.push(pp);
            }
        })
        setSelectedProductPricings(selectPricings);
    }, [allProductPricings, props.preselectedProductPricingIds])

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), {}).then((res) => {
            setBillableItems(_.filter(res, (r) => !r.standard));
        });
    }, [])

    useEffect(() => {
        if (props.onSelectionChange) {
            props.onSelectionChange(selectedProductPricings);
        }
        if (_.isEmpty(selectedProductPricings)) {
            setShowAddMinimumSpendInput(false);
        }
    }, [selectedProductPricings]);

    useEffect(() => {
        const initialFields = props.initialFields
        if (initialFields) {
            if (initialFields.discounts) {
                setDiscountsTypeMap(_.mapValues(initialFields.discounts, (d) => d.type));
            }
        }
    }, [props.initialFields])


    const onFieldChange = (name, value) => {
        if (_.startsWith(name, "discounts.") && _.endsWith(name, ".type")) {
            setDiscountsTypeMap(prevMap => {
                const newMap = _.cloneDeep(prevMap)
                newMap[name.split(".")[1]] = value;
                return newMap;
            })
        }
    }

    const validate = (allFields) => {
        if (selectedProductPricings.length === 0) {
            return "Please select at least one product price."
        }
        const uniqueCurrencies = _.uniq(_.map(selectedProductPricings, (pp) => pp.currency));
        if (uniqueCurrencies.length > 1) {
            return "Multiple currencies are not permitted together."
        }
        if (requireRecurring) {
            const recurringProductPricings = _.filter(selectedProductPricings, (pp) => pp.type !== "ONETIME_PRICING");
            if (recurringProductPricings.length === 0) {
                return "Please select at least one recurring price.";
            }
        }
        return null;
    }

    const getPricingSelectionFields = (allFields) => {
        let discounts = [];
        _.each(allFields.discounts, (value, key) => {
            if (parseFloat(value.percentage) > 0 || parseFloat(value.amount) > 0) {
                discounts.push({
                    name: "Discount",
                    type: value.type,
                    customer_id: allFields.customer.id,
                    percent: value.type === "DISCOUNT_PERCENT" ? parseFloat(value.percentage)/100 : null,
                    amount: value.type === "DISCOUNT_AMOUNT" ? {
                        value_in_cents: parseFloat(value.amount)*100,
                        currency: selectedProductPricings[0].currency,
                    } : null,
                    state:"ACTIVE",
                    item_pricing_id: key,
                    expiration_date: value.expiration_date,
                })
            }
        })
        let onetime_items = []
        _.each(selectedProductPricings, (p, j) => {
            if (p.type !== "ONETIME_PRICING") {
                return;
            }
            const pmp_id = p.product_metric_pricings[0].id;
            onetime_items.push({
                product_pricing_id: p.id,
                aggregate: parseFloat(allFields.config[String(pmp_id)].num_licenses)
            })
        })
        return {
            currency: selectedProductPricings[0].currency,
            config_items: _.map(allFields.config, (v, k) => { return {...v, num_licenses: parseFloat(v.num_licenses || "0"), minimum_units: parseFloat(v.minimum_units || "0"), product_metric_pricing_id: k} }),
            product_pricing_ids: _.map(_.filter(selectedProductPricings, (a) => a.type !== "ONETIME_PRICING"), (p) => p.id),
            onetime_items: onetime_items,
            discounts: discounts,
            minimum_spend: allFields.minimum_spend && {
                value_in_cents: parseFloat(allFields.minimum_spend) * 100,
                currency: selectedProductPricings[0].currency
            }
        }
    }

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    return (
        <div className="text-sm">
            {
                _.map(selectedProductPricings, (pp, i) => {
                    const pmps = pp.product_metric_pricings;
                    return (
                        <React.Fragment key={i}>
                            <Row>
                                <Col md="12">
                                    <span className="body2">{ pp.product.name }</span>
                                    <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)} />
                                </Col>
                            </Row>
                            {
                                _.map(pmps, (pmp, j) =>
                                    <Row key={j} className="metric-pricing-row">
                                        <Col md="4">
                                            { renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item, billableItems) }
                                        </Col>
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "4": "7"} name={`config.${pmp.id}.num_licenses`} label={t('common.quantity')} type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "ONETIME_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "4": "7"} name={`config.${pmp.id}.num_licenses`} label="One time" type="number" step="0.01" min="0" required />
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                                <Col md={allowDiscounts ? "2": "4"} className="d-flex flex-column gap-2">
                                                    <div className="body2">Metric</div>
                                                    <span className="text-sm">{ pmp.metric.name }</span>
                                                </Col>
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                                <BaseForm.Input colSpan={allowDiscounts ? "2": "3"} name={`config.${pmp.id}.minimum_units`} label="Minimum (optional)" type="number" step="0.01" min="0" />
                                        }
                                        {allowDiscounts && (
                                            <Col md="4">
                                                <Row>
                                                    <BaseForm.InputGroup label={"Discount"} formClassName="inline">
                                                        {(!discountsTypeMap[pmp.item_pricing_id] || discountsTypeMap[pmp.item_pricing_id] === "DISCOUNT_PERCENT") && (
                                                            <BaseForm.Number
                                                                name={`discounts.${pmp.item_pricing_id}.percentage`}
                                                                label="Discount Value"
                                                                type="number"
                                                                min="0"
                                                                step="0.5"
                                                                max="100"
                                                            />
                                                        )}
                                                        {discountsTypeMap[pmp.item_pricing_id] === "DISCOUNT_AMOUNT" && (
                                                            <BaseForm.Number
                                                                name={`discounts.${pmp.item_pricing_id}.amount`}
                                                                label="Discount Amount"
                                                                type="number"
                                                                min="0"
                                                                step="0.01"
                                                            />
                                                        )}
                                                        <BaseForm.Divider />
                                                        <BaseForm.Select
                                                            name={`discounts.${pmp.item_pricing_id}.type`}
                                                            label="Type"
                                                            hideLabel
                                                            placeholder="MM"
                                                            options={[
                                                                { label: "%", value: "DISCOUNT_PERCENT" },
                                                                { label: "$", value: "DISCOUNT_AMOUNT" },
                                                            ]}
                                                            showSearch={false}
                                                            alignDropdown={"right"}
                                                            className={"!w-fit"}
                                                        />
                                                    </BaseForm.InputGroup>
                                                </Row>
                                                {isMapleUser && (
                                                    <Row className="justify-end -mt-1.5">
                                                        <BaseForm.DateGroup
                                                            id={`discounts-${pmp.item_pricing_id}-expiration_date-input`}
                                                            name={`discounts.${pmp.item_pricing_id}.expiration_date`}
                                                            placeholder="Add discount expiry"
                                                            prefixText={"Expires: "}
                                                            formClassName={"w-fit"}
                                                            formInputClassName={"!bg-inherit cursor-pointer"}
                                                            valueClassName={"px-0 btn-alink"}
                                                            borderless={true}
                                                            minDate={moment()}
                                                            includeTime={true}
                                                            dateRangeDrops={"auto"}
                                                            dateRangeOpens={"left"}
                                                        />
                                                    </Row>
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                )
                            }
                        </React.Fragment>
                    )
                })
            }
            {
                !_.isEmpty(selectedProductPricings) &&
                    <div className="mt-1"></div>
            }
            {
                editable &&
                    <Row>
                        <Col lg="6">
                            <ProductPricingInput
                                excludeBundlePricing={props.excludeBundlePricing}
                                extraQueryFilters={props.extraQueryFilters}
                                billableItems={billableItems}
                                setSelectedProductPricings={setSelectedProductPricings}
                                selectedProductPricings={selectedProductPricings}
                            />
                        </Col>
                    </Row>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend &&
                <div className="mt-3">
                    <Link onClick={() => setShowAddMinimumSpendInput(true)}>
                        <span className="text-sm">Add Minimum Spend</span>
                    </Link>
                </div>
            }
            {
                !_.isEmpty(selectedProductPricings) && props.allowMinimumSpend && showAddMinimumSpendInput &&
                <div>
                    <Row>
                        <BaseForm.Input
                            colSpan="6" name="minimum_spend" label={`Minimum Spend (in ${selectedProductPricings[0].currency})`}
                            type="number" step="0.01" min="0" placeholder="0.00" />
                    </Row>
                </div>
            }
            <br/>
        </div>
    )
})

export default ProductPricingSelection;
