import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverPost } from "../../server";
import { concat } from "lodash";

function usePostFindCreditsApi({
    options,
    limit = 20,
    query,
    sortKey = "createdAtDesc",
}) {
    const { getApiUrl} = useContext(BaseContext);

    const [credits, setCredits] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = ({ keepPreviousData = false }) => {
        const params = {
            sort_key: sortKey,
            pagination: {
                from_key: keepPreviousData ? fromKey : null,
                limit: limit,
            },
            query: query,
            include_meta: !keepPreviousData,
        };
        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        setLoading(true);
        if (!keepPreviousData) {
            setHasMore(false);
            setCredits([]);
            setTotalCount(null);
            setFromKey(null);
        }

        serverPost(getApiUrl("/credits/find"), params, options).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                const results = res.results || [];
                if (keepPreviousData) {
                    setCredits(concat(credits, results));
                } else {
                    setCredits(results);
                    setTotalCount(res.meta.total);
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    return {
        credits,
        totalUnpaginatedCreditsCount: totalCount,
        isCreditsLoading: isLoading,
        hasMoreCredits: hasMore,
        onCreditsSearch: onSearch,
    };
}

export default usePostFindCreditsApi;
