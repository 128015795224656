import '../App.scss';
import React from 'react';
import { useEffect, useState } from 'react';
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import { getUrl, serverPost } from '../helpers/server';
import BaseForm from '../components/BaseForm';
import SubmitButton from '../components/SubmitButton';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import { Row, Col, Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function Register() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [succeeded, setSucceeded] = useState(false);
    let code = searchParams.get('code');
    const [initialRegisterFields, setInitialRegisterFields] = useState({});
    const [forcedEmail, setForcedEmail] = useState(null);

    useEffect(() => {
        if (code) {
            serverPost(getUrl(`/users/find_by_code`), { code: code }).then((res) => {
                if (res) {
                    setForcedEmail(res.email);
                    setInitialRegisterFields({ email: res.email });
                }
            });
        }
    }, [code]);

    const onRegister = async (data, errorHandler) => {
        const registerData = {
            ...data,
            email: data.email.toLowerCase(),
            code
        };
        const result = await serverPost(getUrl('/users/register'), registerData, {}, errorHandler);
        if (result) {
            setSucceeded(true);
        }
}

    if (_.isNil(code)) {
        return (
            <BaseContainerWithoutNav>
                <div className="max-w-xl mx-auto flex flex-col gap-2">
                    <h3>Register For Maple</h3>
                    <Alert className="mt-2">{t('auth.already_have_account')} <Link to="/login">{t('auth.login_here')}</Link></Alert>
                    <span className="text-sm text-gray-700">Please reach out to support@maplebilling.com to register for an account.</span>
                </div>
            </BaseContainerWithoutNav>
        )
    }

    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                    {
                        !succeeded &&
                            <Col md={{span: 6, offset:3}}>
                                <h3>Register For Maple</h3>
                                <br/>
                                <Alert>{t('auth.already_have_account')} <Link to="/login">{t('auth.login_here')}</Link></Alert>
                                <BaseForm initialFormFields={initialRegisterFields} onSubmit={onRegister}>
                                    <BaseForm.Input type="text" name="name" label={t('common.fullname')} validations={{ required: true, minLength: 2 }} />
                                    <BaseForm.Input type="text" name="email" label={t('common.email')} transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} disabled={!_.isNil(forcedEmail)}/>
                                    <BaseForm.Input type="password" name="password" label={t('auth.password')} validations={{ required: true, minLength: 2 }} />
                                    <SubmitButton>{t('auth.register')}</SubmitButton>
                                </BaseForm>
                            </Col>
                    }
                    {
                        succeeded && forcedEmail &&
                            <Col md={{span: 6, offset:3}}>
                                <span className="body1 text-center">Successfully registered. <a href="/login">Login</a> to continue</span>
                            </Col>
                    }
                    {
                        succeeded && !forcedEmail &&
                            <Col md={{span: 6, offset:3}}>
                                <span className="body1 text-center">Successfully registered. Please refer to your email for verification and continue to <a href="/login">login</a>.</span>
                            </Col>
                    }
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default Register;
