import '../../App.scss';
import '../../css/modals.scss';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import React, { useState, useEffect, useContext } from 'react';
import {
    BaseContext, renderDescriptionForItemPricing, renderTags,
} from '../../helpers/common';
import {Col, Row} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {serverPost} from "../../helpers/server";
import ProductPricingInput from "../ProductPricingInput";
import DeleteButton from "../DeleteButton";
const _ = require('lodash');

function AddOrEditBundlePricingModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);
    const [billableItems, setBillableItems] = useState([]);
    const [selectedProductPricings, setSelectedProductPricings] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [hasNonOneTimeItems, setHasNonOneTimeItems] = useState(false);

    const [initialFields, setInitialFields] = useState({
        term: {
            count: 1,
            frequency: "YEAR"
        }
    });

    useEffect(() => {
        setBillableItems(props.billableItems);
    }, [props.billableItems]);

    useEffect(() => {
        if (_.isNil(props.isEditing)) {
            setIsEditing(!_.isNil(props.itemToEdit));
        } else {
            setIsEditing(props.isEditing);
        }
    }, [props.itemToEdit])

    useEffect(() => {
        if (!props.show) {
            setSelectedProductPricings([]);
            setInitialFields({
                term: {
                    count: 1,
                    frequency: "YEAR"
                }
            })
        }
    }, [props.show])

    const addItem = async (itemFields, errorHandler) => {
        const selectedProductPricingIds = _.map(selectedProductPricings, (pp) => pp.id);
        const bundleData = {
            name: itemFields.name,
            description: itemFields.description,
            term: itemFields.term,
            product_pricing_ids: selectedProductPricingIds
        }
        if (!bundleData.term) {
            bundleData.term = {
                count: 1,
                frequency: "ONETIME"
            }
        }
        const result = await serverPost(getApiUrl("/bundle_pricings"), bundleData, {}, errorHandler);
        if (result) {
            if (props.onClose) {
                props.onClose(true);
            }
        }
    }

    const planLengthOptions = [
        { value: "DAY", label: "Day" },
        { value: "WEEK", label: "Week" },
        { value: "MONTH", label: "Month" },
        { value: "QUARTER", label: "Quarter" },
        { value: "BI_ANNUAL", label: "Bi-Annual" },
        { value: "YEAR", label: "Year" },
    ]

    const onProductProductPricingDelete = (value) => {
        setSelectedProductPricings(prevSelectedProductPricings => {
            return _.filter(prevSelectedProductPricings, (p) => p.id !== value);
        })
    }

    useEffect(() => {
        console.log("on selection change " + JSON.stringify(selectedProductPricings));
        setHasNonOneTimeItems(_.some(selectedProductPricings, (spp) => {
            const flag = _.some(spp.product_metric_pricings, (pmp) => {
                return pmp.item.type !== "ONETIME_ITEM";
            })
            return flag
        }))
    }, [selectedProductPricings]);

    if (!props.show) {
        return;
    }

    console.log("has one time items " + hasNonOneTimeItems);
    return (
        <BaseAddOrEditItemModal
            {...props}
            initialFormFields={initialFields}
            itemLabel={"Bundle"}
            addItem={addItem}
            itemBaseUrl="/bundle_pricings"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="text" />
                {
                    !isEditing && hasNonOneTimeItems &&
                    <Col md="9">
                        <BaseForm.InputGroup label="Term">
                            <BaseForm.Number name="term.count" min="1" required label="Count" hideLabel/>
                            <BaseForm.Divider />
                            <BaseForm.SingleSelect
                                name="term.frequency" options={planLengthOptions} showSearch={false} fullWidth
                            />
                        </BaseForm.InputGroup>
                    </Col>
                }
            </Row>
            {
                !isEditing && _.map(selectedProductPricings, (pp, i) => {
                    const pmps = pp.product_metric_pricings;
                    return (
                        <React.Fragment key={i}>
                            <Row>
                                <Col md="12">
                                    <span className="body2">{pp.product.name}</span>
                                    <DeleteButton onDelete={() => onProductProductPricingDelete(pp.id)}/>
                                </Col>
                            </Row>
                            {
                                _.map(pmps, (pmp, j) =>
                                    <Row key={j} className="metric-pricing-row">
                                        <Col md="6">
                                            {renderDescriptionForItemPricing(pmp.item_pricing, true, pmp.item, billableItems)}
                                        </Col>
                                        {
                                            pmp.metric.type === "LICENSE_METRIC" &&
                                                <Col md="6" className="flex flex-col gap-2">
                                                    <div className="body2"></div>
                                                    <span className="text-sm">Specified Quantity</span>
                                                </Col>
                                        }
                                        {
                                            pmp.metric.type === "ONETIME_METRIC" &&
                                                <Col md="6" className="flex flex-col gap-2">
                                                    <div className="body2"></div>
                                                    <span className="text-sm">Specified Quantity</span>
                                                </Col>
                                        }
                                        {
                                            pmp.metric.type === "CUSTOM_METRIC" &&
                                                <Col md="6" className="flex flex-col gap-2">
                                                    <div className="body2">Metric</div>
                                                    <span className="text-sm">{pmp.metric.name}</span>
                                                </Col>
                                        }
                                    </Row>
                                )
                            }
                        </React.Fragment>
                    )
                })
            }
            {
                !isEditing &&
                    <Row>
                        <Col lg="9">
                            <ProductPricingInput
                                excludeBundlePricing
                                billableItems={billableItems}
                                setSelectedProductPricings={setSelectedProductPricings}
                                selectedProductPricings={selectedProductPricings}
                            />
                        </Col>
                    </Row>
            }
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditBundlePricingModal;
