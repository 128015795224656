import '../../App.scss';
import '../../css/modals.scss';
import React, { useState, useEffect, useContext, createRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Row, Col } from 'react-bootstrap';
import StripeSetupPaymentMethodV2 from '../StripeSetupPaymentMethodV2';
import {BaseContext, CustomerContext, getCurrencyOptions} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import AuthorizeNetPaymentInput from "../AuthorizeNetPaymentInput";
import Notification from "../Notification";
import {serverPost} from "../../helpers/server";
import classnames from "classnames";
import Loader from "../Loader";
import RazorpaySetupPaymentMethod from "../RazorpaySetupPaymentMethod";
const _ = require('lodash');

function CustomerPaymentMethodSetupModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext)
    const customerContext = useContext(CustomerContext);
    const customerAccessToken = customerContext ? customerContext.accessToken: null;
    const [settings, setSettings] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [errorFields, setErrorFields] = useState(null);
    const [paymentIntent, setPaymentIntent] = useState(null);
    const setupRef = createRef();
    const hasMultiplePaymentProviders = settings.payment_provider_count > 1;

    useEffect(() => {
        setSettings(props.settings);
    }, [props.settings])

    useEffect(() => {
        if (!_.isEmpty(settings)) {
            setSelectedCurrency("USD");
        }
    }, [settings]);

    useEffect(() => {
        if (!props.show) {
            return;
        }

        if (selectedCurrency) {
            if (_.isEmpty(props.customer)) {
                return;
            }
            const setupData = {
                currency: selectedCurrency
            }
            const setupOptions = {
                accessToken: props.accessToken || customerAccessToken
            }
            serverPost(getApiUrl(`/customers/${props.customer.id}/payment_methods/setup`), setupData, setupOptions).then((res) => {
                if (res) {
                    setPaymentIntent(res);
                }
            })
        }
    }, [props.show, selectedCurrency])

    useEffect(() => {
        setPaymentIntent(null);
    }, [selectedCurrency])

    const onFieldChange = (name, value) => {
        if (name === "currency") {
            setSelectedCurrency(value);
        }
    }

    const renderPaymentProviderNotSetup = () => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>Payment Provider not setup</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <p>No payment provider has been setup for your company. Please connect your existing payment provider to manage customer's payment methods.</p>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Link to={getCompanySpecificUrl("/integrations")}><Button variant="primary">Connect Payment Provider</Button></Link>
                </BaseModal.Footer>
            </BaseModal>
        )
    }

    const renderPaymentProviderNotSupported = (paymentConfig) => {
        return (
            <BaseModal {...props}>
                <BaseModal.Header>
                    <BaseModal.Title>{t('common.edit')} Provider not supported</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    The provider { paymentConfig.provider } is not supported. Please contact your company admin.
                </BaseModal.Body>
                <BaseModal.Footer>
                </BaseModal.Footer>
            </BaseModal>
        )
    }

    const errorHandler = async (res) => {
        const errorResponse = await res.json();
        setErrorFields({
            error: errorResponse.error_message
        });
    }

    const onSubmit = async (data) => {
        if (_.includes(["AUTHORIZE_NET", "authorize_net", "RAZORPAY", "razorpay"], paymentIntent.provider_key)) {
            if (setupRef.current) {
                const { paymentMethodData, error } = await setupRef.current.getPaymentMethodData(data);
                if (error) {
                    setErrorFields(error);
                    return;
                }
                serverPost(getApiUrl(`/customers/${props.customer.id}/payment_methods`), paymentMethodData, {}, errorHandler).then((res) => {
                    if (res) {
                        Notification.Success("Successfully added payment method");
                        if (props.onClose) {
                            props.onClose(true);
                        }
                    }
                });
            }
        } else {
            if (setupRef.current) {
                await setupRef.current.onSubmit(data);
            }
        }
    }
    const domain = window.location.origin;
    const relativeUrl = getCompanySpecificUrl(`/customer/${props.customer.id}`)
    const redirectUrl = props.redirectUrl || `${domain}${relativeUrl}`

    if (!props.show) {
        return null;
    }

    if (settings.payment_provider_count < 1) {
        return renderPaymentProviderNotSetup();
    }

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{t('payment_methods.add.title')}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm onSubmit={onSubmit} onFieldChange={onFieldChange} errorFields={errorFields}>
                <BaseModal.Body>
                    {
                        hasMultiplePaymentProviders &&
                            <BaseForm.Input
                                type="select" label="Currency" options={getCurrencyOptions()}
                                name="currency" showSearch={false}
                            />
                    }
                    <div className={classnames("body1", hasMultiplePaymentProviders ? "mt-3": "")}>
                        Add payment method to { props.customer.name || props.customer.email }
                    </div>
                    <Loader loading={_.isNil(paymentIntent)}>
                    {
                        () =>
                            <div className="max-w-full">
                                {
                                    paymentIntent && _.includes(["STRIPE", "stripe"], paymentIntent.provider_key) &&
                                    <div className="payment_input mt-3">
                                        <StripeSetupPaymentMethodV2
                                            ref={setupRef} {...props} redirectUrl={redirectUrl}
                                            paymentIntent={paymentIntent}
                                        />
                                    </div>
                                }
                                {
                                    paymentIntent && _.includes(["RAZORPAY", "razorpay"], paymentIntent.provider_key) &&
                                    <div className="mt-3">
                                        <RazorpaySetupPaymentMethod
                                            ref={setupRef} {...props} redirectUrl={redirectUrl}
                                            paymentIntent={paymentIntent}
                                        />
                                    </div>
                                }
                            </div>
                    }
                    </Loader>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">{t('payment_methods.add.action')}</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );
}

export default CustomerPaymentMethodSetupModal;
