import '../App.scss';
import '../css/invoice.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {notifyEvent, serverFetch, serverPatch, serverPost} from '../helpers/server';
import {BaseContext, IntegrationContext, percentageFormat} from '../helpers/common';
import BaseForm from './BaseForm';
import Link from "./Link";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import MapleTable from "./MapleTable";
import SubmitButton from "./SubmitButton";
import Notification from "./Notification";
import Label from "./Label";
const _ = require('lodash');

function AccountingTaxRatesMappingComponent(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [mapleTaxRates, setMapleTaxRates] = useState([]);
    const [accountTaxRates, setAccountTaxRates] = useState([]);
    const [accountTaxRatesLoading, setAccountTaxRatesLoading] = useState(true);
    const initialFields = useMemo(() => {
        if (!_.isEmpty(mapleTaxRates)) {
            const initialData = {
                tax_mapping: {}
            };
            _.each(mapleTaxRates, (mtr) => {
                initialData.tax_mapping[mtr.id] = mtr.remote_tax && mtr.remote_tax.id;
            })
            return initialData
        }
        return {}
    }, [mapleTaxRates]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/tax_mapping`),{ skipCache }).then((res) => {
            if (res) {
                setMapleTaxRates(res.tax_mapping || []);
            }
        });

        refreshAccountTaxRates(false)
    }

    useEffect(() => {
        fetchData();
    }, [])

    const refreshAccountTaxRates = async (notify=true) => {
        setAccountTaxRatesLoading(true);
        serverFetch(getApiUrl(`/integrations/${integration.id}/accounting/tax_rates`), { skipCache: true }).then((res) => {
            if (res) {
                setAccountTaxRates(res.tax_rates);
                if (notify) {
                    Notification.Success(`${integration.name} tax rates refreshed.`)
                }
            }
            setAccountTaxRatesLoading(false);
        })
    }

    const onAccountTaxRatesMap = async (data) => {
        const keys = data.tax_mapping ? Object.keys(data.tax_mapping): [];
        const mappingData = [];
        _.each(keys, (key, i) => {
            mappingData.push({ id: key,  remote_tax_id: data.tax_mapping[key] })
        })
        const updateData = {
            tax_mapping: mappingData,
        }
        console.log("The update data is " + JSON.stringify(updateData));
        const result = await serverPost(getApiUrl(`/integrations/${integration.id}/accounting/tax_mapping/batch_update`), updateData)
        if (result) {
            Notification.Success(`${integration.name} tax rate mapping saved.`)
            notifyEvent('integration_settings');
            if (props.onUpdate) {
                props.onUpdate();
            }
        }
    }

    const accountTaxRateOptions = _.map(accountTaxRates, (pid) => {
        return {
            value: pid.tax_id,
            label: `${pid.name} - ${ percentageFormat(pid.tax_rate) }`
        }
    })
    accountTaxRateOptions.unshift({ value: null, label: "None" })

    return (
        <div>
            <p className="text-base font-semibold text-gray-700">Map the Products between Maple and { integration.name }.</p>
            <p className="text-sm font-normal text-gray-500">In order to ensure that the right set of taxes are calculated, we have to have an accurate mapping of Maple products with { integration.name } products.</p>
            <div className="flex flex-row justify-end items-center mt-2">
                {
                    accountTaxRatesLoading ?
                        <div className="h-4 w-4 spinner-border text-secondary"/>
                        : <Link className="text-sm flex flex-row gap-1 items-center" onClick={() => refreshAccountTaxRates(true)}><ArrowPathIcon className="h-4 w-4"/> Refresh { integration.name } Tax Rates</Link>
                }
            </div>
            <BaseForm className="mt-0" initialFormFields={initialFields} onSubmit={onAccountTaxRatesMap}>
                <MapleTable>
                    <MapleTable.Content>
                        <thead>
                        <tr>
                            <MapleTable.TH>Maple Tax Rate</MapleTable.TH>
                            <MapleTable.TH>{ integration.name } Tax Rate</MapleTable.TH>
                        </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            _.map(mapleTaxRates, (item, i) =>
                                <tr key={i}>
                                    <td width="50%">
                                        <span className="text-gray-700 font-semibold">{ percentageFormat(item.combined_tax_rate) }</span><br/>
                                        {
                                            item.tax_jurisdictions &&
                                                <div className="flex flex-row gap-1 flex-wrap">
                                                    {
                                                        _.map(item.tax_jurisdictions, (js, j) =>
                                                            <Label.Info key={j}>{ js.jurisdiction.name }, { percentageFormat(js.rate) }</Label.Info>
                                                        )
                                                    }
                                                </div>
                                        }
                                    </td>
                                    <td>
                                        <BaseForm.Input name={`tax_mapping.${item.id}`} type="select" label={"Tax Rate"} hideLabel formClassName="mb-0" options={accountTaxRateOptions} showSearch={accountTaxRateOptions.length > 6} />
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </MapleTable.Content>
                </MapleTable>
                <div className="text-sm text-gray-500 my-3 italic">*Note: You can update these mappings later as well.</div>
                <SubmitButton>Update Mapping</SubmitButton>
            </BaseForm>
        </div>
    );
}

export default AccountingTaxRatesMappingComponent;
