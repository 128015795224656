import '../../App.scss';
import React, {useEffect, useState, useContext, useCallback, useRef} from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import MapleTable from '../../components/MapleTable';
import CopyableComponent from '../../components/CopyableComponent';
import moment from "moment/moment";
import Label from "../../components/Label";
import BaseOverlayTrigger from "../../components/BaseOverlayTrigger";
import InfiniteScroll from "react-infinite-scroll-component";
import {BookmarkIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function RequestLog() {
    const { t } = useTranslation('common');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null)

    useEffect(() => {
        setPageTitle(`Request Logs`);
    }, []);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                search: query,
                status: "ACTIVE"
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/requests/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setEvents(results);
                    setMeta(res.meta);
                } else {
                    const allEvents = _.concat(events, results);
                    setEvents(allEvents);
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const renderStatus = (item) => {
        if (item.status >= 200 && item.status < 300) {
            return <Label.Success>{ item.status }</Label.Success>
        } else {
            return <Label.Danger>{ item.status }</Label.Danger>
        }
    }

    const renderPath = (row) => {
        const cutoff = 60;
        let processedPath = row.path;
        if (_.startsWith(processedPath, "/api/v1/companies")) {
            processedPath = processedPath.substring(17);
        } else if (_.startsWith(processedPath, "/web/api/v1/companies")) {
            processedPath = processedPath.substring(21);
        }
        if (_.startsWith(processedPath, "/cmp_")) {
            const parts = _.split(processedPath, "/");
            processedPath = "/" + _.split(processedPath, "/").slice(2).join("/")
        }
        if (processedPath) {
            return <div className="min-w-[150px] max-w-md break-all">
                <BaseOverlayTrigger content={<span>{ row.path }</span>}>
                    <span>{ processedPath }</span>
                </BaseOverlayTrigger>
            </div>
        } else {
            return <div className="min-w-[150px] max-w-md break-all"><span className="">{ processedPath }</span></div>
        }
    }

    const renderBody = (row) => {
        if (!row.post_body) {
            return <span className="gray2">Empty</span>
        }
        const body = row.post_body || "";
        return <div className="flex flex-col items-start max-w-md">
            <div className="text-xs text-gray-500 max-w-md">
                <code className="max-w-md">
                    { body.slice(0, 300) }
                    {
                        body.length > 300 && <span>...</span>
                    }
                </code>
                {
                    body.length > 0 &&
                    <CopyableComponent copyableValue={body} value={"Copy full body"}></CopyableComponent>
                }
            </div>
        </div>
    }

    const renderResponse = (row) => {
        if (row.status === 415) {
            return (
                <span className="text-gray-500">Unsupported Media Type. Please make sure to include 'Content-Type': 'application/json' as part of the header.</span>
            )
        }
        const response = row.response ? JSON.stringify(JSON.parse(row.response)): "";
        return <div className="flex flex-col items-start max-w-md">
            <div className="flex flex-col gap-1 text-xs text-gray-500 max-w-md">
                <code className="max-w-md">
                    { response.slice(0, 300) }
                    {
                        response.length > 300 && <span>...</span>
                    }
                </code>
                {
                    response.length > 0 &&
                        <CopyableComponent copyableValue={response} value={"Copy full response"}></CopyableComponent>
                }
            </div>
        </div>
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={events.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section
                        title="Request Logs" loading={loading}
                        subtitle={"A list of all requests made through the API"}
                    >
                        <MapleTable>
                            <MapleTable.Content>
                                <thead>
                                <tr>
                                    <MapleTable.TH>Time</MapleTable.TH>
                                    <MapleTable.TH>Status</MapleTable.TH>
                                    <MapleTable.TH>Method</MapleTable.TH>
                                    <MapleTable.TH className="!px-0">Path</MapleTable.TH>
                                    <MapleTable.TH className="!px-0">Idempotency</MapleTable.TH>
                                    <MapleTable.TH className="">Body</MapleTable.TH>
                                    <MapleTable.TH className="!px-0">Response</MapleTable.TH>
                                </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                {
                                    _.map(events, (item, i) =>
                                        <tr key={i} className={item.idempotency_used ? "bg-gray-100": ""}>
                                            <td>{ moment(item.time).format("MMM D, YYYY h:mm:ss a") }</td>
                                            <td className="text-center">{ renderStatus(item) }</td>
                                            <td className="text-center bold">{ item.method }</td>
                                            <td className="!pl-0">{ renderPath(item) }</td>
                                            <td className="!pl-0">
                                                <div className="flex flex-row gap-1 items-center">
                                                    { item.idempotency_key }
                                                    { item.idempotency_used &&
                                                        <BaseOverlayTrigger content={
                                                            <span>This request is returning cached results based on the idempotency key used.
                                                                For more details about how Maple handles idempotency, please refer to
                                                                the <a href="https://maplebilling.readme.io/reference/request-idempotency">documentation</a>,
                                                            </span>
                                                        }>
                                                            <BookmarkIcon className="w-5 h-5 items-center"/>
                                                        </BaseOverlayTrigger>
                                                    }
                                                </div>
                                            </td>
                                            <td className="">{ renderBody(item) }</td>
                                            <td className="!pl-0">{ renderResponse(item) }</td>
                                        </tr>
                                    )
                                }
                                {
                                    hasMore &&
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </MapleTable.Content>
                        </MapleTable>
                    </Section>
                </InfiniteScroll>
            </ContentContainer>
        </BaseContainer>
    );
}

export default RequestLog;
