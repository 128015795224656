var templateContent = `<div>
    <h1
        tabindex="-1"
        dir="auto"
        style="
            box-sizing: border-box;
            font-size: 2em;
            margin-right: 0px;
            margin-bottom: 16px;
            margin-left: 0px;
            font-weight: var(--base-text-weight-semibold, 600);
            line-height: 1.25;
            padding-bottom: 0.3em;
            border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
            color: rgb(31, 35, 40);
            font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            letter-spacing: normal;
            orphans: 2;
            text-align: center;
            text-indent: 0px;
            text-transform: none;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            white-space: normal;
            background-color: rgb(255, 255, 255);
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
            margin-top: 0px !important;
        "
    >
        <span contenteditable="false" class="fr-deletable maple-replace maple-variable " data-key="CompanyName" data-show-language-popup="false">CompanyName</span> Service Agreement
    </h1>
    <br />
    <p dir="auto" style="padding-left: 2em; padding-right: 2em; box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
        <span style="font-size: 16px; line-height: 1.25;">
            This SaaS Services Agreement (&quot;Agreement&quot;) is entered into on the signed date (the &quot;Effective Date&quot;) between <span contenteditable="false" class="fr-deletable maple-replace maple-variable " data-key="CompanyName" data-show-language-popup="false">CompanyName</span> 
            based at <span contenteditable="false" class="fr-deletable maple-replace maple-variable " data-key="CompanyAddress" data-show-language-popup="false">CompanyAddress</span>,
            and <span contenteditable="false" class="fr-deletable maple-replace maple-variable" data-key="CustomerOrgName" data-show-language-popup="false">CustomerOrgName</span> (the &quot;Customer&quot;). This Agreement includes
            incorporates the order form below, as well as the attached Terms and Conditions, and contains among other things, warranty disclaimers, liability limitations and use limitations.
        </span>
    </p>

    <p style="padding-left: 2em; padding-right: 2em;">
        <span style="font-size: 18px; font-weight: semibold; font-size: 16px;">
            Pricing Overview (starting <span contenteditable="false" class="fr-deletable maple-replace maple-variable" data-key="ContractStartDate" data-show-language-popup="false">ContractStartDate</span>):
        </span>
    </p>
    <div style="padding-left: 2em; padding-right: 2em; font-size: 16px;">
        <span contenteditable="false" class="fr-deletable maple-replace maple-variable maple-variable-pricing" data-key="Pricing" data-show-language-popup="false">Pricing Table<img src="/images/grid.svg" class="fr-fil fr-dib" /></span>
    </div>
    <div
        dir="auto"
        style="
            box-sizing: border-box;
            padding-left: 2em;
            margin-top: 0px;
            margin-bottom: 16px;
            color: rgb(31, 35, 40);
            font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
            font-size: 16px;
            font-style: normal;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-weight: 400;
            letter-spacing: normal;
            orphans: 2;
            text-align: start;
            text-indent: 0px;
            text-transform: none;
            widows: 2;
            word-spacing: 0px;
            -webkit-text-stroke-width: 0px;
            white-space: normal;
            background-color: rgb(255, 255, 255);
            text-decoration-thickness: initial;
            text-decoration-style: initial;
            text-decoration-color: initial;
        "
    >
        <br />
        <br />
        <div style="box-sizing: border-box; font-size: 16px;">
            <table style="width: 100%;">
                <tbody>
                <tr>
                    <td style="width: 50%;">
                        By: <br />
                        Name: <br />
                        Title: <br />
                    </td>
                    <td style="width: 50%; vertical-align: top;">
                        By:&nbsp;<span contenteditable="false" class="fr-deletable maple-replace maple-variable" data-key="CustomerOrgName" data-show-language-popup="false">CustomerOrgName</span> <br />
                        Name:&nbsp;<span contenteditable="false" class="fr-deletable maple-replace maple-variable" data-key="SignatoryName" data-show-language-popup="false">SignatoryName</span> <br />
                        Date:&nbsp;<span contenteditable="false" class="fr-deletable maple-replace maple-variable" data-key="SignatorySignatureDate" data-show-language-popup="false">SignatorySignatureDate</span> <br />
                        Title:&nbsp; <br />
                        <span contenteditable="false" class="fr-deletable maple-replace maple-variable maple-variable-signature" data-key="SignatorySignature" data-show-language-popup="false">
                                Sign Here<br />
                                <i class="fa fa-signature" aria-hidden="true"></i>
                            </span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <br />
    <br />

    <p class="template-page-break" style="page-break-after: always;">&nbsp;</p>
    <div>
        <br />
        <div id="isPasted">
            <div>
                <div>
                    <div>
                        <h1
                            style="
                                box-sizing: border-box;
                                font-size: 2em;
                                margin-right: 0px;
                                margin-bottom: 16px;
                                margin-left: 0px;
                                font-weight: var(--base-text-weight-semibold, 600);
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                                color: rgb(31, 35, 40);
                                font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
                                font-style: normal;
                                font-variant-ligatures: normal;
                                font-variant-caps: normal;
                                letter-spacing: normal;
                                orphans: 2;
                                text-align: center;
                                text-indent: 0px;
                                text-transform: none;
                                widows: 2;
                                word-spacing: 0px;
                                -webkit-text-stroke-width: 0px;
                                white-space: normal;
                                background-color: rgb(255, 255, 255);
                                text-decoration-thickness: initial;
                                text-decoration-style: initial;
                                text-decoration-color: initial;
                                margin-top: 0px !important;
                                line-height: 1.15;
                                margin-bottom: 0px;
                            "
                        >
                            <span style="font-size: 18px;">Terms of Service</span>
                        </h1>
                    </div>
                </div>
                <div
                    dir="auto"
                    style="
                        box-sizing: border-box;
                        padding-left: 2em;
                        margin-top: 0px;
                        margin-bottom: 16px;
                        color: rgb(31, 35, 40);
                        font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
                        font-size: 16px;
                        font-style: normal;
                        font-variant-ligatures: normal;
                        font-variant-caps: normal;
                        font-weight: 400;
                        letter-spacing: normal;
                        orphans: 2;
                        text-align: start;
                        text-indent: 0px;
                        text-transform: none;
                        widows: 2;
                        word-spacing: 0px;
                        -webkit-text-stroke-width: 0px;
                        white-space: normal;
                        background-color: rgb(255, 255, 255);
                        text-decoration-thickness: initial;
                        text-decoration-style: initial;
                        text-decoration-color: initial;
                    "
                >
                    <div style="box-sizing: border-box;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 16px;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Service</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.1 Access and Use. During the Subscription Period and subject to the Use Limitations, Customer may (a) access and use the Cloud Service; and (b) copy and use the included Software and Documentation only as
                                needed to access and use the Cloud Service, in each case, for its internal business purposes and only if Customer complies with the terms of this Agreement.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.2 The Services shall be available 99.9%, measured monthly, excluding holidays and weekends and scheduled maintenance. &nbsp;If Customer requests maintenance during these hours, any uptime or downtime
                                calculation will exclude periods affected by such maintenance. &nbsp;Further, any downtime resulting from outages of third party connections or utilities or other reasons beyond Company&rsquo;s control will
                                also be excluded from any such calculation. Customer&#39;s sole and exclusive remedy, and Company&#39;s entire liability, in connection with Service availability shall be that for each period of downtime
                                lasting longer than one hour, Company will credit Customer 5% of Service fees for each period of 30 or more consecutive minutes of downtime; provided that no more than one such credit will accrue per day.
                                &nbsp;Downtime shall begin to accrue as soon as Customer (with notice to Company) recognizes that downtime is taking place, and continues until the availability of the Services is restored. &nbsp;In order to
                                receive downtime credit, Customer must notify Company in writing within 24 hours from the time of downtime, and failure to provide such notice will forfeit the right to receive downtime credit. &nbsp;Such
                                credits may not be redeemed for cash and shall not be cumulative beyond a total of credits for one (1) week of Service Fees in any one (1) calendar month in any event.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">1.3 Support. During the Subscription Period, Provider will provide Technical Support as described in the Cover Page, if any.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.4 User Accounts. Customer is responsible for all actions on Users&#39; accounts and for Users&#39; compliance with this Agreement. Customer and Users must protect the confidentiality of their passwords and
                                login credentials. Customer will promptly notify Provider if it suspects or knows of any fraudulent activity with its accounts, passwords, or credentials, or if they become compromised.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.5 Affiliates. If authorized in a Cover Page, individuals from Customer&#39;s Affiliates may access Customer&#39;s account as Users under Customer&#39;s Agreement and Customer will be responsible for its
                                Affiliates&#39; compliance with this Agreement. If a Customer Affiliate enters a separate Cover Page with Provider, the Customer&#39;s Affiliate creates a separate agreement between Provider and that
                                Affiliate, where Provider&#39;s responsibility to the Affiliate is individual and separate from Customer and Customer is not responsible for its Affiliates&#39; agreement.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.6 Feedback and Usage Data. Customer may, but is not required to, give Provider Feedback, in which case Customer gives Feedback &quot;AS IS&quot;. Provider may use all Feedback freely without any restriction
                                or obligation. In addition, Provider may collect and analyze Usage Data, and Provider may freely use Usage Data to maintain, improve, and enhance Provider&#39;s products and services without restriction or
                                obligation. However, Provider may only share Usage Data with others if the Usage Data is aggregated and does not identify Customer or Users.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                1.7 Customer Content. Provider may copy, display, modify, and use Customer Content only as needed to provide and maintain the Product and related offerings. Customer is responsible for the accuracy and
                                content of Customer Content.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Restrictions &amp; Obligations</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">2.1&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Restrictions on Customer.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">(a) Except as expressly permitted by this Agreement,&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">
                                &nbsp;will not (and will not allow any anyone else to): (i) reverse engineer, decompile, or attempt to discover any source code or underlying ideas or algorithms of the Product (except to the extent
                                Applicable Laws prohibit this restriction); (ii) provide, sell, transfer, sublicense, lend, distribute, rent, or otherwise allow others to access or use the Product; (iii) remove any proprietary notices or
                                labels; (iv) copy, modify, or create derivative works of the Product; (v) conduct security or vulnerability tests on, interfere with the operation of, cause performance degradation of, or circumvent access
                                restrictions of the Product; (vi) access accounts, information, data, or portions of the Product to which&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">
                                &nbsp;does not have explicit authorization; (vii) use the Product to develop a competing service or product; (viii) use the Product with any High Risk Activities or with activity prohibited by Applicable
                                Laws; (ix) use the Product to obtain unauthorized access to anyone else&rsquo;s networks or equipment; or (x) upload, submit, or otherwise make available to the Product any Customer Content to which&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;and Users do not have the proper rights.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">(b)&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span>
                            <span style="font-size: 16px;">&nbsp;use of the Product must comply with all Documentation and the&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Acceptable Use Policy</span>
                            <span style="font-size: 16px;">, if any.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 18px;">2.2&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Suspension.</span><span style="font-size: 16px;">&nbsp;If&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 18px;">&nbsp;(a) has an outstanding, undisputed balance on its account for more than 30 days after the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Payment Period</span>
                            <span style="font-size: 16px;">
                                ; (b) breaches Section 2.1 (Restrictions on Customer); or (c) uses the Product in violation of the Agreement or in a way that materially and negatively impacts the Product or others, then&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;may temporarily suspend&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;access to the Product with or without notice. However,&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;will try to inform&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;before suspending&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span>
                            <span style="font-size: 16px;">&nbsp;account when practical.&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;will reinstate&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;access to the Product only if&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;resolves the underlying issue.</span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Professional Services</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;will perform the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">&nbsp;as detailed in a Cover Page, if any, and&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;will reasonably cooperate with&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;to allow the performance of&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">, including providing Customer Content as needed.&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 18px;">Provider</span><span style="font-size: 16px;">&nbsp;is not responsible for any inability to perform the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">&nbsp;if&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;does not cooperate as reasonably requested.</span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Privacy &amp; Security</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">4.1&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Personal Data.</span>
                            <span style="font-size: 16px;">&nbsp;Before submitting Personal Data governed by GDPR,&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;must enter into a data processing agreement with&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Provider</span>
                            <span style="font-size: 16px;">. If the parties have a&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">DPA</span><span style="font-size: 16px;">, the terms of the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">DPA</span><span style="font-size: 16px;">&nbsp;will control each party&#39;s rights and obligations as to Personal Data and the terms of the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">DPA</span><span style="font-size: 16px;">&nbsp;will control in the event of any conflict with this Agreement.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">4.2&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Prohibited Data.</span><span style="font-size: 16px;">&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;will not (and will not allow anyone else to) submit Prohibited Data to the Product unless authorized by the Cover Page.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">4.3&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Security.</span><span style="font-size: 16px;">&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;will comply with the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Security Policy</span><span style="font-size: 16px;">, if any.</span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Payment &amp; Taxes</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">5.1&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Fees and Invoices.</span>
                            <span style="font-size: 16px;">
                                &nbsp;All fees are in U.S. Dollars and are exclusive of taxes. Except for the prorated refund of prepaid fees allowed with specific termination rights, fees are non-refundable.&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;will send invoices for fees applicable to the Product once per&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Invoice Period</span><span style="font-size: 16px;">&nbsp;in advance starting on the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Subscription Start Date</span><span style="font-size: 16px;">. Invoices for&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">&nbsp;may be sent monthly during performance of the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">&nbsp;unless the Cover Page includes a different cadence.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">5.2&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Payment.</span><span style="font-size: 16px;">&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;will pay&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Provider</span>
                            <span style="font-size: 16px;">&nbsp;the fees and taxes in each invoice in U.S. Dollars within the&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Payment Period</span>
                            <span style="font-size: 16px;">.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">5.3&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Taxes.</span><span style="font-size: 16px;">&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;is responsible for all duties, taxes, and levies that apply to fees, including sales, use, VAT, GST, or withholding, that&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;itemizes and includes in an invoice. However,&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;is not responsible for&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider&#39;s</span><span style="font-size: 16px;">&nbsp;income taxes.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">5.4&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Payment Dispute.</span><span style="font-size: 16px;">&nbsp;If&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;has a good-faith disagreement about the amounts charged on an invoice,&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;must notify&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Provider</span>
                            <span style="font-size: 16px;">&nbsp;about the dispute during the&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Payment Period</span>
                            <span style="font-size: 16px;">&nbsp;for the invoice and must pay all undisputed amounts on time. The parties will work together to resolve the dispute within 15 days after the end of the&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Payment Period</span>
                            <span style="font-size: 16px;">. If no resolution is agreed, each party may pursue any remedies available under the Agreement or Applicable Laws.</span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Term &amp; Termination</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                6.1&nbsp; Subscription Period. Each Order Form will start on the&nbsp;Subscription Start Date, continue for the&nbsp;Subscription Period, and automatically renew for additional&nbsp;Subscription
                                Periods&nbsp;unless one party gives notice of non-renewal to the other party before the&nbsp;Non-Renewal Notice Date.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">6.2 Agreement Term. This Agreement will start on the Effective Date and continue for the longer of one year or until all Subscription Periods have ended.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                6.3 Termination. Either party may terminate this Agreement if the other party (a) fails to cure a material breach of the Agreement within 30 days after receiving notice of the breach; (b) materially breaches
                                the Agreement in a manner that cannot be cured; (c) dissolves or stops conducting business without a successor; (d) makes an assignment for the benefit of creditors; or (e) becomes the debtor in insolvency,
                                receivership, or bankruptcy proceedings that continue for more than 60 days. In addition, either party may terminate an affected Order Form if a Force Majeure Event prevents the Product from materially
                                operating for 30 or more consecutive days, and Provider will pay to Customer a prorated refund of prepaid fees for the remainder of the Subscription Period. A party must notify the other of its reason for
                                termination.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">6.4 Effect of Termination. Termination of the Agreement will automatically terminate all Order Forms. Upon expiration or termination:</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">(a) Customer will no longer have any right to use the Product, Technical Support, or Professional Services.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;"><span style="font-size: 16px;">(b) Upon Customer&#39;s request, Provider will delete Customer Content within 60 days.</span></p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">(c) Each Recipient will return or destroy Discloser&#39;s Confidential Information in its possession or control.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">(d) Provider will submit a final invoice for all outstanding fees accrued before termination and Customer will pay the invoice according to Section 5 (Payment &amp; Taxes).</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;"><span style="font-size: 16px;">6.5&nbsp;Survival.</span></p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                (a) The following sections will survive expiration or termination of the Agreement: Section 1.6 (Feedback and Usage Data), Section 2.1 (Restrictions on Customer), Section 5 (Payment &amp; Taxes) for fees
                                accrued or payable before expiration or termination, Section 6.4 (Effect of Termination), Section 6.5 (Survival), Section 7 (Representations &amp; Warranties), Section 8 (Disclaimer of Warranties), Section 9
                                (Limitation of Liability), Section 10 (Indemnification), Section 11 (Insurance) for the time period specified, Section 12 (Confidentiality), Section 13 (Reservation of Rights), Section 14 (General Terms),
                                Section 15 (Definitions), and the portions of a Cover Page referenced by these sections.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                (b) Each Recipient may retain Discloser&#39;s Confidential Information in accordance with its standard backup or record retention policies maintained in the ordinary course of business or as required by
                                Applicable Laws, in which case Section 4 (Privacy &amp; Security) and Section 12 (Confidentiality) will continue to apply to retained Confidential Information.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Representations &amp; Warranties</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">7.1&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Mutual.</span>
                            <span style="font-size: 16px;">
                                &nbsp;Each party represents and warrants to the other that: (a) it has the legal power and authority to enter into this Agreement; (b) it is duly organized, validly existing, and in good standing under the
                                Applicable Laws of the jurisdiction of its origin; (c) it will comply with all Applicable Laws in performing its obligations or exercising its rights in this Agreement; and (d) it will comply with the&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Additional Warranties</span><span style="font-size: 16px;">.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">7.2&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">From Customer</span><span style="font-size: 16px;">.&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">
                                &nbsp;represents and warrants that it, all Users, and anyone submitting Customer Content each have and will continue to have all rights necessary to submit or make available Customer Content to the Product
                                and to allow the use of Customer Content as described in the Agreement.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">7.3&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">From Provider.</span><span style="font-size: 16px;">&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;represents and warrants to&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;that (a) it will not materially reduce the general functionality of the Cloud Service during a&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Subscription Period</span><span style="font-size: 16px;">; and (b) it will perform&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">&nbsp;in a competent and professional manner.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                7.4 Provider Warranty Remedy. If Provider breaches a warranty in Section 7.3, Customer must give Provider notice (with enough detail for Provider to understand or replicate the issue) within 45 days of
                                discovering the issue. Within 45 days of receiving sufficient details of the warranty issue, Provider will attempt to restore the general functionality of the Cloud Service or reperform the Professional
                                Services. If Provider cannot resolve the issue, Customer may terminate the affected Order Form and Provider will pay to Customer a prorated refund of prepaid fees for the remainder of the Subscription Period.
                                Provider&#39;s restoration and reperformance obligations, and Customer&#39;s termination right, are Customer&#39;s only remedies if Provider does not meet the warranties in Section 7.3.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Disclaimer of Warranties</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="box-sizing: border-box;">
                                <span style="box-sizing: border-box; font-size: 16px;">
                                    Provider makes no guarantees that the Product will always be safe, secure, or error-free, or that it will function without disruptions, delays, or imperfections. The warranties in Section 7.3 do not apply
                                    to any misuse or unauthorized modification of the Product, nor to any product or service provided by anyone other than Provider. Except for the warranties in Section 7, Provider and Customer each disclaim
                                    all other warranties, whether express or implied, including the implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. These disclaimers apply to the maximum
                                    extent permitted by Applicable Laws.
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    start="9"
                    dir="auto"
                    style="
                        box-sizing: border-box;
                        padding-left: 2em;
                        margin-top: 0px;
                        margin-bottom: 16px;
                        color: rgb(31, 35, 40);
                        font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
                        font-size: 16px;
                        font-style: normal;
                        font-variant-ligatures: normal;
                        font-variant-caps: normal;
                        font-weight: 400;
                        letter-spacing: normal;
                        orphans: 2;
                        text-align: start;
                        text-indent: 0px;
                        text-transform: none;
                        widows: 2;
                        word-spacing: 0px;
                        -webkit-text-stroke-width: 0px;
                        white-space: normal;
                        background-color: rgb(255, 255, 255);
                        text-decoration-thickness: initial;
                        text-decoration-style: initial;
                        text-decoration-color: initial;
                    "
                >
                    <div style="box-sizing: border-box;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Limitation of Liability</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">9.1&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Liability Caps.</span>
                            <span style="font-size: 16px;">
                                &nbsp;
                                <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">
                                    If there are <span style="box-sizing: border-box;">Increased Claims</span>, each party&#39;s total cumulative liability for the <span style="box-sizing: border-box;">Increased Claims</span> arising out of
                                    or relating to this Agreement will not be more than the <span style="box-sizing: border-box;">Increased Cap Amount</span>. Each party&#39;s total cumulative liability for all other claims arising out of
                                    or relating to this Agreement will not be more than the <span style="box-sizing: border-box;">General Cap Amount</span>.
                                </strong>
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">9.2&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Damages Waiver.</span>
                            <span style="font-size: 16px;">
                                &nbsp;
                                <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">
                                    Each party&#39;s liability for any claim or liability arising out of or relating to this Agreement will be limited to the fullest extent permitted by Applicable Laws. Under no circumstances will either
                                    party be liable to the other for lost profits or revenues, or for consequential, special, indirect, exemplary, punitive, or incidental damages relating to this Agreement, even if the party is informed of
                                    the possibility of this type of damage in advance.
                                </strong>
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">9.3&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Exceptions.</span>
                            <span style="font-size: 16px;">&nbsp;The liability caps in Section 9.1 and the damages waiver in Section 9.2 do not apply to any&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Unlimited Claims</span><span style="font-size: 16px;">. The damages waiver in Section 9.2 does not apply to any&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Increased Claims</span><span style="font-size: 16px;">.</span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Indemnification</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                10.1 Protection by Provider. Provider will indemnify, defend, and hold harmless Customer from and against all Provider Covered Claims made by someone other than Customer, Customer&#39;s Affiliates, or Users,
                                and all out-of-pocket damages, awards, settlements, costs, and expenses, including reasonable attorneys&#39; fees and other legal expenses, that arise from the Provider Covered Claim.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                10.2 Protection by Customer. Customer will indemnify, defend, and hold harmless Provider from and against all Customer Covered Claims made by someone other than Provider or its Affiliates, and all
                                out-of-pocket damages, awards, settlements, costs, and expenses, including reasonable attorneys&#39; fees and other legal expenses, that arise from the Customer Covered Claim.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                10.3 Procedure. The Indemnifying Party&#39;s obligations in this section are contingent upon the Protected Party: (a) promptly notifying the Indemnifying Party of each Covered Claim for which it seeks
                                protection; (b) providing reasonable assistance to the Indemnifying Party at the Indemnifying Party&#39;s expense; and (c) giving the Indemnifying Party sole control over the defense and settlement of each
                                Covered Claim. A Protected Party may participate in a Covered Claim for which it seeks protection with its own attorneys only at its own expense. The Indemnifying Party may not agree to any settlement of a
                                Covered Claim that contains an admission of fault or otherwise materially and adversely impacts the Protected Party without the prior written consent of the Protected Party.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                10.4 Changes to Product. If required by settlement or court order, or if deemed reasonably necessary in response to a Provider Covered Claim, Provider may: (a) obtain the right for Customer to continue using
                                the Product; (b) replace or modify the affected component of the Product without materially reducing the general functionality of the Product; or (c) if neither (a) nor (b) are reasonable, terminate the
                                affected Order Form and issue a pro-rated refund of prepaid fees for the remainder of the Subscription Period.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;"><span style="font-size: 16px;">10.5&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Exclusions.</span></p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                (a) Provider&#39;s obligations as an Indemnifying Party will not apply to Provider Covered Claims that result from (i) modifications to the Product that were not authorized by Provider or that were made in
                                compliance with Customer&#39;s instructions; (ii) unauthorized use of the Product, including use in violation of this Agreement; (iii) use of the Product in combination with items not provided by Provider; or
                                (iv) use of an old version of the Product where a newer release would avoid the Provider Covered Claim.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                (b) Customer&#39;s obligations as an Indemnifying Party will not apply to Customer Covered Claims that result from the unauthorized use of the Customer Content, including use in violation of this Agreement.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                10.6 Exclusive Remedy. This Section 10 (Indemnification), together with any termination rights, describes each Protected Party&#39;s exclusive remedy and each Indemnifying Party&#39;s entire liability for a
                                Covered Claim.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Insurance</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="box-sizing: border-box;">
                                <span style="font-size: 16px;">
                                    During the Subscription Period and for six months after, Provider will carry commercial insurance policies with coverage limits that meet the Insurance Minimums, if any. Upon request, Provider will give
                                    Customer a certificate of insurance evidencing its insurance policies that meet the Insurance Minimums. Provider&#39;s insurance policies will not be considered as evidence of Provider&#39;s liability.
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div
                    start="12"
                    dir="auto"
                    style="
                        box-sizing: border-box;
                        padding-left: 2em;
                        margin-top: 0px;
                        margin-bottom: 0px !important;
                        color: rgb(31, 35, 40);
                        font-family: -apple-system, 'system-ui', 'Segoe UI', 'Noto Sans', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
                        font-size: 16px;
                        font-style: normal;
                        font-variant-ligatures: normal;
                        font-variant-caps: normal;
                        font-weight: 400;
                        letter-spacing: normal;
                        orphans: 2;
                        text-align: start;
                        text-indent: 0px;
                        text-transform: none;
                        widows: 2;
                        word-spacing: 0px;
                        -webkit-text-stroke-width: 0px;
                        white-space: normal;
                        background-color: rgb(255, 255, 255);
                        text-decoration-thickness: initial;
                        text-decoration-style: initial;
                        text-decoration-color: initial;
                    "
                >
                    <div style="box-sizing: border-box;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Confidentiality</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                12.1 Non-Use and Non-Disclosure. Unless otherwise authorized in the Agreement, Recipient will (a) only use Discloser&#39;s Confidential Information to fulfill its obligations or exercise its rights under this
                                Agreement; and (b) not disclose Discloser&#39;s Confidential Information to anyone else. In addition, Recipient will protect Discloser&#39;s Confidential Information using at least the same protections
                                Recipient uses for its own similar information but no less than a reasonable standard of care.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                12.2 Exclusions. Confidential Information does not include information that (a) Recipient knew without any obligation of confidentiality before disclosure by Discloser; (b) is or becomes publicly known and
                                generally available through no fault of Recipient; (c) Recipient receives under no obligation of confidentiality from someone else who is authorized to make the disclosure; or (d) Recipient independently
                                developed without use of or reference to Discloser&#39;s Confidential Information.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                12.3 Required Disclosures. Recipient may disclose Discloser&#39;s Confidential Information to the extent required by Applicable Laws if, unless prohibited by Applicable Laws, Recipient provides the Disclosing
                                Party reasonable advance notice of the required disclosure and reasonably cooperates, at the Discloser&#39;s expense, with the Discloser&#39;s efforts to obtain confidential treatment for the Confidential
                                Information.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                12.4 Permitted Disclosures. Recipient may disclose Discloser&#39;s Confidential Information to Users, employees, advisors, contractors, and representatives who each have a need to know the Confidential
                                Information, but only if the person or entity is bound by confidentiality obligations at least as protective as those in this Section 12 and Recipient remains responsible for everyone&#39;s compliance with
                                the terms of this Section 12.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Reservation of Rights</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                Except for the limited license to copy and use Software and Documentation in Section 1.1 (Access and Use), Provider retains all right, title, and interest in and to the Product, whether developed before or
                                after the Effective Date. Except for the limited rights in Section 1.7 (Customer Content), Customer retains all right, title, and interest in and to the Customer Content.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">General Terms</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.1 Entire Agreement. This Agreement is the only agreement between the parties about its subject and this Agreement supersedes all prior or contemporaneous statements (whether in writing or not) about its
                                subject. Provider expressly rejects any terms included in Customer&#39;s purchase order or similar document, which may only be used for accounting or administrative purposes.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.2 Modifications, Severability, and Waiver. Any waiver, modification, or change to the Agreement must be in writing and signed or electronically accepted by each party. However, Provider may update
                                Technical Support, the SLA, the Security Policy, or the Acceptable Use Policy by giving Customer 30 days prior notice. During the 30-day notice period, Customer may terminate the Agreement or affected Order
                                Form upon notice if the update is a material reduction from the prior version and Provider cannot reasonably restore the prior version or a comparable alternative. If any term of this Agreement is determined
                                to be invalid or unenforceable by a relevant court or governing body, the remaining terms of this Agreement will remain in full force and effect. The failure of a party to enforce a term or to exercise an
                                option or right in this Agreement will not constitute a waiver by that party of the term, option, or right.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.3 Governing Law and Chosen Courts. The Governing Law will govern all interpretations and disputes about this Agreement, without regard to its conflict of laws provisions. The parties will bring any legal
                                suit, action, or proceeding about this Agreement in the Chosen Courts and each party irrevocably submits to the exclusive jurisdiction of the Chosen Courts.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.4 Injunctive Relief. Despite Section 14.3 (Governing Law and Chosen Courts), a breach of Section 12 (Confidentiality) or the violation of a party&#39;s intellectual property rights may cause irreparable
                                harm for which monetary damages cannot adequately compensate. As a result, upon the actual or threatened breach of Section 12 (Confidentiality) or violation of a party&#39;s intellectual property rights, the
                                non-breaching or non-violating party may seek appropriate equitable relief, including an injunction, in any court of competent jurisdiction without the need to post a bond and without limiting its other
                                rights or remedies.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.5 Non-Exhaustive Remedies. Except where the Agreement provides for an exclusive remedy, seeking or exercising a remedy does not limit the other rights or remedies available to a party.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.6 Assignment. Neither party may assign any rights or obligations under this Agreement without the prior written consent of the other party. However, either party may assign this Agreement upon notice if
                                the assigning party undergoes a merger, change of control, reorganization, or sale of all or substantially all its equity, business, or assets to which this Agreement relates. Any attempted but non-permitted
                                assignment is void. This Agreement will be binding upon and inure to the benefit of the parties and their permitted successors and assigns.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">14.7 No Publicity. Neither party may publicly announce the existence of this Agreement without the prior written approval of the other party.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.8 Notices. Any notice, request, or approval about the Agreement must be in writing and sent to the Notice Address. Notices will be deemed given (a) upon confirmed delivery if by email, registered or
                                certified mail, or personal delivery; or (b) two days after mailing if by overnight commercial delivery.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.9 Independent Contractors. The parties are independent contractors, not agents, partners, or joint venturers. Neither party is authorized to bind the other to any liability or obligation.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">14.10 No Third-Party Beneficiary. There are no third-party beneficiaries of this Agreement.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.11 Force Majeure. Neither party will be liable for a delay or failure to perform its obligations of this Agreement if caused by a Force Majeure Event. However, this section does not excuse Customer&#39;s
                                obligations to pay fees.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.12 Export Controls. Customer may not remove or export from the United States or allow the export or re-export of the Product or any related technology or materials in violation of any restrictions, laws,
                                or regulations of the United States Department of Commerce, the United States Department of Treasury Office of Foreign Assets Control, or any other United States or foreign agency or authority.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.13 Government Rights. The Cloud Service and Software are deemed &quot;commercial items&quot; or &quot;commercial computer software&quot; according to FAR section 12.212 and DFAR section 227.7202, and the
                                Documentation is &quot;commercial computer software documentation&quot; according to DFAR section 252.227-7014(a)(1) and (5). Any use, modification, reproduction, release, performance, display, or disclosure
                                of the Product by the U.S. Government will be governed solely by the terms of this Agreement and all other use is prohibited.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.14 Anti-Bribery. Neither party will take any action that would be a violation of any Applicable Laws that prohibit the offering, giving, promising to offer or give, or receiving, directly or indirectly,
                                money or anything of value to any third party to assist Provider or Customer in retaining or obtaining business. Examples of these kinds of laws include the U.S. Foreign Corrupt Practices Act and the UK
                                Bribery Act 2010.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.15 Titles and Interpretation. Section titles are for convenience and reference only. All uses of &quot;including&quot; and similar phrases are non-exhaustive and without limitation. The United Nations
                                Convention for the International Sale of Goods and the Uniform Computer Information Transaction Act do not apply to this Agreement.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                14.16 Signature. This Agreement may be signed in counterparts, including by electronic copies or acceptance mechanism. Each copy will be deemed an original and all copies, when taken together, will be the
                                same agreement.
                            </span>
                        </p>
                    </div>
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <h2
                            tabindex="-1"
                            dir="auto"
                            style="
                                box-sizing: border-box;
                                margin-top: 24px;
                                margin-bottom: 16px;
                                font-size: 1.5em;
                                font-weight: var(--base-text-weight-semibold, 600);
                                line-height: 1.25;
                                padding-bottom: 0.3em;
                                border-bottom: 1px solid var(--borderColor-muted, var(--color-border-muted));
                            "
                        >
                            <span style="font-size: 18px;">Definitions</span>
                        </h2>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.1 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Affiliate&quot;</strong> means an entity that, directly or indirectly, controls, is under the control of,
                                or is under common control with a party, where control means having more than fifty percent (50%) of the voting stock or other ownership interest.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.2 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Agreement&quot;</strong> means these Standard Terms, together with the Cover Pages between&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;and&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;that include or reference a single set of Key Terms and the policies and documents referenced in or attached to those Cover Pages.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.3 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Applicable Data Protection Laws&quot;</strong> means the Applicable Laws that govern how the Cloud
                                Service may process or use an individual&#39;s personal information, personal data, personally identifiable information, or other similar term.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.4 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Applicable Laws&quot;</strong> means the laws, rules, regulations, court orders, and other binding
                                requirements of a relevant government authority that apply to or govern&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;or&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.5 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Cloud Service&quot;</strong> means the product described in an Order Form.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.6 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Confidential Information&quot;</strong> means information in any form disclosed by or on behalf of a
                                Discloser, including before the&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Effective Date</span>
                            <span style="font-size: 16px;">
                                , to a Recipient in connection with this Agreement that (a) the Discloser identifies as &quot;confidential&quot;, &quot;proprietary&quot;, or the like; or (b) should be reasonably understood as confidential
                                or proprietary due to its nature and the circumstances of its disclosure. Confidential Information includes the existence of this Agreement and the information on each Cover Page.&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;Confidential Information includes non-public Customer Content and&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider&#39;s</span><span style="font-size: 16px;">&nbsp;Confidential Information includes non-public information about the Product.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.7 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Cover Page&quot;</strong> means a document that is signed or electronically accepted by the parties that
                                incorporates these Standard Terms, identifies&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;and&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">, and may include an Order Form, Key Terms, or both.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">15.8 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Covered Claim&quot;</strong> means either a&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider Covered Claim</span><span style="font-size: 16px;">&nbsp;or&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer Covered Claim</span><span style="font-size: 16px;">.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.9 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Customer Content&quot;</strong> means data, information, or materials submitted by or on behalf of&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer</span><span style="font-size: 16px;">&nbsp;or Users to the Product, but excludes Feedback.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.10 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Discloser&quot;</strong> means a party to this Agreement when the party is providing or disclosing
                                Confidential Information to the other party.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.11 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Documentation&quot;</strong> means the usage manuals and instructional materials for the Cloud Service
                                or Software that are made available by&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.12 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Feedback&quot;</strong> means suggestions, feedback, or comments about the Product or related offerings.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.13 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Force Majeure Event&quot;</strong> means an unforeseen event outside a party&#39;s reasonable control
                                where the affected party took reasonable measures to avoid or mitigate the impacts of the event. Examples of these kinds of events include unpredicted natural disaster like a major earthquake, war, pandemic,
                                riot, act of terrorism, or public utility or internet failure.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.14 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;GDPR&quot;</strong> means European Union Regulation 2016/679 as implemented by local law in the relevant
                                European Union member nation, and by section 3 of the United Kingdom&#39;s European Union (Withdrawal) Act of 2018 in the United Kingdom.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.15 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;High Risk Activity&quot;</strong> means any situation where the use or failure of the Product could be
                                reasonably expected to lead to death, bodily injury, or environmental damage. Examples include full or partial autonomous vehicle technology, medical life-support technology, emergency response services,
                                nuclear facilities operation, and air traffic control.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.16 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Indemnifying Party&quot;</strong> means a party to this Agreement when the party is providing protection
                                for a particular Covered Claim.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.17 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Key Terms&rdquo;</strong> means the portion of a Cover Page that includes the key legal details and
                                definitions for this Agreement that are not defined in the Standard Terms. The Key Terms may include details about Covered Claims, set the&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Governing Law</span><span style="font-size: 16px;">, or contain other details about this Agreement.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.18 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Order Form&quot;</strong> means the portion of a Cover Page that includes the key business details and
                                definitions for this Agreement that are not defined in the Standard Terms. An Order Form may include details about the level of access and use granted to the Cloud Service, nature and timing of&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Professional Services</span><span style="font-size: 16px;">, extent of&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Technical Support</span><span style="font-size: 16px;">, or other details about the Product.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.19 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Personal Data&quot;</strong> will have the meaning(s) set forth in the Applicable Data Protection Laws
                                for personal information, personal data, personally identifiable information, or other similar term.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.20 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Product&rdquo;</strong> means the Cloud Service, Software, and Documentation.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.21 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Prohibited Data&quot;</strong> means (a) patient, medical, or other protected health information
                                regulated by the Health Insurance Portability and Accountability Act; (b) credit, debit, bank account, or other financial account numbers; (c) social security numbers, driver&#39;s license numbers, or other
                                unique and private government ID numbers; (d) special categories of data as defined in the GDPR; and (e) other similar categories of sensitive information as set forth in the Applicable Data Protection Laws.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.22 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Protected Party&quot;</strong> means a party to this Agreement when the party is receiving the benefit
                                of protection for a particular Covered Claim.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.23 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Recipient&quot;</strong> means a party to this Agreement when the party receives Confidential
                                Information from the other party.
                            </span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.24 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Software&quot;</strong> means the client-side software or applications made available by&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Provider</span><span style="font-size: 16px;">&nbsp;for&nbsp;</span><span style="box-sizing: border-box; font-size: 16px;">Customer</span>
                            <span style="font-size: 16px;">&nbsp;to install, download (whether onto a machine or in a browser), or execute as part of the Product.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">
                                15.25 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;Usage Data&quot;</strong> means data and information about the provision, use, and performance of the
                                Product and related offerings based on&nbsp;
                            </span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;or User&#39;s use of the Product.</span>
                        </p>

                        <p dir="auto" style="box-sizing: border-box; margin-top: 16px; margin-bottom: 16px;">
                            <span style="font-size: 16px;">15.26 <strong style="box-sizing: border-box; font-weight: var(--base-text-weight-semibold, 600);">&quot;User&quot;</strong> means any individual who uses the Product on&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;behalf or through&nbsp;</span>
                            <span style="box-sizing: border-box; font-size: 16px;">Customer&#39;s</span><span style="font-size: 16px;">&nbsp;account.</span>
                        </p>
                    </div>
                </div>
                <div style="padding: 1em 2em;">
                    <div style="box-sizing: border-box; margin-top: 0.25em;">
                        <span contenteditable="false" class="fr-deletable maple-replace maple-variable maple-variable-signature" data-key="SignatorySignature" data-show-language-popup="false">
                            Sign Here<br />
                            <i class="fa fa-signature" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`

export default templateContent