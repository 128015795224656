import React from "react";
import moment from "moment";
import classNames from "classnames";
import MapleTable from "./MapleTable";
import Loader from "./Loader";
import { currencyFormatFromPrice, getDescriptionForCreditState } from "../helpers/common";
import { useTranslation } from "react-i18next";

function CustomerPortalCreditTable({
    creditsData: { credits, isCreditsLoading, hasMoreCredits },
    moreCreditTableRows,
}) {
    const { t } = useTranslation("common");

    return (
        <MapleTable>
            <MapleTable.Content>
                <thead>
                    <tr>
                        <MapleTable.TH>{t("credits.table.header.issued_date")}</MapleTable.TH>
                        <MapleTable.TH>{t("credits.table.header.name")}</MapleTable.TH>
                        <MapleTable.TH>{t("credits.table.header.quantity")}</MapleTable.TH>
                        <MapleTable.TH>{t("credits.table.header.expiry")}</MapleTable.TH>
                        <MapleTable.TH>{t("credits.table.header.status")}</MapleTable.TH>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                    {isCreditsLoading && !hasMoreCredits ? (
                        <tr key="customer-portal-credits-loading">
                            <td className="text-center" colSpan="5">
                                <Loader loading={true} />
                            </td>
                        </tr>
                    ) : credits.length ? (
                        <>
                            {credits.map((credit) => {
                                return (
                                    <tr
                                        key={credit.id}
                                        className={classNames(credit.state !== "ACTIVE" && "de-highlighted")}
                                    >
                                        <td>{moment(credit.created_at).format("MMM D, YYYY")}</td>
                                        <td>{credit.name}</td>
                                        <td>
                                            {credit.type === "AMOUNT" ? (
                                                <span>{currencyFormatFromPrice(credit.issued_amount)}</span>
                                            ) : (
                                                <span>{`${credit.issued_units} ${credit.item?.name}`}</span>
                                            )}
                                            <br />
                                            {credit.state != "CONSUMED" && (
                                                <span className="caption whitespace-nowrap">
                                                    {`${t("credits.table.body.quantity.remaining")}: `}
                                                    {credit.type === "AMOUNT"
                                                        ? currencyFormatFromPrice(credit.amount)
                                                        : `${credit.units} ${credit.item?.name}`}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {credit.expiration_date ? (
                                                moment(credit.expiration_date).format("MMM D, YYYY h:mma")
                                            ) : (
                                                <div className="gray2">{t("credits.table.body.expiry.none")}</div>
                                            )}
                                        </td>
                                        <td>{getDescriptionForCreditState(credit.state)}</td>
                                    </tr>
                                );
                            })}
                            {moreCreditTableRows}
                        </>
                    ) : (
                        <tr>
                            <td className="text-center" colSpan="5">
                                {t("credits.table.empty_search_results")}
                            </td>
                        </tr>
                    )}
                </tbody>
            </MapleTable.Content>
        </MapleTable>
    );
}

export default CustomerPortalCreditTable;
