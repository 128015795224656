import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo} from 'react';
import {
    BaseContext,
    UserContext,
    currencyFormatFromPrice,
    getCurrencyOptions,
    getNameForProviderType, downloadBlob
} from '../helpers/common';
import { serverPost } from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import Columns from './Columns';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from 'react-i18next';
import Section from './Section';
import Label from './Label';
import Link from './Link';
import moment from 'moment';
import BulkInvoiceEmailModal from "./modals/BulkInvoiceEmailModal";
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import BaseOverlayTrigger from "./BaseOverlayTrigger";
import TopBarSummary from "./TopBarSummary";
import {ArrowTopRightOnSquareIcon, BuildingOfficeIcon} from "@heroicons/react/24/outline";
import {
    getInvoiceExportFields,
    getInvoiceLineItemExportFields,
} from "../helpers/exportFields";
import BaseCSVExportModal from "./modals/BaseCSVExportModal";
import DropdownMenu from "./DropdownMenu";
import {renderStatusLabel} from "../helpers/invoices";
const _ = require('lodash');

const InvoiceListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh(delay=0) {
            if (delay) {
                setTimeout(() => {
                    onSearch(true);
                }, delay)
            } else {
                onSearch(true);
            }
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [invoices, setInvoices] = useState([]);
    const [includeCustomer, setIncludeCustomer] = useState(true);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [showBulkSelection, setShowBulkSelection] = useState(false);
    const [showStatusImageColumn, setShowStatusImageColumn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showBulkEmailModal, setShowBulkEmailModal] = useState(false);
    const [showExportInvoicesModal, setShowExportInvoicesModal] = useState(false);
    const [showExportInvoiceLineItemsModal, setShowExportInvoiceLineItemsModal] = useState(false);

    const [checkedItemIds, setCheckedItemIds] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState(null);

    const [sort, setSort] = useState("createdAtDesc");
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [filters, setFilters] = useState([])
    const [meta, setMeta] = useState({});

    const defaultSelectedFilters = useMemo(() => {
        return {
            statuses: ["PAID", "PAYMENT_PROCESSING", "PENDING", "OVERDUE", "VOID", "DRAFT"],
        }
    }, [showTableActions]);
    const cachedSelectedFilters = useMemo(() => {
        if (props.disableInitialFilters) {
            return null;
        }
        const cachedString = localStorage.getItem(company.id + "_filter_cache_invoices");
        if (cachedString && showTableActions) {
            const cachedFilters = JSON.parse(cachedString);
            if (_.isNil(cachedFilters.statuses)) {  // included for backwards compatability, remove after a few weeks
                cachedFilters.statuses = ["PAID", "PAYMENT_PROCESSING", "PENDING", "OVERDUE", "VOID", "DRAFT"];
            }
            if (!_.isNil(cachedFilters.status)) { // included for backwards compatability, remove after a few weeks
                delete cachedFilters.status;
            }
            return cachedFilters;
        } else {
            return null;
        }
    }, [showTableActions])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    const hasReadPermissions = hasAccess("invoices", userInfo, "read");

    useEffect(() => {
        if (!_.isNil(props.showBulkSelection)) {
            setShowBulkSelection(props.showBulkSelection);
        }
    }, [props.showBulkSelection])

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    useEffect(() => {
        setCheckedItems(_.filter(invoices, (i) => _.includes(checkedItemIds, i.uuid)))
    }, [checkedItemIds, invoices])

    useEffect(() => {
        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatically charge" },
            { value: false, label: "Email invoice" },
        ]

        const statusOptions = [
            { value: "PAID", label: "Paid" },
            { value: "PAYMENT_PROCESSING", label: "Payment Processing" },
            { value: "PENDING", label: "Pending" },
            { value: "OVERDUE", label: "Overdue" },
            { value: "VOID", label: "Void" },
            { value: "DRAFT", label: "Draft"},
        ]

        const currencyOptions = getCurrencyOptions();
        currencyOptions.unshift({ value: null, label: "All" })

        setFilters([
            { title: "Status", type: "multi-select", name: "statuses", options: statusOptions},
            { title: "Currency", type: "select", name: "currency", options: currencyOptions },
            { title: "Payment Method", type: "select", name: "auto_charges", options: paymentMechanismOptions },
            { title: "Invoice Date", type: "date", name: "invoice_date" },
            { title: "Total", type: "amount", name: "total" },
            { title: "Due", type: "amount", name: "due" }
        ])
    }, [])

    const getQueryParams = () => {
        return {
            ...props.extraQueryFilters,
            ...selectedFilters
        }
    }

    const onSearch = (restart = true) => {
        const limit = props.limit || 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                ...props.extraQueryFilters,
                ...selectedFilters
            },
            include_meta: restart
        }
        serverPost(getApiUrl("/invoices/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setInvoices(processResults(results));
                    setMeta(res.meta);
                } else {
                    setInvoices(_.concat(invoices, processResults(results)));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        const importedInvoices = _.filter(invoices, (i) =>  !_.isNil(i.imported_from))
        setShowStatusImageColumn(!_.isEmpty(importedInvoices));
    }, [invoices])

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters, props.extraQueryFilters]);

    const processResults = (results) => {
        _.each(results, (r) => {
            if (r.status === "PENDING") {
                r.isOverdue = moment(r.due_date).isBefore(moment());
            }
        })
        return results;
    }

    const renderStatusImage = (invoice) => {
        let imageUrl = null
        if (invoice.imported_from === "HUBSPOT") {
            imageUrl = "/icons/hubspot.png"
        } else if (invoice.imported_from === "STRIPE") {
            imageUrl = "/icons/stripe.png"
        }

        const providerName = getNameForProviderType(invoice.imported_from);

        if (imageUrl) {
            return <BaseOverlayTrigger content={`Invoice has been imported from ${providerName}`}>
                <div className="status-image">
                    <img src={imageUrl} />
                </div>
            </BaseOverlayTrigger>
        }
    }

    const onParamsChange = (params) => {
        setSelectedFilters(params);
        localStorage.setItem(company.id + "_filter_cache_invoices", JSON.stringify(params));
    }

    function getRowId(b) {
        return String(b.uuid);
    }

    const updateChecked = (event) => {
        setSelectedCriteria(null);
        if (event.target.value === "checkall") {
            let newCheckedItems = [];
            if (event.target.checked) {
                newCheckedItems = _.map(invoices, (b, i) => getRowId(b));
                newCheckedItems.push("checkall");
            }
            setCheckedItemIds(newCheckedItems);
        } else {
            const newCheckedItems = [...checkedItemIds];
            if (event.target.checked) {
                newCheckedItems.push(event.target.value);
            } else {
                let index = newCheckedItems.indexOf(event.target.value);
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
                index = newCheckedItems.indexOf("checkall");
                if (index > -1) {
                    newCheckedItems.splice(index, 1);
                }
            }
            setCheckedItemIds(newCheckedItems);
        }
    }

    const onSelectCriteria = () => {
        setSelectedCriteria(getQueryParams())
    }

    const onClick = (event, row) => {
        if (event.target.type === "checkbox" || (event.target.control && event.target.control.type === "checkbox")) {
            // Ignore so the checkbox actions can work.
        } else {
            navigate(getCompanySpecificUrl(`/invoice/${row.uuid}`))
        }
    }

    if (!hasReadPermissions) {
        return null;
    }

    const columns = [
        showBulkSelection && "checkbox",
        includeCustomer && "customer",
        "number",
        "amount",
        "invoice_date",
        "status",
        "due_date",
        showStatusImageColumn && "status_image"
    ];

    const renderColumnHeader = (key, j) => {
        if (!key) {
            return;
        }
        if (key === "checkbox") {
            return <MapleTable.TH key={j} className="!pr-0 w-px whitespace-nowrap">
                <div className="checkbox check-success inline">
                    <input type="checkbox" className="" value="checkall" id="checkall" name="delete[]" checked={checkedItemIds.includes("checkall")} onChange={ (event) => updateChecked(event) }/>
                    <label htmlFor="checkall" className="dont-click"/>
                </div>
            </MapleTable.TH>
        } else if (key === "customer") {
            return <MapleTable.TH key={j} className="">Customer</MapleTable.TH>
        } else if (key === "number") {
            return <MapleTable.TH key={j} className="text-nowrap">Number</MapleTable.TH>
        } else if (key === "amount") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="totalAmountAsc" sortKeyDown="totalAmountDesc"
                currentSort={sort} innerClassName="justify-end" className="d-none d-md-table-cell">
                Amount
            </SortableTableHeader>
        } else if (key === "invoice_date") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="invoiceDateAsc" sortKeyDown="invoiceDateDesc"
                currentSort={sort} className="d-none d-md-table-cell">
                Invoice Date
            </SortableTableHeader>
        } else if (key === "status") {
            return <MapleTable.TH key={j}>Status</MapleTable.TH>
        } else if (key === "due_date") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="dueDateAsc" sortKeyDown="dueDateDesc"
                currentSort={sort} className="d-none d-md-table-cell">
                Due Date
            </SortableTableHeader>
        } else if (key === "status_image") {
            return <MapleTable.TH key={j} className="d-none d-md-table-cell"></MapleTable.TH>
        }
    }

    const renderColumn = (key, row, j) => {
        if (!key) {
            return;
        }
        if (key === "checkbox") {
            return <td key={j} className="!pr-0">
                <div className="checkbox check-success inline">
                    <input type="checkbox" className="" value={getRowId(row)} id={getRowId(row)} name="delete[]"  checked={checkedItemIds.includes(getRowId(row))} onChange={ (event) => updateChecked(event) }/>
                    <label htmlFor={getRowId(row)}/>
                </div>
            </td>
        } else if (key === "customer") {
            return <td key={j} className=""><Columns.CustomerName customer={row.customer}/></td>
        } else if (key === "number") {
            return <td key={j}>
                { row.status === "DRAFT" ? <span className="text-gray-500">Draft</span> : row.number }
                <span className="d-block d-md-none text-gray-500">{ currencyFormatFromPrice(row.total) }</span>
            </td>
        } else if (key === "amount") {
            return <td key={j} className="text-end d-none d-md-table-cell">{ currencyFormatFromPrice(row.total) }</td>
        } else if (key === "invoice_date") {
            return <td key={j} className="d-none d-md-table-cell">{ moment(row.invoice_date).format("MMM D, YYYY h:mm:ssa") }</td>
        } else if (key === "status") {
            return <td key={j}>
                { renderStatusLabel(row, getCompanySpecificUrl) }
            </td>
        } else if (key === "due_date") {
            return <td key={j} className="d-none d-md-table-cell">
                <div className="flex flex-col">
                <span>
                    { moment(row.due_date).format("MMM D, YYYY h:mm:ssa") }
                </span>
                {
                    row.isOverdue &&
                    <>
                        <span className="danger-color caption">
                            {
                                moment().diff(moment(row.due_date), "days") > 0 ?
                                    <span>Overdue by { moment().diff(moment(row.due_date), "days") } days</span>
                                    : <span>Overdue</span>
                            }
                        </span>
                    </>
                }
                </div>
            </td>
        } else if (key === "status_image") {
            return <td key={j} className="d-none d-md-table-cell !pl-0">{ renderStatusImage(row) }</td>
        }
    }

    const exportOptions = [
        { id: "invoice", label: "Export Invoices" },
        { id: "invoice_line_item", label: "Export Invoice Line Items" },
    ];

    const onExportSelected = (type) => {
        if (type === "invoice") {
            setShowExportInvoicesModal(true);
        } else if (type === "invoice_line_item") {
            setShowExportInvoiceLineItemsModal(true);
        }
    }

    return (
        <InfiniteScroll
            dataLength={invoices.length}
            next={() => onSearch(false)}
            hasMore={hasMore && !props.hideMore}
            scrollableTarget="content-wrapper"
        >
            <Section title={t('common.invoices')} loading={loading} actions={props.actions || []}>
                {
                    props.metricsSummary &&
                    <TopBarSummary className="mt-1" entries={props.metricsSummary}/>
                }
                {
                    <MapleTable className={ props.metricsSummary ? "mt-4": "" }>
                        {
                            showTableActions &&
                                <MapleTableHeaderWithActions
                                    showSearch={true}
                                    searchPlaceholder="Search Invoices"
                                    showFilters={true}
                                    filters={filters}
                                    meta={meta}
                                    defaultSelectedFilters={defaultSelectedFilters}
                                    cachedSelectedFilters={cachedSelectedFilters}
                                    onParamsChange={onParamsChange}
                                >
                                    {
                                        !_.isEmpty(checkedItemIds) &&
                                            <div className="flex flex-row items-center gap-2">
                                                <div className="w-px h-[30px] bg-slate-300"/>
                                                <div className="cursor-pointer px-2.5 py-1.5 rounded-md bg-white border-1 border-slate-300" onClick={() => setShowBulkEmailModal(true)}>
                                                    <span className="text-slate-900">Email</span>
                                                </div>
                                            </div>
                                    }
                                    <DropdownMenu className="p-2" items={exportOptions} onClick={onExportSelected} hideCaret>
                                        <div className="flex flex-row gap-1 items-center">
                                            <BaseOverlayTrigger content={"Export"}>
                                                <ArrowTopRightOnSquareIcon className="h-5 w-5"/>
                                            </BaseOverlayTrigger>
                                        </div>
                                    </DropdownMenu>
                                </MapleTableHeaderWithActions>
                        }
                        {
                            showBulkSelection && hasMore && checkedItems.length > 0 && _.includes(checkedItemIds, "checkall") &&
                                <div className="mt-1 text-gray-500 pl-1">
                                {
                                    _.includes(checkedItemIds, "checkall") && (
                                        selectedCriteria !== null ?
                                            <span>All { meta.total } invoices matching the filters selected.</span>
                                        : <>
                                                <span>All { checkedItems.length } shown invoices selected. </span>
                                                <Link onClick={onSelectCriteria}>Select all { meta.total } invoices matching your criteria.</Link>
                                        </>
                                    )
                                }
                                </div>
                        }
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    { _.map(columns, (c, j) => renderColumnHeader(c, j)) }
                                    <td className="!pl-0"></td>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(invoices, (row, i) =>
                                    <tr key={i} className="cursor-pointer" onClick={(event) => onClick(event, row)}>
                                        { _.map(columns, (c, j) => renderColumn(c, row, j)) }
                                        <td className="!pl-0 w-px whitespace-nowrap">
                                            {
                                                row.billed_customer_id != row.customer_id &&
                                                <BaseOverlayTrigger content={`Invoice billed to ${row.billed_customer.name}`}>
                                                    <BuildingOfficeIcon className={"h-5 w-5"} />
                                                </BaseOverlayTrigger>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                hasMore && !props.hideMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                            }
                            {
                                hasMore && props.hideMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <Link href={getCompanySpecificUrl(`/invoices?customer_id=${props.customer && props.customer.id}`)}>View all</Link>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                }
            </Section>
            <BulkInvoiceEmailModal
                show={showBulkEmailModal}
                onClose={setShowBulkEmailModal}
                invoices={checkedItems}
                criteria={getQueryParams()}
                numInvoices={!_.isNil(selectedCriteria) ? meta.total: checkedItems.length}
                useCriteria={!_.isNil(selectedCriteria)}
            />
            <BaseCSVExportModal
                show={showExportInvoicesModal}
                onClose={setShowExportInvoicesModal}
                useQuery={_.isEmpty(checkedItemIds) || !_.isNil(selectedCriteria)}
                query={getQueryParams()}
                sort={sort}
                itemIDs={checkedItemIds}
                title={"Invoices CSV Export"}
                fields={getInvoiceExportFields()}
                filenamePrefix="Invoices-CSV"
                cacheKey={company.id + "_csv_invoices"}
                exportUrl={'/invoices/export'} />
            <BaseCSVExportModal
                show={showExportInvoiceLineItemsModal}
                onClose={setShowExportInvoiceLineItemsModal}
                useQuery={_.isEmpty(checkedItemIds) || !_.isNil(selectedCriteria)}
                query={getQueryParams()}
                sort={sort}
                itemIDs={checkedItemIds}
                title={"Invoice Line Items CSV Export"}
                fields={getInvoiceLineItemExportFields()}
                filenamePrefix="Invoices-Line-Items-CSV"
                cacheKey={company.id + "_csv_invoice_line_items"}
                exportUrl={'/invoice_line_items/export'} />
        </InfiniteScroll>
    );
})

export default InvoiceListSection;
