import '../App.scss';
import React, {useContext, useState} from 'react';
import { BaseFormContext } from './BaseForm';
import { Button, Spinner } from 'react-bootstrap';
import classnames from 'classnames';
const _ = require('lodash');

function SpinnerButton(props) {
    const pprops = {...props};
    delete pprops['onClick'];
    const [isLoading, setIsLoading] = useState(false);

    const onClick = async () => {
        setIsLoading(true);
        if (props.onClick) {
            await props.onClick();
        }
        setIsLoading(false);
    }

    return (
        <div className={classnames("flex items-center gap-2", props.className)}>
            <Button type="submit" {...pprops} onClick={onClick} style={props.color && { background: props.color }}
                    className={classnames("whitespace-nowrap", isLoading ? "loading": "")}
            >
                <div className="d-flex flex-column align-items-center">
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                >&nbsp;</Spinner>
                <span className="submit-button-content">
                    {props.children}
                </span>
                </div>
            </Button>
        </div>
    );
}

export default SpinnerButton;
