import '../../App.scss';
import '../../css/modals.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {serverFetch} from "../../helpers/server";
import {BaseContext, getApprovalRulePropertyKeys, getApprovalRulePropertyValues} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditApprovalRuleModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [teamMembers, setTeamMembers] = useState([])
    const [targetType, setTargetType] = useState(null);

    useEffect(() => {
        setTargetType(props.targetType);
    }, [props.targetType]);

    useEffect(() => {
        serverFetch(getApiUrl("/users")).then((res) => {
            setTeamMembers(_.filter(res, (r) => !r.pending));
        });
    }, [])

    useEffect(() => {
        if (!props.show) {
            setInitialFormFields({});
        } else {
            setInitialFormFields(props.itemToEdit);
        }
    }, [props.show, props.itemToEdit])

    const cleanupFields = (fields) => {
        fields.target_type = targetType;
        fields.approver_ids = [fields.approvers];
        delete fields.approvers;
        return fields;
    }

    const teamMemberOptions = _.map(teamMembers, (member) => {
        return {
            value: member.id,
            label: member.user.name || member.user.email
        }
    })

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemToEdit={props.itemToEdit && _.cloneDeep({
                ...props.itemToEdit,
                approvers: props.itemToEdit.approvers && props.itemToEdit.approvers[0].id
            })}
            size={"lg"}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            initialFormFields={initialFormFields}
            itemLabel={"Approval Rule"}
            itemBaseUrl="/approval_rules"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label="Rule Name" type="text"/>
                <BaseForm.Input
                    colSpan="12" name="rule" label="Rule" type="rule" isColored={true}
                    propertyValues={getApprovalRulePropertyValues()}
                    propertyFields={getApprovalRulePropertyKeys()}
                />
                <BaseForm.Input
                    colSpan="12" name="approvers" label="Approver" type="select" options={teamMemberOptions} required
                    showSearch={teamMemberOptions.length > 5}
                />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditApprovalRuleModal;
