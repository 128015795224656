import '../../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import { useParams } from 'react-router-dom';
import { serverFetch } from '../../../helpers/server';
import {
    BaseContext, CustomerContext,
} from '../../../helpers/common';
import { useTranslation } from 'react-i18next';
import Loader from '../../../components/Loader';
import moment from 'moment';
import ErrorComponent from "../../../components/ErrorComponent";
import SubscriptionUsage from "../../../components/SubscriptionUsage";
import Section from "../../../components/Section";
const _ = require('lodash');

function SubscriptionUsageEmbed(props) {
    const { t } = useTranslation('common');
    const { subscriptionId } = useParams();
    const { getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [subscription, setSubscription] = useState({});

    useEffect(() => {
        setPageTitle(`Subscription Usage`);
    }, []);

    const fetchData = (skipCache=false) => {
        const options = {
            skipCache: skipCache,
            accessToken: accessToken
        }
        serverFetch(getApiUrl(`/subscriptions/${subscriptionId}`), options).then((res) => {
            if (res) {
                res.isCancelled = moment().isAfter(res.end_date);
                setSubscription(res);
            }
            setLoadingSubscription(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="p-3 bg-white">
            <Loader loading={loadingSubscription}>
            {
                _.isEmpty(subscription) ?
                    <ErrorComponent
                        title={"Subscription Not Found"}
                        description={"Oops, we can't find the subscription you are looking for."}
                        hideSupport
                    />
                : <>
                    <Section title={"Usage"}>
                        <SubscriptionUsage subscriptions={[subscription]}/>
                    </Section>
                </>
            }
            </Loader>
        </div>
    );
}

export default SubscriptionUsageEmbed;
