import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import {serverFetch, serverPatch} from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomerInput from "../CustomerInput";
const _ = require("lodash");

function ChangeParentModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const onSubmit = (data, errorHandler) => {
        data.parent_customer_id = data.parent_customer.id;
        serverPatch(getApiUrl(`/customers/${props.customer.id}`), data).then((res) => {
            if (res) {
                if (props.onClose) {
                    props.onClose(true)
                }
            }
        });
    }

    return (
        <BaseSideModal {...props} >
            <BaseSideModal.Form onSubmit={onSubmit} initialFormFields={props.customer}>
                <BaseSideModal.Header title={"Change Parent"}/>
                <BaseSideModal.Body>
                    <Row>
                        <CustomerInput colSpan="12" name="parent_customer" label="Parent" required />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <SubmitButton>Update</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default ChangeParentModal;
