import '../App.scss';
import React, {useState, useEffect, useContext} from 'react';
import { Table } from 'react-bootstrap';
import {
    BaseContext,
    currencyFormatFromPrice,
    UserContext
} from '../helpers/common';
import classnames from 'classnames';
import Link from "./Link";
import {getCreditNoteReasonDescription} from "../helpers/invoices";
const _ = require('lodash');

function PricingBreakdownSummary(props) {
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const { isSuperUser, isLoggedIn } = useContext(UserContext);
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [oneTimeDetails, setOneTimeDetails] = useState([]);
    const [creditDetails, setCreditDetails] = useState([]);
    const [discountDetails, setDiscountDetails] = useState([]);
    const [taxDetails, setTaxDetails] = useState([]);

    useEffect(() => {
        setSubscriptionDetails(props.subscriptionDetails);
    }, [props.subscriptionDetails])

    useEffect(() => {
        setOneTimeDetails(props.oneTimeDetails);
    }, [props.oneTimeDetails])

    useEffect(() => {
        setCreditDetails(props.creditDetails);
    }, [props.creditDetails])

    useEffect(() => {
        setDiscountDetails(props.discountDetails);
    }, [props.discountDetails])

    useEffect(() => {
        if (props.taxDetails) {
            const taxGroups = _.groupBy(props.taxDetails.line_items, "tax.name");
            const processedLineItems = _.map(taxGroups, (items, tg) => {
                return {
                    tax: items[0].tax,
                    aggregate_price: {
                        value_in_cents: _.sumBy(items, (item) => item.aggregate_price.value_in_cents),
                        currency: props.taxDetails.aggregate_price.currency
                    }
                }
            })
            setTaxDetails({
                ...props.taxDetails,
                aggregate_price: props.taxDetails.aggregate_price,
                line_items: processedLineItems
            });
        } else {
            setTaxDetails([]);
        }
    }, [props.taxDetails])

    const hasDiscounts = () => {
        return hasCustomerDiscounts() || hasSubscriptionPercentDiscounts() || hasSubscriptionAmountDiscounts();
    }

    const hasCustomerDiscounts = () => {
        if (_.isEmpty(discountDetails)) {
            return false;
        }
        const aggregate = discountDetails.aggregate_price.value_in_cents;
        if (aggregate > 0) {
            return true;
        }
        return false;
    }

    const hasSubscriptionAmountDiscounts = () => {
        if (_.isEmpty(subscriptionDetails)) {
            return false;
        }
        if (!_.isNil(subscriptionDetails.discount_amount) && subscriptionDetails.discount_amount.aggregate_price.value_in_cents > 0) {
            return true;
        }
        return false;
    }

    const hasSubscriptionPercentDiscounts = () => {
        if (_.isEmpty(subscriptionDetails)) {
            return false;
        }
        if (!_.isNil(subscriptionDetails.discount_percent) && subscriptionDetails.discount_percent.aggregate_price.value_in_cents > 0) {
            return true;
        }
        return false;
    }

    const hasCredits = () => {
        if (_.isEmpty(creditDetails)) {
            return false;
        }
        const aggregate = creditDetails.aggregate_price.value_in_cents;
        if (aggregate !== 0) {
            return true;
        }
        return false;
    }

    const hasTaxes = () => {
        if (_.isEmpty(taxDetails)) {
            return false;
        }
        const aggregate = taxDetails.aggregate_price.value_in_cents;
        if (aggregate > 0 || (isLoggedIn && taxDetails.tax_calculation_state === "SUCCESS")) {
            return true;
        }
        return false;
    }

    const hasTaxError = () => {
        if (_.isEmpty(taxDetails)) {
            return false;
        }
        const state = taxDetails.tax_calculation_state;
        return state === "ERROR";
    }

    const renderInnerLineItemList = (items, getFirst, getSecond) => {
        return (
            <Table borderless className="thin">
                <tbody>
                {
                    _.map(items, (lineItem, i) =>
                        renderInnerLineItem(i, getFirst(lineItem), getSecond(lineItem))
                    )
                }
                </tbody>
            </Table>
        )
    }

    const renderInnerLineItem = (key, first, second) => {
        return (
            <tr key={key} className="thin">
                <td colSpan="4" className="text-end">{ first }</td>
                <td className="no-stretch text-end">{ second }</td>
            </tr>
        )
    }

    const renderLaterSection = (title, details, getFirst, getSecond, negate) => {
        return (
            <>
                {
                    _.map(details.line_items, (lineItem, i) =>
                        renderInnerLineItem(i, getFirst(lineItem), getSecond(lineItem))
                    )
                }
                <tr className="hide">
                    <td colSpan="4" className="text-end body2">{ title }</td>
                    <td className="no-stretch text-end body2">{ currencyFormatFromPrice(details.aggregate_price, negate) }</td>
                </tr>
            </>
        )
    }

    const renderDiscountDescription = (discount) => {
        let description = "";
        if (discount.type === "DISCOUNT_AMOUNT") {
            description = currencyFormatFromPrice(discount.amount);
        } else if (discount.type === "DISCOUNT_PERCENT") {
            description = `${discount.percent * 100 }%`;
        }
        return `Discount (${ discount.name }, ${description})`;
    }

    const renderCreditNotes = (notes) => {
        return (
            <>
                {
                    _.map(notes, (note, i) =>
                        <tr key={i} className={classnames(props.simplified ? "": "thin")}>
                            <td colSpan="4" className="text-end body2">
                                <span>Credit Note</span>
                                {
                                    props.isAdmin ?
                                        <>
                                            <span> : </span>
                                            <Link href={getCompanySpecificUrl(`/invoice/${note.invoice_uuid}/credit_note/${note.id}`)}>{ note.id }</Link>
                                            <span>{ note.internal_reason ? ` (${getCreditNoteReasonDescription(note.internal_reason)})`: "" }</span>
                                        </>
                                    : <>
                                        <span> </span>
                                        <Link href={getCompanySpecificUrl(`/credit_note/view?id=${note.id}`)}>(View Note)</Link>
                                    </>
                                }
                                </td>
                            <td className="no-stretch text-end body2">{ currencyFormatFromPrice(note.total, true) }</td>
                        </tr>
                    )
                }
            </>
        )
    }

    const onChangeIncludeTaxes = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (props.onChangeIncludeTaxes) {
            props.onChangeIncludeTaxes(true);
        }
    }

    const subtotalBeforeDiscount = (_.isEmpty(oneTimeDetails) && _.isEmpty(subscriptionDetails)) ? {}: {
        value_in_cents: oneTimeDetails.one_time_price.aggregate_price.value_in_cents + subscriptionDetails.subscription_price.aggregate_price.value_in_cents,
        currency: props.total.currency
    };

    return (
        <>
            <Table borderless className="no-background">
            <tbody>
                {
                    hasDiscounts() && false &&
                        <tr className={classnames(props.simplified ? "spacey": "thin")}>
                            <td colSpan="4" className="text-end"></td>
                            <td className="no-stretch text-end">
                                <span className="body2">
                                    { currencyFormatFromPrice(subtotalBeforeDiscount) }
                                </span>
                            </td>
                        </tr>
                }
                {
                    hasSubscriptionPercentDiscounts() &&
                        renderLaterSection(
                            "Total Discounts",
                            subscriptionDetails.discount_percent,
                            (item) => renderDiscountDescription(item.discount),
                            (item) => currencyFormatFromPrice(item.aggregate_price, true),
                            true
                        )
                }
                {
                    hasSubscriptionAmountDiscounts() &&
                        renderLaterSection(
                            "Total Discounts",
                            subscriptionDetails.discount_amount,
                            (item) => renderDiscountDescription(item.discount),
                            (item) => currencyFormatFromPrice(item.aggregate_price, true),
                            true
                        )
                }
                {
                    hasCustomerDiscounts() &&
                        renderLaterSection(
                            "Total Discounts",
                            discountDetails,
                            (item) => renderDiscountDescription(item.discount),
                            (item) => currencyFormatFromPrice(item.aggregate_price, true),
                            true
                        )
                }
                {
                    !props.isPreview &&
                        <>
                            <tr className={props.simplified ? "spacey": "thin"}>
                                <td colSpan="4" className="text-end"><span className="body2">Subtotal</span></td>
                                <td className="no-stretch text-end"><span className="body2">{ currencyFormatFromPrice(props.subTotal) }</span></td>
                            </tr>
                            {
                                props.showTaxAsEstimate &&
                                    <tr className={props.simplified ? "": "thin"}>
                                        <td colSpan="4" className="text-end body2"><span className="body2">Tax</span></td>
                                        <td className="no-stretch text-end caption gray3">
                                            <span>*Calculated when finalized.</span><br/>
                                            <span><a href="" onClick={onChangeIncludeTaxes}>Calculate</a></span>
                                        </td>
                                    </tr>
                            }
                        </>
                }
                {
                    !props.showTaxAsEstimate && hasTaxes() &&
                        renderLaterSection(
                            "Total Tax",
                            taxDetails,
                            (item) => `${ item.tax.name } (${ (item.tax.rate *100).toFixed(2) }%)`,
                            (item) => {
                                if (isSuperUser) {
                                    return <Link onClick={props.showTaxDetailsModal}>{currencyFormatFromPrice(item.aggregate_price)}</Link>
                                } else {
                                    return <span>{currencyFormatFromPrice(item.aggregate_price)}</span>
                                }
                            },
                            false
                        )
                }
                {
                    !props.showTaxAsEstimate && hasTaxError() && isLoggedIn && !props.isPreview &&
                        <tr className="thin">
                            <td colSpan="4" className="text-end">
                                <span className="font-semibold">Tax</span>
                            </td>
                            <td className="text-end">
                                <Link className="" onClick={props.showTaxDetailsModal}>error</Link>
                            </td>
                        </tr>
                }
                {
                    hasCredits() &&
                        renderLaterSection(
                            "Total Credits",
                            creditDetails,
                            (item) => `Credit (${ item.credit.name })`,
                            (item) => currencyFormatFromPrice(item.aggregate_price),
                            true
                        )
                }
                <tr className={props.simplified ? "": "thin"}>
                    <td colSpan="4" className="text-end body2"><strong>Total</strong></td>
                    <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.total) }</strong></td>
                </tr>
                { !props.isPreview && props.prePaymentCreditNotes && renderCreditNotes(props.prePaymentCreditNotes) }
                {
                    !props.isPreview && props.paymentProcessing && props.paymentProcessing.value_in_cents > 0 &&
                    <tr className={props.simplified ? "": "thin"}>
                        <td colSpan="4" className="text-end body2"><strong>Total Payments (Pending)</strong></td>
                        <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.paymentProcessing) }</strong></td>
                    </tr>
                }
                {
                    !props.isPreview && props.paid.value_in_cents > 0 &&
                        <tr className={props.simplified ? "": "thin"}>
                            <td colSpan="4" className="text-end body2"><strong>Total Payments</strong></td>
                            <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.paid) }</strong></td>
                        </tr>
                }
                {
                    !props.isPreview && !_.isNil(props.refunds) && !_.isEmpty(props.refunds) &&
                        <>
                            {
                                _.map(props.refunds, (refund, r) =>
                                    <tr key={r} className={props.simplified ? "": "thin"}>
                                        <td colSpan="4" className="text-end body2"><strong>Refund</strong></td>
                                        <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(refund.total_amount) }</strong></td>
                                    </tr>
                                )
                            }
                        </>
                }
                { !props.isPreview && props.postPaymentCreditNotes && renderCreditNotes(props.postPaymentCreditNotes) }
                {
                    !props.isPreview && props.due &&
                        <tr className={props.simplified ? "": "thin"}>
                            <td colSpan="4" className="text-end body2"><strong>Amount Due</strong></td>
                            <td className="no-stretch text-end body2"><strong>{ currencyFormatFromPrice(props.due) }</strong></td>
                        </tr>
                }
            </tbody>
            </Table>
        </>
    );
}

export default PricingBreakdownSummary;
