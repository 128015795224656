import '../../App.scss';
import '../../css/modals.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    BaseContext,
    getCustomTaxRuleCustomerKeys,
    getCustomTaxRuleCustomerValues
} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditCustomTaxRuleModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [referenceType, setReferenceType] = useState("CUSTOMER");
    const [productOptions, setProductOptions] = useState([]);

    useEffect(() => {
        if (!props.show) {
            setInitialFormFields({});
        } else {
            if (!_.isNil(props.itemToEdit)) {
                setReferenceType(props.itemToEdit.reference_type);
                let productIdsString = "";
                if (props.itemToEdit.reference_type === "PRODUCT") {
                    productIdsString = props.itemToEdit.rule.comparator.right_item.value;
                }
                // setPreselectedProductIds(productIdsString.split(","));
                setInitialFormFields({
                    ...props.itemToEdit,
                    tax_name: props.itemToEdit.taxes[0].tax_name,
                    tax_rate: props.itemToEdit.taxes[0].tax_rate*100,
                    product_ids: productIdsString.split("|")
                });
            } else {
                setInitialFormFields(null);
            }
        }
    }, [props.show, props.itemToEdit])

    useEffect(() => {
        if (!_.isNil(props.products)) {
            setProductOptions(_.map(props.products, p => {
                return {
                    value: p.id,
                    label: p.name
                }
            }))
        } else {
            setProductOptions([]);
        }
    }, [props.products])

    const cleanupFields = (fields) => {
        fields.taxes = [{
            tax_name: fields.tax_name,
            tax_rate: parseFloat(fields.tax_rate)/100,
        }]
        if (referenceType === "PRODUCT") {
            fields.rule = {
                "comparator": {
                    "type": "IN",
                    "left_item": {
                        "property": "id",
                        "item_type": "PROPERTY",
                        "comparator": {
                            "type": ""
                        }
                    },
                    "right_item": {
                        "value": fields.product_ids.join("|"),
                        "item_type": "VALUE",
                        "comparator": {
                            "type": ""
                        }
                    }
                }
            }
        }
        return fields;
    }

    const onFieldChange = (name, value) => {
        if (name === "reference_type") {
            setReferenceType(value);
        }
    }

    const getOptionLabel = (option) => {
        return option.label;
    }

    const getOptionValue = (option) => {
        return option.value;
    }

    const referenceTypeOptions = [{
        value: "CUSTOMER",
        label: "Customer Based"
    }, {
        value: "PRODUCT",
        label: "Product Based"
    }]

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemToEdit={initialFormFields}
            size={"lg"}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            initialFormFields={initialFormFields}
            onFieldChange={onFieldChange}
            itemLabel={"Custom Tax Rule"}
            itemBaseUrl="/approval_rules"
            addItem={props.onAdded}
            updateItem={props.onUpdated}
        >
            <Row>
                <BaseForm.Input
                    colSpan="12" name="reference_type" label="Reference" type="select" options={referenceTypeOptions}
                    showSearch={false}
                />
                {
                    referenceType === "CUSTOMER" &&
                        <BaseForm.Input
                            colSpan="12" name="rule" label="Rule" type="rule" isColored={true}
                            propertyValues={getCustomTaxRuleCustomerValues()}
                            propertyFields={getCustomTaxRuleCustomerKeys()}
                            required
                        />
                }
                {
                    referenceType === "PRODUCT" &&
                        <BaseForm.Input
                            colSpan="12" name="product_ids" label="Products" type="selector" formClassName="inline"
                            isMulti={true} formatCreateLabel={(inputValue) => `Select tag - ${inputValue}`}
                            placeholder={"Select products..."}
                            getOptionLabel={getOptionLabel}
                            getOptionValue={getOptionValue}
                            defaultOptions={productOptions}
                            required
                        />
                }
                <p className="mb-3">If an invoice line item matches the rule specified above, the tax rate specified below will be applied.</p>
                <BaseForm.Input colSpan="6" name="tax_name" label="Tax Name" type="text" required/>
                <BaseForm.Input colSpan="6" name="tax_rate" label="Tax Rate" type="number" step={0.01} min={0} max={100} required/>
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditCustomTaxRuleModal;
