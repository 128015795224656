import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {BaseContext, getPaymentLinkUrl} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import ContentBox from '../../components/ContentBox';
import { useTranslation } from 'react-i18next';
import BaseProposalCreateOrUpdateForm from "../../components/BaseProposalCreateOrUpdateForm";
import CopyableComponent from "../../components/CopyableComponent";
import {useSearchParams} from "react-router-dom";
import {serverFetch} from "../../helpers/server";
import variables from "../../App.scss";
const _ = require('lodash');

function CheckoutLinkCreateOrUpdate() {
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    let defaultCustomerId = searchParams.get('cid');
    const { getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [hasCreated, setHasCreated] = useState(false);
    const [createdPaymentLink, setCreatedPaymentLink] = useState(null);
    const [createdPaymentLinkUrl, setCreatedPaymentLinkUrl] = useState(null);
    const [defaultCustomer, setDefaultCustomer] = useState(null);

    const { setPageTitle } = useContext(BaseContext);

    useEffect(() => {
        setPageTitle(`Checkout Link - Create`);
    }, []);

    useEffect(() => {
        if (defaultCustomerId) {
            serverFetch(getApiUrl(`/customers/${defaultCustomerId}`), { skipCache: true }).then((res) => {
                setDefaultCustomer(res);
            });
        }
    }, [defaultCustomerId])

    const getUrl = (link) => {
        return getPaymentLinkUrl(link, getCompanySpecificUrl);
    }

    const onCreateCheckoutSession = async (paymentLink) => {
        if (paymentLink) {
            setHasCreated(true);
            setCreatedPaymentLink(paymentLink);
            const urlResult = await serverFetch(getApiUrl(`/proposals/${paymentLink.id}/payment_url`));
            if (urlResult) {
                setCreatedPaymentLinkUrl(urlResult.url);
            }
        }
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <ContentBox>
                    <ContentBox.Body>
                        {
                            hasCreated ?
                                <div className="flex flex-col items-center gap-3">
                                    <i className="fa fa-thin fa-shopping-cart fa-4x" style={{color: variables.primaryColor}}/>
                                    <h4 className="text-gray-900">Checkout Session Created</h4>
                                    <span>A checkout session link has been generated. Please feel free to share this link with the customer.</span>
                                    <CopyableComponent
                                        value={<a className="">Copy Checkout Link</a>}
                                        copyableValue={createdPaymentLinkUrl}
                                    />
                                </div>
                            : <BaseProposalCreateOrUpdateForm
                                type={"CHECKOUT_SESSION"}
                                customer={defaultCustomer}
                                onCreate={onCreateCheckoutSession}
                            />
                        }
                    </ContentBox.Body>
                </ContentBox>
            </ContentContainer>
        </BaseContainer>
    );
}

export default CheckoutLinkCreateOrUpdate;
