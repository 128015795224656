import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, { useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function InviteCompanyUserModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');

    const inviteTeamMember = async (data, errorHandler) => {
        await serverPost(getApiUrl('/invite'), data, {}, errorHandler).then(res => {
            if (res) {
                props.onClose(true);
                Notification.Success("Invitation Sent Successfully");
            }
        });
    }

    const roleOptions = [
        { value: "default_admin", label: "Full Admin" },
        { value: "default_sales_rw", label: "Sales Admin" },
    ]

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onSubmit={inviteTeamMember}>
                <BaseSideModal.Header title={t('team.invite_modal_title')}/>
                <BaseSideModal.Body>
                    <Row>
                        <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                        <BaseForm.Input colSpan="12" name="email" label={t('common.email')} type="text" transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} />
                        <BaseForm.Input colSpan="12" name="title" label={t('Title')} type="text" />
                        <BaseForm.Input colSpan="12" name="role" label={t('common.role')} type="select" options={roleOptions} showSearch={false} />
                    </Row>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">{t('team.invite')}</SubmitButton>
                        </Col>
                    </Row>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default InviteCompanyUserModal;
