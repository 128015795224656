import '../../App.scss';
import '../../css/modals.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    BaseContext,
    getCustomTaxRuleCustomerKeys,
    getCustomTaxRuleCustomerValues
} from "../../helpers/common";
const _ = require('lodash');

function AddOrEditCustomTaxIdentifiersRuleModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [initialFormFields, setInitialFormFields] = useState({});
    const [referenceType, setReferenceType] = useState("CUSTOMER");

    useEffect(() => {
        if (!props.show) {
            setInitialFormFields({});
        } else {
            if (!_.isNil(props.itemToEdit)) {
                setReferenceType(props.itemToEdit.reference_type);
                setInitialFormFields({
                    ...props.itemToEdit,
                    name: props.itemToEdit.identifiers[0].name,
                    value: props.itemToEdit.identifiers[0].value,
                });
            } else {
                setInitialFormFields(null);
            }
        }
    }, [props.show, props.itemToEdit])

    const cleanupFields = (fields) => {
        fields.reference_type = "CUSTOMER"
        fields.identifiers = [{
            name: fields.name,
            value: fields.value,
        }]
        return fields;
    }

    const onFieldChange = (name, value) => {
        if (name === "reference_type") {
            setReferenceType(value);
        }
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemToEdit={initialFormFields}
            size={"lg"}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            initialFormFields={initialFormFields}
            onFieldChange={onFieldChange}
            itemLabel={"Custom Tax Registration Numbers Rule"}
            addItem={props.onAdded}
            updateItem={props.onUpdated}
        >
            <Row>
                {
                    referenceType === "CUSTOMER" &&
                        <BaseForm.Input
                            colSpan="12" name="rule" label="Rule" type="rule" isColored={true}
                            propertyValues={getCustomTaxRuleCustomerValues()}
                            propertyFields={getCustomTaxRuleCustomerKeys()}
                            required
                        />
                }
                <p className="mb-3">If a customer matches the rule specified above, the tax registration numbers will be shown.</p>
                <BaseForm.Input colSpan="6" name="name" label="Registration Name" type="text" required/>
                <BaseForm.Input colSpan="6" name="value" label="Registration Value" type="text" required/>
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditCustomTaxIdentifiersRuleModal;
