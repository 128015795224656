import { handleCategoryChange } from './server';

export function storeUserInfo(userInfo) {
    if (userInfo) {
        localStorage.setItem("user_info", JSON.stringify(userInfo));
    } else {
        localStorage.removeItem("user_info");
    }
}

export function getUserInfo() {
    return JSON.parse(localStorage.getItem("user_info")) || {};
}

export function clearUserInfo() {
    console.log("Clearing user info");
    for (var key in localStorage){
        if (key.startsWith("user_") || key === "auth_token" || key === "spoof_auth_token") {
            localStorage.removeItem(key);
        }
    }
}

export function storeCompanyInfo(companyInfo) {
    if (companyInfo) {
        localStorage.setItem("company_info", JSON.stringify(companyInfo));
    } else {
        localStorage.removeItem("company_info");
    }
}

export function getCompanyInfo() {
    return JSON.parse(localStorage.getItem("company_info")) || {};
}

export function clearCompanyInfo() {
    localStorage.removeItem("company_info");
    localStorage.removeItem("facility");
    localStorage.removeItem("facilityId");
}

export function storageLogout() {
    console.log("Storage logout");
    localStorage.clear();
}

export function setCategory(categoryId) {
    let facilityId = localStorage.getItem("facilityId");
    if (categoryId === "all") {
        localStorage.removeItem(`${facilityId}:category`);
    } else {
        localStorage.setItem(`${facilityId}:category`, categoryId);
    }
    handleCategoryChange();
}

export function getCategory() {
    let facilityId = localStorage.getItem("facilityId");
    return localStorage.getItem(`${facilityId}:category`)
}
