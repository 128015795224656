import '../App.scss';
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import { getUrl, serverPost } from '../helpers/server';
import BaseForm from '../components/BaseForm';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import { Button, Row, Col, Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ChangePassword() {
    const { t } = useTranslation('common');
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const [succeed, setSucceed] = useState(false);
    const [error, setError] = useState(null);
    let code = searchParams.get('code');

    const onError = async (res) => {
        const errorMessage = await res.json();
        setSucceed(false);
        setError(errorMessage.error_message || errorMessage.error);
    }

    const onChangePassword = async (data) => {
        setSucceed(false);
        setError(null);
        const passwordData = {...data, code };
        const result = await serverPost(getUrl('/users/reset_password'), passwordData, {}, onError)
        if (result) {
            setSucceed(true)
            setTimeout(() => {
                navigate("/login");
            }, [2000])
        }
    }

    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                {
                    code && !succeed &&
                        <Col md={{span: 6, offset:3}}>
                            <h3>Change Password</h3>
                            <br/>
                            <p className="body1">Select a new password of at least 8 characters long.</p>
                            <BaseForm onSubmit={onChangePassword}>
                                <BaseForm.Input type="password" name="password" label={t('auth.new_password')} validations={{ required: true, minLength: 8 }} />
                                <Button type="submit">{t('auth.reset_password')}</Button>
                            </BaseForm>
                        </Col>
                }
                {
                    !code &&
                        <p>Change password link is not valid. Please click on the link from the email or try the forgot password page again</p>
                }
                {
                    succeed &&
                        <Col md={{span: 6, offset:3}}>
                            <span className="body1">Successfully reset your password. <a href="/login">Login</a> to continue</span>
                        </Col>
                }
                {
                    error &&
                        <Col md={{span: 6, offset:3}}>
                            <br/>
                            <Alert variant="danger">{ error }</Alert>
                        </Col>
                }
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default ChangePassword;
