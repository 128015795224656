import '../../App.scss';
import '../../css/modals.scss';
import React, {useState, forwardRef, useImperativeHandle} from 'react';
import BaseForm from '../BaseForm';
import { getCurrencyOptions } from '../../helpers/common';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomerInput from "../CustomerInput";
const _ = require('lodash');

const BaseAddDiscountModal = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        getDiscountData(data, errorHandler) {
            return getDiscountData(data, errorHandler);
        },
        onFieldChange(name, value) {
            onFieldChange(name, value);
        }
    }));
    const { t } = useTranslation('common');
    const [discountType, setDiscountType] = useState("DISCOUNT_AMOUNT");
    const [doesExpire, setDoesExpire] = useState(false)

    const getDiscountData = (data, errorHandler) => {
        if (props.requireCustomer) {
            data.customer_id = props.customer.id;
        }
        if (data.type === "DISCOUNT_AMOUNT") {
            data.amount = {
                value_in_cents: Math.round(parseFloat(data.amount_value) * 100),
                currency: data.amount_currency
            }
            data.amount_value = null;
            data.amount_currency = null;
        } else if (data.type === "DISCOUNT_PERCENT") {
            if (!_.isNil(data.cap_value)) {
                data.cap = {
                    value_in_cents: Math.round(parseFloat(data.cap_value) * 100),
                    currency: data.cap_currency
                }
            } else {
                data.cap = null;
            }
            data.percent = parseFloat(data.percent)/100;
            data.cap_value = null;
            data.cap_currency = null;
        }
        data.state = "ACTIVE";
        if (!data.does_expire) {
            data.expiration_date = null;
        }
        return data;
    }

    const typeOptions = [
        { value: "DISCOUNT_AMOUNT", label: "Amount" },
        { value: "DISCOUNT_PERCENT", label: "Percent" },
    ]

    const onFieldChange = (name, value) => {
        if (name === "type") {
            setDiscountType(value);
        } else if (name === "does_expire") {
            setDoesExpire(value);
        }
    }

    return (
        <>
            <Row>
                {
                    props.requireCustomer &&
                        <CustomerInput colSpan="12" defaultCustomer={props.customer} required />
                }
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="type" label={t('discounts.type')} type="select" options={typeOptions} showSearch={false} />
                {
                    discountType === "DISCOUNT_AMOUNT" &&
                        <>
                            <BaseForm.Input colSpan="6" name="amount_value" label={t('discounts.amount')} type="number" step="0.01" min="0" required />
                            <BaseForm.Input colSpan="6" name="amount_currency" label={t('common.currency')} type="select" options={getCurrencyOptions()} required />
                        </>
                }
                {
                    discountType === "DISCOUNT_PERCENT" &&
                        <>
                            <BaseForm.Input colSpan="12" name="percent" label={t('discounts.percent')} type="number" step="1" min="0" max="100" required />
                            <BaseForm.Input colSpan="6" name="cap_value" label={t('discounts.cap_amount') + " (optional)"} type="number" step="0.01" min="0" />
                            <BaseForm.Input colSpan="6" name="cap_currency" label={t('common.currency') + " (optional)"} type="select" options={getCurrencyOptions()} />
                        </>
                }
            </Row>
            <Row>
                <span className="body2">Expiration</span>
                <BaseForm.Input colSpan="8" name="does_expire" label={t('discounts.does_expire')} type="switch" />
                {
                    doesExpire &&
                        <>
                            <BaseForm.Input colSpan="8" name="expiration_date" label={t('discounts.expires_on')}
                                type="date" includeTime={true} minDate={moment()} required/>
                            <span className="caption">Date/Time is based on your local timezone</span>
                        </>
                }
            </Row>
        </>
    );
})

export default BaseAddDiscountModal;
