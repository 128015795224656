import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect } from 'react';
import {Table, Button} from 'react-bootstrap';
import DeleteButton from "../DeleteButton";
const _ = require('lodash');

function EditScheduleModal(props) {
    const [items, setItems] = useState([]);
    const [actions, setActions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const sitems = [...(props.defaultItems || [])];
        _.each(sitems, (item) => {
            item.days = item.interval_from_date/86400
        })
        setItems(props.defaultItems);
    }, [props.defaultItems]);

    useEffect(() => {
        const options = [
            _.includes(props.actions, "payment_retry") && { value: "payment_retry", label: "Retry payment, with email" },
            _.includes(props.actions, "notification") && { value: "notification", label: "Email customer" },
            _.includes(props.actions, "cancel_subscription") && { value: "cancel_subscription", label: "Cancel subscription" },
            _.includes(props.actions, "unpaid_subscription") && { value: "unpaid_subscription", label: "Mark subscription as unpaid" },
            _.includes(props.actions, "admin_notification") && { value: "admin_notification", label: "Notify account owner" },
        ];
        setActions(_.filter(options, op => op));
    }, [props.actions]);

    const actionOptions = [
        { value: "payment_retry", label: "Retry payment, with email" },
        { value: "notification", label: "Email customer" },
        { value: "cancel_subscription", label: "Cancel subscription" },
        { value: "unpaid_subscription", label: "Mark subscription as unpaid" },
        { value: "admin_notification", label: "Notify account owner"}
    ]

    const onMoveUp = (index) => {
        console.log("on move up " + index);
        setItems(prevItems => {
            const newItems = [...prevItems];
            const item = newItems[index];
            newItems[index] = newItems[index-1];
            newItems[index-1] = item;
            return newItems;
        })
        setError(null);
    }

    const onMoveDown = (index) => {
        console.log("on move down " + index);
        setItems(prevItems => {
            const newItems = [...prevItems];
            const item = newItems[index];
            newItems[index] = newItems[index+1];
            newItems[index+1] = item;
            return newItems;
        })
        setError(null);
    }

    const onAddNew = () => {
        setItems(prevItems => {
            if (prevItems && prevItems.length > 0) {
                const lastDay = prevItems[prevItems.length-1].days;
                return [...prevItems, { days: lastDay+1 }]
            } else {
                return [{ days: 1 }]
            }
        });
        setError(null);
    }

    const onDeleteRow = (index) => {
        setItems(prevItems => {
            const newItems = [...prevItems];
            newItems.splice(index, 1);
            return newItems;
        })
        setError(null);
    }

    const onFieldChange = (name, value) => {
        setError(null);
    }

    const onSubmit = (data) => {
        let processedData = [];
        let hasEnded = false;
        let hasEventsPastEnding = false;
        let lastNumber = -1000000;
        let wrongOrder = false;
        let referenceDate = props.isContractSchedule ? "proposal.finalized_at" : "invoice.due_at"

        _.each(_.values(data), (d, i) => {
            if (hasEnded) {
                hasEventsPastEnding = true;
            } else if (_.includes(["cancel_subscription", "unpaid_subscription"], d.action_type)) {
                hasEnded = true
            }

            if (parseInt(d.days) <= lastNumber) {
                wrongOrder = true;
            }
            lastNumber = parseInt(d.days);

            processedData.push({
                sort_key: parseInt(i),
                interval_reference_date_type: referenceDate,
                interval_from_date: parseInt(d.days)*86400,
                action_type: d.action_type
            })
        })
        if (hasEventsPastEnding) {
            setError("Marking subscription as cancelled or unpaid has to be the last action.");
            return;
        }
        if (wrongOrder) {
            setError("The days in the schedule has to be in an ascending order.")
            return;
        }
        if (props.onScheduleUpdated) {
            props.onScheduleUpdated(processedData);
        }
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={items} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                <BaseSideModal.Header title={"Custom Schedule"} />
                <BaseSideModal.Body>
                    <Table borderless>
                        <thead>
                            <tr className="thin">
                                <th>Day</th>
                                <th>Action</th>
                                <th colSpan="3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(items, (item, i) =>
                                    <tr className="thin" key={i}>
                                        <td className={"max-w-[75px]"}>
                                            <BaseForm.Input
                                                type="number" name={`${i}.days`} step="1" label="Day" hideLabel
                                                validations={{ required: true }} />
                                        </td>
                                        <td>
                                            <BaseForm.Input type="select" options={actions} name={`${i}.action_type`}
                                                            hideLabel required showSearch={false} />
                                        </td>
                                        <td className="thin-horizontal">
                                            {
                                                i !== 0 &&
                                                    <Button variant="text" size="sm"><i className="fa fa-arrow-up"
                                                        onClick={() => onMoveUp(i)}/>
                                                    </Button>
                                            }
                                        </td>
                                        <td className="thin-horizontal">
                                            {
                                                i !== (items.length - 1) &&
                                                <Button variant="text" size="sm"><i className="fa fa-arrow-down"
                                                                                    onClick={() => onMoveDown(i)}/>
                                                </Button>
                                            }
                                        </td>
                                        <td className="thin-horizontal">
                                            <DeleteButton size="sm" onDelete={() => onDeleteRow(i)} />
                                        </td>
                                    </tr>
                                )
                            }
                            <tr>
                                <td colSpan="5">
                                    <Button variant="outline-primary" className="btn-complete" onClick={onAddNew}>
                                        <i className="fa fa-plus"/>Add New
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    {
                        error && <span className="form-error-message align-self-center">{ error }</span>
                    }
                    <SubmitButton disabled={!_.isNil(error)}>Save</SubmitButton>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );
}

export default EditScheduleModal;
