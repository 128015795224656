import { useState } from "react";
import BaseSideModal from "./modals/BaseSideModal";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import BaseForm from "./BaseForm";
import moment from "moment";
import { getSubscriptionCancelReasonOptions } from "../helpers/subscriptions";
import SimpleModal from "./modals/SimpleModal";

function CustomerPortalCancelSubscriptionSideModal({ showSideModal, onClose, onConfirm, subscription: { id, renewal_date }, isCancelActionLoading }) {
    const [cancelReason, setCancelReason] = useState(null);
    const [cancelReasonOther, setCancelReasonOther] = useState(null);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    return (
        <>
            <BaseSideModal
                size="md"
                show={showSideModal}
                onClose={() => {
                    setShowConfirmationModal(false);
                    onClose();
                }}
            >
                <BaseSideModal.Form
                    onSubmit={() => {
                        setShowConfirmationModal(true);
                    }}
                    onFieldChange={(name, value) => {
                        if (name === "cancel_reason") {
                            setCancelReason(value);
                            setCancelReasonOther(null);
                        } else if (name == "cancel_reason_other") {
                            setCancelReasonOther(value);
                        }
                    }}
                >
                    <BaseSideModal.Header title={"Cancel Subscription"} />
                    <BaseSideModal.Body>
                        <Row>
                            <p className="mt-1 mb-3">
                                Your plan will be canceled on <span className="font-semibold">{moment(renewal_date).format("MMM D, YYYY")}</span>.
                            </p>
                        </Row>
                        <Row>
                            <BaseForm.Input
                                type="select"
                                name="cancel_reason"
                                options={getSubscriptionCancelReasonOptions()}
                                showSearch={false}
                                label={"Cancel Reason (optional)"}
                                disabled={isCancelActionLoading}
                            />
                            {cancelReason === "other" && <BaseForm.Input type="text" name="cancel_reason_other" label="Other Reason" required />}
                        </Row>
                    </BaseSideModal.Body>
                    <BaseSideModal.Footer>
                        <Row>
                            <Col md="12" className="text-end">
                                {isCancelActionLoading ? (
                                    <Button variant="danger" disabled>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> <span>Processing...</span>
                                    </Button>
                                ) : (
                                    <Button variant="danger" type="submit">
                                        Cancel
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </BaseSideModal.Footer>
                </BaseSideModal.Form>
            </BaseSideModal>
            <SimpleModal
                show={showConfirmationModal}
                title="Confirm Cancel Subscription?"
                body={
                    <>
                        <p>Are you sure you want to cancel the subscription?</p>
                    </>
                }
                confirmationText="confirm"
                buttonVariant="danger"
                buttonTitle="Confirm Cancel"
                onConfirm={() => {
                    setShowConfirmationModal(false);
                    onConfirm(id, cancelReason, cancelReasonOther);
                }}
                onClose={() => {
                    setShowConfirmationModal(false);
                }}
            />
        </>
    );
}

export default CustomerPortalCancelSubscriptionSideModal;
