import React, {useContext, useEffect, useMemo, useState} from "react";
import {
    BaseContext,
    getConfigForContractReminderSchedule,
    getConfigForInvoiceReminderSchedule, renderCampaignConfigRow
} from "../../../helpers/common";
import {serverFetch, serverPatch, serverPost} from "../../../helpers/server";
import ContentBox from "../../../components/ContentBox";
import {Button, Col, Row} from "react-bootstrap";
import BaseForm from "../../../components/BaseForm";
import BaseContainer from "../../../components/BaseContainer";
import Section from "../../../components/Section";
import ContentContainer from "../../../components/ContentContainer";
import Notification from "../../../components/Notification";
import EditScheduleModal from "../../../components/modals/EditScheduleModal";
import SimpleModal from "../../../components/modals/SimpleModal";
import SubmitButton from "../../../components/SubmitButton";
const _ = require('lodash');

function ContractGeneralSettings() {
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [contractRemindersEnabled, setContractRemindersEnabled] = useState(false);
    const [contractRemindersConfig, setContractRemindersConfig] = useState({});
    const [showEditCustomReminderModal, setShowEditCustomReminderModal] = useState(false);
    const [showDisableRemindersModal, setShowDisableRemindersModal] = useState(false);
    const [contractPaymentMethodRequired, setContractPaymentMethodRequired] = useState(false);

    useEffect(() => {
        setPageTitle(`Contract Settings`);
    }, []);

    const initialFields = () => {
        setContractRemindersEnabled(settings.proposal_sign_reminder_enabled);
        return {
            ...settings,
            contract_complete_cc_emails: _.join(settings.contract_complete_cc_emails, ",")
        }
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl("/settings"), { skipCache: skipCache }).then((res) => {
            processSettings(res)
        });
    }

    const processSettings = (res) => {
        setSettings(res);
        setContractRemindersEnabled(res.proposal_sign_reminder_enabled)
        setContractRemindersConfig(res.proposal_sign_reminder_config)
        setContractPaymentMethodRequired(res.contract_payment_method_required)
    }

    useEffect(() => {
        fetchData()
    }, []);

    const updateSettings = async(data) => {
        const res = await serverPatch(getApiUrl("/settings"), data);
        if (res) {
            processSettings(res)
            Notification.Success("Successfully updated");
        }
    }

    const updateContractSettings = async(data) => {
        let contractCompleteEmails = _.split(data.contract_complete_cc_emails, ",");
        contractCompleteEmails = _.map(contractCompleteEmails, (em) => {
            return _.trim(em);
        })
        contractCompleteEmails = _.filter(contractCompleteEmails, (em) => em);
        const res = await serverPatch(getApiUrl("/settings"), {contract_complete_cc_emails: contractCompleteEmails});
        if (res) {
            processSettings(res)
            Notification.Success("Successfully updated");
        }
    }

    const onFieldChange = async(name, value) => {
        if (name === "proposal_sign_reminder_enabled") {
            if (value === false) {
                setShowDisableRemindersModal(true)
            } else {
                setContractRemindersEnabled(value)
                const data = {
                    proposal_sign_reminder_enabled: value
                }
                await updateSettings(data);
            }
        }
        if (name === "contract_payment_method_required") {
            setContractPaymentMethodRequired(value)
            const data = {
                contract_payment_method_required: value
            }
            await updateSettings(data);
        }
    }

    const disableContractReminders = async() => {
        setContractRemindersEnabled(false)
        const data = {
            proposal_sign_reminder_enabled: false
        }
        await updateSettings(data);
    }

    const renderReminderSchedule = (type) => {
        const isSelected = settings.proposal_sign_reminder_config && (settings.proposal_sign_reminder_config.grouping_name === type);
        const config = (isSelected) ?
            settings.proposal_sign_reminder_config.config.items
            : getConfigForContractReminderSchedule(type);
        console.log(config)
        return renderSchedule(reminderScheduleTitleMap[type], reminderScheduleDescriptionMap[type], config, isSelected,
            type === "custom" && isSelected, () => onReminderScheduleSelected(type));
    }

    const onReminderScheduleSelected = async(type) => {
        if (type !== "custom") {
            await updateSettings({
                proposal_sign_reminder_config: {
                    grouping_name: type
                }
            });
        } else {
            setShowEditCustomReminderModal(true)
        }
    }

    const renderSchedule = (title, description, items, isSelected, editable, onClick) => {
        return <div className="schedule-wrapper">
            <div className="schedule-top">
                <h4>{ title }</h4>
                <span className="body1 flex-grow-1">{ description }</span>
                {
                    editable &&
                    <Button variant="outline-primary" className="btn-complete" onClick={onClick}>
                        <i className="fa fa-edit"/> Edit
                    </Button>
                }
                <Button variant="primary" className="btn-complete" onClick={onClick}
                        disabled={isSelected}>
                    { isSelected ? "Selected" : "Select" }
                </Button>
            </div>
            {
                !_.isEmpty(items) &&
                <div className="schedule-bottom">
                    <span className="body2">Schedule</span>
                    <div className="d-flex flex-column">
                        {
                            _.map(items, (c, i) =>
                                <span key={i}>{ renderCampaignConfigRow(c) }</span>
                            )
                        }
                    </div>
                </div>
            }
        </div>
    }

    const onCustomContractReminderScheduleSelected = async (scheduleItems) => {
        await updateSettings({
            proposal_sign_reminder_config: {
                grouping_name: "custom",
                config: {
                    items: scheduleItems
                }
            }
        });
        setShowEditCustomReminderModal(false);
    }

    const reminderScheduleTitleMap = {
        "basic": "Basic",
        "lenient": "Lenient",
        "aggressive": "Persistent",
        "custom": "Custom"
    }

    const reminderScheduleDescriptionMap = {
        "basic": "",
        "lenient": "",
        "aggressive": "",
        "custom": "Create your own custom schedule that suits your needs."
    }

    return (
        <>
            <ContentContainer>
                <Section title="Contract Payments" className={"mt-4"}>
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="8">
                                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                        <Row>
                                            <BaseForm.Input
                                                type="switch" colSpan="12" name="contract_payment_method_required" label="Require Payment Method By Default"
                                                description="Require a payment method before a contract is executed. This can be overriden per contract."
                                            />
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section title="Contract Emails" className={"mt-4"}>
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="8">
                                    <BaseForm initialFormFields={initialFields} onSubmit={updateContractSettings}>
                                        <Row>
                                            <BaseForm.Input
                                                type="text" colSpan="12" name="contract_complete_cc_emails" label="Contract Complete Emails"
                                                description="These email addresses will be cc'd when contracts are complete"
                                                transformations={["lowercase", "trim"]} validations={{ validEmails: true }}
                                            />
                                        </Row>
                                        <SubmitButton>Update</SubmitButton>
                                    </BaseForm>
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                <Section title="Contract Reminders" className={"mt-4"}>
                    <ContentBox>
                        <ContentBox.Body>
                            <Row>
                                <Col md="8">
                                    <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                                        <Row>
                                            <BaseForm.Input
                                                type="switch" colSpan="12" name="proposal_sign_reminder_enabled" label="Send Customer Contract Reminders"
                                                description="Remind customers about their unsigned and unpaid contracts"
                                            />
                                        </Row>
                                    </BaseForm>
                                </Col>
                            </Row>
                        </ContentBox.Body>
                    </ContentBox>
                </Section>
                {
                    contractRemindersEnabled &&
                    <Section title="Reminder Schedule" className={"mt-4"}>
                        <ContentBox>
                            <ContentBox.Body>
                                <Row>
                                    <Col md="8">
                                        <div className="text-sm font-semibold">Configure Reminder Schedule</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <Row>
                                            <Col lg="3">{renderReminderSchedule("basic")}</Col>
                                            <Col lg="3">{renderReminderSchedule("lenient")}</Col>
                                            <Col lg="3">{renderReminderSchedule("aggressive")}</Col>
                                            <Col lg="3">{renderReminderSchedule("custom")}</Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </ContentBox.Body>
                        </ContentBox>
                    </Section>
                }
                <EditScheduleModal
                    show={showEditCustomReminderModal}
                    actions={["notification"]}
                    onClose={setShowEditCustomReminderModal}
                    onScheduleUpdated={onCustomContractReminderScheduleSelected}
                    defaultItems={settings.proposal_sign_reminder_config && settings.proposal_sign_reminder_config.config.items}
                    isContractSchedule={true}
                />
            </ContentContainer>
            <SimpleModal
                title={"Confirm disable contract reminders?"}
                body={
                    <div>
                        <span>Are you sure you want to disable contract reminders? All active contract reminders will be stopped.</span>
                    </div>
                }
                buttonVariant={"danger"}
                buttonTitle={"Disable Reminders"}
                show={showDisableRemindersModal}
                onClose={setShowDisableRemindersModal}
                onConfirm={() => disableContractReminders()}
            />
        </>
    )
}
export default ContractGeneralSettings;