import '../../App.scss';
import '../../css/modals.scss';
import React, { useContext } from 'react';
import BaseForm from '../BaseForm';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { BaseContext, getLabelForTaxCategory } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function AddOrEditProductModal(props) {
    const { t } = useTranslation('common');
    const { getApiUrl } = useContext(BaseContext);

    const loadCategories = async (query) => {
        return serverPost(getApiUrl('/product_tax_codes/search'), { query: query, sort_key:"aToZ", pagination:{limit:20} }).then((res) => {
            return res;
        });
    }

    const categoryLabel = (category) => {
        return (
            <>
                <span>{ getLabelForTaxCategory(category) }</span>
            </>
        )
    }

    const cleanupFields = (data) => {
        if (data.tax_category) {
            data.tax_code = data.tax_category.product_tax_code;
            delete data['tax_category'];
        } else {
            data.tax_code = null;
        }
        return data;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            processAddFields={cleanupFields}
            processUpdateFields={cleanupFields}
            itemLabel={t('products.product')}
            itemBaseUrl="/products"
        >
            <Row>
                <BaseForm.Input colSpan="12" name="name" label={t('common.name')} type="text" required />
                <BaseForm.Input colSpan="12" name="description" label={t('common.description')} type="textarea" height="125px" />
                <BaseForm.Input colSpan="12" name="image_url" label={t('common.logo')} type="file" fileType="invoiceLogo" />
                <BaseForm.Input colSpan="12" name="tax_category" label={"Tax Code"} type="selector"
                    loadOptions={loadCategories} getOptionLabel={categoryLabel} getOptionValue={(c) => c.product_tax_code}
                    defaultOptions={(props.itemToEdit && props.itemToEdit.tax_category) ? [props.itemToEdit.tax_category]: null} />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditProductModal;
