import '../App.scss';
import React, {useContext, useEffect} from 'react';
import {BaseContext, CustomerContext} from "../helpers/common";
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";

function CustomerAuthorization(props) {
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();
    const { getCompanySpecificUrl } = useContext(BaseContext);
    let token = searchParams.get('token');

    useEffect(() => {
        if (!token) {
            navigate(getCompanySpecificUrl("/user/login"));
        }
    }, [token]);

    return (
        <CustomerContext.Provider value={{
            accessToken: token
        }}>
        {
            token && <Outlet />
        }
        </CustomerContext.Provider>
    );
}

export default CustomerAuthorization;
