import '../App.scss';
import React, {useState, useEffect, useContext, forwardRef, useImperativeHandle, useMemo} from 'react';
import {BaseContext, currencyFormatFromPrice, getCurrencyOptions, getNameForProviderType} from '../helpers/common';
import { serverPost } from '../helpers/server';
import { useNavigate } from 'react-router-dom';
import Columns from './Columns';
import Link from './Link';
import MapleTable from './MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Label from './Label';
import Section from './Section';
import moment from 'moment';
import BaseOverlayTrigger from './BaseOverlayTrigger';
import MapleTableHeaderWithActions from "./MapleTableHeaderWithActions";
import SortableTableHeader from "./SortableTableHeader";
import TopBarSummary from "./TopBarSummary";
import {getPaymentExportFields} from "../helpers/exportFields";
import BaseCSVExportModal from "./modals/BaseCSVExportModal";
import {renderTypeLabel} from "../helpers/payments";
const _ = require('lodash');

const PaymentListSection = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        refresh() {
            onSearch(true);
        },
    }));

    const { t } = useTranslation('common')
    const navigate = useNavigate();
    const { company, getApiUrl, getCompanySpecificUrl } = useContext(BaseContext);
    const [payments, setPayments] = useState([]);
    const [includeCustomer, setIncludeCustomer] = useState(false);
    const [hideTableIfEmpty, setHideTableIfEmpty] = useState(false);
    const [showTableActions, setShowTableActions] = useState(_.isNil(props.showTableActions) ? true: props.showTableActions);
    const [loading, setLoading] = useState(true);
    const [showExportModal, setShowExportModal] = useState(false);

    const [sort, setSort] = useState("createdAtDesc");
    const [filters, setFilters] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [meta, setMeta] = useState({});
    const [initialFields, setInitialFields] = useState({ sort: "createdAtDesc" })

    const defaultSelectedFilters = useMemo(() => {
        return !props.disableInitialFilters ? {"show_connected_account": true}: {}
    }, []);
    const cachedSelectedFilters = useMemo(() => {
        if (props.disableInitialFilters) {
            return null;
        }
        const cachedString = localStorage.getItem(company.id + "_filter_cache_payments");
        if (cachedString && showTableActions) {
            return JSON.parse(cachedString);
        } else {
            return null;
        }
    }, [showTableActions])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        if (!_.isNil(props.includeCustomer)) {
            setIncludeCustomer(props.includeCustomer);
        }
    }, [props.includeCustomer])

    useEffect(() => {
        if (!_.isNil(props.showTableActions)) {
            setShowTableActions(props.showTableActions);
        }
    }, [props.showTableActions])

    useEffect(() => {
        if (!_.isNil(props.hideTableIfEmpty)) {
            setHideTableIfEmpty(props.hideTableIfEmpty);
        }
    }, [props.hideTableIfEmpty])

    useEffect(() => {
        onSearch(true);
    }, [props.extraQueryFilters]);

    const getQueryParams = () => {
        return {
            ...props.extraQueryFilters,
            ...selectedFilters
        };
    }

    const onSearch = (restart = true) => {
        const limit = props.limit || 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: getQueryParams(),
            include_meta: restart
        }
        serverPost(getApiUrl("/payments/transactions/find"), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setPayments(results);
                    setMeta(res.meta);
                } else {
                    setPayments(_.concat(payments, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        const paymentMechanismOptions = [
            { value: null, label: "All" },
            { value: true, label: "Automatically charge" },
            { value: false, label: "Email invoice" },
        ]

        const statusOptions = [
            { value: null, label: "All" },
            { value: "SUCCESS", label: "Success" },
            { value: "REFUNDED", label: "Refunded" },
            { value: "PARTIAL_REFUNDED", label: "Partially Refunded" },
            { value: "FAILED", label: "Failed"},
            { value: "CANCELLED", label: "Cancelled"},
            { value: "PROCESSING", label: "Processing"},
        ]

        const currencyOptions = getCurrencyOptions();
        currencyOptions.unshift({ value: null, label: "All" })

        const providerTypeOptions = [
            { value: null, label: "All" },
            { value: "STRIPE", label: "Stripe" },
            { value: "CHEQUE", label: "Check" },
            { value: "CASH", label: "Cash" },
            { value: "WIRE", label: "Wire" },
            { value: "CREDIT_CARD_OFFLINE", label: "Credit Card Offline" },
            { value: "BANK_TRANSFER", label: "Bank Transfer" },
            { value: "BILL_COM", label: "Bill.com" },
        ]

        const transactionTypeOptions = [
            { value: null, label: "All" },
            { value: "PAYMENT", label: "Payment" },
            { value: "REFUND", label: "Refund" },
        ]

        setFilters([
            { title: "Status", type: "select", name: "status", options: statusOptions},
            { title: "Currency", type: "select", name: "currency", options: currencyOptions },
            { title: "Provider Type", type: "select", name: "provider_type", options: providerTypeOptions },
            { title: "Transaction Type", type: "select", name: "transaction_type", options: transactionTypeOptions },
            { title: "Connected Account", type: "switch", name: "show_connected_account", label: "Show Transactions" },
            { title: "Total Amount", type: "amount", name: "total_amount" }
        ])
    }, [])

    useEffect(() => {
        onSearch(true);
    }, [sort, selectedFilters]);

    const getStatus = (payment) => {
        if (payment.status === "SUCCESS") {
            return <Label.Success><span className="d-none d-md-inline">Succeeded&nbsp;</span><i className="fa fa-check"/></Label.Success>
        } else if (payment.status === "FAILED" || payment.status === "CANCELLED") {
            return <Label.Danger><span className="d-none d-md-inline">Failed&nbsp;</span><i className="fa fa-x"/></Label.Danger>
        } else if (payment.status === "REFUNDED") {
            return <Label.Info><span className="d-none d-md-inline">Refunded&nbsp;</span><i className="fa fa-rotate-left"/></Label.Info>
        } else if (payment.status === "PARTIAL_REFUNDED") {
            return <Label.Info><span className="d-none d-md-inline">Partially Refunded</span></Label.Info>
        } else if (payment.status === "PROCESSING") {
            return <Label.Danger><span className="d-none d-md-inline">Processing</span></Label.Danger>
        } else {
            return <Label.Info><span className="d-none d-md-inline">{ payment.status }</span></Label.Info>
        }
    }

    const onParamsChange = (params) => {
        setSelectedFilters(params);
        localStorage.setItem(company.id + "_filter_cache_payments", JSON.stringify(params));
    }

    const getIndicatorForPayment = (row) => {
        if (row.connected_account_transaction) {
            return (
                <div>
                    <BaseOverlayTrigger content={"Connect Account Transaction"} placement="left">
                        <i className="fa fa-link"/>
                    </BaseOverlayTrigger>
                </div>
            )
        }
        return null;
    }

    const columns = [
        includeCustomer && "customer",
        "invoice",
        "payment_date",
        "payment_type",
        "amount",
        "status",
        "provider",
        "payment_indicator"
    ];

    const renderColumnHeader = (key, j) => {
        if (!key) {
            return;
        }
        if (key === "customer") {
            return <MapleTable.TH key={j}>Customer</MapleTable.TH>
        } else if (key === "invoice") {
            return <MapleTable.TH key={j} className="d-none d-md-table-cell">Invoice</MapleTable.TH>
        } else if (key === "payment_date") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="createdAtAsc" sortKeyDown="createdAtDesc"
                currentSort={sort}>
                Payment Date
            </SortableTableHeader>
        } else if (key === "payment_type") {
            return <MapleTable.TH key={j} className="d-none d-lg-table-cell">Type</MapleTable.TH>
        } else if (key === "amount") {
            return <SortableTableHeader key={j}
                onSortChange={setSort} sortKeyUp="totalAmountAsc" sortKeyDown="totalAmountDesc"
                currentSort={sort} className="d-table-cell d-lg-none d-xl-table-cell" innerClassName="justify-end">
                Amount
            </SortableTableHeader>
        } else if (key === "status") {
            return <MapleTable.TH key={j}>Status</MapleTable.TH>
        } else if (key === "provider") {
            return <MapleTable.TH key={j} className="d-none d-xl-table-cell">Provider</MapleTable.TH>
        } else if (key === "payment_indicator") {
            return <MapleTable.TH key={j} className="d-none d-md-table-cell"></MapleTable.TH>
        }
    }

    const renderColumn = (key, row, j) => {
        if (!key) {
            return;
        }
        if (key === "customer") {
            return <td key={j}>
                <Columns.CustomerName customer={row.customer} />
            </td>
        } else if (key === "invoice") {
            return <td key={j} className="d-none d-md-table-cell"><Link href={getCompanySpecificUrl(`/invoice/${row.invoice_uuid}`)}>{ row.invoice_number || row.invoice_uuid }</Link></td>
        } else if (key === "payment_date") {
            return <td key={j}>{ moment(row.created_at).format("MMM D, YYYY h:mm:ss a") }</td>
        } else if (key === "payment_type") {
            return <td key={j} className="d-none d-lg-table-cell">
                { renderTypeLabel(row) }
                <div className="text-start d-block d-xl-none" style={{ marginTop: "3px" }}>{ currencyFormatFromPrice(row.total_amount) }</div>
            </td>
        } else if (key === "amount") {
            return <td key={j} className="d-table-cell d-lg-none d-xl-table-cell text-end">{ currencyFormatFromPrice(row.total_amount) }</td>
        } else if (key === "status") {
            return <td key={j}>{ getStatus(row) }</td>
        } else if (key === "provider") {
            return <td key={j} className="d-none d-xl-table-cell">{ getNameForProviderType(row.provider_type) }</td>
        } else if (key === "payment_indicator") {
            return <td key={j} className="d-none d-md-table-cell">{ getIndicatorForPayment(row) }</td>
        }
    }

    return (
        <InfiniteScroll
            dataLength={payments.length}
            next={() => onSearch(false)}
            hasMore={hasMore && !props.hideMore}
            scrollableTarget="content-wrapper"
        >
            <Section title="Payments" loading={loading}>
                {
                    props.metricsSummary &&
                        <TopBarSummary className="mt-1" entries={props.metricsSummary}/>
                }
                {
                    <MapleTable className={ props.metricsSummary ? "mt-4": "" }>
                        {
                            showTableActions &&
                                <MapleTableHeaderWithActions
                                    showSearch={true}
                                    searchPlaceholder="Search Payments"
                                    showFilters={true}
                                    filters={filters}
                                    showExport={true}
                                    onExport={() => setShowExportModal(true)}
                                    meta={meta}
                                    defaultSelectedFilters={defaultSelectedFilters}
                                    cachedSelectedFilters={cachedSelectedFilters}
                                    onParamsChange={onParamsChange}
                                />
                        }
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    { _.map(columns, (c, j) => renderColumnHeader(c, j)) }
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(payments, (row, i) =>
                                    <MapleTable.TR key={i} className={classnames("cursor-pointer")} href={getCompanySpecificUrl(`/payment/${row.id}`)}>
                                        { _.map(columns, (c, j) => renderColumn(c, row, j)) }
                                    </MapleTable.TR>
                                )
                            }
                            {
                                hasMore && !props.hideMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                            }
                            {
                                hasMore && props.hideMore &&
                                    <tr>
                                        <td colSpan={10} className="text-center">
                                            <Link href={getCompanySpecificUrl(`/payments?customer_id=${props.customer && props.customer.id}`)}>View all</Link>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                }
            </Section>
            <BaseCSVExportModal
                show={showExportModal}
                onClose={setShowExportModal}
                sort={sort}
                useQuery={true}
                query={getQueryParams()}
                title={"Payment CSV Export"}
                fields={getPaymentExportFields()}
                filenamePrefix="Payments-CSV"
                cacheKey={company.id + "_csv_payments"}
                exportUrl={'/payments/transactions/export'} />
        </InfiniteScroll>
    );
})

export default PaymentListSection;
