import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {serverPost} from '../../helpers/server';
import {
    BaseContext,
    CustomerContext,
    currencyFormatFromPrice,
    CustomerPortalContext, downloadBlob
} from '../../helpers/common';
import Section from "../../components/Section";
import {useTranslation} from "react-i18next";
import MapleTable from "../../components/MapleTable";
import moment from "moment/moment";
import {getShareLinkUrl, renderStatusLabel} from "../../helpers/invoices";
import Link from "../../components/Link";
import InfiniteScroll from "react-infinite-scroll-component";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function CustomerPortalInvoices() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const { portal } = useContext(CustomerPortalContext)
    const [invoices, setInvoices] = useState([]);
    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const showInvoices = portal?.options?.invoice?.show

    useEffect(() => {
        setPageTitle(`Customer Portal - Invoices`);
    }, []);

    useEffect(() => {
        if (!showInvoices) {
            navigate(-1);
        }
    }, [showInvoices]);

    const processResults = (results) => {
        _.each(results, (r) => {
            if (r.status === "PENDING") {
                r.isOverdue = moment(r.due_date).isBefore(moment());
            }
        })
        return results;
    }

    const onSearch = (restart=false) => {
        if (!portal) {
            return;
        }
        const limit = 20;
        const params = {
            sort_key: "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                customer_id: portal.customer_id
            },
            include_meta: true
        }
        const options = {
            accessToken: accessToken
        }
        serverPost(getApiUrl("/invoices/find"), params, options).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setInvoices(processResults(results));
                } else {
                    setInvoices(_.concat(invoices, processResults(results)));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [portal]);

    const goToInvoice = (event, invoice) => {
        window.open(
            getShareLinkUrl(invoice, getCompanySpecificUrl),
            '_blank'
        );
    }

    const downloadInvoicePDF = (event, invoice) => {
        serverPost(getApiUrl(`/invoices/${invoice.uuid}/pdf`), {}, { noJson: true }).then((res) => {
            downloadBlob(res, `Invoice-${invoice.number}`);
        })
    }

    return (
        <div className="max-w-3xl">
            <InfiniteScroll
                dataLength={invoices.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="portal-wrapper"
            >
                <Section title="Invoices" className="mt-4">
                    {
                        !_.isEmpty(invoices) ?
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Date</MapleTable.TH>
                                            <MapleTable.TH>Amount</MapleTable.TH>
                                            <MapleTable.TH>Status</MapleTable.TH>
                                            <MapleTable.TH>Due Date</MapleTable.TH>
                                            <MapleTable.TH></MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(invoices, (row, i) =>
                                            <tr key={i}>
                                                <td className="">{ moment(row.invoice_date).format("MMM D, YYYY") }</td>
                                                <td>{ currencyFormatFromPrice(row.total) }</td>
                                                <td className="">{ renderStatusLabel(row, getCompanySpecificUrl) }</td>
                                                <td className="">{ moment(row.due_date).format("MMM D, YYYY") }</td>
                                                <td className="w-px whitespace-nowrap">
                                                    <div className="flex flex-row gap-3">
                                                        <Link onClick={(event) => goToInvoice(event, row)}>View</Link>
                                                        <Link onClick={(event) => downloadInvoicePDF(event, row)}>
                                                            <ArrowDownTrayIcon className="h-5 w-5" />
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    {
                                        hasMore &&
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                            : <span className={"text-gray-500"}>No invoices yet</span>
                    }
                </Section>
            </InfiniteScroll>
        </div>
    );
}

export default CustomerPortalInvoices;
