import '../App.scss';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
const _ = require('lodash');

function Breadcrumb(props) {

    return (
        <nav className={classnames("flex mb-3", props.className)} aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-2">
                {
                    _.map(props.items, (item, i) =>
                        <li key={i}>
                            <div className="flex items-center">
                                {
                                    i !== 0 &&
                                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                }
                                {
                                    item.href ?
                                        <Link
                                            to={item.href}
                                            className={classnames("text-sm font-medium", i === 0 ? "": "ml-1")}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </Link>
                                        : <span className={classnames("text-sm font-medium text-gray-500", i === 0 ? "": "ml-1")}>{ item.name }</span>
                                }
                            </div>
                        </li>
                    )
                }
            </ol>
        </nav>
    );
}

export default Breadcrumb;