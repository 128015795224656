import '../App.scss';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { renderChildren } from "../helpers/common";
const _ = require('lodash');

function Link(props) {
    const navigate = useNavigate();

    const onClick = (event) => {
        let openInNewTab = false;
        if (event.metaKey || event.ctrlKey) {
            openInNewTab = true;
        }
        event.preventDefault();
        event.stopPropagation();
        if (props.onClick) {
            props.onClick(event);
        } else if (props.href) {
            if (openInNewTab) {
                const url = window.location.origin + props.href;
                window.open(url, '_blank', 'noopener,noreferrer');
            } else {
                navigate(props.href)
            }
        }
    }

    return (
        <a {...props} href={props.href || ""} onClick={onClick} className={props.className}>
            { renderChildren(props) }
        </a>
    );
}

export default Link;