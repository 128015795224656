import '../../App.scss';
import '../../css/invoice.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import {useParams} from 'react-router-dom';
import {serverFetch, serverPost} from '../../helpers/server';
import {
    BaseContext,
    CustomerContext,
    renderPaymentMethod,
    isPaymentMethodExpiring,
    isPaymentMethodExpired,
    getLabelForSubscriptionLength, formatTerm
} from '../../helpers/common';
import { getStartDateForSubscription, getSubscriptionStatus } from '../../helpers/subscriptions';
import ContentContainer from "../../components/ContentContainer";
import {Table} from "react-bootstrap";
import MoreButton from "../../components/MoreButton";
import Section from "../../components/Section";
import {useTranslation} from "react-i18next";
import SetupPaymentMethodModal from "../../components/modals/SetupPaymentMethodModal";
import MapleTable from "../../components/MapleTable";
import Label from "../../components/Label";
import moment from "moment/moment";
const _ = require('lodash');

function CustomerPortalOld() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { customerId } = useParams();
    const [ searchParams ] = useSearchParams();
    let redirectStatus = searchParams.get('redirect_status');
    const { company, getApiUrl, setPageTitle } = useContext(BaseContext);
    const { accessToken } = useContext(CustomerContext);
    const [showSetupPaymentMethodModal, setShowSetupPaymentMethodModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [settings, setSettings] = useState({});
    const [customerDetails, setCustomerDetails] = useState({});

    useEffect(() => {
        setPageTitle(`Customer Portal`);
    }, []);

    const fetchData = (skipCache=false) => {
        const options = {
            skipCache: skipCache,
            accessToken: accessToken
        }
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
        serverFetch(getApiUrl(`/customers/${customerId}/payment_methods`), options).then((res) => {
            if (res) {
                setPaymentMethods(res.methods);
            }
        });
        serverFetch(getApiUrl(`/customers/${customerId}`), options).then((res) => {
            setCustomerDetails(res);
        });
        fetchSubscriptions();
    }

    const fetchSubscriptions = () => {
        const params = {
            company_id: company.id,
            sort_key: "createdAtDesc",
            pagination: {
                from_key: null,
                limit: 50
            },
            query: {
                customer_id: customerId,
                statuses: ["ACTIVE"]
            },
            include_meta: true
        }
        const options = {
            accessToken: accessToken
        }
        serverPost(getApiUrl("/subscriptions/find"), params, options).then((res) => {
            setLoading(false);
            if (res) {
                setSubscriptions(res.results || []);
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isNil(redirectStatus)) {
            return;
        }
        setTimeout(() => {
            let urlParser = new URL(window.location.href);
            urlParser.searchParams.delete('setup_intent');
            urlParser.searchParams.delete('setup_intent_client_secret');
            urlParser.searchParams.delete('redirect_status');
            navigate(urlParser.pathname + urlParser.search, { replace: true });
        }, 100);
    }, [redirectStatus])

    const onModalClose = (didUpdate) => {
        setShowSetupPaymentMethodModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onPaymentActionSelected = (action) => {
        console.log("The action is " + action);
    }

    const paymentActionOptions = [
        { id: "set_default", label: "Set as default" }
    ]

    return (
        <div>
            <div className="d-flex justify-content-center">
                <div className="customer-portal-wrapper">
                    <ContentContainer title={ customerDetails.name } subtitle={"Please review your payment methods and update them if needed."}>
                        <Section title="Subscriptions" className="mt-4" loading={loading}>
                            {
                                !_.isEmpty(subscriptions) ?
                                    <MapleTable>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="d-none d-md-table-cell">Term</th>
                                                        <th>Status</th>
                                                        <th>Since</th>
                                                        <th className="d-none d-md-table-cell">Next Invoice</th>
                                                        <th>Payment Method</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    _.map(subscriptions, (row, i) =>
                                                        <tr key={i}>
                                                            <td className="no-stretch d-none d-md-table-cell">{ formatTerm(row.term) }</td>
                                                            <td className="no-stretch">{ getSubscriptionStatus(row) }</td>
                                                            <td>{ getStartDateForSubscription(row) }</td>
                                                            <td className="d-none d-md-table-cell">
                                                                {
                                                                    row.next_invoice_date &&
                                                                    <span>{ moment(row.next_invoice_date).format("MMM D, YYYY hh:mma") }</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="d-flex flex-row gap-3 align-items-center">
                                                                    {
                                                                        row.payment_method_id ?
                                                                            <span></span>
                                                                        : <span className="gray2">Default Method</span>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                        </MapleTable>
                                : <span className={"text-gray-500"}>No active subscriptions</span>
                            }
                        </Section>
                        <Section title="Payment Methods" className="mt-4" actions={[{
                            variant: "primary",
                            icon: "fa-plus",
                            label: "Add Payment Method",
                            onClick: () => setShowSetupPaymentMethodModal(true)
                        }]}>
                            {
                                _.isEmpty(paymentMethods) ?
                                    <p>Nothing here. Create one</p>
                                    : <Table hover>
                                        <thead>
                                        <tr>
                                            <th>Payment Method</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _.map(paymentMethods, (row, i) =>
                                                <tr key={i}>
                                                    <td>{ renderPaymentMethod(t, row) }</td>
                                                    <td>
                                                        <div className="d-flex flex-row gap-3">
                                                            {
                                                                row.default &&
                                                                <Label.Success><i className="fa fa-check"/> Default</Label.Success>
                                                            }
                                                            {
                                                                isPaymentMethodExpiring(row) &&
                                                                <Label.Warning>Expiring</Label.Warning>
                                                            }
                                                            {
                                                                isPaymentMethodExpired(row) &&
                                                                <Label.Danger>Expired</Label.Danger>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className={"hide"}>
                                                            <MoreButton items={paymentActionOptions} onSelect={onPaymentActionSelected} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                            }
                        </Section>
                        <div className="customer-portal-wrapper-footer">
                            <div className="flex-grow-1"/>
                            <div className="flex-grow-0 d-flex flex-row gap-3 align-items-center" >
                                <span>Powered by Maple</span>
                                <a href="https://www.maplebilling.com/terms" target="_blank">Terms</a>
                                <a href="https://www.maplebilling.com/privacy" target="_blank">Privacy</a>
                            </div>
                            <div className="flex-grow-1"/>
                        </div>
                    </ContentContainer>
                </div>
            </div>
            <SetupPaymentMethodModal
                show={showSetupPaymentMethodModal}
                onClose={onModalClose}
                paymentConfig={settings.payment_config}
                customer={customerDetails}
                company={company}
                redirectUrl={window.location.href}
            />
        </div>
    );
}

export default CustomerPortalOld;
