import '../App.scss';
import React, {useContext, useEffect, useState} from 'react';
import {serverPost} from '../helpers/server';
import {BaseContext} from '../helpers/common';
import BaseForm from './BaseForm';
import {useTranslation} from 'react-i18next';

const _ = require('lodash');

function CustomerInput(props) {
    const {t} = useTranslation('common');
    const {getApiUrl} = useContext(BaseContext);

    const loadCustomers = async (query) => {
        const customerPromise = serverPost(getApiUrl('/customers/autocomplete'), {
            search_query: query,
            sort_key: "aToZ",
            pagination: {limit: 20}
        });
        const customerResult = await customerPromise;
        return (customerResult && customerResult.results) || [];
    }

    const customerLabel = (customer) => {
        let label = customer.email;
        if (customer.name) {
            if (label) {
                label = `${customer.name} (${label})`
            } else {
                label = customer.name
            }
        }
        if (!label && !customer.org_name) {
            label = customer.identifier
        }
        return (
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-col grow">
                    {
                        customer.org_name &&
                        <span className="body2" style={{marginTop: "-4px"}}>{customer.org_name}</span>
                    }
                    <span style={{marginTop: customer.org_name ? "-4px" : "0px"}}>{label}</span>
                </div>
                <div className="h-4 shrink-0">
                    {
                        customer.integration_icon &&
                        <img src={customer.integration_icon} alt="Integration Logo" className="h-4"/>
                    }
                </div>
            </div>
        )
    }

    const getOptionValue = (customer) => {
        if (customer.integration_id) {
            return customer.identifier;
        } else {
            return customer.id;
        }
    }

    const isOptionDisabled = (option) => {
        return option.managed_externally;
    }

    return (
        <BaseForm.Input colSpan={props.colSpan || "6"} name={props.name || "customer"}
                        label={props.label || t('common.customer')} type="selector"
                        loadOptions={loadCustomers} getOptionLabel={customerLabel} getOptionValue={getOptionValue}
                        defaultOptions={props.defaultCustomer ? [props.defaultCustomer] : null}
                        required={props.required}
                        disabled={!_.isNil(props.defaultCustomer)} isOptionDisabled={isOptionDisabled}/>
    )
}

export default CustomerInput;
