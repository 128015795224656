import '../../../App.scss';
import React from 'react';
import {IntegrationContext} from '../../../helpers/common';
import { useEffect, useContext } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";

function OauthCallback(props) {
    const navigate = useNavigate();
    const { setShowNext, setShowProgress, setShowPrev, next } = useOutletContext();
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);

    useEffect(() => {
        if (integration && integration.auth_type === "api") {
            next();
        }
    }, [])

    useEffect(() => {
        setShowProgress(false);
        setShowNext(false);
        setShowPrev(false);
    }, []);

    useEffect(() => {
        if (integration) {
            if (integration.status === "ACTIVE") {
                window.location.href = getIntegrationSpecificUrl("/settings");
            } else if (integration.status === "SETUP_INCOMPLETE") {
                navigate(getIntegrationSpecificUrl("/setup/data"));
            }
        }
    }, [integration]);

    return (
        <div className="flex flex-col justify-center items-center w-full h-full gap-3">
            <div className="spinner-border text-secondary"/>
            <span className="text-gray-700 text-base">Finalizing OAuth Connection...</span>
        </div>
    );
}

export default OauthCallback;
