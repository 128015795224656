import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Columns from "../../components/Columns";
import Notification from '../../components/Notification';
import {BaseContext, IntegrationContext} from '../../helpers/common';
import {serverPost} from '../../helpers/server';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Loader from "../Loader";
import {ChevronDoubleDownIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function UpdateIntegrationReferenceModal(props) {
    const { company, getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const { integration } = useContext(IntegrationContext);
    const [availableReferences, setAvailableReferences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [initialFields, setInitialFields] = useState(null);

    useEffect(() => {
        if (props.companyUser) {
            setInitialFields({...props.companyUser})
        } else {
            setInitialFields({});
        }
    }, [props.companyUser]);

    useEffect(() => {
        if (props.show) {
            setLoading(true);
            const limit = 50;
            const params = {
                company_id: company.id,
                sort_key: "createdAtDesc",
                pagination: {
                    from_key: null,
                    limit: limit
                },
                query: {
                    feature: props.feature,
                    reference_type: props.reference_type,
                    states: ["REMOTE_ONLY"],
                    search: "query"
                }
            }
            serverPost(getApiUrl(`/integrations/${integration.id}/references/find`), params).then((res) => {
                if (res) {
                    const results = res.results || [];
                    setAvailableReferences(results);
                    setLoading(false);
                }
            });
        }
    }, [props.show])

    const updateIntegrationReference = async (data, errorHandler) => {
        const mappingData = {
            reference_id: props.reference.id,
            remote_id: data.match
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/update_reference_mapping`), mappingData, {}, errorHandler).then(res => {
            if (res) {
                props.onClose();
                Notification.Success("Updated Successfully");
            }
        });
    }

    const availableReferenceOptions = _.map(availableReferences, (ref, i) => {
        return {
            value: ref.remote_data && ref.remote_data.id,
            label: ref.remote_data && (ref.remote_data.name || ref.remote_data.id)
        }
    })

    return (
        <BaseSideModal {...props}>
            <Loader loading={loading}>
                <BaseSideModal.Form initialFormFields={initialFields} onSubmit={updateIntegrationReference}>
                    <BaseSideModal.Header title={"Update Integration Mapping"}/>
                    <BaseSideModal.Body>
                        {
                            props.reference &&
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col gap-1">
                                        <span className="text-sm font-semibold">Map Maple customer to { integration.name }</span>
                                        <Columns.CustomerName customer={props.reference.customer}/>
                                    </div>
                                    <div className="align-self-center">
                                        <ChevronDoubleDownIcon className="h-8 w-8 text-gray-500"/>
                                    </div>
                                </div>
                        }
                        <Row className="mt-4">
                            <BaseForm.Input
                                colSpan="12" name="match" label={`${integration.name} Mapping`} type="select"
                                options={availableReferenceOptions} showSearch={false}
                            />
                        </Row>
                    </BaseSideModal.Body>
                    <BaseSideModal.Footer>
                        <Row>
                            <Col md="12" className="text-end">
                                <SubmitButton variant="primary">Update</SubmitButton>
                            </Col>
                        </Row>
                    </BaseSideModal.Footer>
                </BaseSideModal.Form>
            </Loader>
        </BaseSideModal>
    );

}

export default UpdateIntegrationReferenceModal;
