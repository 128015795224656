import '../App.scss';
import '../css/modals.scss';
import React, {forwardRef, useContext, useEffect, useImperativeHandle, useState} from 'react';
import BaseForm from './BaseForm';
import {Col, InputGroup, Row} from 'react-bootstrap';
import {serverFetch} from '../helpers/server';
import {BaseContext} from '../helpers/common';
import {useTranslation} from 'react-i18next';
import SectionNav from "./SectionNav";

const _ = require('lodash');

const AuthorizeNetPaymentInput = forwardRef((props, ref)  => {
    useImperativeHandle(ref, () => ({
        async getPaymentMethodData(data) {
            return getPaymentMethodData(data);
        },
    }));

    const { t } = useTranslation('common');
    const { getApiUrl, company } = useContext(BaseContext);
    const [settings, setSettings] = useState({});
    const [activeNav, setActiveNav] = useState("cc");

    useEffect(() => {
        const script = document.createElement('script');

        script.src = settings.payment_provider_live_mode ? "https://js.authorize.net/v1/Accept.js": "https://jstest.authorize.net/v1/Accept.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl("/settings")).then((res) => {
            setSettings(res);
        });
    }, []);

    const responseHandler = async (response) => {
        if (!response.messages) {
            return { error: { error: "Error with the payment method details."} }
        }
        const resultCode = response.messages.resultCode;
        if (resultCode === "Error") {
            const errorMessages = _.map(response.messages.message, (m) => m.text);
            return { error: { error: errorMessages.join(" ") } };
        }
        if (_.isNil(response.opaqueData)) {
            return { error: { error: "Error while connecting to authorize.net. Unable to get a payment method token." } }
        }
        return {
            paymentMethodData: {
                authorize_net: {
                    token: {
                        data_descriptor: response.opaqueData.dataDescriptor,
                        data_value: response.opaqueData.dataValue,
                    },
                }
            }
        }
    }

    const getPaymentMethodData = async (data) => {
        var authData = {};
        authData.clientKey = settings.payment_config && settings.payment_config.client_secret;
        authData.apiLoginID = settings.payment_config && settings.payment_config.account_id;

        var secureData = {};
        secureData.authData = authData;

        if (activeNav === "cc") {
            const cardNumber = data.cardNumber || "";
            secureData.cardData = {
                cardNumber: cardNumber.replace(/\s/g, ''),
                month: data.month,
                year: data.year,
                cardCode: data.cardCode,
                zip: data.zip
            };
        } else if (activeNav === "bank") {
            secureData.bankData = {
                accountNumber: data.accountNumber,
                routingNumber: data.routingNumber,
                nameOnAccount: data.nameOnAccount,
                accountType: data.accountType
            };
        }

        const { paymentMethodData, error } = await new Promise(async (resolve, reject) => {
            /* global Accept */
            Accept.dispatchData(secureData, async (response) => {
                const { paymentMethodData, error } = await responseHandler(response);
                resolve({ paymentMethodData, error });
            });
        })
        return { paymentMethodData, error };
    }

    const onNavClick = (tabId) => {
        setActiveNav(tabId);
    }

    const tabItems = [{
        'label': 'Credit/Debit Card',
        'id': 'cc',
        active: activeNav === "cc"
    }, {
        'label': 'Bank Account',
        'id': 'bank',
        active: activeNav === "bank"
    }]

    const accountTypeOptions = [
        { value: "businessChecking", label: "Business Checking" },
        { value: "checking", label: "Checking" },
        { value: "savings", label: "Savings" }
    ]

    return (
        <>
            <SectionNav items={tabItems} onClick={onNavClick} className="mt-2 mb-3" />
            {
                activeNav === "cc" &&
                    <>
                        <Row>
                            <BaseForm.Input colSpan="12" type="text" name="cardNumber" label="Card Number"
                                            placeholder="1234 1234 1234 1234" transformations={["cc"]} required />
                        </Row>
                        <Row>
                            <Col md="4">
                                <BaseForm.InputGroup label="Expiration" formInputClassName="gapless">
                                <BaseForm.Text name="month" label="Month" hideLabel placeholder="MM" required/>
                                <InputGroup.Text>/</InputGroup.Text>
                                <BaseForm.Text name="year" label="Year" hideLabel placeholder="YY" required/>
                                </BaseForm.InputGroup>
                            </Col>
                            <BaseForm.Input colSpan="4" type="text" name="cardCode" label="CVC" placeholder="CVC" required/>
                            <BaseForm.Input colSpan="4" type="text" name="zip" label="Zip/Postal Code" placeholder="94110" required/>
                        </Row>
                    </>
            }
            {
                activeNav === "bank" &&
                    <>
                        <Row>
                            <BaseForm.Input colSpan="6" type="text" name="nameOnAccount" label="Accountholder Name" placeholder="John Smith"/>
                            <BaseForm.Input colSpan="6" type="select" name="accountType" label="Account Type" options={accountTypeOptions} showSearch={false}/>
                            <BaseForm.Input colSpan="6" type="text" name="routingNumber" label="Routing Number" placeholder="026009593"/>
                            <BaseForm.Input colSpan="6" type="text" name="accountNumber" label="Account Number" placeholder="123456789"/>
                        </Row>
                    </>
            }
            <Row>
                <span className="text-xs text-gray-500">By providing your payment information, you allow {company.name} to charge future payments in accordance with their terms.</span>
            </Row>
        </>
    );
})

export default AuthorizeNetPaymentInput;
