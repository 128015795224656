import '../../App.scss';
import React, {useEffect, useState, useContext, useMemo} from 'react';
import {serverPost} from '../../helpers/server';
import {BaseContext, formatTerm, renderProductPricing} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import {useNavigate, useSearchParams} from "react-router-dom";
import Section from "../../components/Section";
import MapleTable from "../../components/MapleTable";
import Link from "../../components/Link";
import InfiniteScroll from "react-infinite-scroll-component";
import BundlePricingTable from "../../components/BundlePricingTable";
import AddOrEditBundlePricingModal from "../../components/modals/AddOrEditBundlePricingModal";
import EmptyState from "../../components/EmptyState";
import classnames from "classnames";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
const _ = require('lodash');

function BundlePricings() {
    const navigate = useNavigate();
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [pricings, setPricings] = useState([])
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(true)
    const [filters, setFilters] = useState([]);
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [fromKey, setFromKey] = useState(null);
    const [showAddOrEditBundlePricingModal, setShowAddOrEditBundlePricingModal] = useState(false)
    const [bundlePricingToEdit, setBundlePricingToEdit] = useState(null)

    const defaultSelectedFilters = useMemo(() => {
        return {"state":"ACTIVE"}
    }, []);
    const cachedSelectedFilters = useMemo(() => {
        const cachedString = localStorage.getItem(company.id + "_filter_cache_bundles");
        if (cachedString) {
            return JSON.parse(cachedString);
        }
        return null;
    }, [])
    const [selectedFilters, setSelectedFilters] = useState(cachedSelectedFilters || defaultSelectedFilters);

    useEffect(() => {
        setPageTitle(`Bundles`);
    }, []);

    useEffect(() => {
        const stateOptions = [
            { value: null, label: "All" },
            { value: "ACTIVE", label: "Active" },
            { value: "ARCHIVED", label: "Archived" }
        ]

        setFilters([
            { title: "Status", type: "select", name: "state", options: stateOptions }
        ])
    }, [])

    useEffect(() => {
        onSearch(true);
    }, [query, selectedFilters]);

    const onSearch = (restart) => {
        const limit = 100
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                is_auto_generated: false,
                state: selectedFilters.state,
                search: selectedFilters.search
            }
        }
        serverPost(getApiUrl("/bundle_pricings/find"), params,{}).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setPricings(results);
                    setMeta(res.meta);
                } else {
                    setPricings(_.concat(pricings, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
            setLoading(false);
        });
    }

    const navigateToSubscriptionListForPricing = (event, pricing) => {
        event.preventDefault();
        event.stopPropagation();
        navigate(getCompanySpecificUrl(`/subscriptions?bpp=${ pricing.id }&statuses=ACTIVE`));
    }

    const onAddBundlePricing = () => {
        setShowAddOrEditBundlePricingModal(true);
        setBundlePricingToEdit(null);
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditBundlePricingModal(false);
        if (didUpdate) {
            onSearch(true);
        }
    }

    const onParamsChange = (params) => {
        if (!_.isEqual(selectedFilters, params)) {
            const newFilters = {...params};
            setSelectedFilters(newFilters);
            setQuery(params.search);
            localStorage.setItem(company.id + "_filter_cache_products", JSON.stringify(newFilters));
        }
    }

    const isEmpty = useMemo(() => {
        return (
            pricings.length === 0 &&
            _.isEqual(defaultSelectedFilters, selectedFilters)
        );
    }, [pricings, selectedFilters, defaultSelectedFilters]);
    return (
        <BaseContainer>
            <ContentContainer>
                <InfiniteScroll
                    dataLength={pricings.length}
                    next={() => onSearch(false)}
                    hasMore={hasMore}
                    scrollableTarget="content-wrapper"
                >
                    <Section
                        title="Bundles" loading={loading}
                        actions={!loading && !isEmpty && [
                            {variant: "primary", icon: "fa-plus", label: "Create Bundle", onClick: onAddBundlePricing}
                        ]}
                    >
                        {
                            isEmpty ?
                                <EmptyState
                                    title={"No bundles."}
                                    subtitle={"Get started by creating a new one."}
                                    buttonLabel={"New Bundle"}
                                    onClick={onAddBundlePricing}
                                />
                            : <MapleTable>
                                <MapleTableHeaderWithActions
                                    showSearch={true}
                                    searchPlaceholder="Search Bundles"
                                    showFilters={true}
                                    filters={filters}
                                    meta={meta}
                                    defaultSelectedFilters={defaultSelectedFilters}
                                    cachedSelectedFilters={cachedSelectedFilters}
                                    onParamsChange={onParamsChange}
                                />
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Name</MapleTable.TH>
                                        <MapleTable.TH>Currency</MapleTable.TH>
                                        <MapleTable.TH>Term</MapleTable.TH>
                                        <MapleTable.TH>Prices</MapleTable.TH>
                                        <MapleTable.TH>Subscriptions</MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(pricings, (pricing, i) =>
                                            <MapleTable.TR key={i} className={classnames("cursor-pointer", pricing.state === "ARCHIVED"? "bg-gray-50":"")} href={getCompanySpecificUrl(`/bundle/${pricing.id}`)}>
                                                <td>
                                                    <div className="flex flex-col">
                                                        <span>{ pricing.name }</span>
                                                        <span className="text-gray-400 max-w-lg">{ pricing.description }</span>
                                                    </div>
                                                </td>
                                                <td>{ pricing.currency }</td>
                                                <td><span className="whitespace-nowrap">{ formatTerm(pricing.term) }</span></td>
                                                <td><span className="whitespace-nowrap">{ pricing.bundle_product_pricings.length } prices</span></td>
                                                <td><Link onClick={(event) => navigateToSubscriptionListForPricing(event, pricing)}>{ pricing.num_subs } active</Link></td>
                                            </MapleTable.TR>
                                        )
                                    }
                                    {
                                        hasMore &&
                                        <tr>
                                            <td colSpan={10} className="text-center">
                                                <div className="spinner-border text-secondary"/>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </Section>
                </InfiniteScroll>
                <AddOrEditBundlePricingModal
                    show={showAddOrEditBundlePricingModal}
                    onClose={onModalClose}
                    itemToEdit={bundlePricingToEdit}
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default BundlePricings;
