import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import React, { useContext, useState, useEffect } from 'react';
import {
    BaseContext,
    currencyFormatFromPrice,
    getErrorMessageForTaxCalculationErrors,
    percentageFormat
} from '../../helpers/common';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
import Label from "../Label";
import Link from "../Link";
import moment from 'moment';
const _ = require("lodash");

function InvoiceTaxModal(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [invoice, setInvoice] = useState({});

    useEffect(() => {
        if (props.invoice) {
            setInitialFields({
                ...props.invoice
            })
        } else {
            setInitialFields({});
        }
        setInvoice(props.invoice)
    }, [props.invoice])

    const renderTaxCalculationState = (details) => {
        if (_.isNil(details.tax_calculation_state)) {
            return <Label.Info>Pending</Label.Info>
        } else if (details.tax_calculation_state === "SUCCESS") {
            return <Label.Success>Success</Label.Success>
        } else if (details.tax_calculation_state === "ERROR") {
            return <Label.Danger>Error</Label.Danger>
        } else {
            return <Label.Info>{ details.tax_calculation_state }</Label.Info>
        }
    }

    const renderTaxable = (details) => {
        if (details.taxable) {
            return <Label.Success>Yes</Label.Success>
        } else {
            return <Label.Info>No</Label.Info>
        }
    }

    const recomputeTaxes = () => {
        props.onClose();
        if (props.recomputeTaxes) {
            props.recomputeTaxes();
        }
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form initialFormFields={initialFields}>
                <BaseSideModal.Header title={"Invoice - Tax Details"}/>
                <BaseSideModal.Body>
                    {
                        !_.isEmpty(invoice.tax_details) &&
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="!p-0"><span className="font-semibold">Calculation Date:</span></td>
                                        <td className="!py-0"><span>{ moment(invoice.tax_details.tax_calculation_date).format("DD MMM, YYYY h:mm:ssa") }</span></td>
                                    </tr>
                                    <tr>
                                        <td className="!p-0"><span className="font-semibold">Calculation Status:</span></td>
                                        <td className="!py-0"><span>{ renderTaxCalculationState(invoice.tax_details) }</span></td>
                                    </tr>
                                    {
                                        invoice.tax_details.tax_calculation_state === "ERROR" &&
                                        <>
                                            <tr>
                                                <td className="py-0">
                                                    <span className="font-semibold">Error Reason:</span>
                                                </td>
                                                <td className="py-0">
                                                    <span>{ getErrorMessageForTaxCalculationErrors(invoice.tax_details.tax_calculation_error_reason) }</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Button onClick={() => recomputeTaxes()}>Recompute taxes</Button>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </>
                                    }
                                </tbody>
                            </table>
                    }
                    {
                        !_.isEmpty(invoice.line_items) && !_.isEmpty(invoice.tax_details) &&
                            <div className="mt-4">
                                <span className="text-sm font-semibold">Invoice Line Items:</span>
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <MapleTable.TH>Description</MapleTable.TH>
                                            <MapleTable.TH>Amount</MapleTable.TH>
                                            <MapleTable.TH>Taxable</MapleTable.TH>
                                            <MapleTable.TH>Tax Code</MapleTable.TH>
                                            <MapleTable.TH>Rate</MapleTable.TH>
                                            <MapleTable.TH className="text-end">Tax</MapleTable.TH>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(invoice.line_items, (item, i) =>
                                            <tr key={i}>
                                                <td>{ item.description }</td>
                                                <td>{ currencyFormatFromPrice(item.amount) }</td>
                                                <td>{ renderTaxable(item) }</td>
                                                <td>
                                                    {
                                                        item.product_tax_code ?
                                                            <span>{ item.product_tax_code }</span>
                                                        : <div className="flex flex-row gap-1 items-center">
                                                            <span className="error">Missing</span>
                                                            <Link href={getCompanySpecificUrl(`/integrations/${invoice.tax_details.tax_integration_id}/settings`)}>Update</Link>
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        item.tax_details && _.map(_.filter(item.tax_details.tax_details, (t, j) => t.rate !== 0), (dt, j) =>
                                                            <Label.Info key={j}>{ dt.name }, { percentageFormat(dt.rate) }</Label.Info>
                                                        )
                                                    }
                                                </td>
                                                <td className="text-end">{ item.tax_details && currencyFormatFromPrice(item.tax_details.tax_amount) }</td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                            </div>
                    }
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button onClick={() => props.onClose()}>Close</Button>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default InvoiceTaxModal;
