import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverFetch } from "../../server";
const _ = require('lodash');

function useGetCreditBalance({ customerID, options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [creditBalance, setCreditBalance] = useState(undefined);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = () => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverFetch(
            getApiUrl(`/credits/check_balance/${customerID}`),
            options
        ).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                res.isEmpty = _.isEmpty(res.total_amount) && _.isEmpty(res.total_units);
                delete res["active_credits"];
                setCreditBalance(res);
            }
        });
    };

    return {
        creditBalance,
        isCreditBalanceLoading: isLoading,
        onCreditBalanceSearch: onSearch,
    };
}

export default useGetCreditBalance;
