import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverFetch } from "../../server";

function useGetCustomerPaymentMethodsApi({ customerID, options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [customerPaymentMethods, setCustomerPaymentMethods] = useState(undefined);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = () => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverFetch(getApiUrl(`/customers/${customerID}/payment_methods`), options).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                setCustomerPaymentMethods(res.methods);
            }
        });
    };

    return {
        customerPaymentMethods,
        isCustomerPaymentMethodsLoading: isLoading,
        onCustomerPaymentMethodsSearch: onSearch,
    };
}

export default useGetCustomerPaymentMethodsApi;
