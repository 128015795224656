import '../../App.scss';
import React, { useEffect, useContext } from 'react';
import {BaseContext, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import {Carousel} from "flowbite-react";
import {Button} from "react-bootstrap";
import {DocumentTextIcon, PrinterIcon, UserPlusIcon} from "@heroicons/react/24/outline";
import {useNavigate} from "react-router-dom";
const _ = require('lodash');

function GetStarted() {
    const { userInfo } = useContext(UserContext);
    const { company, getApiUrl, setPageTitle, hasAccess } = useContext(BaseContext);

    useEffect(() => {
        setPageTitle("Get Started");
    }, []);

    const navigateTo = (newLink) => {
        console.log("Navigating to " + newLink)
        window.open(newLink, '_blank');
    }

    return (
        <BaseContainer>
            <div className="w-full min-w-[700px] mx-auto md:px-6">
                <div className="flex flex-col gap-12 pb-10">
                    <ContentContainer title="Get Started">
                        <div className="flex flex-row w-full gap-4 h-96">
                            <div className="basis-3/4 h-full">
                                <Carousel>
                                    <img src="https://flowbite.com/docs/images/carousel/carousel-1.svg" alt="..." />
                                    <img src="https://flowbite.com/docs/images/carousel/carousel-2.svg" alt="..." />
                                    <img src="https://flowbite.com/docs/images/carousel/carousel-3.svg" alt="..." />
                                </Carousel>
                            </div>
                            <div className="basis-1/4 h-full pl-2">
                                <div className="flex flex-col h-full gap-1 items-stretch">
                                    <ActionCard
                                        text={"Invite a team member to help"}
                                        icon={<UserPlusIcon className="h-5 w-5"/>}
                                        buttonText={"Invite Team Member"}
                                    />
                                    <hr/>
                                    <ActionCard
                                        text={"Didn't find what you're looking for?"}
                                        icon={<DocumentTextIcon className="h-5 w-5"/>}
                                        buttonText={"Read our docs"}
                                    />
                                    <hr/>
                                    <ActionCard
                                        text={"Some other thing interesting here?"}
                                        icon={<DocumentTextIcon className="h-5 w-5"/>}
                                        buttonText={"Read our docs"}
                                    />
                                </div>
                            </div>
                        </div>
                    </ContentContainer>
                    <ContentContainer title="I want to...">
                        <div className="flex flex-row w-full gap-3">
                            <InfoCard>
                                <div className="flex flex-col items-center gap-4">
                                    <PrinterIcon className="text-slate-400 h-20 w-20"/>
                                    <div className="text-center text-gray-700 min-h-[44px]">
                                        <strong>Create and Manage</strong> Credit-based billing
                                    </div>
                                    <Button variant="outline" className="flex flex-row gap-1" onClick={() => navigateTo("https://maplebilling.com/blog/credits-based-billing")}>
                                        <UserPlusIcon className="h-5 w-5"/><span>Read our docs</span>
                                    </Button>
                                </div>
                            </InfoCard>
                            <InfoCard>
                                <div className="flex flex-col items-center gap-4">
                                    <PrinterIcon className="text-slate-400 h-20 w-20"/>
                                    <div className="text-center text-gray-700 min-h-[44px]">
                                        <strong>Seamless</strong> Contract Management
                                    </div>
                                    <Button variant="outline" className="flex flex-row gap-1" onClick={() => navigateTo("https://maplebilling.readme.io/reference/contracts-1")}>
                                        <DocumentTextIcon className="h-5 w-5"/><span>Read our docs</span>
                                    </Button>
                                </div>
                            </InfoCard>
                            <InfoCard>
                                <div className="flex flex-col items-center gap-4">
                                    <PrinterIcon className="text-slate-400 h-20 w-20"/>
                                    <div className="text-center text-gray-700 min-h-[44px]">
                                        <strong>Easy</strong> Usage-based billing
                                    </div>
                                    <Button variant="outline" className="flex flex-row gap-1" onClick={() => navigateTo("https://maplebilling.readme.io/reference/kickstart-usage-based-billing-in-maple")}>
                                        <UserPlusIcon className="h-5 w-5"/><span>Read our docs</span>
                                    </Button>
                                </div>
                            </InfoCard>
                            <InfoCard>
                                <div className="flex flex-col items-center gap-4">
                                    <PrinterIcon className="text-slate-400 h-20 w-20"/>
                                    <div className="text-center text-gray-700 min-h-[44px]">
                                        <strong>Create and Manage</strong> Credit-based billing
                                    </div>
                                    <Button variant="outline" className="flex flex-row gap-1" onClick={() => navigateTo("https://maplebilling.com/blog/credits-based-billing")}>
                                        <UserPlusIcon className="h-5 w-5"/><span>Read our docs</span>
                                    </Button>
                                </div>
                            </InfoCard>
                        </div>
                    </ContentContainer>
                </div>
            </div>
        </BaseContainer>
    );
}

function InfoCard(props) {
    return <div className="basis-1/4 bg-white rounded-md shadow-sm ring-1 ring-black ring-opacity-5 px-2 py-4">
        { props.children }
    </div>
}

function ActionCard(props) {
    return <div className="basis-1/3 flex flex-col gap-2 justify-center items-center">
        <div className="text-base text-gray-700 font-semibold">{ props.text }</div>
        <Button variant="text" className="flex flex-row gap-1">
            { props.icon }<span>{ props.buttonText }</span>
        </Button>
    </div>
}

export default GetStarted;
