import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverPost } from "../../server";

function useGetSubscriptionPmpUsage({ subscriptionId, pmpId, options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [pmpUsage, setPmpUsage] = useState(undefined);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = () => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverPost(
            getApiUrl(`/subscriptions/${subscriptionId}/usage`),
            {
                product_metric_pricing_id: pmpId,
            },
            options
        ).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                setPmpUsage(res);
            }
        });
    };

    return {
        pmpUsage,
        isPmpUsageLoading: isLoading,
        onPmpUsageSearch: onSearch,
    };
}

export default useGetSubscriptionPmpUsage;
