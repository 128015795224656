import '../../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { serverFetch, serverPost } from '../../../helpers/server';
import {
    BaseContext, currencyFormatFromPrice, getCustomerNameOrEmail,
    IntegrationContext
} from '../../../helpers/common';
import Label from "../../../components/Label";
import Columns from '../../../components/Columns';
import ContentContainer from '../../../components/ContentContainer';
import Section from '../../../components/Section';
import MapleTable from '../../../components/MapleTable';
import InfiniteScroll from "react-infinite-scroll-component";
import MapleTableHeaderWithActions from "../../../components/MapleTableHeaderWithActions";
import SectionNav from "../../../components/SectionNav";
import Link from "../../../components/Link";
import {Button} from "react-bootstrap";
import UpdateIntegrationReferenceModal from "../../../components/modals/UpdateIntegrationReferenceModal";
const _ = require('lodash');

function IntegrationReferences() {
    const navigate = useNavigate();
    let { entity } = useParams();
    entity = _.upperCase(entity);
    const { integration, getIntegrationSpecificUrl } = useContext(IntegrationContext);
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl, hasAccess } = useContext(BaseContext);
    const [references, setReferences] = useState([]);
    const [referenceToEdit, setReferenceToEdit] = useState(null);
    const [settings, setSettings] = useState({});
    const [showUpdateIntegrationReferenceModal, setShowUpdateIntegrationReferenceModal] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [meta, setMeta] = useState({});
    const [query, setQuery] = useState(null)

    useEffect(() => {
        setPageTitle(`Integration Sync`);
    }, []);

    useEffect(() => {
        serverFetch(getApiUrl(`/settings`)).then((res) => {
            setSettings(res)
        })
    }, []);

    useEffect(() => {
        onSearch(true);
    }, [entity]);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort || "createdAtDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                feature: "ACCOUNTING",
                reference_type: entity,
                states: ["PENDING_REMOTE_CREATE", "ACTIVE", "CONFLICT"],
                search: query
            },
            include_meta: restart
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/references/find`), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setReferences(results);
                    setMeta(res.meta);
                } else {
                    setReferences(_.concat(references, results));
                }
                setLoading(false);
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, [sort, query]);

    const updateReference = (row) => {
        setReferenceToEdit(row);
        setShowUpdateIntegrationReferenceModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowUpdateIntegrationReferenceModal(false);
        if (didUpdate) {
            onSearch(true);
        }
    }

    const tabItems = [{
        'label': 'Customers',
        'href': getIntegrationSpecificUrl("/references/customer"),
        'id': 'customers',
    }, {
        'label': 'Invoices',
        'href': getIntegrationSpecificUrl("/references/invoice"),
        'id': 'invoices',
    }, {
        'label': 'Payments',
        'href': getIntegrationSpecificUrl("/references/payment"),
        'id': 'payments',
    }]

    const onParamsChange = (data) => {
        setQuery(data.search);
    }

    const renderMapleEntity = (row) => {
        if (entity === "CUSTOMER") {
            return (
                <>
                {
                    row.customer ?
                        <Columns.CustomerName hideAvatar customer={row.customer} />
                    : <span>{ row.id }</span>
                }
                </>
            )
        } else if (entity === "INVOICE") {
            return (
                <>
                    {
                        row.invoice &&
                            <Link href={getCompanySpecificUrl(`/invoice/${row.invoice.uuid}`)}>{ row.invoice.number }</Link>
                    }
                </>
            )
        } else if (entity === "PAYMENT") {
            return (
                <>
                    {
                        row.payment &&
                        <Link href={getCompanySpecificUrl(`/payment/${row.payment.id}`)}>{ currencyFormatFromPrice(row.payment.total) }</Link>
                    }
                </>
            )
        }
    }

    const renderRemoteData = (row) => {
        if (!row.remote_data) {
            return null;
        }
        return (
            <div>
                <div className="flex flex-col justify-center">
                    <p className="font-medium text-gray-700">{ row.remote_data.name }</p>
                    <p className="font-normal text-gray-500 break-all">{ row.remote_data.id }</p>
                </div>
            </div>
        )
    }

    const renderActions = (row) => {
        if (row.state === "PENDING_REMOTE_CREATE") {
            return (
                <div>
                    <div className="flex flex-row justify-center">
                        <Button variant="text-primary" onClick={() => updateReference(row)}>Match</Button>
                    </div>
                </div>
            )
        } else if (row.state === "CONFLICT") {
            return (
                <div>
                    <div className="flex flex-row justify-center">
                        <Button variant="text-primary" onClick={() => updateReference(row)}>Resolve</Button>
                    </div>
                </div>
            )
        }
    }

    const renderState = (row) => {
        if (row.state === "PENDING_REMOTE_CREATE") {
            return <Label.Info>Missing in {integration.name}</Label.Info>
        } else if (row.state === "ACTIVE") {
            return <Label.Success>Synced</Label.Success>
        } else if (row.state === "CONFLICT") {
            return <Label.Danger>Conflict</Label.Danger>
        } else {
            return <Label.Info>{row.state}</Label.Info>
        }
    }

    return (
        <ContentContainer>
            <InfiniteScroll
                dataLength={references.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                <Section title="" loading={loading}>
                    <SectionNav size="sm" items={tabItems} shouldAutoMatch={true} />
                    <MapleTable className="mt-2">
                        <MapleTableHeaderWithActions
                            showSearch={true}
                            searchPlaceholder="Search References"
                            showFilters={false}
                            meta={meta}
                            onParamsChange={onParamsChange}
                        />
                        <MapleTable.Content>
                            <thead>
                                <tr>
                                    <MapleTable.TH>Name</MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                    <MapleTable.TH></MapleTable.TH>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {
                                _.map(references, (row, i) =>
                                     <tr key={i}>
                                         <td>{ renderMapleEntity(row) }</td>
                                         <td>{ renderState(row) }</td>
                                         <td>{ renderRemoteData(row) }</td>
                                         <td className="w-px whitespace-nowrap">{ renderActions(row) }</td>
                                     </tr>
                                )
                            }
                            {
                                hasMore &&
                                    <tr>
                                        <td colSpan="10" className="text-center">
                                            <div className="spinner-border text-secondary"/>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </MapleTable.Content>
                    </MapleTable>
                </Section>
            </InfiniteScroll>
            <UpdateIntegrationReferenceModal
                show={showUpdateIntegrationReferenceModal}
                onClose={onModalClose}
                feature={"ACCOUNTING"}
                reference_type={entity}
                reference={referenceToEdit}
            />
        </ContentContainer>
    );
}

export default IntegrationReferences;
