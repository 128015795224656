import React from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import { serverPost, getUrl } from '../../helpers/server';
import SuperContainer from '../../components/SuperContainer';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import ContentBox from '../../components/ContentBox';
import {Button, Row} from 'react-bootstrap';
import Link from "../../components/Link";

function Tasks() {

    const runMRRSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_mrr_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    const runStripeSyncSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_payment_provider_sync_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    const runInvoiceDatesSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_invoice_dates_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("MRR Sanity Checks");
            }
        });
    }

    const runPricingProductSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_pricing_product_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("Pricing Product Checks");
            }
        });
    }
    
    const runCreditLogSanityCheckForCompany = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_credit_log_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("Credit Log Sanity Check for company");
            }
        });
    }

    const runSubscriptionMethodRecordsSanityCheck = (data) => {
        data.always_email = true;
        serverPost(getUrl("/super_user/run_subscription_metric_records_sanity_check"), data).then((res) => {
            if (res) {
                // do something
                console.log("Subscription Metric Records Sanity Checks");
            }
        });
    }
    
    return (
        <SuperContainer>
            <ContentBox>
                <ContentBox.Body>
                    <h3>List Pending Tasks</h3>
                    <Link href={"/internal/super/pending_tasks"}><Button>List Tasks</Button></Link>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <h3>List Scheduled Tasks</h3>
                    <Link href={"/internal/super/scheduled_tasks"}><Button>List Tasks</Button></Link>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runMRRSanityCheck}>
                        <h3>Run MRR Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runStripeSyncSanityCheck}>
                        <h3>Run Payment Provider Sync Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runInvoiceDatesSanityCheck}>
                        <h3>Run Invoice Dates Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runPricingProductSanityCheck}>
                        <h3>Run Pricing Product Match Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runCreditLogSanityCheckForCompany}>
                        <h3>Run Credit Log Sanity Check For Company</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
            <ContentBox>
                <ContentBox.Body>
                    <BaseForm onSubmit={runSubscriptionMethodRecordsSanityCheck}>
                        <h3>Run Subscription Metric Records Sanity Check</h3>
                        <Row>
                            <BaseForm.Input colSpan="4" type="text" label="Company ID" name="company_id" required/>
                        </Row>
                        <SubmitButton>Run Check</SubmitButton>
                    </BaseForm>
                </ContentBox.Body>
            </ContentBox>
        </SuperContainer>
    );
}

export default Tasks;
