import '../App.scss';
import '../css/contract.scss';
import React, { useEffect, useState, useContext } from 'react';
import { serverFetch } from '../helpers/server';
import { BaseContext, PaymentLinkContext } from '../helpers/common';
import Loader from '../components/Loader';
import { useParams, Outlet, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

function PaymentLinkComponent() {
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const outletContext = useOutletContext();

    const { getApiUrl } = useContext(BaseContext);
    const [paymentLink, setPaymentLink] = useState(null);
    const [paymentLinkLoading, setPaymentLinkLoading] = useState(true);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchData();
        }
    }, [uuid])

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/proposals/${uuid}`), { suppressUnauthenticated: true, skipCache: skipCache }).then((res) => {
            setPaymentLinkLoading(false);
            if (res) {
                setPaymentLink(res);
            } else {
                setPaymentLink(null);
            }
        })
    }

    const renderNotFound = () => {
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-cart-shopping danger-color fa-4x"/>
                    <h3 className="gray4">Payment Link/Checkout Session not found</h3>
                    <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, we can't find the link you are looking for. Please reach out to the person who send you the link.</div>
                </div>
            </div>
        )
    }

    const renderCheckoutSessionExecuted = () => {
        return (
            <div className="contract-wrapper">
                <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center gap-3">
                    <i className="fa fa-thin fa-cart-shopping danger-color fa-4x"/>
                    <h3 className="gray4">Checkout Session completed.</h3>
                    <div className="gray3 body1 text-center" style={{ maxWidth: "400px" }}>Oops, this checkout session has already been completed. Please request a new link.</div>
                </div>
            </div>
        )
    }

    const checkoutSessionExecuted = !_.isNil(paymentLink) && paymentLink.type === "CHECKOUT_SESSION" && paymentLink.status === "EXECUTED";
    const allGood = !_.isNil(paymentLink) && !checkoutSessionExecuted;
    return (
        <PaymentLinkContext.Provider value={{
            paymentLink
        }}>
            {
                !paymentLinkLoading &&
                <>
                    { _.isNil(paymentLink) && renderNotFound() }
                    { !_.isNil(paymentLink) && checkoutSessionExecuted && renderCheckoutSessionExecuted() }
                    { allGood && <Outlet context={outletContext} /> }
                </>
            }

        </PaymentLinkContext.Provider>
    )
}

export default PaymentLinkComponent;
