import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Row, Alert } from 'react-bootstrap';
const _ = require("lodash");

function SingleInvoiceEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});

    useEffect(() => {
        setInitialFields({
            email: props.invoice && props.invoice.customer && props.invoice.customer.email,
            cc_email: props.invoice && props.invoice.customer && _.join(props.invoice.customer.billing_emails, ",")
        })
    }, [props.invoice])

    const onSubmit = (data) => {
        const emails = _.filter(_.map(data.email.split(","), (e) => e.trim()), e => e);
        const ccEmails = _.filter(_.map(data.cc_email.split(","), (e) => e.trim()), e => e);
        const emailData = {
            emails: emails,
            cc_emails: ccEmails,
            attach_pdf: data.attachPDF,
            message: data.message
        };
        serverPost(getApiUrl(`/invoices/${props.invoice.uuid}/send_email`), emailData).then((res) => {
            props.onClose();
        });
    }

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={initialFields} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Send Invoice Email</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Row>
                        <Alert variant="info">
                            Feel free to add as many emails as you like. Separate each email with a comma such as "one@maplebilling.com, two@maplebilling.com, three@maplebilling.com"
                        </Alert>
                        <BaseForm.Input colSpan={12} name="email" label="Email Addresses" type="text" placeholder="Email addresses" transformations={["lowercase"]} validations={{required: true, validEmails: true}} />
                        <BaseForm.Input colSpan={12} name="cc_email" description="The cc emails will be added to each of the emails sent to the addresses above" label="CC Email Addresses" type="text" placeholder="CC Email addresses" transformations={["lowercase"]} validations={{validEmails: true}} />
                        <BaseForm.Input colSpan={12} name="message" label="Customer Message (Optional)" type="textarea" placeholder="Your email message to the customer" />
                        <BaseForm.Input colSpan={12} name="attachPDF" value="Attach PDF" type="check" />
                    </Row>
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton>Send</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default SingleInvoiceEmailModal;
