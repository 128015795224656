import '../../../App.scss';
import React, { useEffect, useContext, useState } from 'react';
import { serverPost } from '../../../helpers/server';
import { BaseContext, IntegrationContext } from '../../../helpers/common';
import ContentContainer from '../../../components/ContentContainer';
import ContentBox from '../../../components/ContentBox';
import Label from '../../../components/Label';
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Table } from 'react-bootstrap';
import moment from 'moment';
import MapleTable from "../../../components/MapleTable";
const _ = require('lodash');

function Syncs() {
    const { getApiUrl, getCompanySpecificUrl, setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    const [loading, setLoading] = useState(true);
    const [sort, setSort] = useState("createdAtDesc");
    const [query, setQuery] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [syncs, setSyncs] = useState([]);

    useEffect(() => {
        setPageTitle(`${integration.name} - Syncs`);
    }, [integration]);

    const onSearch = (restart = true) => {
        const limit = 50;
        const params = {
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                feature: "ACCOUNTING"
            }
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/syncs/find`), params).then((res) => {
            setLoading(false);
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setSyncs(results);
                } else {
                    setSyncs(_.concat(syncs, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    };

    useEffect(() => {
        onSearch(true);
    }, []);

    const onStartSync = () => {
        if (integration.enabled_features.length === 0) {
            return;
        }
        // TODO(aswin): Make this feature specific page?
        const feature = integration.enabled_features[0];
        const data = {
            feature: feature.toUpperCase(),
            sync_type: "INITIAL"
        }
        serverPost(getApiUrl(`/integrations/${integration.id}/trigger_sync`), data, {}).then((res) => {

        });
    }

    const renderStatus = (sync) => {
        if (sync.status === "IN_PROGRESS") {
            return (<Label.Info>In Progress</Label.Info>);
        } else if (sync.status === "SUCCESS") {
            return (<Label.Success>Succeeded</Label.Success>);
        } else if (sync.status === "FAIL") {
            return (<Label.Danger>Failed</Label.Danger>);
        } else if (sync.status === "ENQUEUED") {
            return (<Label.Info>Queued</Label.Info>);
        }
    }

    return (
        <ContentContainer>
            <div className="d-flex flex-row gap-3">
                <div className="flex-grow-1 text-end">
                    <Button variant="primary" onClick={onStartSync}>Start Manual Sync</Button>
                </div>
            </div>
            <br/>
            <h5>Syncs</h5>
            <InfiniteScroll
                dataLength={syncs.length}
                next={() => onSearch(false)}
                hasMore={hasMore}
                scrollableTarget="content-wrapper"
            >
                <MapleTable>
                    <MapleTable.Content>
                    <thead>
                        <tr>
                            <MapleTable.TH>Start Time</MapleTable.TH>
                            <MapleTable.TH>Status</MapleTable.TH>
                            <MapleTable.TH>Progress</MapleTable.TH>
                            <MapleTable.TH></MapleTable.TH>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(syncs, (sync, i) =>
                            <tr key={i}>
                                <td>{ moment(sync.created_at).format("MMM D, YYYY h:mm:ssa") }</td>
                                <td>{ renderStatus(sync) }</td>
                                <td>{ sync.progress*100 }%</td>
                            </tr>
                        )
                    }
                    </tbody>
                    </MapleTable.Content>
                </MapleTable>
            </InfiniteScroll>
        </ContentContainer>
    );
}

export default Syncs;
