import { useContext, useEffect, useMemo, useState } from "react";
import { CustomerContext } from "../helpers/common";
import BaseForm from "./BaseForm";
import Loader from "./Loader";
import Chart from "./Chart";
import moment from "moment/moment";
import useGetSubscriptionPmpUsage from "../helpers/hooks/api/useGetSubscriptionPmpUsage";
import { Col, Row } from "react-bootstrap";
const _ = require("lodash");

function CustomerPortalSubscriptionUsageChart({ subscription }) {
    const { accessToken } = useContext(CustomerContext);

    const customMetricPricings = subscription.product_pricings
        .flatMap((pp) => pp.product_metric_pricings)
        .filter(
            (pmp) =>
                pmp.metric.type === "CUSTOM_METRIC" &&
                !_.startsWith(pmp.metric.metering_rule.aggregator, "OBJ_") &&
                !_.includes(["CUSTOMER_LAST_EVER", "LAST_EVER_SUM"], pmp.metric.metering_rule.aggregator)
        );
    const customMetricPricingOptions = customMetricPricings.map((customMetricPricing) => ({
        value: customMetricPricing.id,
        label: customMetricPricing.metric.name,
    }));
    const [customMetricPricing, setCustomMetricPricing] = useState(null);

    const {
        pmpUsage: customMetricPricingUsage,
        isPmpUsageLoading: isCustomMetricPricingUsageLoading,
        onPmpUsageSearch: onCustomMetricPricingUsageSearch,
    } = useGetSubscriptionPmpUsage({
        subscriptionId: subscription.id,
        pmpId: customMetricPricing?.id,
        options: { accessToken: accessToken },
    });
    const customMetricPricingUsagePeriodOptions = useMemo(() => {
        if (!customMetricPricingUsage) {
            return [];
        }

        const pastAndCurrentUsagePeriods = customMetricPricingUsage?.usage.filter((u) => moment(u.period.end_date).isBefore(moment())).reverse();
        const usagePeriods = pastAndCurrentUsagePeriods.length ? pastAndCurrentUsagePeriods : [_.first(customMetricPricingUsage?.usage)]; // if no past periods, show the first future period
        return usagePeriods.map((usagePeriod) => {
            return {
                value: usagePeriod.period.start_date,
                label: `${moment(usagePeriod.period.start_date).format("MMM D, YYYY")} - ${moment(usagePeriod.period.end_date).format("MMM D, YYYY")}`,
            };
        });
    }, [customMetricPricingUsage]);
    const [customMetricPricingUsagePeriod, setCustomMetricPricingUsagePeriod] = useState(null);

    useEffect(() => {
        if (!customMetricPricing) {
            return;
        }
        onCustomMetricPricingUsageSearch();
    }, [customMetricPricing]);

    const onFieldChange = (name, value) => {
        if (name === "product_metric_pricing") {
            setCustomMetricPricing(_.find(customMetricPricings, (pmp) => pmp.id === value));
        } else if (name === "period_start") {
            setCustomMetricPricingUsagePeriod(_.find(customMetricPricingUsage?.usage, (u) => u.period.start_date === value));
        }
    };

    return (
        <>
            <BaseForm onFieldChange={onFieldChange}>
                <Row>
                    <Col>
                        <BaseForm.Input type="select" name="product_metric_pricing" label="Metric" options={customMetricPricingOptions} showSearch={false} />
                    </Col>
                    <Col>
                        <BaseForm.Input type="select" name="period_start" label="Period" options={customMetricPricingUsagePeriodOptions} showSearch={false} />
                    </Col>
                </Row>
            </BaseForm>
            {customMetricPricingOptions.length ? (
                isCustomMetricPricingUsageLoading || !customMetricPricingUsagePeriod ? (
                    <Loader loading={true} />
                ) : (
                    <Chart
                        xKey={(d) => d.period}
                        data={[customMetricPricingUsagePeriod.breakdown]}
                        onDatesChange={(start, end) => {}}
                        defaultDateRange={"This Month"}
                        lines={[
                            {
                                key: "value",
                                label: customMetricPricing.metric.name,
                                type: "integer",
                                getValue: (d) => d["value"],
                                visible: true,
                            },
                        ]}
                        summaryAggregator={"last"}
                        chartType={"line"}
                        frequency={"DAY"}
                        hideHeader
                    />
                )
            ) : (
                <div>
                    <span className="text-sm text-gray-400">No records found to show subscription usage.</span>
                </div>
            )}
        </>
    );
}

export default CustomerPortalSubscriptionUsageChart;
