import '../App.scss';
import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/20/solid'
import {useNavigate} from "react-router-dom";
import { renderChildren } from "../helpers/common";

const _ = require('lodash');

function DropdownMenu(props) {
    const [hideCaret, setHideCaret] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!_.isNil(props.hideCaret)) {
            setHideCaret(props.hideCaret);
        } else {
            setHideCaret(false);
        }
    }, [props.hideCaret])

    const handleClick = (event, action, close) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (action.onClick) {
            action.onClick();
            close();
        } else if (action.link) {
            navigate(action.link);
        } else if (props.onClick && action.id) {
            props.onClick(action.id);
            close();
        }
    }

    const onButtonClick = (event) => {
        if (event) {
            event.stopPropagation();
        }
    }

    return (
        <Menu as="div" className={classnames("relative inline-block text-left text-sm", props.className)}>
            {({ open, close }) => (
                <>
                <Menu.Button onClick={onButtonClick} className="flex flex-row items-center text-gray-700 hover:text-gray-900 focus:outline-none outline-none">
                    <span className="sr-only">Open options</span>
                    { renderChildren(props) }
                    { !hideCaret && <>{ open && false ? <ChevronLeftIcon className="h-4 w-4"/> : <ChevronDownIcon className="h-4 w-4"/> }</> }
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={classnames(props.fullWidth ? "w-full": "w-56", props.alignDropdown === "left" ? "left-0": "right-0", "absolute z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none")}>
                        <div className="py-1">
                            {
                                _.map(props.items, (item, i) => {
                                    if (!item) {
                                        return null;
                                    } else if (item.divider) {
                                        return (<hr key={i} className="my-1 bg-gray-400"/>);
                                    } else {
                                        return (
                                            <Menu.Item key={i}>
                                                {({active}) => (
                                                    <a
                                                        href={item.link || "#"}
                                                        className={classnames(
                                                            active ? 'bg-gray-100 text-gray-900 hover:text-gray-900' : 'text-gray-700 hover:text-gray-700',
                                                            'block px-3 py-2 text-sm', item.className
                                                        )}
                                                        onClick={(event) => handleClick(event, item, close)}
                                                    >
                                                        <div className="flex flex-row gap-1 items-center">
                                                            { item.icon && <><i className={"fa " + item.icon}/>&nbsp;</> }
                                                            <span>{item.label}</span>
                                                        </div>
                                                    </a>
                                                )}
                                            </Menu.Item>
                                        );
                                    }
                                })
                            }
                        </div>
                    </Menu.Items>
                </Transition>
                </>
            )}
        </Menu>
    )
}

export default DropdownMenu;
