import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseModal from '../modals/BaseModal';
import { Button } from 'react-bootstrap';
import {QRCodeCanvas} from 'qrcode.react';
const _ = require('lodash');

function PaymentLinkQRCodeModal(props) {
    const downloadQR = () => {
        const canvas = document.getElementById("qr-code");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "payment_link_qr_code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>Your QR Code</BaseModal.Title>
            </BaseModal.Header>
            <BaseModal.Body>
                <div className="flex flex-row justify-center">
                    <div className="max-w-[200px] sm:max-w-[270px]">
                        <QRCodeCanvas
                            id={"qr-code"}
                            size={400}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={props.value}
                            viewBox={`0 0 196 196`}
                        />
                    </div>
                </div>
            </BaseModal.Body>
            <BaseModal.Footer>
                <div className="flex flex-row gap-2">
                    <Button variant={"primary"} onClick={() => downloadQR()}>Download Image</Button>
                </div>
            </BaseModal.Footer>
        </BaseModal>
    );
}

export default PaymentLinkQRCodeModal;
