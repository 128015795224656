import Label from "../components/Label";
import React from "react";


export function renderTypeLabel(payment) {
    if (payment.transaction_type === "PAYMENT") {
        return (<Label.Success>Payment</Label.Success>);
    } else if (payment.transaction_type === "REFUND") {
        return (<Label.Info>Refund</Label.Info>);
    }
    return (
        <Label.Info>{payment.transaction_type}</Label.Info>
    )
}

