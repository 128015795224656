import '../../App.scss';
import '../../css/modals.scss';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import React, { useContext, useState, useEffect } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost } from '../../helpers/server';
import { Button, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
const _ = require("lodash");

function BulkInvoiceEmailModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [initialFields, setInitialFields] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [criteria, setCriteria] = useState(null);
    const [confirmationTextMatches, setConfirmationTextMatches] = useState(true);

    useEffect(() => {
        setInvoices(props.invoices);
    }, [props.invoices]);

    useEffect(() => {
        setCriteria(props.criteria);
    }, [props.criteria]);

    useEffect(() => {
        if (props.numInvoices > 10) {
            setConfirmationTextMatches(false);
        } else {
            setConfirmationTextMatches(true);
        }
    }, [props.numInvoices])

    const onFieldChange = (name, value) => {
        if (name === "confirmationText") {
            setConfirmationTextMatches(value === "SEND EMAILS");
        }
    }

    const onSubmit = (data) => {
        const emailData = {
            message: data.message,
            attach_pdf: data.attachPDF
        }
        if (props.useCriteria) {
            emailData['query'] = criteria;
        } else {
            emailData['UUIDs'] = _.map(invoices, (i) => i.uuid);
        }
        serverPost(getApiUrl(`/invoices/bulk_send_emails`), emailData).then((res) => {
            props.onClose();
        });
    }

    return (
        <BaseModal {...props}>
            <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange} onSubmit={onSubmit}>
                <BaseModal.Header>
                    <BaseModal.Title>Bulk Invoice Email</BaseModal.Title>
                </BaseModal.Header>
                <BaseModal.Body>
                    <Row>
                        <BaseForm.Input colSpan={12} name="message" label="Customer Message (Optional)" type="textarea" placeholder="Your email message to the customer" />
                        <BaseForm.Input colSpan={12} name="attachPDF" value="Attach PDF" type="check" />
                    </Row>
                    <hr className="mb-2"/>
                    <p>Preparing to send { props.numInvoices } invoices. Are you sure you want to send out emails for these invoices? This action can not be undone.</p>
                    {
                        props.numInvoices > 10 &&
                            <div className="mt-3">
                                <p>Please type "<span className="font-semibold">SEND EMAILS</span>" in the box below in order to proceed.</p>
                                <BaseForm.TextGroup name="confirmationText" transformations={["uppercase"]} />
                            </div>
                    }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <div className="d-flex flex-row align-items-end">
                        <SubmitButton disabled={!confirmationTextMatches}>Send</SubmitButton>
                    </div>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default BulkInvoiceEmailModal;
