import '../App.scss';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import BaseModal from './modals/BaseModal';
import BaseForm from './BaseForm';
const _ = require("lodash");

function ConfirmationButton(props) {
    const [confirmationText, setConfirmationText] = useState("");
    const [confirmationTextMatches, setConfirmationTextMatches] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const onFieldChange = (name, value) => {
        if (name === "confirmationText") {
            setConfirmationText(value);
        }
    }

    useEffect(() => {
        if (!props.confirmationText || _.isNil(props.confirmationText)) {
            setConfirmationTextMatches(true);
        } else {
            setConfirmationTextMatches(props.confirmationText.toUpperCase() === confirmationText);
        }
    }, [confirmationText, props.confirmationText]);

    const onConfirm = (event) => {
        if (event) {
            event.stopPropagation();
        }
        setShowModal(false);
        if (props.onConfirm) {
            props.onConfirm();
        }
    }

    const renderModal = () => {
        return (
            <BaseModal show={showModal} onClose={() => setShowModal(false)}>
                <BaseForm onSubmit={() => {}} onFieldChange={onFieldChange}>
                    <BaseModal.Header>
                        <BaseModal.Title>{props.title ? props.title : "Confirm?"}</BaseModal.Title>
                    </BaseModal.Header>
                    <BaseModal.Body>
                        <div className="body1">
                            {props.body ? props.body : "Are you sure you want to proceed?"}
                        </div>
                        {
                            props.confirmationText &&
                                <>
                                    <br/>
                                    <div className="body1">
                                    {
                                        props.confirmationBody ?
                                            <i>{props.confirmationBody}</i>
                                        : <i>Please type the text "{props.confirmationText.toUpperCase()}" in the box below in order to proceed.</i>
                                    }
                                    </div>
                                    <BaseForm.TextGroup name="confirmationText" transformations={["uppercase"]} />
                                </>
                        }
                    </BaseModal.Body>
                    <BaseModal.Footer>
                        <div className="d-flex flex-row align-items-end">
                            <Button variant={ props.buttonVariant || "danger"} onClick={onConfirm} className={props.buttonClassName} disabled={!confirmationTextMatches}>{ props.buttonTitle || "Confirm" }</Button>
                        </div>
                    </BaseModal.Footer>
                </BaseForm>
            </BaseModal>
        )
    }

    const onClick = (event) => {
        if (event) {
            event.stopPropagation();
        }
        setShowModal(true);
    }

    return (
        <>
        <Button variant={props.variant} size={props.size} disabled={props.disabled} onClick={onClick} className={props.className}>{props.children}</Button>
        { renderModal() }
        </>
    );
}

export default ConfirmationButton;
