import '../../App.scss';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { serverFetch, serverPost, serverPatch } from '../../helpers/server';
import {BaseContext, convertVariablesIntoHtml, UserContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import BaseForm from '../../components/BaseForm';
import Notification from '../../components/Notification';
import Breadcrumb from '../../components/Breadcrumb';
import ContentBox from '../../components/ContentBox';
import { Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {getDefaultContractTemplates} from "../../helpers/contracts";
const _ = require('lodash');

function ContractTemplateCreateOrUpdate() {
    const navigate = useNavigate();
    const { t } = useTranslation('common');
    const { uuid } = useParams();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const { isMapleUser } = useContext(UserContext);
    const [template, setTemplate] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [initialFields, setInitialFields] = useState({});
    const [templateFields, setTemplateFields] = useState({});
    const [error, setError] = useState(null);
    const [ searchParams ] = useSearchParams();
    let pid = searchParams.get('pid');
    let name = searchParams.get('name');

    useEffect(() => {
        setPageTitle(`Contract Template - ${template ? template.name: "New"}`);
    }, [template]);

    useEffect(() => {
        serverFetch(getApiUrl(`/proposals/templates/fields`)).then((res) => {
            if (res) {
                if (!isMapleUser) {
                    res = res.filter((obj) => obj.group !== "Counter Signatory")
                }
                setTemplateFields(_.keyBy(res, 'key'))
            }
        })
    }, []);

    useEffect(() => {
        if (!_.isNil(uuid)) {
            fetchTemplate(true);
            setIsEditing(true);
        } else {
            let content = "<div style='line-height: 1.5; font-family: Arial, Helvetica, sans-serif; font-size: 15px; word-break: break-word'></div>"
            if (pid) {
                let templates = getDefaultContractTemplates();
                let foundTemplate = _.find(templates, (te) => String(te.id) === String(pid));
                if (foundTemplate) {
                    content = foundTemplate.template
                }
            }
            setInitialFields({
                name: name,
                content: content
            })
            setIsEditing(false);
        }
    }, [uuid])

    const fetchTemplate = (skipCache=false) => {
        serverFetch(getApiUrl(`/proposals/templates/${uuid}`), { skipCache }).then((res) => {
            setTemplate(res)
            setInitialFields(res);
        })
    }

    const createOrUpdateTemplate = (data) => {
        if (!new RegExp(".*\{\{ *\\.SignatorySignature *\}\}.*").test(data.content)) {
            setError('We couldn\'t find a signature in the template for the signatory. Please add at least one "Signatory Signature" variable in your template.');
            return;
        }
        if (!new RegExp(".*\{\{ *\\.Pricing *\}\}.*").test(data.content)) {
            setError('We couldn\'t find a pricing table in the template. Please add at least one "Pricing Table" variable in your template.');
            return;
        }
        setError(null);
        const templateData = {
            name: data.name,
            content: data.content
        }

        if (isEditing) {
            serverPatch(getApiUrl(`/proposals/templates/${uuid}`), templateData, {}, templateModifyErrorHandler).then((res) => {
                if (res) {
                    Notification.Success(`${res.name} Updated`);
                    fetchTemplate(true);
                } else {
                    Notification.Danger("Error updating template data")
                }
            })
        } else {
            serverPost(getApiUrl('/proposals/templates'), templateData, {}, templateModifyErrorHandler).then((res) => {
                if (res) {
                    navigate(getCompanySpecificUrl(`/contract/template/edit/${res.id}`))
                }
            })
        }
    }

    const templateModifyErrorHandler = (res) => {
        if (res.status === 400) {
            res.json().then((r) => {
                if (r.error_message) {
                    Notification.Danger(r.error_message, 5000);
                }
            })
        } else {
            Notification.Danger("Error updating template data")
        }
    }

    const breadcrumbItems = [
        { name: 'Templates', href: getCompanySpecificUrl(`/settings/contracts`), current: false },
        { name: isEditing ? template.name: "New", current: true }
    ]

    return (
        <BaseContainer>
            <ContentContainer>
                <Breadcrumb items={breadcrumbItems}/>
                <h4>{ isEditing ? "Edit": "Create" } Contract Template</h4>
                <ContentBox>
                    <ContentBox.Body className="scrollable">
                        <div className="contract-content">
                            <BaseForm initialFormFields={initialFields} onSubmit={createOrUpdateTemplate}>
                                <Row>
                                    <BaseForm.Input colSpan="10" name="name" label="Name" type="text" required />
                                    <BaseForm.InputWrapper colSpan="2" className="d-flex align-items-end">
                                        <Button variant="primary" className="btn-complete" type="submit">{ isEditing ? "Update": "Create" }</Button>
                                    </BaseForm.InputWrapper>
                                    <BaseForm.Input colSpan="12" name="content" label="Template" type="editor2" height="800" tokens={templateFields}/>
                                </Row>
                                {
                                    error &&
                                        <div className="form-error-message">{ error }</div>
                                }
                                <Row>
                                    <Col md="12">
                                        <br/>
                                        <Button variant="primary" type="submit">{ isEditing ? "Update": "Create" } Template</Button>
                                    </Col>
                                </Row>
                            </BaseForm>
                        </div>
                    </ContentBox.Body>
                </ContentBox>
                {
                    !_.isEmpty(template) &&
                        <>
                            <h4>{ template.name } - Preview</h4>
                            <div className="content-box">
                                <div className="content-body scrollable">
                                    <div className="contract-content" dangerouslySetInnerHTML={{__html: convertVariablesIntoHtml(template.content) }} />
                                </div>
                            </div>
                        </>
                }
            </ContentContainer>
        </BaseContainer>
    );
}

export default ContractTemplateCreateOrUpdate;
