import '../../App.scss';
import React, { useEffect, useState, useContext, createRef } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import Columns from '../../components/Columns';
import BaseContainer from '../../components/BaseContainer';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import InfiniteScroll from "react-infinite-scroll-component";
import AddOrEditBillableMetricModal from '../../components/modals/AddOrEditBillableMetricModal';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MapleTable from "../../components/MapleTable";
import CopyableComponent from "../../components/CopyableComponent";
const _ = require('lodash');

function BillableObjects() {
    const { company, getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [originalObjects, setOriginalObjects] = useState([]);
    const [objects, setObjects] = useState([]);
    const [billableItems, setBillableItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(true);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [rule, setRule] = useState(null);
    const [ruleChanged, setRuleChanged] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [fromKey, setFromKey] = useState(null);
    const [sort, setSort] = useState("createdAtDesc");
    const [query, setQuery] = useState(null)
    const [showAddOrEditBillableMetricModal, setShowAddOrEditBillableMetricModal] = useState(false);
    const [metricToCreate, setMetricToCreate] = useState(null);
    const formRef = createRef();

    useEffect(() => {
        setPageTitle(`Billable Objects`);
    }, []);

    useEffect(() => {
        serverPost(getApiUrl("/billable/items/find"), { companyId: company.id }).then((res) => {
            setBillableItems(_.filter(res, (r) => r.type === "CUSTOM_OBJ"));
            setLoadingItems(false);
        });
    }, [])

    useEffect(() => {
        if (_.isEmpty(originalObjects) || _.isEmpty(billableItems)) {
            setObjects([]);
            return;
        }
        const processedObjects = [...originalObjects];
        _.each(processedObjects, (o) => {
            o.item = _.find(billableItems, (i) => i.id === o.item_id);
        })
        setObjects(processedObjects);
    }, [originalObjects, billableItems])

    useEffect(() => {
        onSearch(true);
    }, [sort, query, selectedItemId]);

    const onSubmit = (data) => {
        onSearch(true)
    }

    const onSearch = (restart = true) => {
        if (!selectedItemId) {
            return;
        }
        if (restart) {
            setRuleChanged(false);
        }
        const limit = 50;
        const params = {
            company_id: company.id,
            sort_key: sort,
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {
                matching_rule: rule,
                item_id: selectedItemId
            }
        }
        serverPost(getApiUrl("/objects/find"), params).then((res) => {
            if (res) {
                const results = res.results || [];
                if (restart) {
                    setOriginalObjects(results);
                } else {
                    setOriginalObjects(_.concat(objects, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    }

    const onFieldChange = (name, value) => {
        if (name === "sort") {
            setSort(value);
        } else if (name === "query") {
            setQuery(value);
        } else if (name === "rule") {
            setRule(value);
            setRuleChanged(true);
        } else if (name === "item_id") {
            setSelectedItemId(value);
        }
    }

    const convertToMetrics = () => {
        const formData = formRef.current.getFormData();
        const metric = formData.rule;
        setMetricToCreate(metric);
        setShowAddOrEditBillableMetricModal(true);
    }

    const billableItemOptions = _.map(billableItems, (bi) => {
        return { value: bi.id, label: bi.name }
    })

    if (!loadingItems && _.isEmpty(billableItems)) {
        return (
            <BaseContainer>
                <ContentContainer>
                    <Section title="Objects">
                        <div className="d-flex flex-column gap-3">
                            <div className="body1">In order to start managing objects, you will have create a object-based item first. <Link to={getCompanySpecificUrl("/billable_items")}>Get started here.</Link></div>
                        </div>
                    </Section>
                </ContentContainer>
            </BaseContainer>
        );
    }

    return (
        <BaseContainer>
            <ContentContainer>
                <Section title="Objects">
                    {
                        !loadingItems && _.isEmpty(billableItems) &&
                            <p>In order to start managing objects, you will have create a object-based item first. <Link to={getCompanySpecificUrl("/billable_items")}>Get started here.</Link></p>
                    }
                    <BaseForm onFieldChange={onFieldChange} onSubmit={onSubmit} ref={formRef}>
                        <BaseForm.Input colSpan="3" type="select" name="item_id" label={"Item"} options={billableItemOptions} showSearch={false}/>
                        <BaseForm.Input type="rule" name="rule" label="Rule" isColored={false}/>
                        <br/>
                        <div className="d-flex flex-row gap-3">
                            <SubmitButton>Search</SubmitButton>
                            <Button variant="outline-primary" onClick={convertToMetrics}>Convert to metrics</Button>
                        </div>
                    </BaseForm>
                    {
                        ruleChanged &&
                        <p className="body1">The filter has changed so the results might be out of date. Click on "Search" to get updated results.</p>
                    }
                    <hr className="my-2"/>
                    <p className="text-lg font-semibold text-gray-900">Filtered Results</p>
                    <InfiniteScroll
                        dataLength={objects.length}
                        next={() => onSearch(false)}
                        hasMore={hasMore}
                        scrollableTarget="content-wrapper"
                    >
                        {
                            _.isEmpty(objects) ?
                                <span className="text-sm">No matching results for the filter. Try modifying the filter above.</span>
                            : <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                        <tr>
                                            <th>Customer</th>
                                            <th>Item/Identifier</th>
                                            <th className="!pl-0">Properties</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(objects, (row, i) =>
                                             <tr key={i} className="cursor-pointer">
                                                 <td><Columns.CustomerName customer={row.customer} hideAvatar /></td>
                                                 <td className="w-px whitespace-nowrap">
                                                     <div className="flex flex-col gap-2">
                                                         <CopyableComponent value={ row.item_id }/>
                                                         <p className="text-sm text-gray-500">Identifier: { row.object_identifier }</p>
                                                     </div>
                                                 </td>
                                                 <td className="!pl-0">
                                                     <code className="text-xs break-all">{ JSON.stringify(row.properties) }</code>
                                                 </td>
                                             </tr>
                                        )
                                    }
                                    {
                                        hasMore &&
                                            <tr>
                                                <td colSpan={3} className="text-center">
                                                    <div className="spinner-border text-secondary"/>
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                        }
                    </InfiniteScroll>
                </Section>
            </ContentContainer>
            <AddOrEditBillableMetricModal show={showAddOrEditBillableMetricModal} onClose={() => setShowAddOrEditBillableMetricModal(false)}
                metricToCreate={metricToCreate} billableItems={billableItems} />
        </BaseContainer>
    );
}

export default BillableObjects;
