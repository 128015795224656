import '../../../App.scss';
import React, { useEffect, useContext } from 'react';
import {BaseContext, IntegrationContext} from '../../../helpers/common';
import ContentContainer from '../../../components/ContentContainer';
import ContentBox from "../../../components/ContentBox";

function Dashboard() {
    const { setPageTitle } = useContext(BaseContext);
    const { integration } = useContext(IntegrationContext);

    useEffect(() => {
        setPageTitle(`Integrations`);
    }, []);

    return (
        <ContentContainer>
            <ContentBox className="mt-2">
                <ContentBox.Body>
                    <div className="text-base font-semibold mb-4">Dashboard</div>
                </ContentBox.Body>
            </ContentBox>
        </ContentContainer>
    );
}

export default Dashboard;
