import cloudServiceAgreement from '../helpers/contract_templates/cloud_service_agreement';
import ycSales from '../helpers/contract_templates/yc_sales';
const _ = require("lodash");

export function getDefaultContractTemplates() {
    return [{
        id: 1,
        title: "Cloud Service Template",
        path: "/helpers/contract_templates/cloud_service_agreement.txt",
        template: cloudServiceAgreement
    }]
}
/*
    {
        id: 2,
        title: "YC Sales Template",
        path: "/helpers/contract_templates/yc_sales.txt",
        template: ycSales
    }
*/
