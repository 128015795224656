import '../../App.scss';
import '../../css/modals.scss';
import React from 'react';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import BaseSideModal from './BaseSideModal';
import { useState, useEffect, useContext, useMemo } from 'react';
import { BaseContext } from '../../helpers/common';
import { serverPost} from '../../helpers/server';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {Button} from "react-bootstrap";
import classnames from "classnames";
const _ = require('lodash');

function UpdateSubscriptionMetricUsageModal(props) {
    const { t } = useTranslation('common');
    const { company, getApiUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [metric, setMetric] = useState(null);
    const [initialFields, setInitialFields] = useState({});
    const [fromKey, setFromKey] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [records, setRecords] = useState([]);

    useEffect(() => {
        setSubscription(props.subscription);
    }, [props.subscription]);

    useEffect(() => {
        setMetric(props.metric);
        if (props.usage) {
            setInitialFields({
                value: props.usage.value
            })
        }
    }, [props.metric, props.usage]);

    useEffect(() => {
        if (props.show && subscription && metric) {
            fetchRecords(true);
        }
    }, [props.show, subscription, metric])

    const fetchRecords = (restart=false) => {
        const limit = 50;
        const params = {
            sort_key: "startDateDesc",
            pagination: {
                from_key: restart ? null: fromKey,
                limit: limit
            },
            query: {

            }
        }
        serverPost(getApiUrl(`/subscriptions/${subscription.id}/metric_records`), params).then((res) => {
            if (res) {
                let results = res.results || [];
                results = _.filter(results, (r) => moment(r.period.start_date).isBefore(moment()) && r.state !== "LOCKED" && r.metric_id === metric.id);
                if (restart) {
                    setRecords(results);
                } else {
                    setRecords(_.concat(records, results));
                }
                setFromKey(res.pagination.from_key);
                setHasMore(results.length === limit);
            }
        });
    }

    const onSubmit = (data, errorHandler) => {
        const recordId = data.record_id;
        const record = _.find(records, (r) => r.id === recordId);
        if (!record) {
            return;
        }
        const key = metric.metering_rule.property || "id";
        let properties = {};
        properties[key] = data.value;
        let eventTimestamp = moment();
        if (moment(record.period.end_date).isBefore(eventTimestamp)) {
            eventTimestamp = moment(record.period.end_date).add(-1, 'minutes')
        }
        const updateData = {
            events: [{
                company_id: subscription.company.id,
                customer_id: subscription.customer.id,
                item_id: record.item_pricing.item_id,
                timestamp: eventTimestamp,
                transaction_id: String(moment().valueOf()),
                properties: properties,
            }]
        }
        serverPost(getApiUrl(`/events/ingest`), updateData, {}, errorHandler).then(res => {
            // if (res) {
                props.onClose(true);
            // }
        })
    }

    if (!props.show) {
        return;
    }
    return (
        <BaseSideModal {...props}>
            <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <BaseSideModal.Header title={"Update Usage"}/>
                <BaseSideModal.Body>
                    <div className="flex flex-col text-sm divide-y divide-gray-200">
                    {
                        _.map(records, (record, i) =>
                            <div className={classnames("pb-4", i === 0 ? "": "pt-2")} key={i}>
                                <BaseForm initialFormFields={{ record_id: record.id, value: record.metric_meter.aggregate }} onSubmit={onSubmit} >
                                    <p className="font-semibold">Report last value for period: </p>
                                    <span>{ moment(record.period.start_date).format("MMM D, YYYY h:mm:ssa") } - { moment(record.period.end_date).format("MMM D, YYYY h:mm:ssa") }</span>
                                    <div className="py-1 flex flex-row gap-3 items-end">
                                        <BaseForm.Hidden name="record_id"/>
                                        <BaseForm.Input
                                            outerInputClassName="grow"
                                            formClassName="inline" type="number" name="value" label="New Value"
                                        />
                                        <SubmitButton>Update</SubmitButton>
                                    </div>
                                </BaseForm>
                            </div>
                        )
                    }
                    </div>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <Button onClick={() => props.onClose()}>Close</Button>
                </BaseSideModal.Footer>
            </div>
        </BaseSideModal>
    );
}

export default UpdateSubscriptionMetricUsageModal;
