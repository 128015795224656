import '../../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import {serverPost, serverDelete} from '../../../helpers/server';
import {BaseContext, getApprovalRulePropertyMap, getDescriptionForRule, UserContext} from '../../../helpers/common';
import MapleTable from '../../../components/MapleTable';
import Notification from '../../../components/Notification';
import Section from '../../../components/Section';
import ContentContainer from '../../../components/ContentContainer';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AddOrEditApprovalRuleModal from "../../../components/modals/AddOrEditApprovalRuleModal";
import Link from "../../../components/Link";
import DeleteButton from "../../../components/DeleteButton";
import Columns from "../../../components/Columns";
const _ = require('lodash');

function ContractApprovalRules() {
    const { t } = useTranslation('common');
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loading, setLoading] = useState(true);
    const [approvalRules, setApprovalRules] = useState([]);
    const [approvalRuleToEdit, setApprovalRuleToEdit] = useState(null);
    const [showAddOrEditApprovalRuleModal, setShowAddOrEditApprovalRuleModal] = useState(false);

    useEffect(() => {
        setPageTitle(`Contract Approval Rules`);
    }, []);

    useEffect(() => {
        fetchData(true);
    }, []);

    const fetchData = (skipCache=false) => {
        const rulesFindData = {
            query: {
                target_type: "PROPOSAL"
            }
        }
        serverPost(getApiUrl("/approval_rules/find"), rulesFindData).then((res) => {
            setApprovalRules(res);
            setLoading(false);
        });
    }

    const onModalClose = (didUpdate) => {
        setShowAddOrEditApprovalRuleModal(false);
        setApprovalRuleToEdit(null);
        if (didUpdate) {
            fetchData(true)
        }
    }

    const onEditApprovalRule = (rule) => {
        setShowAddOrEditApprovalRuleModal(true);
        setApprovalRuleToEdit(rule);
    }

    const onDeleteApprovalRule = (rule) => {
        serverDelete(getApiUrl(`/approval_rules/${rule.id}`)).then((res) => {
            Notification.Success("Approval rule deleted");
            fetchData(true);
        })
    }

    const renderApprovers = (row) => {
        if (!row.approvers) {
            return <span className="text-gray-400">None</span>
        }

        return <Columns.CustomerAvatarGroup users={_.map(row.approvers, m => m.user)}/>
    }

    return (
        <>
            <ContentContainer>
                <Section
                    title="Approval Rules"
                    className={"mt-4"}
                    loading={loading}
                    actions={[{
                        variant: "primary",
                        icon: "fa-plus",
                        label: "Create Approval Rule",
                        link: getCompanySpecificUrl("/contract/template/create"),
                        onClick: () => setShowAddOrEditApprovalRuleModal(true)
                    }]}>
                    {
                        _.isEmpty(approvalRules) &&
                            <span className="text-gray-500">No contract approval rules setup.</span>
                    }
                    {
                        !_.isEmpty(approvalRules) &&
                            <MapleTable>
                                <MapleTable.Content>
                                    <thead>
                                    <tr>
                                        <MapleTable.TH>Name</MapleTable.TH>
                                        <MapleTable.TH>Rule</MapleTable.TH>
                                        <MapleTable.TH>Approvers</MapleTable.TH>
                                        <MapleTable.TH></MapleTable.TH>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                    {
                                        _.map(approvalRules, (row, i) =>
                                            <tr key={i}>
                                                <td>{ row.name }</td>
                                                <td>{ getDescriptionForRule(row.rule, getApprovalRulePropertyMap())}</td>
                                                <td className="w-px whitespace-nowrap">{ renderApprovers(row) }</td>
                                                <td className="w-px whitespace-nowrap">
                                                    <div className="flex flex-row items-center justify-end">
                                                        <Link onClick={() => onEditApprovalRule(row)}>
                                                            <Button variant="text-primary" size="sm">Edit</Button>
                                                        </Link>
                                                        <DeleteButton size="md" onDelete={() => onDeleteApprovalRule(row)}></DeleteButton>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    </tbody>
                                </MapleTable.Content>
                            </MapleTable>
                    }
                </Section>
            </ContentContainer>
            <AddOrEditApprovalRuleModal
                show={showAddOrEditApprovalRuleModal} onClose={onModalClose} targetType={"PROPOSAL"}
                itemToEdit={approvalRuleToEdit}
            />
        </>
    );
}

export default ContractApprovalRules;
