import '../App.scss';
import React, {useEffect, useState} from "react";
import { renderChildren } from '../helpers/common';
import MapleTable from '../components/MapleTable';
import classnames from "classnames";

const SortableTableHeader = (props) => {
    const [sortOrder, setSortOrder] = useState(null);
    const [sortIcon, setSortIcon] = useState(null);
    const pprops = {...props};
    delete pprops['onSortChange'];
    delete pprops['sortKeyUp'];
    delete pprops['sortKeyDown'];
    delete pprops['currentSort'];
    delete pprops['innerClassName'];

    const onSortClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newSortOrder = null;
        if (sortOrder === props.sortKeyUp) {
            newSortOrder = props.sortKeyDown;
        } else if (sortOrder === props.sortKeyDown) {
            newSortOrder = null;
        } else {
            if (sortOrder === null || (props.currentSort !== props.sortKeyUp && props.currentSort !== props.sortKeyDown)) {
                newSortOrder = props.sortKeyUp;
            }
        }

        if (props.onSortChange) {
            props.onSortChange(newSortOrder)
        }
        setSortOrder(newSortOrder);
    }

    useEffect(() => {
        if (props.currentSort) {
            if (props.currentSort !== props.sortKeyUp && props.currentSort !== props.sortKeyDown) {
                setSortOrder(null);
            } else {
                setSortOrder(props.currentSort);
            }
        } else {
            setSortOrder(null);
        }
    }, [props.currentSort, props.sortKeyUp, props.sortKeyDown])

    useEffect(() => {
        if (sortOrder === null) {
            setSortIcon("unsorted-icon");
        } else if (sortOrder === props.sortKeyUp) {
            setSortIcon("sort-up-icon");
        } else if (sortOrder === props.sortKeyDown) {
            setSortIcon("sort-down-icon");
        }
    }, [sortOrder])

    return (
        <MapleTable.TH  {...pprops} className={classnames("cursor-pointer whitespace-nowrap !pr-1", props.className)}>
            <div className={classnames("flex flex-row items-center", props.innerClassName)} onClick={onSortClick}>
                <div className="">{ renderChildren(props) }</div>
                <div className={classnames("icon-pack", sortIcon)}/>
            </div>
        </MapleTable.TH>
    );
}

export default SortableTableHeader;
