import '../../App.scss';
import '../../css/modals.scss';
import BaseSideModal from './BaseSideModal';
import React, { useContext, useState, useEffect } from 'react';
import {
    BaseContext,
    currencyFormatFromPrice,
    getErrorMessageForTaxCalculationErrors,
    percentageFormat
} from '../../helpers/common';
import {Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MapleTable from "../MapleTable";
import Label from "../Label";
import Link from "../Link";
import moment from 'moment';
import BundlePricingTable from "../BundlePricingTable";
import classnames from "classnames";
const _ = require("lodash");

function SubscriptionHistoryModal(props) {
    const { t } = useTranslation('common');
    const { getCompanySpecificUrl } = useContext(BaseContext);
    const [subscription, setSubscription] = useState(null);
    const [changeHistory, setChangeHistory] = useState([]);

    useEffect(() => {
        setSubscription(props.subscription)
        let cs = [...props.subscription.change_history];
        _.reverse(cs);
        setChangeHistory(cs);
    }, [props.subscription])

    if (!props.show) {
        return null;
    }

    const renderProrationType = (item) => {
        if (item.proration_type === "NONE") {
            return <span className="text-gray-400">No proration</span>
        } else if (item.proration_type === "NEXT") {
            return <span>Charge proration amount in next invoice.</span>
        } else if (item.proration_type === "IMMEDIATE") {
            return <span>Charge proration amount immediately.</span>
        }
    }

    const renderHistoryRow = (item, i) => {
        const isTrial = subscription.trial_conversion_date && moment(subscription.trial_conversion_date).isAfter(moment(item.actual_change_time))
        // const isConvertedTrial = subscription.trial_conversion_date && moment(subscription.trial_conversion_date).isSame(moment(item.actual_change_time))
        return (
            <div key={i} className={classnames("text-sm mb-3", "border-b")}>
                <div className="flex flex-row gap-1">
                    <div className="grow">
                        {
                            item.timing ?
                                <p><span className="font-semibold">Change time: </span>{ item.actual_change_time && moment(item.actual_change_time).format("MMM D, YYYY h:mm:ssa") }</p>
                                : <p><span className="font-semibold">Start Date: </span>{ item.actual_change_time && moment(item.actual_change_time).format("MMM D, YYYY h:mm:ssa") }</p>
                        }
                        {
                            item.proration_type &&
                            <p><span className="font-semibold">Proration: </span>{ renderProrationType(item) }</p>
                        }
                        {
                            !_.isNil(item.reset_billing_anchor) &&
                            <p><span className="font-semibold">Reset Billing Anchor: </span>{ item.reset_billing_anchor ? "True": "False" }</p>
                        }
                    </div>
                    {
                        isTrial &&
                            <div>
                                <Label.Neutral className="mr-2">Trial</Label.Neutral>
                            </div>
                    }
                </div>
                <BundlePricingTable
                    bundlePricing={item.bundle_pricing}
                    oneTimeBillables={item.one_time_billables}
                    configItems={item.config_items}
                    discounts={item.discounts}
                    term={item.term}
                    displayDiscountExpiration={true}
                />
            </div>
        )
    }

    return (
        <BaseSideModal {...props} size="lg">
            <BaseSideModal.Form>
            <BaseSideModal.Header title="Subscription - History"/>
            <BaseSideModal.Body>
                <p className="text-sm mb-4">Note: Changes to the subscription are shown in the reverse chronological order.</p>
                { _.map(changeHistory, (item, i) => renderHistoryRow(item, i)) }
            </BaseSideModal.Body>
            <BaseSideModal.Footer>
                <Button onClick={() => props.onClose()}>Close</Button>
            </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default SubscriptionHistoryModal;
