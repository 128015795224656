import '../../App.scss';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { serverFetch, serverPatch } from '../../helpers/server';
import {
    BaseContext, formatTerm
} from '../../helpers/common';
import { useTranslation } from 'react-i18next';
import BaseContainer from '../../components/BaseContainer';
import KeyValueDisplay2 from '../../components/KeyValueDisplay2';
import ArchiveButton from '../../components/ArchiveButton';
import ContentContainer from '../../components/ContentContainer';
import Section from '../../components/Section';
import BundlePricingTable from "../../components/BundlePricingTable";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import Label from "../../components/Label";
import AddOrEditBundlePricingModal from "../../components/modals/AddOrEditBundlePricingModal";
import KeyValueDisplay from "../../components/KeyValueDisplay";
const _ = require('lodash');

function BundlePricing(props) {
    const { t } = useTranslation('common');
    const { bundlePricingId } = useParams();
    const { getApiUrl, setPageTitle, getCompanySpecificUrl } = useContext(BaseContext);
    const [loadingPrice, setLoadingPrice] = useState(true);
    const [bundlePricing, setBundlePricing] = useState({});
    const [details, setDetails] = useState({});
    const [showAddOrEditBundlePricingModal, setShowAddOrEditBundlePricingModal] = useState(false);
    const [isEditingMetadata, setIsEditingMetadata] = useState(false);

    useEffect(() => {
        setPageTitle(`Pricing - ${bundlePricing.name}`);
    }, [bundlePricing]);

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl(`/bundle_pricings/${bundlePricingId}`), { skipCache }).then((res) => {
            if (res) {
                setBundlePricing(res);
            }
            setLoadingPrice(false);
        });
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (_.isEmpty(bundlePricing)) {
            setDetails({});
        } else {
            const data = {
                'Name': bundlePricing.name,
                'Description': bundlePricing.description,
                'Currency': bundlePricing.currency,
                'Term': formatTerm(bundlePricing.term),
            };
            setDetails(data)
        }
    }, [bundlePricing]);

    const onModalClose = () => {
        setShowAddOrEditBundlePricingModal(false);
        fetchData(true);
    }

    const editPricing = () => {
        setShowAddOrEditBundlePricingModal(true);
    }

    const onArchiveProductPricing = (pricing) => {
        serverPatch(getApiUrl(`/bundle_pricings/${pricing.id}`), { state: "ARCHIVED" }).then((res) => {
            if (res) {
                fetchData(true);
            }
        })
    }

    const renderStatus = () => {
        if (bundlePricing.state === "ACTIVE") {
            return <Label.Success>Active</Label.Success>
        } else if (bundlePricing.state === "ARCHIVED") {
            return <Label.Danger>Archived</Label.Danger>
        }
    }

    const editMetadata = () => {
        setIsEditingMetadata(true);
    }

    const saveMetadata = (data) => {
        const processedData = {
            metadata: data
        };

        serverPatch(getApiUrl(`/bundle_pricings/${bundlePricingId}`), processedData).then((res) => {
            if (res) {
                fetchData(true);
            }
            setIsEditingMetadata(false);
        });
    }

    let product_pricing_ids = [];
    if (!_.isEmpty(bundlePricing.bundle_product_pricings)) {
        product_pricing_ids = _.map(bundlePricing.bundle_product_pricings, bpp => {
            return bpp.product_pricing_id
        })
    }
    return (
        <BaseContainer>
            <Loader loading={loadingPrice}>
            {
                _.isEmpty(bundlePricing) ?
                    <ErrorComponent
                        title={"Bundle Not Found"}
                        description={"Oops, we can't find the bundle you are looking for."}
                        primaryAction={{ href:getCompanySpecificUrl("/bundles"), label:"Show all broducts" }}
                        hideSupport
                    />
                : <>
                    <ContentContainer title={
                        <div className="d-flex flex-row gap-3">
                            <span>{ bundlePricing.name }</span>
                            <div className="flex items-center">{ renderStatus() }</div>
                        </div>
                    } actions={[
                        {variant: "text-primary", icon: "fa-edit", label: t('common.edit'), onClick: editPricing}
                    ]} customActions={<ArchiveButton size="md" onDelete={() => onArchiveProductPricing(bundlePricing)}></ArchiveButton>}>
                        <Section title="Details" actions={[]}>
                            <KeyValueDisplay2 items={details} />
                        </Section>
                        <Section title="Metadata" className="mt-4" actions={[
                            {variant: "text-primary", icon: "fa-edit", label: "Edit", onClick: editMetadata}
                        ]} subtitle="These are fields that are stored on the bundle pricing for internal reference.">
                            {
                                (!isEditingMetadata && (_.isNil(bundlePricing.metadata) || _.isEmpty(bundlePricing.metadata))) ?
                                    <p className="text-gray-500">No metadata</p>
                                    : <KeyValueDisplay items={bundlePricing.metadata} isEditing={isEditingMetadata}
                                                       onUpdateKeyValues={saveMetadata}
                                                       onCancelEditing={() => setIsEditingMetadata(false)}/>
                            }
                        </Section>
                        <Section title="Bundle Details" className="mt-4">
                            <BundlePricingTable product_pricing_ids={product_pricing_ids} showProductMetricPricingID />
                        </Section>
                    </ContentContainer>
                    <AddOrEditBundlePricingModal show={showAddOrEditBundlePricingModal} onClose={onModalClose} itemToEdit={bundlePricing} />
                </>
            }
            </Loader>
        </BaseContainer>
    );
}

export default BundlePricing;
