import '../../App.scss';
import '../../css/modals.scss';
import '../../css/forms.scss';
import React, {useContext, useEffect, useState} from 'react';
import BaseForm from '../BaseForm';
import BaseSideModal from './BaseSideModal';
import SubmitButton from '../SubmitButton';
import Notification from '../Notification';
import {BaseContext, renderAddress} from '../../helpers/common';
import {serverPatch, serverPost} from '../../helpers/server';
import {Row, Col, Button} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {ChevronDoubleDownIcon} from "@heroicons/react/24/outline";
const _ = require('lodash');

function CustomerAddressValidationModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [customer, setCustomer] = useState(null);

    useEffect(() => {
        setCustomer(props.customer)
    }, [props.customer]);

    const updateCustomer = async (data, errorHandler) => {
        serverPatch(getApiUrl(`/customers/${props.customer.id}`), data, {}, errorHandler).then(res => {
            if (res) {
                props.onClose();
                Notification.Success("Address Updated Successfully");
            }
        });
    }

    if (!customer) {
        return null;
    }

    return (
        <BaseSideModal {...props}>
            <BaseSideModal.Form onSubmit={updateCustomer}>
                <BaseSideModal.Header title={"Validate Address"}/>
                <BaseSideModal.Body>
                    <div className="flex flex-col gap-3">
                        <div>
                            <span className="text-sm font-semibold ">Existing Address</span>
                            <div className="mt-2 text-sm rounded-md shadow-md ring-1 ring-black ring-opacity-5 p-3">
                                { renderAddress(customer.address) }
                            </div>
                        </div>
                        <div className="align-self-center">
                            <ChevronDoubleDownIcon className="h-8 w-8 text-gray-500"/>
                        </div>
                        <div>
                            <span className="text-sm font-semibold">Suggested Address</span>
                            <div className="mt-2 text-sm rounded-md shadow-md ring-1 ring-black ring-opacity-5 p-3">
                                { renderAddress(customer.address) }
                            </div>
                        </div>
                    </div>
                </BaseSideModal.Body>
                <BaseSideModal.Footer>
                    <div className="flex flex-row gap-1">
                        <Button variant="text" onClick={() => props.onClose()}>Keep existing</Button>
                        <SubmitButton variant="primary">Use Suggested</SubmitButton>
                    </div>
                </BaseSideModal.Footer>
            </BaseSideModal.Form>
        </BaseSideModal>
    );

}

export default CustomerAddressValidationModal;
