import React, {useEffect, useState} from 'react';
import '../../App.scss';
import '../../css/table.scss';
import '../../css/forms.scss';
import { serverPost, getUrl } from '../../helpers/server';
import SuperContainer from '../../components/SuperContainer';
import MapleTable from "../../components/MapleTable";
import moment from 'moment';
import SortableTableHeader from "../../components/SortableTableHeader";
import DeleteButton from "../../components/DeleteButton";
import BaseForm from "../../components/BaseForm";
import SubmitButton from "../../components/SubmitButton";
import {Row} from "react-bootstrap";
import MapleTableHeaderWithActions from "../../components/MapleTableHeaderWithActions";
const _ = require('lodash');

function ScheduledTasks(props) {
    const [sort, setSort] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState("cleanup_queue");
    const [tasks, setTasks] = useState([]);
    const [params, setParams] = useState({});
    const [filteredTasks, setFilteredTasks] = useState([]);

    const getTasks = (data) => {
        if (props.type === "SCHEDULED") {
            serverPost(getUrl("/super_user/scheduled_tasks"), data).then((res) => {
                if (res) {
                    // do something
                    setTasks(res);
                }
            });
        } else if (props.type === "PENDING") {
            const taskData = {
                queue: data.queue || selectedQueue
            }
            setSelectedQueue(taskData.queue);
            serverPost(getUrl("/super_user/pending_tasks"), taskData).then((res) => {
                if (res) {
                    // do something
                    setTasks(res);
                }
            });
        }
    }

    const renderResponse = (res) => {
        const response = res ? JSON.stringify(res): "";
        return <div className="flex flex-col items-start max-w-md">
            <div className="flex flex-col gap-1 text-xs text-gray-500 max-w-md">
                <code className="max-w-md">
                    { response.slice(0, 300) }
                    {
                        response.length > 300 && <span>...</span>
                    }
                </code>
            </div>
        </div>
    }

    const purgeTask = (task) => {
        const purgeData = {
            uuid: task.UUID,
            type: props.type
        }
        serverPost(getUrl("/super_user/purge_task"), purgeData).then((res) => {
            // do something
            getTasks({})
        });
    }

    useEffect(() => {
        getTasks({})
    }, []);

    useEffect(() => {
        let ftasks = _.filter(tasks, (task) => {
            if (_.isEmpty(params) || !params.search) {
                // nothing to filter.
                return true;
            }
            const search = params.search;
            if (task.UUID.includes(search) || task.ETA.includes(search) || task.Name.includes(search) || task.RoutingKey.includes(search)) {
                return true;
            }
            const argMatches = _.filter(task.Args, (a) => {
                return String(a.Value).includes(search);
            })
            if (argMatches.length > 0) {
                return true;
            }

            return false;
        });
        if (sort === "timeAsc") {
            ftasks = _.orderBy(ftasks, 'ETA')
        } else if (sort === "timeDesc") {
            ftasks = _.orderBy(ftasks, 'ETA', 'desc')
        }
        setFilteredTasks(ftasks);
    }, [tasks, sort, params]);

    return (
        <SuperContainer>
            {props.type === "PENDING" &&
            <BaseForm onSubmit={getTasks}>
                <h3 className="mb-4">Pending Tasks</h3>
                <Row>
                <BaseForm.Input colSpan="4" label="Queue Name" type="text" name="queue" placeholder="subscription_queue"/>
                </Row>
                <SubmitButton className="mb-4">List Tasks</SubmitButton>
            </BaseForm>
            }
            {props.type === "SCHEDULED" &&
                <h3 className="mb-4">Scheduled Tasks</h3>
            }
            <MapleTable>
                <MapleTableHeaderWithActions
                    showSearch={true}
                    searchPlaceholder="Search tasks"
                    showFilters={false}
                    showExport={false}
                    meta={{ total: filteredTasks.length }}
                    onParamsChange={setParams}
                />
                <MapleTable.Content>
                    <thead>
                        <tr>
                            <MapleTable.TH>UUID</MapleTable.TH>
                            <SortableTableHeader
                                onSortChange={setSort} sortKeyUp="timeAsc" sortKeyDown="timeDesc"
                                currentSort={sort}>ETA</SortableTableHeader>
                            <MapleTable.TH>Name</MapleTable.TH>
                            <MapleTable.TH>Args</MapleTable.TH>
                            <MapleTable.TH></MapleTable.TH>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {
                        _.map(filteredTasks, (task, i) =>
                            <tr key={i}>
                                <td>{ task.UUID }</td>
                                <td>{ task.ETA? moment(task.ETA).format(): "" }</td>
                                <td>
                                    <span className="text-sm font-semibold">{ task.Name }</span><br/>
                                    <span className="text-sm font-normal text-gray-400">{ task.RoutingKey }</span>
                                </td>
                                <td>{ renderResponse(task.Args) }</td>
                                <td className="w-px whitespace-nowrap">
                                    <DeleteButton
                                        title="Delete Task?"
                                        body="Are you sure you want to remove the task?"
                                        onDelete={() => purgeTask(task)}/>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </MapleTable.Content>
            </MapleTable>
        </SuperContainer>
    );
}

export default ScheduledTasks;
