import '../App.scss';
import React, { useState } from 'react';
import { getUrl, serverPost } from '../helpers/server';
import BaseForm from '../components/BaseForm';
import BaseContainerWithoutNav from '../components/BaseContainerWithoutNav';
import { Button, Row, Col, Container, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
    const { t } = useTranslation('common');
    const [succeeded, setSucceeded] = useState(false);
    const [forgotEmail, setForgotEmail] = useState("");

    const onForgotPassword = (data) => {
        setSucceeded(false);
        setForgotEmail(data.email);
        serverPost(getUrl('/users/forgot_password'), data).then((res) => {
            setSucceeded(true);
        });
    }

    return (
        <BaseContainerWithoutNav>
            <Container>
                <Row>
                    <Col md={{span: 6, offset:3}}>
                        <h3>Forgot Password</h3>
                        <p className="body1">Enter your email address to reset your password. You may need to check your
                            spam folder or unblock no-reply@maplebilling.com.</p>
                        <br/>
                        <BaseForm onSubmit={onForgotPassword}>
                            <BaseForm.Input type="text" name="email" label={t('common.email')} placeholder={t('common.email')}
                                            transformations={["lowercase", "trim"]} validations={{ required: true, validEmail: true }} />
                            <Button type="submit">{t('auth.forgot_password')}</Button>
                        </BaseForm>
                        <br/>
                        {
                            succeeded &&
                                <Alert variant="success">If a Maple account exists for {forgotEmail},
                                    an e-mail will be sent with further instructions.</Alert>
                        }
                    </Col>
                </Row>
            </Container>
        </BaseContainerWithoutNav>
    );
}

export default ForgotPassword;
