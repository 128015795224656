import '../../App.scss';
import React, {useContext, useEffect, useState} from 'react';
import {BaseContext} from '../../helpers/common';
import BaseContainer from '../../components/BaseContainer';
import ContentContainer from '../../components/ContentContainer';
import {useSearchParams} from "react-router-dom";
import CreditNoteListSection from "../../components/CreditNoteListSection";

function CreditNotes() {
    const { setPageTitle, getApiUrl } = useContext(BaseContext);
    const [ searchParams ] = useSearchParams();
    let customer_id = searchParams.get('customer_id');
    const [disableInitialFilters, setDisableInitialFilters] = useState(customer_id != null);
    const [extraQueryFilters, setExtraQueryFilters] = useState({
        customer_id: customer_id || null,
    });

    useEffect(() => {
        setPageTitle(`Credit Notes`);
    }, []);

    useEffect(() => {
        setDisableInitialFilters(customer_id != null);
        setExtraQueryFilters({
            customer_id: customer_id || null,
        })
    }, [customer_id]);

    return (
        <BaseContainer>
            <ContentContainer>
                <CreditNoteListSection
                    showTableActions={true} showBulkSelection={true}
                    disableInitialFilters={disableInitialFilters}
                    extraQueryFilters={extraQueryFilters}
                />
            </ContentContainer>
        </BaseContainer>
    );
}

export default CreditNotes;
