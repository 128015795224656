import { useContext, useRef, useState } from "react";
import { BaseContext } from "../../common";
import { serverFetch } from "../../server";

function useGetSubscribtionApi({ subscriptionId, options }) {
    const { getApiUrl } = useContext(BaseContext);

    const [subscription, setSubscription] = useState(undefined);
    const [isLoading, setLoading] = useState(false);

    const currentSearchRef = useRef(null);

    const onSearch = () => {
        setLoading(true);

        const randomKey = Math.floor(Math.random() * 100000);
        currentSearchRef.current = randomKey;

        serverFetch(
            getApiUrl(`/subscriptions/${subscriptionId}`),
            options
        ).then((res) => {
            if (randomKey !== currentSearchRef.current) {
                // Got old results
                return;
            }

            setLoading(false);

            if (res) {
                setSubscription(res);
            }
        });
    };

    return {
        subscription,
        isSubscriptionLoading: isLoading,
        onSubscriptionSearch: onSearch,
    };
}

export default useGetSubscribtionApi;
